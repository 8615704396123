import {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'

const DEFAULT_CENTER = { lat: 39.8283, lng: -98.5795 }
const DEFAULT_ZOOM = 10

const LoadMap = ({ dropOff, pickUp, deliveryDate }) => {
    const ref = useRef(null)
    const [routes, setRoutes] = useState([])
    const [routeIndex, setRouteIndex] = useState(0)
    const [directionsRenderer, setDirectionsRenderer] = useState()
    const [visible, setVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const findRoutesAsync = useCallback(async (directionsRenderer, directionsService, request) => {
        return new Promise((resolve, reject) => {
            directionsService.route(request, function (result, status) {
                if (status == 'OK') {
                    directionsRenderer.setDirections(result)
                    resolve(result.routes)
                } else {
                    reject(new Error(`Error assigning directions. Status: ${status}`))
                }
            })
        })
    }, [ref, directionsRenderer])

    useEffect(() => {
        if (!ref.current) return

        const map = new window.google.maps.Map(ref.current, {
            center: DEFAULT_CENTER,
            zoom: DEFAULT_ZOOM,
        })

        const DirectionsService = new window.google.maps.DirectionsService()
        const DirectionsRenderer = new window.google.maps.DirectionsRenderer({
            map
        })

        DirectionsRenderer.setMap(map)

        setDirectionsRenderer(DirectionsRenderer)

        const findRoute = async () => {
            try {
                setIsLoading(true)
                const routes = await findRoutesAsync(DirectionsRenderer, DirectionsService, {
                    origin: pickUp,
                    destination: dropOff,
                    travelMode: 'DRIVING',
                    drivingOptions: {
                        departureTime: new Date(deliveryDate),
                        trafficModel: 'pessimistic'
                    }
                })
                setRoutes(routes)
            } catch (err) {
                console.log(err)
                setIsLoading(false)
            } finally {
                setIsLoading(false)
            }
        }

        findRoute()
    }, [ref, visible])

    useEffect(() => {
        if (!ref.current || !directionsRenderer) return
        directionsRenderer.setRouteIndex(routeIndex)
    }, [routeIndex])

    const selectedRoute = routes[routeIndex]
    const distance = selectedRoute?.legs[0]?.distance.text
    const duration = selectedRoute?.legs[0]?.duration.text


    return (
        <div className="flex w-full flex-col">
            <div
                className="mb-2 cursor-pointer underline hover:text-primary-500"
                onClick={() => setVisible(!visible)}
            >
                {visible ? 'Hide' : 'Show'} Map
            </div>
            {visible && (
                <div className='relative w-[100%] h-96'>
                    <div
                        ref={ref}
                        className='absolute w-full h-full'
                    />
                    {
                        isLoading ? (
                            <div className='absolute bottom-5 left-5 p-5 bg-slate-500 bg-opacity-70 z-50'>
                                <p className='text-gray-100 text-sm'>Calculating route...</p>
                            </div>
                        ) : selectedRoute ? (
                            <div className='absolute bottom-5 left-5 p-5 bg-slate-500 bg-opacity-70 z-50'>
                                <h4 className='font-bold mb-1'>{selectedRoute?.summary}</h4>
                                <p className='text-gray-100 text-sm'>{`From ${selectedRoute?.legs[0]?.start_address}`}</p>
                                <p className='text-gray-100 text-sm'>{`To ${selectedRoute?.legs[0]?.end_address}`}</p>
                                <p className='text-gray-100 text-sm'>{distance}</p>
                                <p className='text-gray-100 text-sm'>{duration}</p>
                                <h5 className='font-bold mt-3 mb-1'>Other Routes</h5>
                                <ul className='list-disc pl-4 mb-2'>
                                    {routes.map((route, index) => (
                                        <li key={route.summary} className='cursor-pointer'>
                                            <button
                                                className='text-orange-400 text-sm'
                                                onClick={() => setRouteIndex(index)}>
                                                {route.summary}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ) : (
                            <div className='absolute bottom-5 left-5 p-5 bg-slate-500 bg-opacity-70 z-50'>
                                <p className='text-gray-100 text-sm'>No route was found.</p>
                            </div>
                        )
                    }
                </div>
            )}
        </div>
    )
}

export default LoadMap
