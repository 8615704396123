import { GoDotFill } from 'react-icons/go'

const Status = ({ color, statusName }) => {
  if (statusName === 'Created') {
    color = 'lime'
  } else if (statusName === 'Awaiting Customer') {
    color = 'yellow'
  } else if (statusName === 'Pending') {
    color = 'yellow'
  } else if (statusName === 'On Hold') {
    color = 'yellow'
  } else if (statusName === 'Approved') {
    color = 'green'
  } else if (statusName === 'Assigning Carrier') {
    color = 'sky'
  } else if (statusName === 'Ready For Pickup') {
    color = 'violet'
  } else if (statusName === 'In Transit') {
    color = 'blue'
  } else if (statusName === 'Delivered') {
    color = 'green'
  } else if (statusName === 'Completed') {
    color = 'green'
  } else if (statusName === 'Awaiting Dispatcher') {
    color = 'primary'
  } else if (statusName === 'Accepted') {
    color = 'green'
  } else if (statusName === 'Awaiting Carrier') {
    color = 'yellow'
  } else if (statusName === 'Canceled') {
    color = 'red'
  } else if (statusName === 'Declined') {
    color = 'red'
  } else if (statusName === 'Rejected') {
    color = 'red'
  } else if (statusName === 'pdf') {
    color = 'primary'
  } else if (statusName === 'Denied') {
    color = 'red'
  } else if (statusName === 'open') {
    color = 'green'
  } else if (statusName === 'closed') {
    color = 'red'
  }
  else if (statusName === 'Paid') {
    color = 'green'
  }
   else if (statusName === 'Unpaid') {
    color = 'red'
  }
  
  return (
    <span
      className={` border-${color}-600 w-fit 
       whitespace-nowrap bg-${color}-100  text-${color}-600
        leading-1 flex h-fit items-center gap-1  
         border px-3 py-0.5 text-xs font-medium  `}
    >
      <GoDotFill className=" my-auto " />
      {statusName}
    </span>
  )
}
export default Status
