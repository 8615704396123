import FModal from '../../../components/FModal'
import FFormWrapper from '../../../components/FFormWrapper'
import FLabel from '../../../components/FLabel'
import { useParams } from 'react-router-dom'
import FButton from '../../../components/FButton'
import FIconWrapper from '../../../components/FIconWrapper'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { useFormik } from 'formik'
import { axiosInstance } from '../../../axios/requister'
import React, { useState } from 'react'
import * as Yup from 'yup'

import ESpinner from '../../../components/ESpinner'
import { toast } from 'react-toastify'
import FInputField from '../../../components/FInputField'
import FFormInvalidInput from '../../../components/FFormInvalidInput'

const AddDeclaredValueModal = ({
  isDeclaredValueModalOpen,
  setIsDeclaredValueModalOpen,
  callback,
}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [serverError, setServerError] = useState('')
  const { id } = useParams()
  const Formik = useFormik({
    initialValues: {
      declared_value: '',
    },
    validationSchema: Yup.object({
      declared_value: Yup.number().required('Declared value is required'),
    }),

    onSubmit: () => {
      setServerError('')
      setIsLoaded(true)
      axiosInstance
        .patch(`/shipment/load/${id}/`, {
          declared_value: Formik.values.declared_value,
        })
        .then(() => {
          toast.success('Declared value added successfully')
          setIsLoaded(false)
          setIsDeclaredValueModalOpen(false)
          callback()
          Formik.resetForm(true)
        })
        .catch((error) => {
          setServerError('Error adding declared value')
          const messages = Object.values(error.response?.data || {}).map(
            (data) => data.details
          )
          toast.error(
            messages.length !== 0 ? messages.join(', ') : 'Error adding declared value'
          )
          setIsLoaded(false)
        })
    },
  })

  return (
    <>
      <FModal
        callbackFunction={() => {
          Formik.resetForm()
          setServerError('')
        }}
        title={'Declare Value'}
        isOpen={isDeclaredValueModalOpen}
        setIsOpen={() => {
          Formik.resetForm()
          setIsDeclaredValueModalOpen(false)
          setServerError('')
        }}
      >
        <form onSubmit={Formik.handleSubmit}>
          <FFormWrapper className={'w-12/12'}>
            <FLabel>Add Declared Value</FLabel>
            <FInputField
              name={'declared_value'}
              type={'number'}
              placeholder={'Enter declared value in $'}
              value={Formik.values.declared_value}
              onChange={Formik.handleChange}
              onBlur={Formik.handleBlur}
            />
            <FFormInvalidInput
              touched={Formik.touched}
              FieldName={'declared_value'}
              errors={Formik.errors}
            />

            {serverError !== '' && (
              <small className={'text-left font-medium text-red-700'}>
                {serverError}
              </small>
            )}
          </FFormWrapper>

          <div className={'flex w-full flex-row-reverse gap-1'}>
            <FButton type={'submit'}>
              <FIconWrapper>
                <AiOutlinePlusCircle size={20} />
                Add Declared Value
                <ESpinner isVisible={isLoaded} />
              </FIconWrapper>
            </FButton>
            <FButton
              btnType={'secondary'}
              onClick={() => {
                Formik.resetForm()
                setIsDeclaredValueModalOpen(false)
                setServerError('')
              }}
            >
              <FIconWrapper>Cancel</FIconWrapper>
            </FButton>
          </div>
        </form>
      </FModal>
    </>
  )
}
export default AddDeclaredValueModal
