// import React, { useState } from 'react'
import FCard from '../../../components/FCard'
// import FAvatar from '../../../components/FAvatar'
import { BsArrowLeftRight } from 'react-icons/bs'
import { axiosInstance } from '../../../axios/requister'
import { toast } from 'react-toastify'
import CounterModal from './CounterModal'
import { useState } from 'react'
import ESpinner from '../../../components/ESpinner'

const OfferCard = ({ name1, name2, price, BidId, startBidInfo, Refetch, title }) => {
  const [isCounterModalOpen, setIsCounterModalOpen] = useState(false)
  const [isLoadedAccepted, setIsLoadedAccepted] = useState(false)
  const [isLoadedRejected, setIsLoadedRejected] = useState(false)

  const handleAccept = () => {
    setIsLoadedAccepted(true)
    axiosInstance
      .put(`/shipment/offer/${BidId}/`, {
        action: 'accept',
      })
      .then(() => {
        setIsLoadedAccepted(false)
        toast.success('Offer accepted successfully')
        Refetch()
        startBidInfo.Refetch()
      })
      .catch((err) => {
        setIsLoadedAccepted(false)
        toast.error(
          err.response.data.details ? err.response.data.details : 'Offer accepted failed'
        )
      })
  }
  const handleReject = () => {
    setIsLoadedRejected(true)
    axiosInstance
      .put(`/shipment/offer/${BidId}/`, {
        action: 'reject',
      })
      .then(() => {
        setIsLoadedRejected(false)
        toast.success('Offer rejected ')
        Refetch()
        startBidInfo.Refetch()
      })
      .catch(() => {
        setIsLoadedRejected(false)
        toast.error('Offer rejected field')
      })
  }

  return (
    <>
      <CounterModal
        startBidInfo={startBidInfo}
        setIsCounterModalOpen={setIsCounterModalOpen}
        isCounterModalOpen={isCounterModalOpen}
        Refetch={Refetch}
        BidId={BidId}
      />
      <div className={`m-2 mt-6`}>
        <FCard>
          <FCard.Header>
            <span className="text-lg font-semibold text-gray-800">{title}</span>
          </FCard.Header>
          <FCard.Body className="justify-between lg:flex lg:flex-row">
            <div>
              <div className="mt-2 flex ">
                <div className="gap-5 lg:flex">
                  <div className="mb-3 flex gap-5 lg:mb-0">
                    <span className="my-auto text-lg font-semibold text-slate-700">
                      {' '}
                      {name1}
                    </span>
                    <BsArrowLeftRight size={25} className="my-auto  text-slate-600  " />

                    <span className="my-auto text-lg font-semibold text-slate-700">
                      {' '}
                      {name2}
                    </span>
                  </div>
                  <div className="flex gap-5 lg:ml-20 lg:flex-col lg:gap-0 ">
                    <span className="text-base font-normal text-slate-600">
                      OFFERED PRICE
                    </span>
                    <span className="text-xl font-semibold text-primary-500">
                      $ {price}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="my-2 flex ">
                <button
                  className="mr-6  flex  gap-2 bg-green-600 py-2 px-4 text-white hover:bg-green-700"
                  onClick={() => handleAccept()}
                >
                  Accept
                  <ESpinner isVisible={isLoadedAccepted} />
                </button>
                <button
                  className="mr-6  bg-yellow-500 py-2 px-4 text-white hover:bg-yellow-600 "
                  onClick={() => setIsCounterModalOpen(true)}
                >
                  Counter
                </button>
                <button
                  className=" flex  gap-2  bg-red-600 py-2 px-4 text-white hover:bg-red-700"
                  onClick={() => handleReject()}
                >
                  Reject
                  <ESpinner isVisible={isLoadedRejected} />
                </button>
              </div>
            </div>
          </FCard.Body>
        </FCard>
      </div>
    </>
  )
}

export default OfferCard
