import React from 'react'
import FInputField from '../../../components/FInputField'
import FLabel from '../../../components/FLabel'
import PhoneInput from 'react-phone-number-input'

import FFormInvalidInput from '../../../components/FFormInvalidInput'

const CreateIndividualBillingForm = ({Formik}) => {
  // Component logic goes here
 
  return (
    // JSX markup goes here
    <div>
      <div className=' grid grid-cols-2 gap-4 '>
      <div className="mt-5  ">
        <FLabel htmlFor="name_to_print_on_checks">Name to Print on Check</FLabel>
        <FInputField
          name="name_to_print_on_checks"
          id="name_to_print_on_checks"
          value={Formik.values.name_to_print_on_checks}
            onChange={Formik.handleChange}
          type="text"
          placeholder="Enter your name which will be printed on check"
            onBlur={Formik.handleBlur}
        />
        <div className="flex">
          <FFormInvalidInput
            touched={Formik.touched}
            FieldName={'name_to_print_on_checks'}
            errors={Formik.errors}
          />
        </div>
      </div>
      <div className="mt-5 ">
        <FLabel htmlFor={'email'}>Billing-Email</FLabel>
        <FInputField
          id={'email'}
          name="email"
          placeholder="Enter your billing email address"
          type="email"
          value={Formik.values.email}
          onChange={Formik.handleChange}
          onBlur={Formik.handleBlur}
        />
        <FFormInvalidInput
            touched={Formik.touched}
            FieldName="email"
            errors={Formik.errors}
          />
      </div>
      </div>

     
      <div className=' grid grid-cols-2 gap-4 '>
      
      <div className=" mb-0 mt-5 ">
      <FLabel>Billing Phone number</FLabel>
        <PhoneInput
          name="phone_number"
          className={'input-phone-number'}
          placeholder="Enter phone number"
          defaultCountry="US"
          countries={['US', 'MX', 'CA']}
          value={Formik.values.phone_number}
          onChange={(value, country) => {
            const countryCode = country ? `+${country.countryCallingCode}` : ''
            const formattedValue = countryCode
              ? `${countryCode} ${value}`
              : value
            Formik.setFieldValue('phone_number', formattedValue)
            // setErrorPhone([])
          }}
          onBlur={Formik.handleBlur}
        />
        <FFormInvalidInput
            touched={Formik.touched}
            FieldName="phone_number"
            errors={Formik.errors}
          />
      </div>

      <div className="mt-5">
        <FLabel htmlFor="address">Billing Address</FLabel>
        <FInputField
          name="address"
          id="address"
            value={Formik.values.address}
            onChange={Formik.handleChange}
          type="text"
          placeholder="Enter your address"
            onBlur={Formik.handleBlur}
        />
        <div className="flex">
          <FFormInvalidInput
            touched={Formik.touched}
            FieldName={'address'}
            errors={Formik.errors}
          />
        </div>
      </div>
      </div>
      <div className="mt-5 grid grid-cols-2 gap-4">
        <div className="">
          <FLabel htmlFor="city">City</FLabel>
          <FInputField
            name="city"
            id="city"
            value={Formik.values.city}
            onChange={Formik.handleChange}
            type="text"
            placeholder="Enter your city"
            onBlur={Formik.handleBlur}
          />
          <div className="flex">
            <FFormInvalidInput
              touched={Formik.touched}
              FieldName={'city'}
              errors={Formik.errors}
            />
          </div>
        </div>
        <div className="">
          <FLabel htmlFor="state">State</FLabel>
          <FInputField
            name="state"
            id="state"
            value={Formik.values.state}
            onChange={Formik.handleChange}
            type="text"
            placeholder="Enter your state"
            onBlur={Formik.handleBlur}
          />
          <div className="flex">
            <FFormInvalidInput
              touched={Formik.touched}
              FieldName={'state'}
              errors={Formik.errors}
            />
          </div>
        </div>
      </div>
      <div className="mt-5 grid grid-cols-2 gap-4">
        <div className="">
          <FLabel htmlFor="country">Country</FLabel>
          <FInputField
            name="country"
            id="country"
            value={Formik.values.country}
            onChange={Formik.handleChange}
            type="text"
            placeholder="Enter your country"
            onBlur={Formik.handleBlur}
          />
          <div className="flex">
            <FFormInvalidInput
              touched={Formik.touched}
              FieldName={'country'}
              errors={Formik.errors}
            />
          </div>
        </div>
        <div className="">
          <FLabel htmlFor="zip_code">Zip Code</FLabel>
          <FInputField
            name="zip_code"
            id="zip_code"
            value={Formik.values.zip_code}
            onChange={Formik.handleChange}
            type="text"
            placeholder="Enter your zip code"
            onBlur={Formik.handleBlur}
          />
          <div className="flex">
            <FFormInvalidInput
              touched={Formik.touched}
              FieldName={'zip_code'}
              errors={Formik.errors}
            />
          </div>
        </div>
        <div className="">
          <FLabel htmlFor="fax_number">
            Fax Number <span className="text-gray-500">(Optional)</span>
          </FLabel>
          <FInputField
            name="fax_number"
            id="fax_number"
            value={Formik.values.fax_number}
            onChange={Formik.handleChange}
            type="text"
            placeholder="Enter your fax number"
            onBlur={Formik.handleBlur}
          />
          <div className="flex">
            <FFormInvalidInput
              touched={Formik.touched}
              FieldName={'fax_number'}
              errors={Formik.errors}
            />
          </div>
         
        </div>
        <div className="">
          <FLabel htmlFor="website">
            Website <span className="text-gray-500">(Optional)</span>
          </FLabel>
          <FInputField
            name="website"
            id=" website"
            value={Formik.values.website}
            onChange={Formik.handleChange}
            type=" text"
            placeholder="Enter your website"
            onBlur={Formik.handleBlur}
          />
          <div className="flex">
            <FFormInvalidInput
              touched={Formik.touched}
              FieldName={'website'}
              errors={Formik.errors}
            />
          </div>
          
        </div>
      </div>
    </div>
  )
}

export default CreateIndividualBillingForm
