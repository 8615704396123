import { Link, useNavigate } from 'react-router-dom'
import FButton from '../../components/FButton'
import FIconWrapper from '../../components/FIconWrapper'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import FCustomDataTable from '../../components/FCustomDataTable'
import { useState, useContext } from 'react'
import ESpinner from '../../components/ESpinner'
import { UserContext } from '../../components/UserContext'
import { useQuery } from '@tanstack/react-query'
import { axiosInstance } from '../../axios/requister'
import { useEffect } from 'react'
import { MdOutlineEdit } from 'react-icons/md'

const ListFacilities = () => {
  const [page, setPage] = useState(1)
  const [filter, setFilter] = useState([])
  const { user } = useContext(UserContext)
  const navigate = useNavigate()
  const [filterOptions, setFilterOptions] = useState([
    {
      id: 0,
      value: '',
      key: '',
    },
  ])
  const { data, status } = useQuery(
    ['facility', page],
    async () => {
      if (user.selected_role === 'manager') {
        const response = await axiosInstance.get(`manager/facility/?page=${page}`)
        return response.data
      } else {
        const response = await axiosInstance.get(`shipment/facility/?page=${page}`)
        return response.data
      }
    },
    {
      keepPreviousData: true,
      retry: false,
      enabled: page > 0,
    }
  )
  // useEffect(() => {
  //   refetch()
  // }, [page, refetch])

  useEffect(() => {
    if (user.selected_role === 'carrier') {
      navigate('/dashboard')
    }
  }, [user.selected_role])

  const FacilitiesHeader = () => {
    return (
      <div
        className={
          'sticky top-0 z-10 flex w-full justify-between border-b bg-white p-3 text-left text-xl font-semibold text-gray-700'
        }
      >
        <span className={''}>Facilities</span>
        {user.selected_role === 'shipment party' && (
          <Link to={'/facilities/create'}>
            <FButton>
              <FIconWrapper>
                <AiOutlinePlusCircle size={20} />
                <span className={'text-sm font-medium'}>Create Facility</span>
              </FIconWrapper>
            </FButton>
          </Link>
        )}
      </div>
    )
  }

  const addressCell = (row) => {
    return <span className={'text-sm font-semibold'}>{row.address}</span>
  }
  const buildingNameCell = (row) => {
    return <span className={'text-sm font-semibold'}>{row}</span>
  }
  const cityCell = (row) => {
    return <span className={'text-sm font-semibold'}>{row.city}</span>
  }
  const stateCell = (row) => {
    return <span className={'text-sm font-semibold'}>{row.state}</span>
  }
  const zipCodeCell = (row) => {
    return <span className={'text-sm font-semibold'}>{row.zip_code}</span>
  }
  const cols = [
    {
      Header: 'Address',
      accessor: 'address',
      cell: addressCell, // String-based value accessors!
    },
    {
      Header: 'Building name',
      accessor: 'building_name', // String-based value accessors!
      cell: buildingNameCell,
    },
    {
      Header: 'City',
      accessor: 'address', // String-based value accessors!
      cell: cityCell,
    },
    {
      Header: 'State',
      accessor: 'address', // String-based value accessors!
      cell: stateCell,
    },
    {
      Header: 'Zip code',
      accessor: 'address', // String-based value accessors!
      cell: zipCodeCell,
    },
  ]

  // Conditionally add the "Edit" column based on user role
  if (user.selected_role !== 'manager') {
    cols.push({
      Header: 'Edit',
      accessor: 'id',
      cell: (row) => {
        return (
          <FIconWrapper>
            <Link to={`/facilities/edit/${row}`}>
              <MdOutlineEdit
                className="cursor-pointer hover:text-primary-500 "
                size={20}
              />
            </Link>
          </FIconWrapper>
        )
      },
    })
  }

  if (status === 'loading') {
    return (
      <div>
        <FacilitiesHeader />
        <div className={'mt-60  flex w-full items-center justify-center '}>
          <div className={'f-col-center-center'}>
            <ESpinner isVisible={true} size={30} />
            <span className="mt-5">Loading...</span>
          </div>
        </div>
      </div>
    )
  } else if (data?.results?.length === 0 && status === 'success') {
    return (
      <>
        <FacilitiesHeader />

        <div className={' flex  flex-col items-center justify-center   py-40'}>
          <img
            src="https://storage.googleapis.com/freight_static_files/empty-state.svg"
            alt={'empty_state'}
            width={200}
            className={''}
          />
          <span className={'mt-5 font-semibold text-gray-700'}>No Data Found</span>
          {user.selected_role === 'manager' ? (
            <span className={'mt-1 text-center text-sm text-gray-600 '}>
              Sorry, there are currently no facilities available in your organization.
            </span>
          ) : (
            <span className={'mt-1 text-center text-sm text-gray-600 '}>
              Sorry, no facilities found. you can create one yourself!
            </span>
          )}
        </div>
      </>
    )
  } else {
    return (
      <>
        <FacilitiesHeader />

        <FCustomDataTable
          cols={cols}
          data={data?.results ?? []}
          isLoading={status === 'loading'}
          page={page}
          setPage={setPage}
          size={2}
          setFilter={setFilter}
          filter={filter}
          totalNumberOfPages={Math.ceil(data?.count / 10)}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          // Refetch={refetch}
        />
      </>
    )
  }
}
export default ListFacilities
