import { useState } from 'react'
import { HiOutlineCheckCircle, HiOutlineX } from 'react-icons/hi'
function MessageCard(props) {
  const [isMessageVisible, setIsMessageVisible] = useState(true)
  const handleClick = () => {
    setIsMessageVisible(false)
  }
  return (
    <>
      {isMessageVisible && (
        <div
          className={`flex flex-row items-start justify-between p-5 text-sm font-semibold leading-5 m-3${
            props.type === 'success'
              ? ' border border-green-600 bg-green-100 text-green-600'
              : props.type === 'pending'
              ? ' border border-yellow-600 bg-yellow-100 text-yellow-600'
              : ' border border-red-600 bg-red-100 text-red-600'
          }`}
        >
          <div className="flex">
            <span className="mr-2  text-xl">
              <HiOutlineCheckCircle />
            </span>
            <span className="text-left">{props.message}</span>
          </div>
          <div className=" text-xl ">
            <HiOutlineX
              onClick={handleClick}
              className="ml-2 cursor-pointer text-gray-700"
            />
          </div>
        </div>
      )}
    </>
  )
}

export default MessageCard
