import FModal from '../../../components/FModal'
import React, { useState } from 'react'
import FFormWrapper from '../../../components/FFormWrapper'
import FLabel from '../../../components/FLabel'
import FButton from '../../../components/FButton'
import { axiosInstance } from '../../../axios/requister'
import { toast } from 'react-toastify'
import ESpinner from '../../../components/ESpinner'

const CreateBidModal = ({
  isBidModalOpen,
  setisBidModalOpen,
  response,
  Refetch,
  startBidInfo,
}) => {
  const [amount, setAmount] = useState('')
  const [error, setError] = useState('')
  const [isLoaded, setIsLoaded] = useState(false)

  const handleChange = (event) => {
    setError('')
    const value = event.target.value
    const formattedValue = value.replace(/\D/g, '')

    setAmount(formattedValue)
  }

  const handleBlur = () => {
    if (amount === '' || parseFloat(amount) <= 0 || amount === 0) {
      setError('This field is required and should be positive')
    } else {
      setError('')
    }
  }

  const handleSubmit = () => {
    if (amount === '' || parseFloat(amount) <= 0 || amount === 0) {
      setError('This field is required and should be positive')
      return
    }

    setIsLoaded(true)

    const requestData = {
      initial: parseInt(amount),
      party_1: response?.dispatcher?.username,
      party_2: response?.customer?.username,
      load: response?.id,
    }

    if (response?.status === 'Assigning Carrier') {
      requestData.party_2 = response?.carrier?.username
    }

    axiosInstance
      .post('/shipment/offer/', requestData)
      .then(() => {
        setisBidModalOpen(false)
        setError('')
        Refetch()
        startBidInfo.Refetch()
        setIsLoaded(false)
        toast.success('Offer created successfully')
      })
      .catch((err) => {
        setIsLoaded(false)
        console.log(err)
        setError(err?.response?.data?.details || 'Something went wrong')
      })
  }

  return (
    <>
      <FModal
        callbackFunction={() => {
          setAmount('')
          setError('')
        }}
        title={' $ BID PRICE '}
        isOpen={isBidModalOpen}
        setIsOpen={setisBidModalOpen}
      >
        <div>
          <FFormWrapper className="mx-auto w-full">
            <FLabel htmlFor="bid">Enter bid amount </FLabel>
            <div className="flex">
              <span className="my-auto pr-2 text-xl text-primary-500">$</span>
              <input
                maxLength={'6'}
                type="text"
                value={amount}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter your offer"
                className="focus:shadow-outline-blue block w-full appearance-none rounded-md bg-white py-2 px-4 leading-normal text-gray-700 focus:border-blue-300 focus:outline-none"
              />
            </div>
            {error && <small className="text-red-700">{error}</small>}
          </FFormWrapper>
          <FButton
            className={'mx-auto mt-5 w-4/12 gap-2'}
            type={'submit'}
            onClick={handleSubmit}
          >
            Send Bid
            <ESpinner isVisible={isLoaded} />
          </FButton>
        </div>
      </FModal>
    </>
  )
}

export default CreateBidModal
