export const digitsOnly = (value) => /^\d+$/.test(value)

export const getObjectValueByStringPath = (o, s) => {
  s = s.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
  s = s.replace(/^\./, '') // strip a leading dot
  const a = s.split('.')
  let i = 0
  const n = a.length
  for (; i < n; ++i) {
    const k = a[i]
    if (k in o) {
      o = o[k]
    } else {
      return
    }
  }
  return o
}

export const setObjectValueByStringPath = (o, s, newValue) => {
  const newObject = { ...o }
  s = s.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
  s = s.replace(/^\./, '') // strip a leading dot
  const a = s.split('.')
  let i = 0
  const n = a.length
  for (; i < n; ++i) {
    const k = a[i]
    if (k in newObject) {
      newObject[k] = newValue
    } else {
      return
    }
  }
  return newObject
}

export const updateObject = (object, newValue, path) => {
  const stack = path.split('>')

  while (stack.length > 1) {
    object = object[stack.shift()]
  }

  object[stack.shift()] = newValue
}

export const put = function (obj, path, val) {
  /**
   * If the path is a string, convert it to an array
   * @param  {String|Array} path The path
   * @return {Array}             The path array
   */
  const stringToPath = function (path) {
    // If the path isn't a string, return it
    if (typeof path !== 'string') return path

    // Create new array
    const output = []

    // Split to an array with dot notation
    path.split('.').forEach(function (item) {
      // Split to an array with bracket notation
      item.split(/\[([^}]+)\]/g).forEach(function (key) {
        // Push to the new array
        if (key.length > 0) {
          output.push(key)
        }
      })
    })

    return output
  }

  // Convert the path to an array if not already
  path = stringToPath(path)

  // Cache the path length and current spot in the object
  const length = path.length
  let current = obj

  path.forEach(function (key, index) {
    // If this is the last item in the loop, assign the value
    if (index === length - 1) {
      current[key] = val
      // eslint-disable-next-line brace-style
    }

    // Otherwise, update the current place in the object
    else {
      // If the key doesn't exist, create it
      if (!current[key]) {
        current[key] = {}
      }

      // Update the current place in the objet
      current = current[key]
    }
  })
}

export const getNested = (obj, ...args) => {
  const res = args.reduce((obj, level) => obj && obj[level], obj)
  return res === null || res === undefined ? '' : res
}

const AUTHORITIES = {
  HEAD_MANAGER_AUTHORITY: 'مدير',
  ADMIN_AUTHORITY: 'مسؤول النظام',
  AGENT_AUTHORITY: 'موظف خدمة عملاء',
  SALES_REP_AUTHORITY: 'مندوب مبيعات',
  CUSTOMER_AUTHORITY: 'عميل',
}
export const getAuthorities = (authority) => AUTHORITIES[authority]
export const logFormData = (formData) => {
  console.table(Object.fromEntries(formData)) // another representation in table form
}

export const isJSONParsable = (obj) => {
  try {
    JSON.parse(obj)
  } catch (e) {
    return false
  }
  return true
}

export const camelize = (str) => {
  return str
  // .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
  //   return index === 0 ? word.toLowerCase() : word.toUpperCase()
  // })
  // .replace(/\s+/g, "")
}
/* export const getRoutePathByName = (name) =>
  AppRoutes.find((route) => route.name === name).path*/

// Handle nested props too
export const extractNonEmptyProps = (obj) => {
  return Object.entries(obj)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, value]) => {
      if (typeof value === 'object' && value !== null) {
        const nestedProps = extractNonEmptyProps(value)
        return Object.keys(nestedProps).length > 0
      } else {
        return value && !(Array.isArray(value) && value.length === 0)
      }
    })
    .reduce((acc, [key, value]) => {
      if (typeof value === 'object' && value !== null) {
        acc[key] = extractNonEmptyProps(value)
      } else {
        acc[key] = value
      }
      return acc
    }, {})
}
