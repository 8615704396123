import React, { useState } from 'react'
import FButton from '../../../../components/FButton'
import ESpinner from '../../../../components/ESpinner'
import { toast } from 'react-toastify'
import { axiosInstance } from '../../../../axios/requister'
import FModal from '../../../../components/FModal'
import FFormWrapper from '../../../../components/FFormWrapper'
import FLabel from '../../../../components/FLabel'
import FFormInvalidInput from '../../../../components/FFormInvalidInput'
import * as Yup from 'yup'
import { uploadSignedUrl } from '../../../Load/utils'
import { useFormik } from 'formik'

import FileUploadButton from '../../../../components/FileUploadButton '

const ConfirmStatus = ({ response, userInfo, Refetch }) => {
  // Your component logic here
  const [isLoaded, setIsLoaded] = useState(false)
  const [isCerrierModalOpen, setIsCerrierModalOpen] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)

  const testBilling = () => {
    axiosInstance.post(`shipment/load/billings/?load_id=${response?.id}`).then((res) => {
      console.log(res)
    })
  }


  const formik = useFormik({
    initialValues: {
      evidence: [],
      evidenceType: 'delivery'
    },
    validationSchema: Yup.object().shape({
      evidence: Yup.array()
    .min(1, 'Upload evidence is required') // Check if the array has at least one item
    .required('Upload evidence is required'),
      // evidenceType: Yup.string().trim().required('Evidence type is required') // TODO: add enums to types
    }),
    onSubmit: async (values) => {
      setIsLoaded(true)


      try {
        const formData = {
          load_id: response?.id,
          evidence: values.evidence.map((file) => ({
            name: file.name,
            content_type: file.type,
          })),
          evidence_type: values.evidenceType
        }

        const serverResponse = await axiosInstance.post('/shipment/load/evidence/', formData)
        

        // Upload the evidence file using signed URL
        const serverAttachments = serverResponse.data.evidence
        const onUploadProgress = (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          setUploadProgress(progress)
        }


        await uploadSignedUrl({
          serverAttachments,
          uploadedFiles: values.evidence,
          onUploadProgress,
          objId: serverResponse.data.id,
          type:"load evidence",
          metaKey:'load_evidence_id',
        })

        // Confirm the status update
        await axiosInstance.put(`/shipment/load-status/`, {
          load: response?.id,
          status: response?.status,
        })

        setIsLoaded(false)
        Refetch()
        toast.success('Load status confirmed')
        setIsCerrierModalOpen(false)
        testBilling()
        
        formik.resetForm()
      } catch (error) {
        setIsLoaded(false)
        toast.error(
          'Something went wrong'
        )
      }
    },
  })

  const confirmStatus = () => {
    setIsLoaded(true)
    axiosInstance
      .put(`/shipment/load-status/ `, {
        load: response?.id,
        status: response?.status,
      })
      .then(() => {
        setIsLoaded(false)
        Refetch()
         

        toast.success('Load status confirmed')
      })
      .catch(() => {
        setIsLoaded(false)
        toast.error(
          'Something went wrong, please double check The billing documents agreement from the customer and carrier'
        )
      })
  }

  const checkUserRole = (selectedStatus, role, selected_role) => {
    return (
      response?.status.toLowerCase() === selectedStatus.toLowerCase() &&
      userInfo.response?.username === response?.[role]?.username &&
      userInfo.response?.selected_role === selected_role
    )
  }
  const isShipperConfirmPick = checkUserRole(
    'Ready For Pickup',
    'shipper',
    'shipment party'
  )
  const isCarrierConfirmPick = checkUserRole('Ready For Pickup', 'carrier', 'carrier')
  const isShipperConfirmDelivery = checkUserRole(
    'In Transit',
    'consignee',
    'shipment party'
  )
  const isCarrierConfirmDelivery = checkUserRole('In Transit', 'carrier', 'carrier')

  return (
    <>
      <FModal
        isOpen={isCerrierModalOpen}
        setIsOpen={setIsCerrierModalOpen}
        title={'Confirm Delivery'}
        callbackFunction={() => formik.resetForm()}
      >
        <form onSubmit={formik.handleSubmit}>
          
          <div className="flex flex-col gap-5">
           
            <FFormWrapper className="w-full lg:w-1/2">
              <FLabel htmlFor="evidence"> Upload Evidence</FLabel>
              <FileUploadButton
                name="evidence"
                initialFiles={formik.values.evidence}
                acceptedTypes={['image/png', 'image/jpeg']}
                onFileChange={(files) => formik.setFieldValue('evidence', files)}
                onBlur={formik.handleBlur}
                multiple={false}
                onRemoveImage={(image) => {
                  formik.setFieldValue(
                    'evidence',
                    formik.values.evidence.filter((file) => file.name !== image.name)
                  )
                }}
              />

           

              <FFormInvalidInput
                touched={formik.touched}
                FieldName="evidence"
                errors={formik.errors}
              />
            </FFormWrapper>

            <FButton className={'flex h-fit w-fit gap-2 self-end'} type="submit">
              <span>Confirm Delivery {isLoaded && `(${uploadProgress}%)`}

              </span>
              <ESpinner size={15} isVisible={isLoaded} />
            </FButton>
          </div>
        </form>
      </FModal>
      <div>
        {(isShipperConfirmPick || isCarrierConfirmPick) && (
          <div className="mx-2 mt-5 flex justify-between border border-primary-600 bg-primary-50 p-4  px-2  text-primary-600">
            {' '}
            <div className="my-auto">
              {`As a ${isCarrierConfirmPick ? 'Carrier' : 'Shipper'} of this load`},
              <span className="font-medium">
                First check the billing documents agreement and then confirm Pickup by
                clicking the button
              </span>
            </div>
            <div>
              <FButton className={'flex gap-2'} onClick={confirmStatus}>
                <span>Confirm Pickup </span>
                <ESpinner size={15} isVisible={isLoaded} />
              </FButton>
            </div>
          </div>
        )}
        {(isShipperConfirmDelivery || isCarrierConfirmDelivery) && (
          <div className="mx-2 mt-5 flex justify-between border border-primary-600 bg-primary-50 p-4  px-2  text-primary-600">
            {' '}
            <div className="my-auto">
              {`As a ${isCarrierConfirmDelivery ? 'Carrier' : 'Consignee'} of this load`},
              <span className="font-medium">
                First check the billing documents agreement and then confirm Delivery by
                clicking the button
              </span>
            </div>
            <div>
              <FButton
                className={'flex gap-2'}
                onClick={
                  isCarrierConfirmDelivery
                    ? () => setIsCerrierModalOpen(true)
                    : () => {
                        confirmStatus()
                        testBilling()
                      }
                }
              >
                <span>Confirm Delivery </span>
                <ESpinner size={15} isVisible={isLoaded} />
              </FButton>
            </div>
          </div>
        )}

      </div>
    </>
  )
}

export default ConfirmStatus
