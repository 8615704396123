import { TbSmartHome } from 'react-icons/tb'
import { HiChevronLeft, HiOutlineReceiptTax, HiOutlineTicket } from 'react-icons/hi'
import FFlexWrapper from '../FFlexWrapper'
import React, { useState, useContext, useEffect } from 'react'
import FNavLink from '../FNavLink'
import { BASE_URL, SITE_LOGO } from '../../env'
import { BsBox } from 'react-icons/bs'
import { AiOutlineFileProtect } from 'react-icons/ai'

import {
  RiContactsLine,
  RiTruckLine,
  RiRoadMapLine,
  RiUserSettingsLine,
} from 'react-icons/ri'
import { FaRegBuilding } from 'react-icons/fa'
import { GiHamburgerMenu } from 'react-icons/gi'
import { UserContext } from '../UserContext'
import { axiosInstance } from '../../axios/requister'
import { SiAcclaim } from 'react-icons/si'
import { RiDraftLine } from 'react-icons/ri'

const SideBar = ({ textColor }) => {
  const [open, setOpen] = useState(false)
  const [isCollapse, setCollapse] = useState(false)
  const [isBg, setBg] = useState(false)
  const [taxInfo, setTaxInfo] = useState(null)
  const { user } = useContext(UserContext)

  useEffect(() => {
    axiosInstance
      .get(`${BASE_URL}authentication/tax-info/ `)
      .then((res) => {
        setTaxInfo(res.data)
      })
      .catch(() => {})
  }, [])

  const userRole = user?.selected_role.toLowerCase()

  return (
    <>
      <div
        className={`absolute top-0 right-0 z-40  h-full w-full bg-black opacity-30 lg:hidden ${
          isBg ? 'block' : 'hidden'
        } `}
        onClick={() => {
          setBg(false)
          setOpen(false)
        }}
      ></div>
      <div className={' '}>
        <GiHamburgerMenu
          className={` absolute top-3 left-3 z-50  cursor-pointer  ${textColor} lg:hidden  ${
            open ? 'hidden' : ''
          } `}
          onClick={() => {
            setBg(true)
            setOpen(true)
          }}
          size={30}
        />
      </div>

      <div
        className={` sidebar absolute z-40 flex h-full flex-col border-r-2 bg-white text-gray-500 shadow-sm lg:relative  lg:flex
         ${open ? 'block ' : 'hidden'}
        ${isCollapse ? 'w-64 lg:w-16 ' : 'w-64'}`}
      >
        <img src={SITE_LOGO} className={'mx-auto  p-5'} alt={'freight_logo'} />
        <div className={'  '}>
          <div
            className={'f-col  gap-1 '}
            onClick={() => {
              setOpen(false)
              setBg(false)
            }}
          >
            {userRole !== 'support'.toLowerCase() && (
              <FNavLink isCollapse={isCollapse} to={'/dashboard'}>
                <TbSmartHome size={20} style={{ minWidth: '20px', marginLeft: '5px' }} />
                <span className={` ${isCollapse ? ' block lg:hidden' : ''}`}>
                  Dashboard
                </span>
              </FNavLink>
            )}
            {userRole !== 'support'.toLowerCase() && (
              <div onClick={() => localStorage.setItem('loadListPage', 1)}>
                <FNavLink isCollapse={isCollapse} to={'/load'}>
                  <BsBox size={20} style={{ minWidth: '20px', marginLeft: '5px' }} />
                  <span className={` ${isCollapse ? ' block lg:hidden' : ''}`}>
                    Loads
                  </span>
                </FNavLink>
              </div>
            )}
            {userRole !== 'support'.toLowerCase() && (
              <FNavLink isCollapse={isCollapse} to={'/contact'}>
                <RiContactsLine
                  size={20}
                  style={{ minWidth: '20px', marginLeft: '5px' }}
                />
                <span className={` ${isCollapse ? 'block lg:hidden' : ''}`}>
                  Contacts
                </span>
              </FNavLink>
            )}

            {userRole === 'shipment party'.toLowerCase() ||
            userRole === 'dispatcher'.toLowerCase() ||
            userRole === 'manager'.toLowerCase() ? (
              <div>
                <FNavLink className="" isCollapse={isCollapse} to={'/shipment'}>
                  <RiTruckLine
                    size={20}
                    style={{ minWidth: '20px', marginLeft: '5px' }}
                  />
                  <span className={` ${isCollapse ? 'block lg:hidden' : ''}`}>
                    Shipments
                  </span>
                </FNavLink>
              </div>
            ) : null}

            {userRole === 'shipment party'.toLowerCase() ||
            userRole === 'manager'.toLowerCase() ? (
              <div>
                <FNavLink isCollapse={isCollapse} to={'/facilities'}>
                  <RiRoadMapLine
                    size={22}
                    style={{ minWidth: '20px', marginLeft: '5px' }}
                  />
                  <span className={` ${isCollapse ? 'block lg:hidden' : ''}`}>
                    Facilities
                  </span>
                </FNavLink>
              </div>
            ) : null}
            {taxInfo?.type === 'individual' && (
              <FNavLink isCollapse={isCollapse} to={'/individualuser'}>
                <HiOutlineReceiptTax
                  size={20}
                  style={{ minWidth: '20px', marginLeft: '5px' }}
                />
                <span className={`${isCollapse ? 'block lg:hidden' : ''}`}>
                  Billing Info
                </span>
              </FNavLink>
            )}

            {(taxInfo?.type === 'company' || userRole === 'manager'.toLowerCase()) && (
              <FNavLink isCollapse={isCollapse} to={'/company-profile'}>
                <FaRegBuilding
                  size={20}
                  style={{ minWidth: '20px', marginLeft: '5px' }}
                />
                <span className={`${isCollapse ? 'block lg:hidden' : ''}`}>Company</span>
              </FNavLink>
            )}
            {userRole !== 'support'.toLowerCase() && (
            <FNavLink isCollapse={isCollapse} to={'/insurance'}>
              <AiOutlineFileProtect
                size={20}
                style={{ minWidth: '20px', marginLeft: '5px' }}
              />
              <span className={` ${isCollapse ? 'block lg:hidden' : ''}`}>Insurance</span>
            </FNavLink>
            )}

            {userRole !== 'support'.toLowerCase() &&
            userRole !== 'manager'.toLowerCase() ? (
              <div>
                <FNavLink isCollapse={isCollapse} to={'/roles'}>
                  <RiUserSettingsLine
                    size={20}
                    style={{ minWidth: '20px', marginLeft: '5px' }}
                  />
                  <span className={`${isCollapse ? 'block lg:hidden' : ''}`}>Roles</span>
                </FNavLink>
              </div>
            ) : null}
            {userRole === 'support'.toLowerCase() && (
              <div>
                <FNavLink isCollapse={isCollapse} to={'/tickets'}>
                  <HiOutlineTicket
                    size={20}
                    style={{ minWidth: '20px', marginLeft: '5px' }}
                  />
                  <span className={`${isCollapse ? 'block lg:hidden' : ''}`}>
                    Tickets
                  </span>
                </FNavLink>
                <FNavLink isCollapse={isCollapse} to={'/support-claims'}>
                  <SiAcclaim size={18} style={{ minWidth: '20px', marginLeft: '5px' }} />
                  <span className={`${isCollapse ? 'block lg:hidden' : ''}`}>Claims</span>
                </FNavLink>
              </div>
            )}
            {userRole !== 'support'.toLowerCase() &&
            userRole !== 'manager'.toLowerCase() ? (
              <div>
                <FNavLink isCollapse={isCollapse} to={'/drafts'}>
                  <RiDraftLine
                    size={20}
                    style={{ minWidth: '20px', marginLeft: '5px' }}
                  />
                  <span className={`${isCollapse ? 'block lg:hidden' : ''}`}>Drafts</span>
                </FNavLink>
              </div>
            ) : null}
          </div>
        </div>
        <div
          onClick={() => setCollapse(!isCollapse)}
          className={
            'border-t-orient-400  mt-auto hidden cursor-pointer border-t p-3 lg:block'
          }
        >
          <FFlexWrapper>
            <HiChevronLeft
              className={`${isCollapse && '-rotate-180 transform '}`}
              size={25}
            />
            <span className={`text-sm  text-gray-500 ${isCollapse ? 'hidden' : ''}`}>
              Collapse
            </span>
          </FFlexWrapper>
        </div>
      </div>
    </>
  )
}
export default SideBar
