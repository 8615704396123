import { useEffect, useRef, useState } from 'react'
import { constructAddress } from '../Load/utils'

const DEFAULT_CENTER = { lat: 39.8283, lng: -98.5795 }
const DEFAULT_ZOOM = 2

const FacilityMap = ({ Formik }) => {
  const mapRef = useRef(null)
  const searchInputRef = useRef(null)
  const [searchBox, setSearchBox] = useState(null)
  const [map, setMap] = useState(null)
  const [marker, setMarker] = useState(null)

  const location = constructAddress({
    buildingName: Formik.values.building_name,
    address: Formik.values.address,
    city: Formik.values.city,
    state: Formik.values.state,
    postalCode: Formik.values.zip_code,
    country: Formik.values.country,
  })

  useEffect(() => {
    const map = new window.google.maps.Map(mapRef.current, {
      center: DEFAULT_CENTER,
      zoom: DEFAULT_ZOOM,
    })
    setMap(map)

    // Initialize the search box
    const searchBox = new window.google.maps.places.SearchBox(searchInputRef.current)
    setSearchBox(searchBox)

    // Bias the search box to within the bounds of the map
    map.addListener('bounds_changed', () => {
      searchBox.setBounds(map.getBounds())
    })

    // Create a default marker at the center of the USA
    const coords = Formik.values.coords
    const marker = new window.google.maps.Marker({
      position: coords,
      map,
    })
    setMarker(marker)

    // Fit bounds
    if (coords) {
      const bounds = new window.google.maps.LatLngBounds()
      const latlng = new window.google.maps.LatLng(coords.lat, coords.lng)
      bounds.extend(latlng)
      map.fitBounds(bounds)
    }
  }, [])

  useEffect(() => {
    if (!mapRef.current || !searchInputRef.current || !map || !searchBox) return

    searchBox.addListener('places_changed', () => {
      const places = searchBox.getPlaces()

      if (places.length === 0) return

      // For each place, get the icon, name, and location.
      const bounds = new window.google.maps.LatLngBounds()
      places.forEach((place) => {
        if (!place.geometry) {
          console.log('Returned place contains no geometry')
          return
        }

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport)
        } else {
          bounds.extend(place.geometry.location)
        }

        // Set marker at the selected location
        const coords = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        }

        marker.setPosition(coords)

        const addressComponents = place.address_components

        let updatedBuildingName = ''
        let updatedAddress = ''
        let updatedCity = ''
        let updatedState = ''
        let updatedCountry = ''
        let updatedZip = ''

        addressComponents.forEach((component) => {
          const types = component.types
          if (types.includes('street_number') || types.includes('route')) {
            updatedAddress += component.long_name + ' '
          } else if (types.includes('locality')) {
            updatedCity = component.long_name
          } else if (types.includes('administrative_area_level_1')) {
            updatedState = component.long_name
          } else if (types.includes('country')) {
            updatedCountry = component.long_name
          } else if (types.includes('postal_code')) {
            updatedZip = component.long_name
          } else if (types.includes('subpremise')) {
            updatedBuildingName = component.long_name
          }
        })

        // Update Formik values
        Formik.setFieldValue('building_name', updatedBuildingName)
        Formik.setFieldValue('address', updatedAddress.trim())
        Formik.setFieldValue('city', updatedCity)
        Formik.setFieldValue('state', updatedState)
        Formik.setFieldValue('country', updatedCountry)
        Formik.setFieldValue('zip_code', updatedZip)
        Formik.setFieldValue('coords', coords)
      })

      map.fitBounds(bounds)
    })
  }, [mapRef, searchInputRef, map, searchBox, Formik])

  // i want to make a zomm is 3 when ever i open the map

  useEffect(() => {
    if (map) {
      map.setZoom(5)
    }
  }, [map])

  useEffect(() => {
    Formik.validateForm()
  }, [location])
  // i want here to cosole the degree of zoom
  return (
    <div className="flex w-full flex-col">
      <input
        ref={searchInputRef}
        type="text"
        placeholder="Search for a location"
        className="input-style my-2"
      />
      <div ref={mapRef} className="h-96 w-full" />
    </div>
  )
}

export default FacilityMap
