import { Menu, Transition } from '@headlessui/react'
import { Fragment, useState, useContext } from 'react'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { UserContext } from '../UserContext'
import { axiosInstance } from '../../axios/requister'
import { BROKER_ICON, CARRIER_ICON, SHIPPER_ICON } from '../../env'

export default function FMenu({ children ,bgColor, borderColor, textColor }) {
  const { user, userRoles, refreshUser } = useContext(UserContext)
  const [selectedRole, setSelectedRole] = useState(user?.selected_role)

  const handleRoleSelection = (role) => {
    if (role === 'shipment party') {
      axiosInstance
        .put('authentication/select-role/', {
          type: 'shipment party',
        })
        .then(() => {
          setSelectedRole('shipment party')
          refreshUser()
          setTimeout(() => {
            toast.success('You are now acting as Shipment Party')
          }, 200)
        })
        .catch(() => {
          toast.error('Something went wrong')
        })
    } else if (role === 'dispatcher') {
      axiosInstance
        .put('authentication/select-role/', {
          type: 'dispatcher',
        })
        .then(() => {
          setSelectedRole('dispatcher')
          refreshUser()
          setTimeout(() => {
            toast.success('You are now acting as Dispatcher')
          }, 200)
        })
        .catch(() => {
          toast.error('Something went wrong')
        })
    } else if (role === 'carrier') {
      axiosInstance
        .put('authentication/select-role/', {
          type: 'carrier',
        })
        .then(() => {
          setSelectedRole('carrier')
          refreshUser()
          setTimeout(() => {
            toast.success('You are now acting as Carrier')
          }, 200)
        })
        .catch(() => {
          toast.error('Something went wrong')
        })
    }
  }

  return (
    <div className="z-30 flex flex-col items-center text-left">
      <Menu as="div" className="relative z-40 inline-block text-left">
        <div className={'f-col-center-center'}>
          <Menu.Button className=" w-full">{children}</Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="primary-shadow absolute right-0 z-50 mt-2  origin-top-right divide-gray-100 rounded-sm border bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="f-col-center-center   py-1 ">
              <Menu.Item>
                <div
                  className={
                    'mb-2 flex w-52 flex-col items-center gap-3 px-3  py-2 lg:w-80 lg:px-3'
                  }
                >
                  <div className={`text-base font-semibold text-gray-700`}>
                    Select the role you want to act as
                  </div>
                  {userRoles.includes('shipment party') && (
                    <div
                      className={`w-full cursor-pointer rounded-sm border py-2 hover:bg-gray-100 ${
                        selectedRole === 'shipment party'
                          ? `{${borderColor} ${bgColor} }`
                          : ''
                      }`}
                      onClick={() => handleRoleSelection('shipment party')}
                    >
                      <div className="ml-5 flex justify-between">
                        <div className=" flex gap-3">
                          <SHIPPER_ICON textColor= {textColor} />
                          <span className="my-auto">Shipment Party</span>
                        </div>
                        <div className="my-auto mr-5">
                          {selectedRole === 'shipment party' && (
                            <BsFillCheckCircleFill className={`${textColor}`} />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {userRoles.includes('dispatcher') && (
                    <div
                      className={`w-full cursor-pointer rounded-sm border py-2 hover:bg-gray-100 ${
                        selectedRole === 'dispatcher'
                          ? `{${borderColor} ${bgColor} }`
                          : ''
                      }`}
                      onClick={() => handleRoleSelection('dispatcher')}
                    >
                      <div className="ml-5 flex justify-between">
                        <div className=" flex gap-3">
                          <BROKER_ICON textColor= {textColor} />
                          <span className="my-auto">Dispatcher</span>
                        </div>
                        <div className="my-auto mr-5">
                          {selectedRole === 'dispatcher' && (
                            <BsFillCheckCircleFill className={`${textColor}`} />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {userRoles.includes('carrier') && (
                    <div
                      className={`w-full cursor-pointer rounded-sm border py-2 hover:bg-gray-100 ${
                        selectedRole === 'carrier'
                          ? `{${borderColor} ${bgColor} }`
                          : ''
                      }`}
                      onClick={() => handleRoleSelection('carrier')}
                    >
                      <div className="ml-5 flex justify-between">
                        <div className="flex gap-3">
                          <CARRIER_ICON textColor= {textColor} />
                          <span className="my-auto">Carrier</span>
                        </div>
                        <div className="my-auto mr-5">
                          {selectedRole === 'carrier' && (
                            <BsFillCheckCircleFill className={`${textColor}`} />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}
