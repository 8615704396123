import React from 'react'
import { FaRegFilePdf } from "react-icons/fa"

const FileRenderer = ({ name = '', url, alt }) => {
    return (
        <a
            key={url}
            href={url}
            rel='noreferrer'
            target='_blank'
            className="w-[100px] aspect-square rounded-[2px] 
            border-[3px] border-gray-200 hover:cursor-pointer"
        >
            {name?.includes('.pdf')
                ? (<FaRegFilePdf className='h-20 w-20 mx-auto my-2' />)
                : (
                    <img
                        className='object-cover h-full w-full rounded-[1px]'
                        src={url}
                        alt={alt}
                    />
                )}

        </a>
    )
}

export default FileRenderer