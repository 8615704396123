import React from 'react'
// import {
//   ResponsiveContainer,
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
// } from 'recharts'
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement
)

function LoadStatusStat({ response }) {
  const loadStatusData = {
    labels: response?.chart?.map((item) => item.name),
    datasets: [
      {
        label: 'Total Loads',
        data: response?.chart?.map((item) => item.total),
        fill: false,
        backgroundColor: '#4b5563',
        borderColor: '#4b5563',
        borderWidth: 1,
        lineTension: 0.5,
      },
      {
        label: 'Pending',
        data: response?.chart?.map((item) => item.pending),
        fill: false,
        backgroundColor: '#ffc658',
        borderColor: '#ffc658',
        borderWidth: 1,
        lineTension: 0.5,
      },
      {
        label: 'Ready For Pickup',
        data: response?.chart?.map((item) => item.ready_for_pick_up),
        fill: false,
        backgroundColor: '#7c3aed',
        borderColor: '#7c3aed',
        borderWidth: 1,
        lineTension: 0.5,
      },
      {
        label: 'In Transit',
        data: response?.chart?.map((item) => item.in_transit),
        fill: false,
        backgroundColor: '#2563eb',
        borderColor: '#2563eb',
        borderWidth: 1,
        lineTension: 0.5,
      },
      {
        label: 'Delivered',
        data: response?.chart?.map((item) => item.delivered),
        fill: false,
        backgroundColor: '#16a34a',
        borderColor: '#16a34a',
        borderWidth: 1,
        lineTension: 0.5,
      },
      {
        label: 'Canceled',
        data: response?.chart?.map((item) => item.canceled),
        fill: false,
        backgroundColor: '#f87171',
        borderColor: '#f87171',
        borderWidth: 1,
        lineTension: 0.5,
      },
    ],
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  }
  return (
    <div className=" bg-white p-3">
      <div className="text-xl font-medium text-gray-900">Loads Status Statistics</div>
      <hr className="my-3" />

      <div className="mt-3 flex  h-60 w-full items-center justify-center">
        <Line data={loadStatusData} options={chartOptions} />
      </div>
    </div>
  )
}

export default LoadStatusStat
