import FButton from '../../components/FButton'
import FIconWrapper from '../../components/FIconWrapper'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import FFormWrapper from '../../components/FFormWrapper'
import FLabel from '../../components/FLabel'
import FInputField from '../../components/FInputField'
import FFormInvalidInput from '../../components/FFormInvalidInput'
import { axiosInstance } from '../../axios/requister'
import { toast } from 'react-toastify'
import { useState } from 'react'
import ESpinner from '../../components/ESpinner'

export default function CarrierWithTax({ setIsCarrierRoleModalOpen, handleRefreshUser }) {
  const [isLoading, setIsLoading] = useState(false)
  const [isErr, setIsErr] = useState([])
  const Formik = useFormik({
    initialValues: {
      dotNumber: '',
    },
    validationSchema: Yup.object({
      dotNumber: Yup.string().required('DOT Number is required'),
    }),
    onSubmit: (values) => {
      setIsLoading(true)
      setIsErr([])
      axiosInstance
        .post('authentication/add-role/', {
          type: 'carrier',
          dot_number: values.dotNumber,
        })
        .then(() => {
          setIsLoading(false)
          setIsCarrierRoleModalOpen(false)
          handleRefreshUser()
          setTimeout(() => {
            toast.success('Carrier Role Added Successfully')
          }, 200)
        })
        .catch(() => {
          setIsLoading(false)
          toast.error('Something went wrong')
          setIsErr([
            'Invalid DOT number, Please double check your DOT number or contact the FMCSA for assistance.',
          ])
        })
    },
  })
  return (
    <div>
      <div className="text-base font-medium leading-6 text-gray-700">
        <p>
          By selecting [Carrier], you will be adding a new role to your account that
          allows you to transport cargo from one facility to another. This role is
          suitable if you need to manage cargo transportation for your business. Please
          enter your DOT number to continue.
        </p>
      </div>
      <form onSubmit={Formik.handleSubmit}>
        <FFormWrapper>
          <FLabel htmlFor="dotNumber"> DOT Number</FLabel>
          <FInputField
            type="text"
            name={'dotNumber'}
            id={'dotNumber'}
            placeholder={'Enter a DOT Number'}
            onChange={Formik.handleChange}
            onBlur={Formik.handleBlur}
            value={Formik.values.dotNumber}
          />

          <FFormInvalidInput
            touched={Formik.touched}
            FieldName={'dotNumber'}
            errors={Formik.errors}
          />
          {isErr.length > 0 && (
            <div>
              {isErr.map((err, index) => (
                <small className={'text-left font-medium text-red-700'} key={index}>
                  {isErr}
                </small>
              ))}
            </div>
          )}
        </FFormWrapper>

        <div className="text-base font-medium leading-6 text-gray-700"></div>
        <div className={'mt-3 flex w-full flex-row-reverse gap-5'}>
          <FButton
            type={'submit'}
            //  onClick={() => setIsCarrierRoleModalOpen(true)}
          >
            <FIconWrapper>
              <AiOutlinePlusCircle size={20} />
              Add Carrier Role
              <ESpinner isVisible={isLoading} />
            </FIconWrapper>
          </FButton>
          <FButton
            btnType={'secondary'}
            onClick={() => {
              setIsCarrierRoleModalOpen(false)
              setIsErr([])
              Formik.resetForm()
            }}
          >
            <FIconWrapper>Cancel</FIconWrapper>
          </FButton>
        </div>
      </form>
    </div>
  )
}
