import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useNavigate, useParams } from 'react-router-dom'
import { axiosInstance } from '../../axios/requister'
import { toast } from 'react-toastify'
import FacilityForm from './FacilityForm'
import ESpinner from '../../components/ESpinner'

const EditFacility = () => {
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [facility, setFacility] = useState(null)
  const buttonTitle = 'Save Changes'
  const FormTitle = 'Edit Facility'
  const navigation = useNavigate()

  useEffect(() => {
    // Fetch facility details when component mounts
    axiosInstance
      .get(`/shipment/facility/${id}/`)
      .then((response) => {
        setFacility(response.data)
      })
      .catch((error) => {
        console.error('Error fetching facility details:', error)
      })
  }, [id])

  useEffect(() => {
    if (facility) {
      // Initialize Formik once facility data is available
      Formik.setValues({
        building_name: facility?.building_name || '',
        address: facility?.address?.address || '',
        city: facility?.address?.city || '',
        state: facility?.address?.state || '',
        zip_code: facility?.address?.zip_code || '',
        country: facility?.address?.country || '',
        extra_info: facility?.extra_info || '',
      })
    }
  }, [facility])

  const Formik = useFormik({
    initialValues: {
      building_name: '',
      address: '',
      city: '',
      state: '',
      zip_code: '',
      country: '',
      extra_info: '',
    },
    onSubmit: () => {
      setLoading(true)
      axiosInstance
        .patch(`/shipment/facility/${id}/`, Formik.values)
        .then(() => {
          toast.success('Facility updated successfully')
          navigation('/facilities')
          setLoading(false)
        })
        .catch(() => {
          toast.error('Facility update failed')
          setLoading(false)
        })
    },

    validationSchema: Yup.object({
      city: Yup.string().required('City is required'),
      building_name: Yup.string().trim().required('Building name is required'),
      address: Yup.string().trim().required('Address is required'),
      zip_code: Yup.string().trim().required('Zip code is required'),
      country: Yup.string().required('Country is required'),
      state: Yup.string().required('State is required'),
    }),
  })

  if (!facility) {
    return (
      <div className="f-col-center-center h-full w-full">
        <ESpinner isVisible={true} size={30} />
        Loading...
      </div>
    )
  }

  return (
    <FacilityForm
      Formik={Formik}
      loading={loading}
      buttonTitle={buttonTitle}
      FormTitle={FormTitle}
    />
  )
}

export default EditFacility
