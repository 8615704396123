import React from 'react'
import FCard from '../../components/FCard'
import FFormWrapper from '../../components/FFormWrapper'
import FLabel from '../../components/FLabel'
import FFormInvalidInput from '../../components/FFormInvalidInput'
import FInputField from '../../components/FInputField'
import { MenuItem, Select } from '@mui/material'
import { useCustomAxios } from '../../Hooks/useAxios'

export function InsuranceCardBody({
  Formik,
  handleInputChange,
  insranceNameErr,
  insurancePolicyErr,
  setInsurancePolicyErr,
  setInsuranceNameErr,
}) {
  const { response } = useCustomAxios(
    {
      method: 'GET',
      url: 'insurance/types/      ',
    },
    true
  )
  return (
    <FCard.Body>
      <FFormWrapper>
        <FLabel htmlFor={'insurance_name'}>Insurance Name</FLabel>
        <FInputField
          id={'insurance_name'}
          name="insurance_name"
          placeholder="Enter your insurance name"
          type="text"
          value={Formik.values.insurance_name}
          onChange={(e) => {
            handleInputChange(e)
            setInsuranceNameErr([])
          }}
          onBlur={Formik.handleBlur}
        />
        <FFormInvalidInput
          touched={Formik.touched}
          FieldName="insurance_name"
          errors={Formik.errors}
        />
        {insranceNameErr &&
          insranceNameErr.map((err) => (
            <small key={err} className="text-red-700">
              {err}
            </small>
          ))}
      </FFormWrapper>
      <FFormWrapper>
        <FLabel htmlFor={'insurance_provider'}>Insurance Provider</FLabel>
        <FInputField
          id={'insurance_provider'}
          name="insurance_provider"
          placeholder="Enter your insurance provider"
          type="text"
          value={Formik.values.insurance_provider}
          onChange={handleInputChange}
          onBlur={Formik.handleBlur}
        />
        <FFormInvalidInput
          touched={Formik.touched}
          FieldName="insurance_provider"
          errors={Formik.errors}
        />
      </FFormWrapper>
      <div className="flex gap-3">
        <FFormWrapper className="w-1/2">
          <FLabel htmlFor={'insurance_policy_number'}>Insurance Policy Number</FLabel>
          <FInputField
            id={'insurance_policy_number'}
            name="insurance_policy_number"
            placeholder="Enter your policy number"
            type="text"
            onChange={(e) => {
              handleInputChange(e)
              setInsurancePolicyErr([])
            }}
            onBlur={Formik.handleBlur}
            value={Formik.values.insurance_policy_number}
          />
          <FFormInvalidInput
            touched={Formik.touched}
            FieldName="insurance_policy_number"
            errors={Formik.errors}
          />
          {insurancePolicyErr &&
            insurancePolicyErr.map((err) => (
              <small key={err} className="text-red-700">
                {err}
              </small>
            ))}
        </FFormWrapper>

        <FFormWrapper className="w-1/2">
          <FLabel htmlFor={'insurance_type'}>Insurance Type</FLabel>
          <Select
            id={'insurance_type'}
            name="insurance_type"
            placeholder="Enter your insurance type"
            className="h-10 w-full rounded-sm border  border-gray-300"
            labelId="demo-select-small-label"
            value={Formik.values.insurance_type}
            onChange={handleInputChange}
            onBlur={Formik.handleBlur}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {response?.results.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.type}
              </MenuItem>
            ))}
          </Select>
          <FFormInvalidInput
            touched={Formik.touched}
            FieldName="insurance_type"
            errors={Formik.errors}
          />
        </FFormWrapper>
      </div>
      <div className="flex gap-3">
        <FFormWrapper className="w-1/2">
          <FLabel htmlFor={'insurance_premium_amount'}>Premium Amount</FLabel>
          <FInputField
            id={'insurance_premium_amount'}
            name="insurance_premium_amount"
            placeholder="Enter the premium amount"
            type="number"
            value={Formik.values.insurance_premium_amount}
            onChange={handleInputChange}
            onBlur={Formik.handleBlur}
          />

          <FFormInvalidInput
            touched={Formik.touched}
            FieldName="insurance_premium_amount"
            errors={Formik.errors}
          />
        </FFormWrapper>
        <FFormWrapper className="w-1/2">
          <FLabel htmlFor={'insurance_expiration_date'}>Expiration Date</FLabel>
          <FInputField
            id={'insurance_expiration_date'}
            name="insurance_expiration_date"
            placeholder="Enter the expiration date"
            type="date"
            value={Formik.values.insurance_expiration_date}
            onChange={handleInputChange}
            onBlur={Formik.handleBlur}
          />
          <FFormInvalidInput
            touched={Formik.touched}
            FieldName="insurance_expiration_date"
            errors={Formik.errors}
          />
        </FFormWrapper>
      </div>
    </FCard.Body>
  )
}

export default function InsuranceCard({ Formik, handleInputChange }) {
  return (
    <FCard>
      <FCard.Header>
        <span>Insurance Information</span>
      </FCard.Header>
      <InsuranceCardBody Formik={Formik} handleInputChange={handleInputChange} />
    </FCard>
  )
}
