import React from 'react'
import { useParams } from 'react-router-dom'
import { useCustomAxios } from '../../../Hooks/useAxios'
import TimeAgo from '../../../components/TimeAgo'
// import FAvatar from '../../../components/FAvatar'
// import Status from '../../../components/Status'
import ESpinner from '../../../components/ESpinner'

export default function LogsTab() {
  const { id } = useParams()

  const { response, loading } = useCustomAxios({
    url: `/shipment/load/${id}/log/`,
    method: 'GET',
  })

  if (loading) {
    return (
      <div className="mt-24 flex flex-col items-center justify-center gap-2">
        <ESpinner isVisible={true} size={30} />
        <span>Loading...</span>
      </div>
    )
  } else if (response?.length === 0) {
    return (
      <div className={' flex  flex-col items-center justify-center   py-40'}>
        <img
          src="https://storage.googleapis.com/freight_static_files/empty-state.svg"
          alt={'empty_state'}
          width={200}
          className={''}
        />
        <span className={'mt-5 font-semibold text-gray-700'}>
          No logs were added yet.
        </span>
      </div>
    )
  } else
    return (
      <div>
        {response?.logs?.map((item) => (
          <div key={item.id}>
            <div className="m-5 justify-between bg-white   p-5  lg:flex">
              <div className="flex gap-5">
                {/* <div>
                  <FAvatar name={item.app_user?.username} width={40} />
                </div> */}
                <div className="flex flex-col">
                  {' '}
                  <div className="gap-1 lg:flex ">
                    <span className="my-auto text-xs font-medium"> New log .</span>
                    <span className="my-auto">
                      {<TimeAgo timestamp={item.timestamp} />}
                    </span>
                  </div>
                  <span className='font-normal'>{item.message}</span>
                </div>
              </div>
              {/* <div className="ml-14 mt-4 lg:my-auto lg:ml-0  ">
                <Status statusName={item.status} />
              </div> */}
            </div>
          </div>
        ))}
      </div>
    )
}
