import FModal from '../../components/FModal'
import FFormWrapper from '../../components/FFormWrapper'
import FButton from '../../components/FButton'
import FIconWrapper from '../../components/FIconWrapper'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { useFormik } from 'formik'
import { axiosInstance } from '../../axios/requister'
import { useState } from 'react'
import ESpinner from '../../components/ESpinner'
import { toast } from 'react-toastify'

const CreateShipment = ({
  isCreateShipmentModalOpen,
  setIsCreateShipmentModalOpen,
  callback,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const Formik = useFormik({
    initialValues: {
      name: '',
    },

    onSubmit: (values) => {
      setIsSubmitting(true)
      axiosInstance
        .post('/shipment/', values)
        .then(() => {
          setIsSubmitting(false)
          setIsCreateShipmentModalOpen(false)
          toast.success('Shipment created successfully')
          callback()
        })
        .catch(() => {
          toast.error('Error creating shipment')
          setIsSubmitting(false)
        })
    },
  })
  return (
    <>
      <FModal
        title={'Create Shipment'}
        isOpen={isCreateShipmentModalOpen}
        setIsOpen={setIsCreateShipmentModalOpen}
      >
        <form onSubmit={Formik.handleSubmit}>
          <FFormWrapper>
            <div className="text-base font-medium leading-6 text-gray-700">
              Creating a shipment allows you to organize the movement of goods. You can
              add loads and admins to collaborate and ensure successful loads delivery by
              sharing shipment details.
            </div>
          </FFormWrapper>

          <div className={'flex w-full flex-row-reverse gap-1'}>
            <FButton type={'submit'}>
              <FIconWrapper>
                <AiOutlinePlusCircle size={20} />
                Add Shipment
                <ESpinner isVisible={isSubmitting} />
              </FIconWrapper>
            </FButton>
            <FButton
              btnType={'secondary'}
              onClick={() => {
                Formik.resetForm()
                setIsCreateShipmentModalOpen(false)
              }}
            >
              <FIconWrapper>Cancel</FIconWrapper>
            </FButton>
          </div>
        </form>
      </FModal>
    </>
  )
}
export default CreateShipment
