import React, { useState } from 'react'
import LoadsCards from './LoadsCards'
import LoadStatusStat from './LoadStatusStat'
import { useNavigate } from 'react-router-dom'
import { BsStars } from 'react-icons/bs'

import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
} from 'chart.js'
import { Pie, Line } from 'react-chartjs-2'

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement
)

function ManagerDashboard({ response }) {
  const navigate = useNavigate()
  const [selectedTab, setSelectedTab] = useState('year') // Default tab is 'year'

  ChartJS.register(ArcElement, Tooltip, Legend)

  const handleTabChange = (tab) => {
    setSelectedTab(tab)
  }

  const LoadTypesdata = {
    labels: ['LTL', 'FTL', 'HHL'],
    datasets: [
      {
        label: '# OF LOADS',
        data: [
          response?.load_types?.ltl,
          response?.load_types?.ftl,
          response?.load_types?.hhl,
        ],
        backgroundColor: [
          'rgba(255, 99, 132, 0.5)',

          'rgba(255, 206, 86, 0.5)',
          'rgba(54, 162, 235, 0.5)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',

          'rgba(255, 206, 86, 1)',
          'rgba(54, 162, 235, 1)',
        ],
        borderWidth: 1,
      },
    ],
  }
  const DeliveryPerformanceData = {
    labels: ['On Time', 'Late', 'Missed'],
    datasets: [
      {
        label: '# OF LOADS',
        data: [
          response?.delivery_performance?.on_time,
          response?.delivery_performance?.late,
          response?.delivery_performance?.missed,
        ],
        backgroundColor: [
          'rgba(54, 162, 235, 0.5)',
          'rgba(255, 206, 86, 0.5)',
          'rgba(255, 99, 132, 0.5)',
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(255, 99, 132, 1)',
        ],
        borderWidth: 1,
      },
    ],
  }

  // Extracting names and counts from the array
  const equipmentNames = Object.values(response?.equipments).map(
    (equipment) => equipment.name
  )
  const equipmentCounts = Object.values(response?.equipments).map(
    (equipment) => equipment.count
  )
  // Creating the EquipmentsTypesData object
  const EquipmentsTypesData = {
    labels: equipmentNames,
    datasets: [
      {
        label: '# OF LOADS',
        data: equipmentCounts,
        backgroundColor: [
          'rgba(255, 99, 132, 0.5)',
          'rgba(54, 162, 235, 0.5)',
          'rgba(255, 206, 86, 0.5)',
          'rgba(75, 192, 192, 0.5)',
          'rgba(153, 102, 255, 0.5)',
          'rgba(255, 159, 64, 0.5)',
          'rgba(104, 226, 86, 0.5)',
          'rgba(255, 217, 61,0.5)',
          'rgba(193, 71, 233,0.5)',
          'rgba(0, 121, 255,0.5)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(104, 226, 86, 1)',
          'rgba(255, 217, 61,1)',
          'rgba(193, 71, 233,1)',
          'rgba(0, 121, 255,1)',
        ],
        borderWidth: 1,
      },
    ],
  }

  const labels =
    selectedTab === 'year'
      ? response?.profit_summary_chart.year?.map((item) => item.name) || []
      : response?.profit_summary_chart.month?.map((item) => item.name) || []

  const totalPaid =
    selectedTab === 'year'
      ? response?.profit_summary_chart.year?.map((item) => item.total_paid) || []
      : response?.profit_summary_chart.month?.map((item) => item.total_paid) || []

  const totalReceived =
    selectedTab === 'year'
      ? response?.profit_summary_chart.year?.map((item) => item.total_received) || []
      : response?.profit_summary_chart.month?.map((item) => item.total_received) || []

  const revenue =
    selectedTab === 'year'
      ? response?.profit_summary_chart.year?.map((item) => item.revenue) || []
      : response?.profit_summary_chart.month?.map((item) => item.revenue) || []

  const lineChartData = {
    labels: labels,
    datasets: [
      {
        label: 'Total Income',
        data: totalReceived,
        fill: false,
        backgroundColor: 'rgba(39, 76, 245, 0.8)',
        borderColor: 'rgba(39, 76, 245, 0.8)',
        borderWidth: 1,
        lineTension: 0.5,
      },
      {
        label: 'Total Outcome',
        data: totalPaid,
        fill: false,
        backgroundColor: 'rgba(210, 45, 45, 0.8)',
        borderColor: 'rgba(210, 45, 45, 0.8)',
        borderWidth: 1,
        lineTension: 0.5,
      },
      {
        label: 'Total Revenue',
        data: revenue,
        fill: false,
        backgroundColor: 'rgba(0, 158, 77, 0.8)',
        borderColor: 'rgba(0, 158, 77, 0.8)',
        borderWidth: 1,
        lineTension: 0.5,
      },
    ],
  }
  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  }
  const pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
  }

  return (
    <div>
      <div className="flex flex-col px-5 pb-5">
        <div className="mt-2 grid grid-cols-1 gap-4   md:grid-cols-2 lg:grid-cols-6">
          <LoadsCards response={response} />
        </div>
      </div>

      {/* ----------------------------LoadStatusStat------------------------------------------- */}
      <div className="mx-5 flex   flex-col gap-5  lg:grid lg:grid-cols-2">
        <LoadStatusStat height={250} response={response} />
        <div className=" flex flex-col gap-5 lg:grid lg:grid-cols-2 ">
          {/* ----------------------------EMPLOYEES TABLE------------------------------------------- */}

          <div className="  bg-white p-3">
            <div className="  flex justify-between">
              <div className="text-lg font-medium  text-gray-900">Top Employees </div>

              <span
                className="my-auto cursor-pointer text-sm font-medium text-primary-400 underline hover:text-primary-500"
                onClick={() => navigate('/company-profile')}
              >
                View All Employees
              </span>
            </div>
            <hr className="mt-3" />
            <div className="px-2 pb-3">
              <table className=" mt-4 min-w-full">
                <thead>
                  <tr className=" border-b border-dashed  border-gray-300  text-left ">
                    <th className=" py-2 font-medium text-gray-400">Employee Name</th>
                    <th className="py-2 text-center font-medium text-gray-400">
                      Progress
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(response?.top_employees).map((key) => {
                    const employee = response.top_employees[key]
                    return (
                      <tr
                        key={employee.name}
                        className="border-b border-dashed text-left font-medium last:border-none"
                      >
                        <td>
                          <div className="flex items-center gap-1">
                            <BsStars className="flex text-yellow-400" size={20} />
                            {employee.name}
                          </div>
                        </td>
                        <td className="flex justify-center gap-4 py-2">
                          <span className="text-green-500">+$ {employee.revenue}</span>
                          <span>{employee.load_count} Load</span>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
          {/* ----------------------------Load Types------------------------------------------- */}

          <div className=" bg-white p-3 ">
            <div className=" text-lg  font-medium text-gray-900  ">Load Types</div>
            <hr className="my-3" />
            <div className=" mt-3 flex h-5/6 w-full items-center justify-center">
              <Pie data={LoadTypesdata} options={pieChartOptions} />
            </div>
          </div>
        </div>
      </div>

      {/* ----------------------------last Section------------------------------------------- */}
      <div className="mx-5 mt-5 flex  flex-col gap-5   lg:grid lg:grid-cols-2 ">
        <div className=" mb-5 flex flex-col gap-5 lg:grid lg:grid-cols-2 ">
          {/* ----------------------------Delivery Performance------------------------------------------- */}

          <div className=" bg-white p-3 ">
            <div className=" text-lg  font-medium text-gray-900  ">
              {' '}
              Delivery Performance
            </div>
            <hr className="my-3" />
            <div className=" mt-3 flex h-5/6 w-full items-center justify-center">
              <Pie data={DeliveryPerformanceData} options={pieChartOptions} />
            </div>
          </div>
          {/* ----------------------------Equipments Types CHART------------------------------------------- */}

          <div className=" bg-white p-3 ">
            <div className=" text-lg  font-medium text-gray-900  ">Equipments Types</div>
            <hr className="my-3" />
            <div className=" mt-3 flex h-5/6 w-full items-center justify-center">
              <Pie data={EquipmentsTypesData} options={pieChartOptions} />
            </div>
          </div>
        </div>
        {/* ----------------------------Profit Summary CHART------------------------------------------- */}

        <div className="mb-5  w-full bg-white py-3">
          <div className=" flex  justify-between  px-3 text-lg font-medium text-gray-900 ">
            <span>Profit Summary</span>
            <select
              className="rounded-sm text-sm font-medium"
              value={selectedTab}
              onChange={(e) => handleTabChange(e.target.value)}
            >
              <option value="year">This Year</option>
              <option value="month">This Month</option>
            </select>
          </div>

          <hr className="my-3" />
          <div className="flex justify-between  gap-5">
            <div className="  flex w-3/12 items-center border-r-2  px-3">
              <div className=" flex flex-col items-start justify-center gap-y-1  ">
                <span className=" text-xl text-gray-400 ">Total Income </span>
                <span className="text-xl font-semibold text-blue-500">
                  $ {response?.total_received}
                </span>
                <span className=" text-xl text-gray-400">Total Outcome </span>
                <span className="text-xl font-semibold text-red-500">
                  $ {response?.total_paid}
                </span>
                <span className=" text-xl text-gray-400">Total Revenue </span>
                <span className="text-xl font-semibold text-green-500">
                  $ {response?.revenue}
                </span>
              </div>
            </div>

            <div className="  w-9/12">
              <div className="mt-3 flex  h-60 w-full items-center justify-center">
                <Line data={lineChartData} options={lineChartOptions} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManagerDashboard
