import React, { useEffect, useState, useContext } from 'react'
import FCustomDataTable from '../../components/FCustomDataTable'
import { axiosInstance } from '../../axios/requister'
import { toast } from 'react-toastify'
import ESpinner from '../../components/ESpinner'
import FButton from '../../components/FButton'
import FAvatar from '../../components/FAvatar'
import MessageCard from '../../components/MessageCard'
import { AiOutlineSearch } from 'react-icons/ai'
import { UserContext } from '../../components/UserContext'
import { Tooltip } from '@mui/material'
import { LiaShareSquareSolid } from 'react-icons/lia'
import { useNavigate } from 'react-router-dom'

const ContactTab = ({
  // response,
  loading,
  Refetch,
  // contactListpage,
  // setContactListPage,
  inviteRequests,
}) => {
  const [page, setPage] = useState(1)
  const [filter, setFilter] = useState([])
  const [filterOptions, setFilterOptions] = useState([
    {
      id: 0,
      value: '',
      key: '',
    },
  ])
  const [loadingAcceptedIndex, setLoadingAcceptedIndex] = useState(null)
  const [loadingRejectedIndex, setLoadingRejectedIndex] = useState(null)
  const [searchValue, setSearchValue] = useState('')
  const [filteredResults, setFilteredResults] = useState([])
  const [filterValue, setFilterValue] = useState('')
  const [countContacts, setcountContacts] = useState(0)
  const { user } = useContext(UserContext)
  const navigate = useNavigate()

  const handleSearch = (e) => {
    setSearchValue(e.target.value)
    setPage(1)
  }
  const handleFilter = (e) => {
    setFilterValue(e.target.value)
    setPage(1)
  }

  useEffect(() => {
    let endpoint
    if (user.selected_role === 'manager') {
      endpoint = `/manager/search-contacts/?page=${page}`
    } else {
      endpoint = `/shipment/search-contacts/?page=${page}`
    }
    let filter
    if (filterValue === 'shipment party') {
      filter = { 'shipment party': true }
    } else if (filterValue === 'dispatcher') {
      filter = { dispatcher: true }
    } else if (filterValue === 'carrier') {
      filter = { carrier: true }
    } else {
      filter = {}
    }
    axiosInstance
      .post(endpoint, {
        search: searchValue,
        filter,
      })
      .then((res) => {
        setFilteredResults(res.data.results)
        setcountContacts(res.data.count)
      })
  }, [page, searchValue, filterValue])

  // useEffect(() => {
  //   Refetch()
  // }, [page])
  console.log('filteredResults', filteredResults)
  const handleNavigate = (row) => {
    navigate('/load/create', { state: { customer: row } })
  }

  const userNameCell = (row) => {
    // State to manage tooltip title

    const copyToClipboard = () => {
      navigator.clipboard
        .writeText(row.username)
        .then(() => {
          toast.success('Username copied to clipboard')
        })
        .catch((err) => {
          console.error('Failed to copy: ', err)
          toast.error('Failed to copy to clipboard')
        })
    }

    if (!row || !row.username || !row.first_name || !row.last_name) {
      return null // or some fallback UI
    }

    return (
      <Tooltip title={row.username} placement="top" arrow>
        <div className="flex w-fit cursor-pointer items-center text-sm font-semibold">
          <div onClick={copyToClipboard}>{row.first_name + ' ' + row.last_name}</div>
        </div>
      </Tooltip>
    )
  }

  const emailCell = (row) => {
    return (
      <a href={`mailto:${row.email}`} className="text-sm font-semibold">
        {row.email}
      </a>
    )
  }
  const phoneNumberCell = (row) => {
    // Format the phone number
    const formattedPhoneNumber = row.phone_number.replace(
      /^(\+\d{1})(\d{3})(\d{3})(\d{4})$/,
      '($2) $3-$4'
    )

    return (
      <a href={`tel:${row.phone_number}`} className={'text-sm font-semibold'}>
        {formattedPhoneNumber}
      </a>
    )
  }
  const typeCell = (row) => {
    const roles = row.user_type.split('-')
    const colorMap = {
      'shipment party': 'bg-blue-100 text-blue-600 border-blue-300 border',
      dispatcher: 'bg-green-100 text-green-600 border-green-300 border',
      carrier: 'bg-yellow-100 text-yellow-600 border-yellow-300 border',
    }

    return (
      <div className="flex justify-between">
        <div className="flex">
          {' '}
          {roles.map((role, index) => (
            <div
              key={index}
              className={`mr-2  px-2 py-1 font-semibold  ${colorMap[role]}`}
            >
              {role}
            </div>
          ))}
        </div>
      </div>
    )
  }

  const cols = [
    {
      Header: 'Username',
      accessor: 'contact',
      //elly shayl el contact=row
      cell: userNameCell,
    },
    {
      Header: 'Email address',
      accessor: 'contact', // String-based value accessors!
      //elly shayl el contact=row
      cell: emailCell,
    },

    {
      Header: 'Phone number',
      accessor: 'contact', // String-based value accessors!
      cell: phoneNumberCell,
    },
    {
      Header: 'ROLE(s)',
      accessor: 'contact', // String-based value accessors!
      cell: typeCell,
    },
    {
      Header: 'Create Load',
      accessor: 'contact', // String-based value accessors!
      cell: (row) => {
        const isShipmentParty = row.user_type.includes('shipment party')
        return isShipmentParty ? (
          <Tooltip title="Create Load" placement="top" arrow>
            <div
              className="my-auto mr-10 flex cursor-pointer items-center justify-center font-bold text-primary-500
               underline hover:text-primary-600"
              onClick={() => handleNavigate(row)}
            >
              <LiaShareSquareSolid size={25} />
            </div>
          </Tooltip>
        ) : (
          <div className="flex  justify-center">
            <span className="text-gray-400 ">Not a shipment party</span>
          </div>
        )
      },
    },
  ]
  const handleAccept = (index) => {
    setLoadingAcceptedIndex(index)

    // Accept the invitation
    axiosInstance
      .post('invites/handle-invite/', {
        id: inviteRequests.response[index]?.id,
        action: 'accept',
      })
      .then(() => {
        toast.success('Invitation accepted successfully')
        setLoadingAcceptedIndex(null)
      })
      .catch(() => {
        setLoadingAcceptedIndex(null)
        toast.error('Invitation acceptance failed')
      })
      .finally(() => {
        inviteRequests.Refetch()
        Refetch()
      })
  }

  const handleReject = (index) => {
    setLoadingRejectedIndex(index)
    // Reject the invitation
    axiosInstance
      .post('invites/handle-invite/', {
        id: inviteRequests.response[index]?.id,
        action: 'reject',
      })
      .then(() => {
        toast.success('Invitation rejected successfully')
        setLoadingRejectedIndex(null)
        inviteRequests.Refetch()
      })
      .catch(() => {
        setLoadingRejectedIndex(null)
        toast.error('Invitation rejection failed')
        inviteRequests.Refetch()
      })
  }
  const totalNumberOfPages = Math.ceil(countContacts / 10)

  console.log('totalNumberOfPages', totalNumberOfPages)
  if (loading) {
    return (
      <div className={'f-col-center-center mt-20 h-full w-full'}>
        <ESpinner isVisible={true} size={30} />
        <span className="mt-5">Loading...</span>
      </div>
    )
  } else if (
    filteredResults?.length === 0 &&
    inviteRequests.error?.response?.request?.status === 404 &&
    searchValue == ''
  ) {
    return (
      <div className={' flex  flex-col items-center justify-center   py-40'}>
        <img
          src="https://storage.googleapis.com/freight_static_files/empty-state.svg"
          alt={'empty_state'}
          width={200}
          className={''}
        />
        <span className={'mt-5 font-semibold text-gray-700'}>No Data Found</span>
        {user.selected_role === 'manager' ? (
          <span className={'mt-1 text-center text-sm text-gray-600'}>
            Sorry, there are currently no contacts available in your organization or your
            account.
          </span>
        ) : (
          <span className={'mt-1 text-center text-sm text-gray-600'}>
            Sorry, there are currently no contacts available. Please check back later or
            create one yourself!
          </span>
        )}
      </div>
    )
  } else {
    return (
      <div className="">
        {filteredResults !== 0  && (
          <div className="mx-auto  mt-5 mb-5 flex overflow-hidden px-10">
            <div className="relative flex w-full gap-5 ">
              <div className="w-10/12">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <AiOutlineSearch className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  className="block  w-full rounded-sm border border-gray-300 py-2  pl-10 text-sm text-gray-800 shadow-sm focus:border-gray-400  focus:outline-none focus:ring-0  "
                  type="text"
                  name="search"
                  placeholder="e.g. username#xxxxx"
                  value={searchValue}
                  onChange={handleSearch}
                />
              </div>
              <div className="my-auto w-2/12 ">
                <select
                  value={filterValue}
                  onChange={handleFilter}
                  className="block w-full cursor-pointer rounded-sm border border-gray-300 py-1  text-gray-800 focus:border-gray-400 focus:outline-none focus:ring-0"
                  disabled={loading}
                >
                  <option value="">All</option>
                  <option value="shipment party">Shipment Party</option>
                  <option value="dispatcher">Dispatcher</option>
                  <option value="carrier">Carrier</option>
                </select>
              </div>
            </div>
          </div>
        )}
        {searchValue !== '' && filteredResults.length === 0 && (
          <div className={' flex  flex-col items-center justify-center   py-40'}>
            <img
              src="https://storage.googleapis.com/freight_static_files/empty-state.svg"
              alt={'empty_state'}
              width={200}
              className={''}
            />
            <span className={'mt-5 font-semibold text-gray-700'}>No Results Found</span>
          </div>
        )}
        <FCustomDataTable
          cols={cols}
          data={filteredResults}
          isLoading={loading}
          page={page}
          setPage={setPage}
          Refetch={Refetch}
          setFilter={setFilter}
          filter={filter}
          totalNumberOfPages={totalNumberOfPages}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
        />

        {inviteRequests.response?.length > 0 &&
          inviteRequests.error?.response?.request?.status !== 404 && (
            <div>
              <MessageCard
                message={`You have ${inviteRequests.response?.length} pending invitation request(s).`}
                type={'pending'}
              />
              <div className="flex p-5 text-xl font-semibold text-gray-700">
                Invite Requests
              </div>
              {inviteRequests.response?.map((item, index) => (
                <div
                  key={item.id}
                  className="mx-5 mb-3 flex flex-col  bg-white p-5 lg:flex lg:flex-row "
                >
                  <div>
                    <FAvatar
                      name={item.inviter?.first_name + ' ' + item.inviter?.last_name}
                      width={40}
                    />
                  </div>
                  <div className="ml-4 flex flex-grow flex-col">
                    <div className="text-base font-semibold">
                      {item.inviter?.first_name + ' ' + item.inviter?.last_name}
                    </div>
                    <div className="mt-2 flex-grow text-gray-700">
                      <p className="mb-2 text-sm">
                        You have received an invitation request from{' '}
                        {item.inviter?.first_name + ' ' + item.inviter?.last_name} .
                        Accepting this request will automatically add Ahmed to your
                        contact list, and you will also be added to thier contact list.
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-5">
                    <FButton
                      btnType="success"
                      className="flex h-fit gap-2"
                      onClick={() => {
                        handleAccept(index)
                      }}
                    >
                      Accept
                      {loadingAcceptedIndex === index && (
                        <ESpinner isVisible={true} size={15} />
                      )}
                    </FButton>
                    <FButton
                      btnType="danger"
                      className="flex h-fit gap-2"
                      onClick={() => {
                        handleReject(index)
                      }}
                    >
                      Decline
                      {loadingRejectedIndex === index && (
                        <ESpinner isVisible={true} size={15} />
                      )}
                    </FButton>
                  </div>
                </div>
              ))}
            </div>
          )}
      </div>
    )
  }
}
export default ContactTab
