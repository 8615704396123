import React from 'react'
import FCard from '../../../../../components/FCard'
import Status from '../../../../../components/Status'
import { BsArrowRight } from 'react-icons/bs'



const BidCard = ({ bid, party2,to, bidPriceClass  , party1 ,condition1,condition2 }) => {
    return (
        <FCard className="my-2">
    <FCard.Header>
      <span className="text-lg font-semibold text-gray-800">Your bid information</span>
      <span className="text-base font-normal text-gray-400"> {to}</span>
    </FCard.Header>
    <FCard.Body className="justify-between lg:flex">
      <div className="my-auto lg:flex">
        <div className="text-lg font-semibold text-slate-700 mr-1">
          { 
           condition1 ? 'You' :
             party1  }
        </div> 
        <div className="mt-1 flex  font-medium  text-slate-600">
        
         (Place bid with)   <BsArrowRight size={25} className="my-auto ml-2 mr-2  text-slate-600 lg:block hidden" />
        </div>
        
        <div className="text-lg font-semibold text-slate-700">
          { 
           condition2 ? 'You' :
             party2  }
        </div>
      </div>
      <div className="flex gap-5 lg:flex-col lg:gap-0">
        <span className="text-base font-normal text-slate-600">YOUR BID PRICE</span>
        <span className={`text-xl font-semibold ${bidPriceClass}`}>$ {bid?.current}</span>
      </div>
      <Status statusName={bid?.status} />
    </FCard.Body>
  </FCard>
)
    
}

export default BidCard