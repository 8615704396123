import LoadForm from "./LoadForm"
import useLoad from "./useLoad"
import { useFormik } from 'formik'
import {
    customValidation,
    getValidationSchema,
} from "../utils"
import { useContext, useEffect } from "react"
import { useCustomAxios } from "../../../Hooks/useAxios"
import { UserContext } from "../../../components/UserContext"
import { useNavigate } from "react-router-dom"

const EditLoadForm = () => {
    const navigate = useNavigate()
    const {
        id,
        initialValues,
        loadInfo,
        setUnits,
        setIsExistingShipment,
        setSelectorKeyForPickup,
        setSelectorKeyForDrop,
        mapLoad,
        onSubmit
    } = useLoad({ formType: 'edit' })

    const { user } = useContext(UserContext)

    const { response: load, loading } = useCustomAxios({
        url: `/shipment/load-details/${id}/`,
        method: 'GET',
    })

    useEffect(() => {
        setSelectorKeyForPickup(Math.random())
        setSelectorKeyForDrop(Math.random())
        //TODO: navigate to 403
        if (load) {
            if (load?.created_by !== user?.username) {
                navigate('/')
            }
        }
    }, [load, loading])


    const Formik = useFormik({
        initialValues: mapLoad(load) ?? initialValues,
        onSubmit,
        validationSchema: getValidationSchema(),
        validate: (values) => customValidation(values, loadInfo.isExistingShipment),
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
    })

    return (
        <LoadForm
            formType='edit'
            Formik={Formik}
            loadInfo={loadInfo}
            formTitle='Edit Load'
            setUnits={setUnits}
            setIsExistingShipment={setIsExistingShipment}
            setSelectorKeyForDrop={setSelectorKeyForDrop}
            setSelectorKeyForPickup={setSelectorKeyForPickup}
            primaryBtnTitle='Update'
            primaryLoading={loadInfo.editLoadLoading}
            secondaryBtnTitle='Cancel'
            onClickSecondary={() => navigate(`/load-details/${id}`)}
            isLoading={loading}
        />
    )
}

export default EditLoadForm