import { useFormik } from 'formik'
import { useState } from 'react'
import * as Yup from 'yup'
import FInputField from '../../components/FInputField'
import FLabel from '../../components/FLabel'
import FButton from '../../components/FButton'
import { BASE_URL, SITE_LOGO } from '../../env'
import FFormInvalidInput from '../../components/FFormInvalidInput'
import FFormWrapper from '../../components/FFormWrapper'
import PasswordStrengthIndicator from '../../components/PasswordStrengthIndicator'
import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import ESpinner from '../../components/ESpinner'
import { toast, ToastContainer } from 'react-toastify'
import PhoneInput from 'react-phone-number-input'

const Register = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [serverError, setServerError] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const navigation = useNavigate()
  const [errorPhone, setErrorPhone] = useState('')
  // Get the current location and parse query parameters
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const selectedAccountType = searchParams.get('type')
  const phoneRegExp = /^(\+?(1|52)[- ]?)?(\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$/

  // Validation schema for fields common to all account types
  let validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required('First name is required')
      .min(2, 'First name is too short')
      .max(30, 'First name is too long')
      .matches('^[a-zA-Z ]+$', 'First name must be only letters'),
    last_name: Yup.string()
      .required('Last name is required')
      .min(2, 'Last name is too short')
      .max(30, 'Last name is too long')
      .matches('^[a-zA-Z ]+$', 'Last name must be only letters'),
    email: Yup.string().email('Email is not valid').required('Email is required'),
    password: Yup.string()
      .required('Password is required')
      .matches('(?=.{8,})', 'length')
      .matches('(?=.*[0-9])', 'number')
      .matches('(?=.*[A-Z])', 'capital')
      .matches('(?=.*[a-z])', 'small')
      .matches('(?=.*[^A-Za-z0-9])', 'symbol'),
  })

  // If the selected account type is 'CompanyManager', add phoneInput field validation
  if (selectedAccountType === 'CompanyManager') {
    validationSchema = validationSchema.concat(
      Yup.object().shape({
        phoneInput: Yup.string()
          .required('Phone number is required')
          .matches(phoneRegExp, 'Phone number is not valid'),
      })
    )
  }

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      phoneInput: '',
    },
    validationSchema: validationSchema,

    onSubmit: (values) => {
      setServerError([])
      values['password1'] = values['password']
      values['password2'] = values['password']
      setIsLoading(true)
      const requestBody = { ...values }
      delete requestBody.password
      if (selectedAccountType === 'CompanyManager') {
        requestBody.is_company_manager = true
        requestBody.phone_number = values.phoneInput // Assuming phoneInput field represents phone number
      }

      axios
        .post(`${BASE_URL}authentication/signup/`, requestBody)
        .then(() => {
          localStorage.setItem('registered_email', values.email)
          setIsLoading(false)
          navigation('/verification')
        })
        .catch((error) => {
          setIsLoading(false)
          Object.keys(error.response.data).map((key) => {
            setServerError((prevState) => [...prevState, error.response.data[key]])
          })
          // if error response is 500, then show this message to user instead of server error messages from backend
          if (error.response.status === 500) {
            setServerError(['Server error. Please try again later.'])
          }
          toast.error('Error creating account')
        })
    },
  })

  const [passwordValidity, setPasswordValidity] = useState({
    length: false,
    capitalLetters: false,
    number: false,
    symbol: false,
  })
  const [isPasswordValidityVisible, setIsPasswordValidityVisible] = useState(false)

  const handlePasswordChange = (password) => {
    formik.handleChange(password)
    setPasswordValidity({
      length: password.target.value.length >= 8,
      capitalLetters: /[A-Z]/.test(password.target.value),
      number: /\d/.test(password.target.value),
      symbol: /\W|_/g.test(password.target.value),
    })
  }
  const handlePasswordFocus = () => {
    setIsPasswordValidityVisible(true)
  }

  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-[#F2F3F3]">
      <div className="flex w-full flex-col items-center bg-white p-5 shadow-lg md:h-[750] md:w-4/12  ">
        <img
          src={SITE_LOGO}
          width={300}
          alt={'SITE_LOGO'}
          className="mb-5"
          style={{ maxHeight: '200px' }}
        />
        <span className=" text-center text-2xl font-bold text-gray-700 ">
          {selectedAccountType === 'CompanyManager'
            ? 'Create your company manager account'
            : 'Create your user account'}{' '}
        </span>
        <form onSubmit={formik.handleSubmit} className="flex w-full flex-col p-5">
          {serverError.length > 0 && (
            <div
              className={
                'f-col-center-center mb-5 flex flex-col items-start border border-red-500 bg-red-50 p-3'
              }
            >
              {serverError.map((error, index) => (
                <span className={'block text-left text-sm text-red-600'} key={index}>
                  {error}
                </span>
              ))}
            </div>
          )}
          <div className="flex justify-between gap-3">
            <FFormWrapper className="w-1/2">
              <FLabel htmlFor={'first_name'}>First name</FLabel>
              <FInputField
                id={'first_name'}
                name="first_name"
                placeholder="Enter your First name"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.first_name}
              />
              <FFormInvalidInput
                touched={formik.touched}
                FieldName={'first_name'}
                errors={formik.errors}
              />
            </FFormWrapper>
            <FFormWrapper className="w-1/2">
              <FLabel htmlFor={'last_name'}>Last name</FLabel>
              <FInputField
                id={'last_name'}
                name="last_name"
                placeholder="Enter your Last name"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.last_name}
              />
              <FFormInvalidInput
                touched={formik.touched}
                FieldName={'last_name'}
                errors={formik.errors}
              />
            </FFormWrapper>
          </div>
          <FFormWrapper>
            <FLabel htmlFor={'email'}>Email</FLabel>
            <FInputField
              id={'email'}
              name="email"
              placeholder="Enter your email address"
              type="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            <FFormInvalidInput
              touched={formik.touched}
              FieldName={'email'}
              errors={formik.errors}
            />
          </FFormWrapper>
          <FFormWrapper>
            <FLabel htmlFor={'password'}>Password</FLabel>
            <div className={'relative'}>
              <FInputField
                id={'password'}
                name="password"
                placeholder="Enter your password"
                type={isPasswordVisible ? 'text' : 'password'}
                onChange={handlePasswordChange}
                onBlur={formik.handleBlur}
                onFocus={handlePasswordFocus}
                value={formik.values.password}
              />
              {!isPasswordVisible && (
                <HiOutlineEye
                  className="absolute right-3 cursor-pointer text-gray-400 hover:text-gray-500"
                  style={{ top: '10px' }}
                  onClick={() => {
                    setIsPasswordVisible(true)
                  }}
                />
              )}
              {isPasswordVisible && (
                <HiOutlineEyeOff
                  className="absolute right-3 cursor-pointer text-gray-400 hover:text-gray-500"
                  style={{ top: '10px' }}
                  onClick={() => {
                    setIsPasswordVisible(false)
                  }}
                />
              )}
            </div>
            {isPasswordValidityVisible && (
              <div className="mt-2">
                <PasswordStrengthIndicator passwordValidity={passwordValidity} />
              </div>
            )}
          </FFormWrapper>
          {selectedAccountType === 'CompanyManager' && (
            <>
              <FFormWrapper>
                <FLabel>Phone number</FLabel>

                <div className=" mb-0  ">
                  <PhoneInput
                    name="phoneInput"
                    className={'input-phone-number'}
                    placeholder="Enter phone number"
                    defaultCountry="US"
                    countries={['US', 'MX', 'CA']}
                    value={formik.values.phoneInput}
                    onChange={(value, country) => {
                      const countryCode = country ? `+${country.countryCallingCode}` : ''
                      const formattedValue = countryCode
                        ? `${countryCode} ${value}`
                        : value
                      formik.setFieldValue('phoneInput', formattedValue)
                      setErrorPhone([])
                    }}
                    onBlur={formik.handleBlur}
                  />
                  <div className="ml-10 flex">
                    <FFormInvalidInput
                      touched={formik.touched}
                      FieldName={'phoneInput'}
                      errors={formik.errors}
                    />
                  </div>
                </div>
              </FFormWrapper>
              {errorPhone.length > 0 && (
                <div>
                  <small className={'ml-10  flex font-medium text-red-700'}>
                    {errorPhone}
                  </small>
                </div>
              )}
            </>
          )}

          <FButton
            className={'mt-5 flex gap-2'}
            disabled={
              Object.keys(formik.errors).length ||
              formik.values.email === '' ||
              formik.values.password === '' ||
              isLoading
            }
            type="submit"
          >
            <ESpinner isVisible={isLoading} />
            Register
          </FButton>
          <span className={'mt-2 text-center'}>
            Already have an account?
            <Link
              to={'/login'}
              className="mt-4 ml-1 text-center font-semibold text-primary-500"
            >
              Login
            </Link>
          </span>
        </form>
        <ToastContainer position="bottom-right" />
      </div>
    </div>
  )
}
export default Register
