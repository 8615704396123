import { useEffect, useContext } from 'react'
import { TbTruck, TbUser, TbDimensions } from 'react-icons/tb'
import { BsTruckFlatbed, BsBoxSeam, BsTelephone } from 'react-icons/bs'
import {
  MdOutlineMapsHomeWork,
  MdOutlineMailOutline,
  MdOutlineFactCheck,
} from 'react-icons/md'
import { HiOutlineLocationMarker, HiPrinter } from 'react-icons/hi'
import { FaWeightHanging } from 'react-icons/fa'
import FButton from './FButton'
import { useCustomAxios } from '../Hooks/useAxios'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Tooltip } from '@mui/material'
import ESpinner from './ESpinner'
import { UserContext } from './UserContext'

const RBol = () => {
  const [searchParams] = useSearchParams()
  const LoadId = searchParams.get('load')
  const navigate = useNavigate()
  const { user } = useContext(UserContext)

  useEffect(() => {
    if (!localStorage.getItem('access')) {
      window.location.href = '/login'
    }
  }, [])

  const { response, loading } = useCustomAxios({
    url:
      user?.selected_role === 'manager'
        ? `/manager/billing/?load=${LoadId}`
        : `/docs/billing/?load=${LoadId}`,
    method: 'GET',
  })

  const handlePrint = () => {
    window.print()
  }
  function closeTab() {
    window.close()
  }
  const generatedAt = response?.generated_at
  const formattedDate = new Date(generatedAt).toLocaleDateString('en-US', {
    //i want to display date in this format: Tue Nov 22, 2016 12:58pm MST (i.e. Tue Nov 22, 2016 12:58pm MST)
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  })
  if (loading) {
    return (
      <div className={'f-col-center-center h-full w-full'}>
        <ESpinner isVisible={true} size={30} />
        <span className="mt-5">Loading...</span>
      </div>
    )
  } else if (response === undefined) {
    navigate('/404')
  } else
    return (
      <>
        <div className="z-0 h-full overflow-auto w-full bg-gray-200">
          <div className="relative py-5 z-10 mx-auto my-5 w-a4 border bg-white pb-2 shadow-lg  print:border-0 print:shadow-none">
            <HiPrinter
              onClick={handlePrint}
              size={25}
              className="absolute right-0 top-0 m-3 transform cursor-pointer transition  duration-300 hover:scale-110 print:hidden "
              style={{ color: '#D86407' }}
            />

            <div className="px-5">
              <div className="flex flex-col  text-center">
                <span className=" text-xl font-semibold  text-gray-700 ">
                  Streamline LLC Delivey Rate Confirmation
                </span>
                <span className="text-sm text-gray-500">{formattedDate}</span>
              </div>

              {/* ----------------------------------------LOAD----------------------------------------------------------- */}

              <div className="mt-5 flex text-gray-700">
                <div className="flex gap-1 border p-1">
                  <TbTruck size={27} />
                  <span className=" text-xl font-semibold ">
                    Load {response?.load_name}
                  </span>
                </div>
              </div>

              {/* ----------------------------------------SHIPPER----------------------------------------------------------- */}
              <div className="mt-1 flex items-center text-gray-700">
                <div className=" flex  gap-1">
                  <TbUser size={27} />
                  <span className="text-xl font-semibold">Shipper</span>
                </div>
              </div>

              <div className="flex  text-sm ">
                <div className=" w-1/2 p-2">
                  <h3 className="font-inter text-gray-400 ">Contact</h3>
                  <p className=" mt-2 flex gap-1">
                    <Tooltip title={'Facility name'} placement={'top'} arrow>
                      <div>
                        <MdOutlineMapsHomeWork style={{ color: '#D86407' }} size={24} />
                      </div>
                    </Tooltip>
                    {response?.shipper_facility_name}
                  </p>
                  <p className=" mt-2 flex gap-1">
                    <Tooltip title={'Telephone'} placement={'top'} arrow>
                      <div>
                        <BsTelephone style={{ color: '#D86407' }} size={22} />
                      </div>
                    </Tooltip>
                    {response?.shipper_phone_number}
                  </p>

                  <p className=" mt-2 flex gap-1 whitespace-nowrap">
                    <span>
                      <Tooltip title={'Facility address'} placement={'top'} arrow>
                        <div>
                          <HiOutlineLocationMarker style={{ color: '#D86407' }} size={22} />
                        </div>
                      </Tooltip>
                    </span>
                    {response?.shipper_facility_address}
                  </p>
                </div>
                <div className="w-1/3 p-2">
                  <h3 className="font-inter  text-gray-400 ">Information</h3>
                  <p className=" mt-2 flex gap-1">
                    <Tooltip title={'Dimensions'} placement={'top'} arrow>
                      <div>
                        <TbDimensions className="text-gray-400" size={24} />
                      </div>
                    </Tooltip>
                    {response?.length ? parseFloat(response?.length).toFixed(1) : 'N/A'} x{' '}
                    {response?.width ? parseFloat(response?.width).toFixed(1) : 'N/A'} x{' '}
                    {response?.height ? parseFloat(response?.height).toFixed(1) : 'N/A'} in
                  </p>
                  <p className=" mt-2 flex gap-1">
                    <Tooltip title={'Weight'} placement={'top'} arrow>
                      <div>
                        <FaWeightHanging className=" text-gray-400" size={22} />
                      </div>
                    </Tooltip>
                    {response?.weight ? parseFloat(response?.weight).toFixed(1) : 'N/A'} lbs
                  </p>
                </div>
                <div className="w-1/3 p-2">
                  <h3 className="font-inter  text-gray-400 ">Pickup date</h3>
                  <p className=" mt-2 flex gap-1"> {response?.pickup_date}</p>
                </div>
              </div>
              <hr className="" />
              {/* ----------------------------------------RECIEVER----------------------------------------------------------- */}

              <div className="mt-1 flex text-gray-700">
                <div className=" flex gap-1">
                  <TbUser size={27} />
                  <span className="text-xl font-semibold">Receiver </span>
                </div>
              </div>

              <div className="flex  text-sm ">
                <div className=" w-1/2 p-2">
                  <h3 className="font-inter text-gray-400 ">Contact</h3>
                  <p className=" mt-2 flex gap-1">
                    <Tooltip title={'Facility name'} placement="top" arrow>
                      <div>
                        {' '}
                        <MdOutlineMapsHomeWork style={{ color: '#D86407' }} size={24} />
                      </div>
                    </Tooltip>
                    {response?.consignee_facility_name}
                  </p>
                  <p className=" mt-2 flex gap-1">
                    <Tooltip title={'Telephone'} placement="top" arrow>
                      <div>
                        {' '}
                        <BsTelephone style={{ color: '#D86407' }} size={22} />
                      </div>
                    </Tooltip>
                    {response?.consignee_phone_number}
                  </p>

                  <p className=" mt-2 flex gap-1 whitespace-nowrap">
                    <span>
                      <Tooltip title={'Facility address'} arrow placement="top">
                        <div>
                          <HiOutlineLocationMarker style={{ color: '#D86407' }} size={22} />
                        </div>
                      </Tooltip>
                    </span>
                    {response?.consignee_facility_address}
                  </p>
                </div>
                <div className="w-1/3 p-2">
                  <h3 className="font-inter  text-gray-400 ">Information</h3>
                  <p className=" mt-2 flex gap-1">
                    <Tooltip title={'Dimensions'} placement={'top'} arrow>
                      <div>
                        <TbDimensions className="text-gray-400" size={24} />
                      </div>
                    </Tooltip>
                    {response?.length ? parseFloat(response?.length).toFixed(1) : 'N/A'} x{' '}
                    {response?.width ? parseFloat(response?.width).toFixed(1) : 'N/A'} x{' '}
                    {response?.height ? parseFloat(response?.height).toFixed(1) : 'N/A'} in
                  </p>
                  <p className=" mt-2 flex gap-1">
                    <Tooltip title={'Weight'} placement={'top'} arrow>
                      <div>
                        <FaWeightHanging className=" text-gray-400" size={22} />
                      </div>
                    </Tooltip>
                    {response?.weight ? parseFloat(response?.weight).toFixed(1) : 'N/A'} lbs
                  </p>
                </div>
                <div className="w-1/3 p-2">
                  <h3 className="font-inter  text-gray-400 ">Dropoff date</h3>
                  <p className=" mt-2 flex gap-1">{response?.dropoff_date} </p>
                </div>
              </div>
              <hr className="" />
              {/* ----------------------------------------Carrier----------------------------------------------------------- */}

              <div className="mt-1 flex items-center text-gray-700">
                <div className=" flex  gap-1">
                  <TbUser size={27} />
                  <span className="text-xl font-semibold">Carrier</span>
                </div>
              </div>
              <div className="flex text-sm">
                <div className="w-1/2 p-2 ">
                  <h3 className="font-inter text-sm text-gray-400 ">Contact</h3>
                  <p className=" mt-2 flex gap-1">
                    <Tooltip title="Company name" placement={'top'} arrow>
                      <div>
                        <MdOutlineMapsHomeWork style={{ color: '#D86407' }} size={24} />
                      </div>
                    </Tooltip>
                    {response?.carrier_billing_name}
                  </p>
                  <div className="lg:flex lg:gap-6">
                    <p className=" mt-2 flex gap-1">
                      <Tooltip title="Telephone" placement={'top'} arrow>
                        <div>
                          <BsTelephone style={{ color: '#D86407' }} size={22} />
                        </div>
                      </Tooltip>
                      {response?.carrier_billing_phone_number}
                    </p>
                  </div>
                  <p className=" mt-2 flex gap-1 whitespace-nowrap  ">
                    <span>
                      <Tooltip title="Company location" placement={'top'} arrow>
                        <div>
                          <HiOutlineLocationMarker style={{ color: '#D86407' }} size={22} />
                        </div>
                      </Tooltip>
                    </span>{' '}
                    {response?.carrier_billing_address}
                  </p>
                </div>
                <div className="w-1/3 p-2 ">
                  <h3 className="font-inter text-sm text-gray-400 ">Information</h3>
                  <p className=" mt-2 flex gap-1">
                    <Tooltip title={'Equipment'} placement={'top'} arrow>
                      <div>
                        <BsTruckFlatbed className="text-gray-400" size={24} />{' '}
                      </div>
                    </Tooltip>
                    {response?.equipment}
                  </p>
                  <p className=" mt-2 flex gap-1">
                    <Tooltip title="Commodity" placement={'top'} arrow>
                      <BsBoxSeam className="text-gray-400" size={22} />
                    </Tooltip>{' '}
                    {response?.commodity}
                  </p>
                </div>
                <div className="w-1/3 p-2 ">
                  <h3 className="font-inter text-sm text-gray-400 ">Load type</h3>
                  <p className=" mt-2 flex gap-1">{response?.load_type}</p>
                </div>
              </div>
              <hr />
              {/* ----------------------------------------TERMS----------------------------------------------------------- */}
              <div className="mt-2 flex text-gray-700">
                <div className=" flex gap-1">
                  <div className="flex flex-col">
                    <span className="flex text-xl font-semibold">
                      {' '}
                      <MdOutlineFactCheck size={27} /> Terms of service
                    </span>
                    <span className="text-xs text-gray-400">Last updated April 2020</span>
                  </div>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="mt-1 w-2/3">
                  that the carrier is responsible for the safe and timely transportation of
                  the goods and liable for any loss, damage, or delay that occurs during
                  transportation, except in cases of unavoidable accidents or circumstances
                  beyond the carriers control.
                  <ul className="list-disc  px-5 text-xs  ">
                    <li>
                      The BOL should include a detailed description of the goods being
                      transported, including their quantity, weight, dimensions, and any
                      special handling or storage requirements.
                    </li>
                    <li>
                      The BOL should state the agreed-upon freight charges for the
                      transportation of the goods, and the shipper is responsible for paying
                      the freight charges, unless otherwise agreed upon in writing.
                    </li>
                  </ul>
                </div>

                <div className="mt-1 flex w-1/3 flex-col gap-2">
                  {' '}
                  <p className=" flex items-center gap-1 text-lg ">
                    <Tooltip title={'Company name'} arrow placement="top">
                      <div>
                        <MdOutlineMapsHomeWork style={{ color: '#D86407' }} size={24} />
                      </div>
                    </Tooltip>
                    <span className="font-semibold">
                      {response?.dispatcher_billing_name}
                    </span>
                  </p>
                  <p className="flex items-center gap-1 ">
                    <Tooltip title={'Company location'} arrow placement="top">
                      <div>
                        <HiOutlineLocationMarker style={{ color: '#D86407' }} size={22} />
                      </div>
                    </Tooltip>
                    {response?.dispatcher_billing_address}
                  </p>
                  <p className=" flex items-center gap-1">
                    <Tooltip title={'Dispatcher name'} arrow placement="top">
                      <div>
                        <TbUser style={{ color: '#D86407' }} size={22} />
                      </div>
                    </Tooltip>
                    <span className="font-semibold">{response?.dispatcher_full_name}</span>
                  </p>
                  <p className="flex items-center gap-1">
                    <Tooltip title={'E-mail address'} arrow placement="top">
                      <div>
                        <MdOutlineMailOutline style={{ color: '#D86407' }} size={24} />{' '}
                      </div>
                    </Tooltip>
                    {response?.dispatcher_email}
                  </p>
                  <p className=" flex items-center gap-1">
                    <Tooltip title={'Telephone'} arrow placement="top">
                      <div>
                        <BsTelephone style={{ color: '#D86407' }} size={22} />
                      </div>
                    </Tooltip>
                    {response?.dispatcher_phone_number}
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-2 flex justify-center gap-20 print:hidden">
              {/* <FButton btnType={'success'}>I AGREE</FButton> */}
              <FButton btnType={'secondary'} onClick={closeTab}>
                CANCEL
              </FButton>
            </div>
          </div>
        </div>
      </>
    )
}

export default RBol