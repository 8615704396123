import React from 'react'
import FButton from '../../../components/FButton'
import FFormWrapper from '../../../components/FFormWrapper'
import FInputField from '../../../components/FInputField'
import FLabel from '../../../components/FLabel'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { SITE_LOGO } from '../../../env'
import * as Yup from 'yup'
import FFormInvalidInput from '../../../components/FFormInvalidInput'
import { useFormik } from 'formik'
import FileUploadButton from '../../../components/FileUploadButton '

const PersonalInfoStep = ({ formData, setFormData, nextStep }) => {
  const handleChange = (e) => {
    const { name, value } = e.target

    // For non-file inputs, update both formData and Formik values
    setFormData({ ...formData, [name]: value })
    Formik.handleChange(e)
  }

  const phoneRegExp = /^(\+?(1|52)[- ]?)?(\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$/

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
    email: Yup.string().email('Please enter a valid email').required('Email is required'),
    personal_phone_number: Yup.string()
      .required('Phone number is required')
      .matches(phoneRegExp, 'Phone number is not valid'),
    sid_photo: Yup.array()
      .of(Yup.mixed().required())
      .nullable()
      .required('Id picture is required.'),

    personal_photo: Yup.array()
      .of(Yup.mixed().required())
      .nullable()
      .required('Profile picture is required.'),
  })

  const Formik = useFormik({
    initialValues: {
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      personal_phone_number: formData.personal_phone_number || '',
      sid_photo: formData.sid_photo,
      personal_photo: formData.personal_photo,
    },

    validationSchema: validationSchema,
    onSubmit: () => {
      // This function will be called if the form is valid
      nextStep()
    },
  })

  return (
    <form className="flex w-full flex-col p-5" onSubmit={Formik.handleSubmit}>
      <img
        src={SITE_LOGO}
        width={250}
        alt={'SITE_LOGO'}
        className="mx-auto mb-2 flex"
        style={{ maxHeight: '200px' }}
      />
      <span className=" text-center text-lg font-semibold text-gray-700 ">
        Let’s have a new beginning, Sign up as a company manager{' '}
      </span>
      <div className="justify-between gap-3 lg:flex">
        <FFormWrapper className="lg:w-1/2">
          <FLabel htmlFor={'first_name'}>First name</FLabel>
          <FInputField
            id={'first_name'}
            name="first_name"
            placeholder="Enter your First name"
            value={Formik.values.first_name}
            type="text"
            onChange={handleChange}
            onBlur={Formik.handleBlur}
          />
          <FFormInvalidInput
            touched={Formik.touched}
            FieldName="first_name"
            errors={Formik.errors}
          />
        </FFormWrapper>
        <FFormWrapper className="lg:w-1/2">
          <FLabel htmlFor={'last_name'}>Last name</FLabel>
          <FInputField
            id={'last_name'}
            name="last_name"
            placeholder="Enter your Last name"
            value={Formik.values.last_name}
            type="text"
            onChange={handleChange}
            onBlur={Formik.handleBlur}
          />
          <FFormInvalidInput
            touched={Formik.touched}
            FieldName="last_name"
            errors={Formik.errors}
          />
        </FFormWrapper>
      </div>
      <div className="justify-between gap-3 lg:flex">
        <FFormWrapper className="lg:w-1/2">
          <FLabel htmlFor={'email'}>Work-Email</FLabel>
          <FInputField
            id={'email'}
            name="email"
            placeholder="Enter your email address"
            type="email"
            value={Formik.values.email}
            onChange={handleChange}
            onBlur={Formik.handleBlur}
          />
          <FFormInvalidInput
            touched={Formik.touched}
            FieldName="email"
            errors={Formik.errors}
          />
        </FFormWrapper>

        <FFormWrapper className="lg:w-1/2">
          <FLabel htmlFor={'personal_phone_number'}>Phone number</FLabel>

          <PhoneInput
            name="personal_phone_number"
            id={'personal_phone_number'}
            className={'input-phone-number '}
            placeholder="Enter phone number"
            defaultCountry="US"
            countries={['US', 'MX', 'CA']}
            value={Formik.values.personal_phone_number}
            onChange={(e) => {
              setFormData({ ...formData, personal_phone_number: e })
              Formik.setFieldValue('personal_phone_number', e)
            }}
            onBlur={Formik.handleBlur}
          />
          <FFormInvalidInput
            touched={Formik.touched}
            FieldName="personal_phone_number"
            errors={Formik.errors}
          />
        </FFormWrapper>
      </div>
      <div className="justify-between gap-5 lg:flex">
        <FFormWrapper className="lg:w-1/2">
          <FLabel htmlFor={'sid_photo'}>Upload your SID </FLabel>
          <FileUploadButton
            initialFiles={Formik.values.sid_photo}
            acceptedTypes={['image/png', 'image/jpeg', 'application/pdf']}
            onFileChange={(files) => {
              Formik.setFieldValue('sid_photo', files)
              setFormData({ ...formData, sid_photo: files })
            }}
            onBlur={Formik.handleBlur}
            name={'sid_photo'}
            multiple={false}
          />

          <FFormInvalidInput
            touched={Formik.touched}
            FieldName="sid_photo"
            errors={Formik.errors}
          />
        </FFormWrapper>
        <FFormWrapper className="w-full lg:w-1/2">
          <FLabel htmlFor={'personal_photo'}>Upload your picture </FLabel>

          <FileUploadButton
            initialFiles={Formik.values.personal_photo}
            acceptedTypes={['image/png', 'image/jpeg', 'application/pdf']}
            onFileChange={(files) => {
              Formik.setFieldValue('personal_photo', files)
              setFormData({ ...formData, personal_photo: files })
            }}
            onBlur={Formik.handleBlur}
            name={'personal_photo'}
            multiple={false}
          />

          <FFormInvalidInput
            touched={Formik.touched}
            FieldName="personal_photo"
            errors={Formik.errors}
          />
        </FFormWrapper>
      </div>

      <FButton
        type="submit"
        className={'mx-auto mt-5 flex w-full justify-center gap-2 lg:w-8/12'}
      >
        Next
      </FButton>
    </form>
  )
}

export default PersonalInfoStep
