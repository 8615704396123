import LoadForm from "./LoadForm"
import useLoad from "./useLoad"
import { useFormik } from 'formik'
import {
    customValidation,
    getValidationSchema,
} from "../utils"

import { useCustomAxios } from "../../../Hooks/useAxios"
import { useEffect } from "react"

const UpdateDraftForm = () => {
    const {
        id,
        initialValues,
        loadInfo,
        setUnits,
        setIsExistingShipment,
        setSelectorKeyForPickup,
        setSelectorKeyForDrop,
        updateDraft,
        mapLoad,
        onSubmit: createExistingLoadFromDraft
    } = useLoad({ formType: 'draft' })

 
    const { response: draftedLoad, loading } = useCustomAxios({
        url: `/shipment/load/draft/${id}/`,
        method: 'GET',
    })

    useEffect(() => {
        setSelectorKeyForPickup(Math.random())
        setSelectorKeyForDrop(Math.random())
    }
        , [draftedLoad])

    const Formik = useFormik({
        initialValues: mapLoad(draftedLoad) ?? initialValues,
        onSubmit: (values) => createExistingLoadFromDraft({ ...values, id }),
        validationSchema: getValidationSchema(),
        validate: (values) => customValidation(values, loadInfo.isExistingShipment),
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
    })
    console.log('loadInfo', loadInfo)
    console.log('draftedLoad', draftedLoad)
    console.log('Formik', Formik)
     

   
    return (

        <LoadForm
            formType='draft'
            Formik={Formik}
            loadInfo={loadInfo}
            formTitle='Update Draft Load'
            setUnits={setUnits}
            setIsExistingShipment={setIsExistingShipment}
            setSelectorKeyForDrop={setSelectorKeyForDrop}
            setSelectorKeyForPickup={setSelectorKeyForPickup}
            primaryBtnTitle='Create'
            primaryLoading={loadInfo.creatLoadLoading}
            secondaryBtnTitle='Save Changes'
            secondaryLoading={loadInfo.draftLoading}
            onClickSecondary={() => updateDraft(Formik.values)}
            isLoading={loading}
        />
    )
}

export default UpdateDraftForm