import {
  MdOutlineAdminPanelSettings,
  MdOutlineModeEditOutline,
  MdOutlineDeleteOutline,
  MdDomain,
} from 'react-icons/md'
import { GiCargoCrate } from 'react-icons/gi'
import { TbHexagonLetterS } from 'react-icons/tb'
import { PiDotsThreeCircleLight } from 'react-icons/pi'

const getInsuranceTypeData = (type) => {
  const defaultData = { color: 'gray', Icon: MdDomain } // Replace DefaultIcon with your default icon
  const insuranceTypeData = {
    Auto : { color: 'orange', Icon: MdDomain },
    Cargo: { color: 'green', Icon: GiCargoCrate },
    Liability: { color: 'blue', Icon: MdOutlineAdminPanelSettings },
    Spike: { color: 'red', Icon: TbHexagonLetterS },
    Other: { color: 'yellow', Icon: PiDotsThreeCircleLight },
  }

  return insuranceTypeData[type] || defaultData
}

const InsuranceList = ({
  insuranceData,
  deleteInsurance = undefined,
  handleEditInsurance = undefined,
}) => {
  const canDelete = typeof deleteInsurance === 'function' && deleteInsurance
  const canEdit = typeof handleEditInsurance === 'function' && handleEditInsurance

  return insuranceData?.results?.map((insurance) => {
    const { color, Icon } = getInsuranceTypeData(insurance?.type.type)

    return (
      <div
        key={insurance.id}
        className="my-5 mx-auto flex w-11/12 flex-col bg-white md:flex-row relative"
      >
        <div className="flex w-full flex-col   md:w-2/12">
          <div className={`bg-${color}-100 my-auto rounded-md p-10`}>
            <Icon size={50} className={`mx-auto text-${color}-600`} />
          </div>
          <span className="mx-auto pt-2 text-center font-medium">
            {insurance.type.type.charAt(0).toUpperCase() +
              insurance.type.type.slice(1) +
              ' ' +
              'Insurance'}
          </span>
          <span className="mx-auto pb-1 text-center text-sm  text-gray-400 ">
            {'Name:' +
              ' ' +
              insurance.name.charAt(0).toUpperCase() +
              insurance.name.slice(1)}
          </span>
        </div>
        <div className=" flex w-full flex-col justify-between px-5 md:w-10/12 md:flex-row ">
          <div className="my-auto flex flex-col gap-5">
            <div className="flex gap-1">
              <p className="font my-auto text-gray-400">Provider: </p>
              <p className="text-lg">{insurance?.provider}</p>
            </div>
            <div className="flex gap-1">
              <p className="font my-auto text-gray-400">Policy Number: </p>
              <p className="text-lg">{insurance.policy_number}</p>
            </div>
          </div>
          <div className="my-auto flex flex-col gap-5">
            <div className="flex gap-1">
              <p className="font my-auto text-gray-400">Premium Amount: </p>
              <p className="text-lg">{insurance.premium_amount}</p>
            </div>
            <div className="flex gap-1">
              <p className="font my-auto text-gray-400">Expiration Date: </p>
              <p className="text-lg">{insurance.expiration_date}</p>
            </div>
          </div>
          <div className="flex gap-2 p-2">
            {canDelete && (
              <MdOutlineModeEditOutline
                size={20}
                className={'cursor-pointer text-gray-500 hover:text-gray-700'}
                onClick={() => handleEditInsurance(insurance)}
              />
            )}
            {canEdit && (
              <MdOutlineDeleteOutline
                size={20}
                className={'cursor-pointer text-gray-500 hover:text-gray-700'}
                onClick={() => deleteInsurance(insurance.id)}
              />
            )}
          </div>
          {insurance?.is_expired && (
          <div className="absolute top-0 -left-2 rotate-12 transform bg-red-500 p-1  font-semibold text-white shadow-md">
            Expired
          </div>
        )}
        </div>
      </div>
    )
  })
}

export default InsuranceList
