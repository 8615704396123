import FLabel from './FLabel'
import FInputField from './FInputField'
import FFormWrapper from './FFormWrapper'
import React from 'react'
import FFormInvalidInput from './FFormInvalidInput'
const FInputFieldCompound = ({
  Formik,
  fieldName,
  label,
  placeHolder,
  type = 'text',
  className,
  ...props
}) => {
  return (
    <>
      <FFormWrapper className={className && className}>
        <FLabel htmlFor={fieldName}>{label}</FLabel>
        <FInputField
          type={type}
          id={fieldName}
          name={fieldName}
          placeholder={placeHolder ? placeHolder : 'Enter ' + label}
          onChange={Formik.handleChange}
          value={Formik.values[fieldName]}
          onBlur={Formik.handleBlur}
          {...props}
        />
        <FFormInvalidInput
          touched={Formik.touched}
          FieldName={fieldName}
          errors={Formik.errors}
        />
      </FFormWrapper>
    </>
  )
}
export default FInputFieldCompound
