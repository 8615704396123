import { useFormik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import { HiLogout } from 'react-icons/hi'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import * as Yup from 'yup'
import { axiosInstance } from '../../axios/requister'
import ESpinner from '../../components/ESpinner'
import FButton from '../../components/FButton'
import FFormInvalidInput from '../../components/FFormInvalidInput'
import FIconWrapper from '../../components/FIconWrapper'
import FInputField from '../../components/FInputField'
import FLabel from '../../components/FLabel'
import { UserContext } from '../../components/UserContext'

const RoleForm = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [errorTax, setErrorTax] = useState([])
  const [errorId, setErrorId] = useState('')
  const { user, refreshUser, logout } = useContext(UserContext)
  const [errorPhone, setErrorPhone] = useState([])
  const [companyIdError, setCompanyIdError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isCompanyLoading, setIsCompanyLoading] = useState(false)
  const [validationSchema, setValidationSchema] = useState({})
  const [hasCompanyDomain, setHasCompanyDomain] = useState(false)
  const [companyDetails, setCompanyDetails] = useState({})
  const [tab, setTab] = useState('companyId')
  const label = 'block text-lg  text-gray-700 font-semibold my-auto'
  const info = 'block text-gray-500 my-auto ml-2'

  useEffect(() => {
    const checkCompanyDomain = async () => {
      const domain = localStorage.getItem('email').split('@')[1]
      const personalDomains = [
        'gmail.com',
        'yahoo.com',
        'hotmail.com',
        'outlook.com',
        'aol.com',
        'icloud.com',
        'zoho.com',
        'protonmail.com',
        'mail.com',
      ]

      if (!personalDomains.includes(domain)) {
        try {
          setIsCompanyLoading(true)
          const res = await axiosInstance.get(
            `/authentication/check-company/?domain=${domain}`
          )
          setHasCompanyDomain(true)
          setCompanyDetails(res.data)
        } catch (error) {
          setHasCompanyDomain(false)
        } finally {
          setIsCompanyLoading(false)
        }
      } else {
        setHasCompanyDomain(false)
      }
    }

    checkCompanyDomain()
  }, []) // Empty dependency array ensures this effect runs once when the component mounts

  useEffect(() => {}, [hasCompanyDomain]) // Log whenever hasCompanyDomain changes

  const phoneRegExp = /^(\+?(1|52)[- ]?)?(\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$/

  useEffect(() => {
    const commonCompanyIdSchema = {
      companyIdentifier: Yup.string().required('Company ID is required'),
      phoneInput: Yup.string()
        .required('Phone number is required')
        .matches(phoneRegExp, 'Phone number is not valid'),
    }

    const commonTinNumberSchema = {
      phoneInput: Yup.string()
        .required('Phone number is required')
        .matches(phoneRegExp, 'Phone number is not valid'),
      taxNumber: Yup.string()
        .required('TIN number is required')
        .matches(/^\d{9}$/, 'TIN number must be exactly 9 numeric digits')
        .typeError('TIN number must be a number'),
      address: Yup.string().required('Address is required'),
      city: Yup.string().required('City is required'),
      state: Yup.string().required('State is required'),
      country: Yup.string().required('Country is required'),
      zip_code: Yup.string().required('Zip code is required'),
    }

    const commonOtherSchema = {
      phoneInput: Yup.string()
        .required('Phone number is required')
        .matches(phoneRegExp, 'Phone number is not valid'),
    }

    const dispatcherSchema = {
      MCNumber: Yup.string()
        .required('MC number is required')
        .typeError('MC number must be a number'),
    }

    const carrierSchema = {
      DOTNumber: Yup.string()
        .required('DOT number is required')
        .typeError('DOT number must be a number'),
    }

    let newValidationSchema = {}

    if (!hasCompanyDomain) {
      // Company domain exists, update validation schema accordingly
      if (tab === 'companyId') {
        if (searchParams.get('role') === 'Dispatcher') {
          newValidationSchema = {
            ...commonCompanyIdSchema,
            ...dispatcherSchema,
          }
        } else if (searchParams.get('role') === 'Carrier') {
          newValidationSchema = {
            ...commonCompanyIdSchema,
            ...carrierSchema,
          }
        } else {
          newValidationSchema = { ...commonCompanyIdSchema }
        }
      } else if (tab === 'tinNumber') {
        if (searchParams.get('role') === 'Dispatcher') {
          newValidationSchema = {
            ...commonTinNumberSchema,
            ...dispatcherSchema,
          }
        } else if (searchParams.get('role') === 'Carrier') {
          newValidationSchema = {
            ...commonTinNumberSchema,
            ...carrierSchema,
          }
        } else {
          newValidationSchema = { ...commonTinNumberSchema }
        }
      } else if (tab === 'other') {
        newValidationSchema = { ...commonOtherSchema }
      }
    } else {
      // Company domain  exist, update validation schema accordingly
      if (searchParams.get('role') === 'Dispatcher') {
        newValidationSchema = {
          ...dispatcherSchema,
          ...commonOtherSchema,
        }
      }
      if (searchParams.get('role') === 'Carrier') {
        newValidationSchema = {
          ...carrierSchema,
          ...commonOtherSchema,
        }
      }
      if (searchParams.get('role') === 'Shipment Party') {
        newValidationSchema = {
          ...commonOtherSchema,
        }
      }
    }

    setValidationSchema(Yup.object(newValidationSchema))
  }, [tab, hasCompanyDomain])

  useEffect(() => {
    if (!localStorage.getItem('access')) {
      window.location.href = '/login'
    }

    if (user) {
      axiosInstance
        .get(`authentication/send-invite/`)
        .then(() => {
          navigate('/contact')
        })
        .catch(() => {
          navigate('/dashboard')
        })
    }
  }, [])

  const inviteRequest = () => {
    axiosInstance
      .get(`authentication/send-invite/`)
      .then(() => {
        navigate('/contact')
      })
      .catch(() => {
        navigate('/dashboard')
      })
  }

  // Formik Implementation

  const Formik = useFormik({
    initialValues: {
      phoneInput: '',
      taxNumber: '',
      MCNumber: '',
      DOTNumber: '',
      address: '',
      city: '',
      state: '',
      zip_code: '',
      country: '',
      companyIdentifier: '',
    },
    validationSchema: validationSchema,

    onSubmit: (values) => {
      setIsLoading(true)
      let url, data
      let companyIdNumber = null
      let appUserId = null
      if (searchParams.get('role') === 'Dispatcher') {
        url = '/authentication/dispatcher/'
        data = { MC_number: Formik.values.MCNumber }
      } else if (searchParams.get('role') === 'Carrier') {
        url = '/authentication/carrier/'
        data = { DOT_number: Formik.values.DOTNumber }
      }
      if (searchParams.get('role') === 'Shipment Party') {
        url = '/authentication/shipment-party/'
        data = { app_user: ' ' }
      }
      setErrorId('')
      setErrorPhone('')
      setErrorTax('')
      setCompanyIdError('')

      if (!hasCompanyDomain) {
        if (tab === 'companyId') {
          axiosInstance
            .get(`/authentication/check-company/?id=${Formik.values.companyIdentifier}`)
            .then((res) => {
              companyIdNumber = res.data.id
              axiosInstance
                .post(`/authentication/app-user/`, {
                  phone_number: values.phoneInput,
                  user_type: searchParams.get('role').toLowerCase(),
                  selected_role: searchParams.get('role').toLowerCase(),
                })
                .then((res) => {
                  appUserId = res.data.id
                  axiosInstance
                    .post(url, data)
                    .then(() => {
                      setIsLoading(false)
                      refreshUser()
                      inviteRequest()
                      setTimeout(() => {
                        toast.success('App user created successfully')
                      }, 200)
                      localStorage.setItem(
                        'roles',
                        searchParams.get('role').toLowerCase()
                      )
                    })
                    .catch((err) => {
                      setIsLoading(false)
                      if (err.response.data.detail) {
                        setErrorId(err.response.data.detail)
                      }
                    })

                  axiosInstance.post('/authentication/company-employee/', {
                    company: companyIdNumber,
                    app_user: appUserId,
                  })
                })
                .catch((err) => {
                  setIsLoading(false)
                  toast.error('App user creation failed')
                  Object.keys(err.response.data).map(() => {
                    if (err.response.data.includes('already exists')) {
                      setErrorPhone('Phone number already exists')
                    } else {
                      setErrorPhone('something went wrong')
                    }
                  })
                })
            })
            .catch((error) => {
              setIsLoading(false)
              if (error.response.status === 404) {
                setCompanyIdError('Company ID does not exist')
              } else {
                setCompanyIdError('Something went wrong')
              }
              toast.error('App user creation failed')
            })
        } else if (tab === 'tinNumber') {
          axiosInstance
            .post('authentication/app-user/', {
              phone_number: values.phoneInput,
              user_type: searchParams.get('role').toLowerCase(),
              selected_role: searchParams.get('role').toLowerCase(),
            })
            .then(() => {
              axiosInstance
                .post('authentication/user-tax/', {
                  TIN: values.taxNumber,
                  address: values.address,
                  city: values.city,
                  state: values.state,
                  country: values.country,
                  zip_code: values.zip_code,
                  first: true,
                })
                .then(() => {
                  axiosInstance

                    .post(url, data)
                    .then(() => {
                      setIsLoading(false)
                      refreshUser()
                      inviteRequest()
                      setTimeout(() => {
                        toast.success('App user created successfully')
                      }, 200)
                      localStorage.setItem(
                        'roles',
                        searchParams.get('role').toLowerCase()
                      )
                    })
                    .catch((err) => {
                      setIsLoading(false)
                      if (err.response.data.detail) {
                        setErrorId(err.response.data.detail)
                      }
                    })
                })
                .catch((err) => {
                  setIsLoading(false)
                  Object.keys(err.response.data.TIN).map((key) => {
                    setErrorTax([err.response.data.TIN[key]])
                  })
                })
            })
            .catch((err) => {
              setIsLoading(false)
              toast.error('App user creation failed')
              Object.keys(err.response.data).map(() => {
                if (err.response.data.includes('already exists')) {
                  setErrorPhone('Phone number already exists')
                } else {
                  setErrorPhone('something went wrong')
                }
              })
            })
        } else if (tab === 'other') {
          axiosInstance
            .post('authentication/app-user/', {
              phone_number: values.phoneInput,
              user_type: searchParams.get('role').toLowerCase(),
              selected_role: searchParams.get('role').toLowerCase(),
            })
            .then(() => {
              axiosInstance
                .post('/authentication/shipment-party/', {
                  app_user: ' ',
                })
                .then(() => {
                  setIsLoading(false)
                  refreshUser()
                  inviteRequest()
                  setTimeout(() => {
                    toast.success('App user created successfully')
                  }, 200)
                  localStorage.setItem('roles', searchParams.get('role').toLowerCase())
                })
                .catch((err) => {
                  setIsLoading(false)
                  if (err.response.data.detail) {
                    setErrorId(err.response.data.detail)
                  }
                })
            })
            .catch((err) => {
              setIsLoading(false)
              toast.error('App user creation failed')
              Object.keys(err.response.data).map(() => {
                if (err.response.data.includes('already exists')) {
                  setErrorPhone('Phone number already exists')
                } else {
                  setErrorPhone('something went wrong')
                }
              })
            })
        }
      }
      //----------------------------------------------------------------------------------------------
      else {
        axiosInstance
          .post('authentication/app-user/', {
            phone_number: values.phoneInput,
            user_type: searchParams.get('role').toLowerCase(),
            selected_role: searchParams.get('role').toLowerCase(),
          })
          .then((res) => {
            appUserId = res.data.id
            axiosInstance
              .post('/authentication/company-employee/', {
                company: companyDetails.id,
                app_user: appUserId,
              })
              .then(() => {
                axiosInstance
                  .post(url, data)
                  .then(() => {
                    setIsLoading(false)
                    refreshUser()
                    inviteRequest()
                    setTimeout(() => {
                      toast.success('App user created successfully')
                    }, 200)
                    localStorage.setItem('roles', searchParams.get('role').toLowerCase())
                  })
                  .catch((err) => {
                    setIsLoading(false)
                    if (err.response.data.detail) {
                      setErrorId(err.response.data.detail)
                    }
                  })
              })
          })
          .catch((err) => {
            setIsLoading(false)
            toast.error('App user creation failed')
            Object.keys(err.response.data).map(() => {
              if (err.response.data.includes('already exists')) {
                setErrorPhone('Phone number already exists')
              } else {
                setErrorPhone('something went wrong')
              }
            })
          })
      }
    },
  })

  if (isCompanyLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <ESpinner isVisible={isCompanyLoading} />
      </div>
    )
  } else {
    return (
      <>
        {/* Header */}
        <div
          className={'flex h-14 items-center justify-end  divide-x  bg-gray-900 px-3 '}
        >
          <div className={'mr-5 lg:mr-10 '}>
            <button
              className={
                ' cursor-pointer self-center text-base text-white  hover:text-primary-500 '
              }
              onClick={() => logout()}
            >
              <FIconWrapper>
                <HiLogout size={20} />
                <span className={'whitespace-nowrap'}>Logout</span>
              </FIconWrapper>
            </button>
          </div>
        </div>
        <div className="flex flex-col p-5 md:flex-row">
          {/* Left Section  */}
          <div className="m-5 w-full p-4 md:w-1/3  ">
            <div>
              <FIconWrapper>
                <Link
                  to={'/role-select'}
                  className={'hove:border  flex gap-2 rounded p-0.5 hover:bg-gray-100'}
                >
                  <FaArrowLeft size={15} className="my-auto text-primary-500" />
                  <span className={' text-sm font-semibold text-primary-500'}>
                    Back To Select Role
                  </span>
                </Link>
              </FIconWrapper>
            </div>
            <p className="mb-4  mt-5 text-3xl font-bold text-gray-700 lg:mt-10">
              Welcome to Frieghtslayer!
            </p>
            <p className="text-gray-700">
              We are thrilled that you want to join our community of logistics
              professionals.
              <br /> At Freight Slayer, we are dedicated to providing top-notch brokerage
              services to our clients To get started with Freight Slayer, <br /> please
              fill out the form below so we can get to know you and your shipping
              requirements better.
            </p>
            <div className="lg:mt-30 mt-10 flex w-fit flex-col items-center bg-primary-50 p-3 md:flex-row ">
              <div className=" md:w-2/3 ">
                <p className="mb-2 text-lg font-bold text-gray-700">Policy Question?</p>

                <p className="text-gray-700">
                  If you have any questions or concerns, please feel free to contact us by
                  clicking the button below
                </p>
                <div className="mt-2 flex items-center">
                  <button
                    onClick={() =>
                      window.open(`${window.location.origin}/help-center`, '_blank')
                    }
                    className="flex items-center text-sm font-semibold text-primary-500  "
                  >
                    Contact Us <FaArrowRight className="ml-2" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="m-5 w-full p-5 md:w-2/3 lg:px-40">
            <div>
              <div className="mb-4 mt-5">
                {/* Big form here  */}
                <form
                  onSubmit={Formik.handleSubmit}
                  className=" menu-wrap-y-dashboard max-h-fit border bg-white p-6 shadow-sm "
                >
                  {!hasCompanyDomain ? (
                    <>
                      <h2 className="mt-3 flex justify-center text-lg font-semibold text-gray-700">
                        Sign your selected role information by :
                      </h2>
                      {/* tabs style handling here  */}

                      <div className="mx-auto mt-5 flex w-fit justify-center gap-3 rounded-sm bg-gray-100 py-2 px-10 font-medium ">
                        <div
                          className={`transition-all duration-300 ease-in-out 
      ${tab === 'companyId' ? 'bg-primary-500 text-white' : 'text-gray-700'}
      cursor-pointer rounded-sm p-2`}
                          tabIndex={0} // Make the element focusable
                          onClick={() => {
                            setTab('companyId')
                            Formik.resetForm()
                            setErrorId ('')
                          }}
                          onKeyDown={(e) => {
                            // Handle keyboard events, e.g., pressing Enter key
                            if (e.key === 'Enter') {
                              setTab('companyId')
                              Formik.resetForm()
                            }
                          }}
                        >
                          Company ID
                        </div>
                        <div
                          className={`transition-all duration-300
      ${tab === 'tinNumber' ? 'bg-primary-500 text-white' : 'text-gray-700'}
      cursor-pointer rounded-sm p-2 `}
                          tabIndex={0}
                          onClick={() => {
                            setTab('tinNumber')
                            Formik.resetForm()
                            setErrorId ('')

                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              setTab('tinNumber')
                              Formik.resetForm()
                            }
                          }}
                        >
                          TIN Number
                        </div>
                        {searchParams.get('role') === 'Shipment Party' && (
                          <div
                            className={`transition-all duration-300
        ${tab === 'other' ? 'bg-primary-500 text-white' : 'text-gray-700'}
        cursor-pointer rounded-sm p-2`}
                            tabIndex={0}
                            onClick={() => {
                              setTab('other')
                              Formik.resetForm()
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                setTab('other')
                                Formik.resetForm()
                              }
                            }}
                          >
                            Other
                          </div>
                        )}
                      </div>

                      {/* tabs functionality and conditional rendering here */}

                      <div className=" p-5">
                        {/* if the tab is tin number then show this */}
                        {tab === 'tinNumber' && (
                          <>
                            <FLabel htmlFor="taxNumber">
                              Tax Identification Number (TIN)
                            </FLabel>
                            <FInputField
                              name="taxNumber"
                              id="taxNumber"
                              maxLength="9"
                              value={Formik.values.taxNumber}
                              onChange={(e) => {
                                Formik.handleChange(e)
                                setErrorTax([])
                              }}
                              type="text"
                              placeholder="Enter your TIN"
                              pattern="\d{9}"
                              onBlur={Formik.handleBlur}
                            />
                            <div className="flex">
                              <FFormInvalidInput
                                touched={Formik.touched}
                                FieldName="taxNumber"
                                errors={Formik.errors}
                              />
                            </div>

                            <div className="mt-5">
                              <FLabel htmlFor="address">Address</FLabel>
                              <FInputField
                                name="address"
                                id="address"
                                value={Formik.values.address}
                                onChange={Formik.handleChange}
                                type="text"
                                placeholder="Enter your address"
                                onBlur={Formik.handleBlur}
                              />
                              <div className="flex">
                                <FFormInvalidInput
                                  touched={Formik.touched}
                                  FieldName={'address'}
                                  errors={Formik.errors}
                                />
                              </div>
                            </div>
                            <div className="mt-5 grid grid-cols-2 gap-4">
                              <div className="">
                                <FLabel htmlFor="city">City</FLabel>
                                <FInputField
                                  name="city"
                                  id="city"
                                  value={Formik.values.city}
                                  onChange={Formik.handleChange}
                                  type="text"
                                  placeholder="Enter your city"
                                  onBlur={Formik.handleBlur}
                                />
                                <div className="flex">
                                  <FFormInvalidInput
                                    touched={Formik.touched}
                                    FieldName={'city'}
                                    errors={Formik.errors}
                                  />
                                </div>
                              </div>
                              <div className="">
                                <FLabel htmlFor="state">State</FLabel>
                                <FInputField
                                  name="state"
                                  id="state"
                                  value={Formik.values.state}
                                  onChange={Formik.handleChange}
                                  type="text"
                                  placeholder="Enter your state"
                                  onBlur={Formik.handleBlur}
                                />
                                <div className="flex">
                                  <FFormInvalidInput
                                    touched={Formik.touched}
                                    FieldName={'state'}
                                    errors={Formik.errors}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mt-5 grid grid-cols-2 gap-4">
                              <div className="">
                                <FLabel htmlFor="country">Country</FLabel>
                                <FInputField
                                  name="country"
                                  id="country"
                                  value={Formik.values.country}
                                  onChange={Formik.handleChange}
                                  type="text"
                                  placeholder="Enter your country"
                                  onBlur={Formik.handleBlur}
                                />
                                <div className="flex">
                                  <FFormInvalidInput
                                    touched={Formik.touched}
                                    FieldName={'country'}
                                    errors={Formik.errors}
                                  />
                                </div>
                              </div>
                              <div className="">
                                <FLabel htmlFor="zip_code">Zip Code</FLabel>
                                <FInputField
                                  name="zip_code"
                                  id="zip_code"
                                  value={Formik.values.zip_code}
                                  onChange={Formik.handleChange}
                                  type="text"
                                  placeholder="Enter your zip code"
                                  onBlur={Formik.handleBlur}
                                />
                                <div className="flex">
                                  <FFormInvalidInput
                                    touched={Formik.touched}
                                    FieldName={'zip_code'}
                                    errors={Formik.errors}
                                  />
                                </div>
                              </div>
                            </div>
                            {errorTax.length > 0 && (
                              <div>
                                {errorTax.map((error, index) => (
                                  <small className="flex text-red-700" key={index}>
                                    {error}
                                  </small>
                                ))}
                              </div>
                            )}
                          </>
                        )}
                        {/* if the tab is companyId then show this */}
                        {tab === 'companyId' && (
                          <>
                            <FLabel htmlFor="companyIdentifier">
                              Company Identifier
                            </FLabel>
                            <FInputField
                              name="companyIdentifier"
                              id="companyIdentifier"
                              maxLength="10"
                              minLength="10"
                              value={Formik.values.companyIdentifier}
                              onChange={(e) => {
                                Formik.handleChange(e)
                                setCompanyIdError('')
                              }}
                              type="text"
                              placeholder="Enter your Company ID"
                              onBlur={Formik.handleBlur}
                            />
                            <div className="flex">
                              <FFormInvalidInput
                                touched={Formik.touched}
                                FieldName="companyIdentifier"
                                errors={Formik.errors}
                              />
                            </div>
                            {companyIdError.length > 0 && (
                              <div>
                                <small className="flex font-medium text-red-700">
                                  {companyIdError}
                                </small>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    // if you have company domain then show this
                    <>
                      <div className="menu-wrap-y-dashboard max-h-fit p-5">
                        <p className="mb-4 text-center text-lg font-semibold text-gray-700">
                          Welcome back! We have identified that your email domain is
                          associated with {companyDetails?.name}, where you are listed as
                          an employee. Here is some of your company information:
                        </p>
                        <div className="ml-5">
                          <div className=" mt-10 grid grid-cols-2 gap-4  ">
                            <div className="flex">
                              <span className={label}>Company Name:</span>
                              <span className={info}>{companyDetails?.name}</span>
                            </div>

                            <div className="flex">
                              <span className={label}>Company Size:</span>
                              <span className={info}>{companyDetails?.company_size}</span>
                            </div>

                            <div className="flex">
                              <span className={label}>Company EIN:</span>
                              <span className={info}>{companyDetails?.EIN}</span>
                            </div>

                            <div className="flex">
                              <span className={label}>Company Address:</span>
                              <span className={info}>
                                {companyDetails?.address.address}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="mb-5" />
                    </>
                  )}
                  <div>
                    <div className={' px-5 '}>
                      {/* If the role is Dispatcher, then show this field */}
                      {searchParams.get('role') === 'Dispatcher' && (
                        <>
                          <FLabel htmlFor="MCNumber">MC number</FLabel>
                          <div className=" mb-5  ">
                            <FInputField
                              name="MCNumber"
                              id="MCNumber"
                              value={Formik.values.MCNumber}
                              onChange={(e) => {
                                Formik.handleChange(e)
                                setErrorId('')
                              }}
                              type="text"
                              placeholder="Enter your MC number"
                              onBlur={Formik.handleBlur}
                            />
                            <div className="flex ">
                              <FFormInvalidInput
                                touched={Formik.touched}
                                FieldName={'MCNumber'}
                                errors={Formik.errors}
                              />
                            </div>
                            <small className={'flex text-red-700'}>{errorId}</small>
                          </div>
                        </>
                      )}
                      {/* If the role is Carrier, then show this field */}
                      {searchParams.get('role') === 'Carrier' && (
                        <>
                          <FLabel htmlFor="DOTNumber">DOT number</FLabel>
                          <div className=" mb-3  ">
                            <FInputField
                              name="DOTNumber"
                              id="DOTNumber"
                              value={Formik.values.DOTNumber}
                              onChange={(e) => {
                                Formik.handleChange(e)
                                setErrorId('')
                              }}
                              type="text"
                              placeholder="Enter your DOT number"
                              onBlur={Formik.handleBlur}
                            />
                            <div className="flex ">
                              <FFormInvalidInput
                                touched={Formik.touched}
                                FieldName={'DOTNumber'}
                                errors={Formik.errors}
                              />
                            </div>
                            <small className={'flex text-red-700'}>{errorId}</small>
                          </div>
                        </>
                      )}
                      <div className="ml-10">
                        <FLabel>Phone number</FLabel>
                      </div>
                      <div className=" mb-0  ">
                        <PhoneInput
                          name="phoneInput"
                          className={'input-phone-number'}
                          placeholder="Enter phone number"
                          defaultCountry="US"
                          countries={['US', 'MX', 'CA']}
                          value={Formik.values.phoneInput}
                          onChange={(value, country) => {
                            const countryCode = country
                              ? `+${country.countryCallingCode}`
                              : ''
                            const formattedValue = countryCode
                              ? `${countryCode} ${value}`
                              : value
                            Formik.setFieldValue('phoneInput', formattedValue)
                            setErrorPhone([])
                          }}
                          onBlur={Formik.handleBlur}
                        />
                        <div className="ml-10 flex">
                          <FFormInvalidInput
                            touched={Formik.touched}
                            FieldName={'phoneInput'}
                            errors={Formik.errors}
                          />
                        </div>
                      </div>
                      {errorPhone.length > 0 && (
                        <div>
                          <small className={'ml-10  flex font-medium text-red-700'}>
                            {errorPhone}
                          </small>
                        </div>
                      )}

                      <div className=" mt-10  flex  justify-center">
                        <FButton
                          type={'submit'}
                          className={'gap-2 px-20'}
                          disabled={isLoading}
                        >
                          Submit <ESpinner isVisible={isLoading} />
                        </FButton>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </>
    )
  }
}

export default RoleForm
