import RadioCard from '../../components/RadioCard'
import FButton from '../../components/FButton'
import MessageCard from '../../components/MessageCard'
import { SITE_LOGO } from '../../env'
import { useEffect, useState, useContext } from 'react'
// import { useCustomAxios } from '../../Hooks/useAxios'
import { useNavigate } from 'react-router-dom'
import { HiLogout } from 'react-icons/hi'
import FIconWrapper from '../../components/FIconWrapper'
import { UserContext } from '../../components/UserContext'
// import { axiosInstance } from '../../axios/requister'

function RoleSelect() {
  const navigate = useNavigate()
  const { user, logout } = useContext(UserContext)

  useEffect(() => {
    if (!localStorage.getItem('access')) {
      window.location.href = '/login'
    }
  }, [])

  useEffect(() => {
    if (user) {
      navigate('/dashboard')
      // localStorage.setItem('roles', appUserResponse.data.user_type)
    }
  }, [])

  const [selectedRole, setSelectedRole] = useState('')

  const handleSelectRole = (role) => {
    setSelectedRole(role)
  }
  const handleClick = () => {
    navigate('/roleform?role=' + selectedRole)
  }
  const handleChange = (selectedRole) => {
    setSelectedRole(selectedRole)
  }

  return (
    <>
      <div className={'flex h-14 items-center justify-end  divide-x  bg-gray-900 px-3 '}>
        <div className={'mr-5 lg:mr-10 '}>
          <button
            className={
              ' cursor-pointer self-center text-base text-white  hover:text-primary-500 '
            }
            onClick={() => logout()}
          >
            <FIconWrapper>
              <HiLogout size={20} />
              <span className={'whitespace-nowrap'}>Logout</span>
            </FIconWrapper>
          </button>
        </div>
      </div>
      <div className={'f-col-center mt-10  gap-y-4  p-5'}>
        <img src={SITE_LOGO} width="300" alt={'freight_logo'} />
        <div>
          <MessageCard
            message="It's you! Your email address has been successfully verified."
            type="success"
          />
        </div>
        <span className="mt-2 text-left text-xl font-medium text-gray-700">
          I will use the app as a
        </span>
        <div className={' flex flex-col gap-3 '}>
          <RadioCard
            label="Shipment Party"
            body="I need to ship/receive cargo from one facility to another."
            handleClick={handleSelectRole}
            isActive={selectedRole === 'Shipment Party'}
            onChange={(e) => {
              handleChange(e.target.value)
            }}
            value="Shipment Party"
          />
          <RadioCard
            label="Dispatcher"
            body="I am a middleman who connects shippers and receivers."
            handleClick={handleSelectRole}
            isActive={selectedRole === 'Dispatcher'}
            onChange={(e) => {
              handleChange(e.target.value)
            }}
            value="Dispatcher"
          />
          <RadioCard
            label="Carrier"
            body="I am in charge of transporting cargo from one facility to another."
            handleClick={handleSelectRole}
            isActive={selectedRole === 'Carrier'}
            onChange={(e) => {
              handleChange(e.target.value)
            }}
            value="Carrier"
          />
          <FButton onClick={handleClick} disabled={selectedRole === ''}>
            Next
          </FButton>
        </div>
      </div>
    </>
  )
}

export default RoleSelect
