import PropTypes from 'prop-types'

const SFormWrapper = ({children, key, className}) => {
  return (
    <div key={key} className={`my-3 flex flex-col gap-2 ${className}`}>
      {children}
    </div>
  )
}
export default SFormWrapper

SFormWrapper.prototype = {
  children: PropTypes.node,
  key: PropTypes.string,
  className: PropTypes.string,
}

SFormWrapper.defaultProps = {
  className: '',
}
