import React, { useEffect } from 'react'
import FModal from './FModal'
import FInputField from './FInputField'
import ESpinner from './ESpinner'
import CustomPagination from './CustomPaginationStyle'

const DataTable = ({
  data,
  columns,
  totalNumberOfPages,
  isLoading,
  page,
  setPage,
  rowFunction,
  filterOptions,
  Refetch,
}) => {
  const [isConfigTableModalOpen, setIsConfigTableModalOpen] = React.useState(false)

  const [isFilterReset, setIsFilterReset] = React.useState(false)

  useEffect(() => {
    if (isFilterReset) {
      // eslint-disable-next-line new-cap
      Refetch()
      setIsFilterReset(false)
      // setIsFilterApplied(false)
    }
  }, [filterOptions])

  const [showedColumns, setShowedColumns] = React.useState(
    columns.map((col) => ({ display: true, ...col }))
  )


  const handleCellValue = (row, col) => {
    if (row[col.accessor] === undefined || row[col.accessor] === null) {
      return 'N/A'
    }

    return col.cell ? col.cell(row[col.accessor], row) : row[col.accessor]
  }
  const handlePageChange = (e, value) => {
    setPage(value)
  }

  return (
    <>
      {data?.length !== 0 && !isLoading && (
        <div>
          <FModal
            setIsOpen={setIsConfigTableModalOpen}
            isOpen={isConfigTableModalOpen}
            title={'Table Configuration'}
          >
            {columns.map((col, index) => (
              <div className={'flex gap-10 '} key={index}>
                <div className={'flex gap-3'}>
                  <FInputField
                    className={'h-4 w-4'}
                    type={'checkbox'}
                    id={columns[index].accessor}
                    name={columns[index].accessor}
                    value={col.accessor}
                    checked={showedColumns[index].display}
                    onChange={(e) => {
                      const newShowedColumns = showedColumns.map((c) => {
                        if (c.accessor === e.target.value) {
                          return { ...c, display: e.target.checked }
                        }
                        return c
                      })
                      setShowedColumns(newShowedColumns)
                    }}
                  />
                  <label htmlFor={columns[index].accessor}>
                    {columns[index].Header}
                  </label>
                </div>
              </div>
            ))}
          </FModal>
          <div className={'w-full '}>
            <div className={'w-full  overflow-x-auto bg-white h-fit '}>
              <table className={'w-full rounded  text-left text-sm text-gray-500 '}>
                <thead
                  className={'rounded-t-md bg-gray-50 text-xs uppercase text-gray-700  '}
                >
                  <tr className={'rounded'}>
                    {showedColumns.map((col, index) => {
                      if (col.display) {
                        return (
                          <th
                            key={index}
                            className={
                              'whitespace-nowrap rounded border-b border-r px-3 py-3 text-left text-sm'
                            }
                          >
                            {col.Header}
                          </th>
                        )
                      }
                    })}
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan={columns.length}>
                        <div className={'flex items-center justify-center p-5 '}>
                          <ESpinner size={40} isVisible={true} />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    data?.map((row, index) => (
                      <tr
                        className={`border bg-white text-left ${rowFunction && 'cursor-pointer hover:bg-gray-100'
                          } `}
                        key={index}
                        onClick={() => rowFunction && rowFunction(row)}
                      >
                        {showedColumns.map((col, index) => {
                          if (col.display) {
                            return (
                              <td
                                key={index}
                                className={'whitespace-nowrap border-l p-3'}
                              >
                                {handleCellValue(row, col)}
                              </td>
                            )
                          }
                        })}
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div
              className={`${isLoading || data?.length === 0
                ? 'hidden'
                : ' flex justify-center p-3 bg-white'
                }`}
            >
              

          <CustomPagination
            count={totalNumberOfPages}
            page={page}
            onChange={handlePageChange}
            color="primary"
            size='large'
          />


            </div>
        </div>
      )}
    </>
  )
}

const FCustomDataTable = ({
  cols,
  data,
  isLoading,
  totalNumberOfPages,
  setPage,
  page,
  rowFunction,
  allFilters,
  setAllFilters,
  filterOptions,
  setFilterOptions,
  Refetch,
}) => (
  <>
    <div>
      <DataTable
        isLoading={isLoading}
        totalNumberOfPages={totalNumberOfPages}
        page={page}
        setPage={setPage}
        columns={cols}
        data={data}
        rowFunction={rowFunction}
        allFilters={allFilters}
        setAllFilters={setAllFilters}
        filterOptions={filterOptions}
        setFilterOptions={setFilterOptions}
        Refetch={Refetch}
      />
    </div>
  </>
)
export default FCustomDataTable
