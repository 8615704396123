import React, { useState } from 'react'
import { SITE_LOGO } from '../../env'
import { AiOutlineSearch } from 'react-icons/ai'
import { FaPlus, FaMinus } from 'react-icons/fa' // Import plus and minus icons from react-icons
import { useFormik } from 'formik'
import * as Yup from 'yup'
import FInputField from '../../components/FInputField'
import FLabel from '../../components/FLabel'
import FFormInvalidInput from '../../components/FFormInvalidInput'
import FFormWrapper from '../../components/FFormWrapper'
import FButton from '../../components/FButton'
import { useNavigate } from 'react-router-dom'
// import FButton from '../../components/FButton'

const HelpCenter = () => {
  const commonQuestions = [
    {
      question: 'What are your business hours?',
      answer: 'Our business hours are Monday to Friday, from 9:00 AM to 5:00 PM.',
      id: 1,
    },
    {
      question: 'How can I switch my role?',
      answer:
        'To switch your role, click on the menu at the top-right corner of the header From there, you can select the desired role from the available options.',
      id: 2,
    },
    {
      question: 'How can I change my password?',
      answer:
        'To change your password, click on the avatar at the top-right corner of the header From there, go to Settings and click on the Change Password tab.',
      id: 3,
    },

    // Add more questions and answers here as needed
  ]

  // State to track which questions are expanded
  const [expandedQuestions, setExpandedQuestions] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const navigate = useNavigate()

  const handleSearch = (e) => {
    setSearchQuery(e.target.value)
  }

  const filteredQuestions = commonQuestions.filter((item) =>
    item.question.toLowerCase().includes(searchQuery.toLowerCase())
  )

  // Function to toggle the visibility of an answer
  const toggleAnswer = (index) => {
    // If the question is already expanded, remove it from the array
    if (expandedQuestions.includes(index)) {
      // Remove the question from the array
      setExpandedQuestions(expandedQuestions.filter((item) => item !== index))
    } else {
      // If the question is not expanded, add it to the array
      setExpandedQuestions([...expandedQuestions, index])
    }
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      subject: '',
      description: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Email is not valid').required('Email is required'),
      subject: Yup.string().required('Subject is required'),
      description: Yup.string().required('Description is required'),
    }),
    onSubmit: (values) => {
      console.log(values)
    },
  })
  return (
    <div>
      {/* Header */}
      <header
        className=" sticky top-0  z-10 flex w-full justify-between border-b bg-gray-800 p-3
            text-left text-lg  text-white"
      >
        {/* Left side of the header */}
        <div className="ml-10 flex items-center">
          {/* Replace 'your-logo.png' with the path to your logo */}
          <img src={SITE_LOGO} alt="Logo" className="h-8 w-8" />
          <span className="ml-2 text-base">Help Center</span>
        </div>

        {/* Right side of the header */}
        <div>
          {/* Replace 'username' with the actual username */}
          <span
            className="mr-10 cursor-pointer border-l pl-3 text-base hover:underline
          "
            onClick={() => navigate('/login')}
          >
            Login
          </span>
        </div>
      </header>

      {/* FAQ Section */}
      <section className="py-8 text-center">
        {/* FAQs Title */}
        <h1 className="text-lg font-semibold text-gray-800">FAQs</h1>
        {/* Subtitle */}
        <p className="text-2xl font-medium text-gray-800">Frequently Asked Questions</p>
        {/* Description */}
        <p className="mt-5 text-gray-500">Have questions? We’re here to help.</p>

        {/* Search Bar */}
        <div className="mx-auto mt-5 max-w-md px-5 lg:px-5">
          <div className="mb-2 flex overflow-hidden">
            <div className="relative w-full ">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <AiOutlineSearch className="h-5 w-5 text-gray-400" />
              </div>
              <input
                className="block  w-full rounded-sm border border-gray-300 py-2  pl-10 text-sm text-gray-800 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 "
                type="text"
                name="search"
                placeholder="Search Problem ..."
                onChange={handleSearch}
                value={searchQuery}
              />
            </div>
          </div>
        </div>
        <ul className="mx-auto mt-10 flex  flex-col px-5 lg:w-6/12 lg:px-0">
          {filteredQuestions.map((item, index) => (
            <li key={item.id} className="mb-4 ">
              <div className="mb-3  flex items-center justify-between ">
                <span className="text-lg ">{item.question}</span>
                <span className="">
                  {expandedQuestions.includes(index) ? (
                    <FaMinus
                      size={20}
                      onClick={() => toggleAnswer(index)}
                      className=" cursor-pointer rounded-full border border-gray-800 p-1 text-gray-800 hover:bg-gray-200 hover:text-gray-900"
                    />
                  ) : (
                    <FaPlus
                      size={20}
                      onClick={() => toggleAnswer(index)}
                      className="  cursor-pointer rounded-full border border-gray-800 p-1 text-gray-800 hover:bg-gray-200 hover:text-gray-900"
                    />
                  )}
                </span>
              </div>
              {expandedQuestions.includes(index) && (
                <p className="my-2 flex w-10/12 text-start text-sm text-gray-500">
                  {item.answer}
                </p>
              )}
              <hr className="my-3" />
            </li>
          ))}
        </ul>
        <p className="text-2xl font-medium text-gray-800">Still Need Help?</p>
        <p className="mt-5 px-5 text-gray-500 lg:px-0">
          Provide a problem subject and detailed description of the problem you’re facing
          or the question you have.
        </p>
        <div className="mx-auto mt-5 flex flex-col px-5 lg:w-6/12 lg:px-0">
          <FFormWrapper>
            <FLabel htmlFor={'email'}>
              Email <span className="text-red-600">*</span>
            </FLabel>
            <FInputField
              id={'email'}
              name="email"
              placeholder="Enter your email address"
              type="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            <FFormInvalidInput
              touched={formik.touched}
              FieldName={'email'}
              errors={formik.errors}
            />
          </FFormWrapper>
          <FFormWrapper>
            <FLabel htmlFor={'subject'}>
              Subject <span className="text-red-600">*</span>
            </FLabel>
            <FInputField
              id={'subject'}
              name="subject"
              placeholder="Enter your problem subject"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.subject}
            />
            <FFormInvalidInput
              touched={formik.touched}
              FieldName={'subject'}
              errors={formik.errors}
            />
          </FFormWrapper>
          <FFormWrapper>
            <FLabel htmlFor={'description'}>
              Description <span className="text-red-600">*</span>
            </FLabel>
            <textarea
              id={'description'}
              name="description"
              placeholder="Enter your problem description"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
              className="input-style h-28"
            />
            <FFormInvalidInput
              touched={formik.touched}
              FieldName={'description'}
              errors={formik.errors}
            />
          </FFormWrapper>
          <div className="mt- flex justify-end">
            <FButton type="submit" className=" w-40  " onClick={formik.handleSubmit}>
              Send To Support
            </FButton>
          </div>
        </div>
      </section>
      {/* ... */}
    </div>
  )
}

export default HelpCenter
