import React from 'react'
import FButton from './FButton'
import FIconWrapper from './FIconWrapper'
import { HiChevronRight, HiChevronLeft } from 'react-icons/hi'

const PaginationControls = ({
    page,
    totalNumberOfPages,
    getNextPage,
    getPreviousPage
}) => {
    return (
        <div
            className={
                'flex flex-col items-center justify-between gap-2 rounded-b px-10 py-3 lg:flex-row-reverse'
            }
        >
            <div className={'flex items-center justify-between  rounded-b '}>
                <div className={'mt-1 flex flex-row-reverse gap-2  '}>
                    <FButton
                        disabled={parseInt(page) === parseInt(totalNumberOfPages) || !totalNumberOfPages}
                        onClick={() => getNextPage()}
                    >
                        <FIconWrapper>
                            <span>Next</span>
                            <HiChevronRight />
                        </FIconWrapper>
                    </FButton>
                    <FButton
                        onClick={() => getPreviousPage()}
                        disabled={parseInt(page) === 1}
                    >
                        <FIconWrapper>
                            <HiChevronLeft />
                            <span>Previous</span>
                        </FIconWrapper>
                    </FButton>
                </div>
            </div>
            <span className={'text-sm'}>
                {`Page ${page} of ${totalNumberOfPages}`}
            </span>
        </div>
    )
}

export default PaginationControls