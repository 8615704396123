import FFormWrapper from '../../components/FFormWrapper'
import FButton from '../../components/FButton'
// import container from '../../assets/images/container.png'
import { Link } from 'react-router-dom'

const Onboarding = () => {
  return (
    <>
      <div className={'f-col-center-center flex h-full  text-gray-800'}>
        <div style={{ maxWidth: '700px' }} className={'flex items-end gap-2'}>
          <div className={'flex flex-col gap-2'}>
            <span className={'block text-left text-3xl font-semibold'}>
              Create Your Loads
            </span>
            <span className={'mt-2 text-sm text-gray-500'}>
              <span className={'block text-left'}>
                No Data Found Please create a new load
              </span>
            </span>
            <FFormWrapper className={'w-fit'}>
              <Link to={'/load/create'}>
                <FButton>+ Create Load</FButton>
              </Link>
            </FFormWrapper>
          </div>
          {/* <img src={container} alt={'container'} /> */}
        </div>
      </div>
    </>
  )
}
export default Onboarding
