import { useEffect, useState } from 'react'
import { axiosInstance } from '../axios/requister'

export const useCustomAxios = (axiosParams, enabled = true) => {
  const [totalNumberOfPages, setTotalNumberOfPages] = useState(0)

  const [response, setResponse] = useState(undefined)
  const [error, setError] = useState('')
  const [loading, setloading] = useState(false)

  const fetchData = async (params) => {
    setloading(true)
    try {
      const result = await axiosInstance.request(params)
      setResponse(result.data)
      setTotalNumberOfPages(result.data.totalNumberOfPages)
    } catch (error) {
      setError(error)
    } finally {
      setloading(false)
    }
  }
  const Refetch = async () => {
    fetchData(axiosParams)
  }

  useEffect(() => {
    if (enabled) {
      fetchData(axiosParams)
    }
  }, [enabled]) // execute once only

  return { response, error, loading, totalNumberOfPages, Refetch }
}
