import React, { useState } from 'react'
import { useFormik } from 'formik'
import { axiosInstance } from '../../axios/requister'
import { PasswordIcon } from '../Authentication/components/PasswordIcon'
import FButton from '../../components/FButton'
import FInputField from '../../components/FInputField'
import { toast } from 'react-toastify'
import ESpinner from '../../components/ESpinner'
export default function PasswordTab() {
  const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] = useState(false)
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false)
  const [isConfirmNewPasswordVisible, setIsConfirmNewPasswordVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },

    onSubmit: () => {
      setIsLoading(true)
      axiosInstance
        .put('authentication/change-password/', {
          old_password: formik.values.currentPassword,
          new_password: formik.values.newPassword,
          confirm_password: formik.values.confirmNewPassword,
        })
        .then(() => {
          setIsLoading(false)
          toast.success('Password changed successfully')
          formik.resetForm()
        })
        .catch((err) => {
          setIsLoading(false)
          if (err.response.data.details === 'old password is incorrect') {
            formik.setFieldError('currentPassword', 'Incorrect password')
          } else if (
            err.response.data.details ===
            'new password and confirm password does not match'
          ) {
            formik.setFieldError('confirmNewPassword', 'Passwords do not match')
          } else if (
            err.response.data.details ===
            'old password and new password cannot be the same'
          ) {
            formik.setFieldError(
              'newPassword',
              'New password cannot be same as old password'
            )
          }

          toast.error('Password change failed')
        })
    },

    validate: (values) => {
      const errors = {}

      if (!values.currentPassword) {
        errors.currentPassword = 'Current Password is required'
      }

      if (!values.newPassword) {
        errors.newPassword = 'New Password is required'
      } else if (
        !values.newPassword.match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/
        )
      ) {
        errors.newPassword =
          'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character'
      }
      if (!values.confirmNewPassword) {
        errors.confirmNewPassword = 'Confirm New Password is required'
      } else if (values.newPassword !== values.confirmNewPassword) {
        errors.confirmNewPassword = 'Passwords do not match'
      }

      return errors
    },
  })

  const showCurrentPassword = () => setIsCurrentPasswordVisible(!isCurrentPasswordVisible)
  const showNewPassword = () => setIsNewPasswordVisible(!isNewPasswordVisible)
  const showConfirmNewPassword = () =>
    setIsConfirmNewPasswordVisible(!isConfirmNewPasswordVisible)

  return (
    <div className="mx-2 my-5 flex flex-col items-center p-4 sm:mx-8 sm:my-8 md:mx-20 md:my-5 ">
      <div className="flex flex-col items-center gap-4 sm:w-full sm:flex-row sm:justify-between">
        <span className=" text-xl font-bold text-gray-700">
          Change Your Password
          <br />{' '}
          <span className="mt-2 flex text-base font-normal text-gray-500">
            Manage your password settings on the website. You can change your password to
            enhance account security.
            <br /> Make sure to choose a strong password that includes a combination of
            uppercase and lowercase letters, numbers, and special characters.
          </span>
        </span>
      </div>
      <hr className="my-5 w-full border-gray-300" />
      <form className=" w-full " onSubmit={formik.handleSubmit}>
        <div className="mb-3 ">
          <label htmlFor="currentPassword" className="mb-1 block">
            Current Password
          </label>
          <div className="relative flex w-full flex-col lg:w-8/12">
            <FInputField
              name="currentPassword"
              type={isCurrentPasswordVisible ? 'text' : 'password'}
              placeholder="Enter your current password"
              value={formik.values.currentPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <PasswordIcon
              isVisible={isCurrentPasswordVisible}
              showPassword={showCurrentPassword}
            />
            {formik.touched.currentPassword && formik.errors.currentPassword && (
              <span className="mt-1 select-none text-sm text-red-600">
                {formik.errors.currentPassword}
              </span>
            )}
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor="newPassword" className="mb-1 block">
            New Password
          </label>
          <div className="relative flex w-full flex-col lg:w-8/12">
            <FInputField
              name="newPassword"
              type={isNewPasswordVisible ? 'text' : 'password'}
              placeholder="Enter your new password"
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            <PasswordIcon
              isVisible={isNewPasswordVisible}
              showPassword={showNewPassword}
            />
            {formik.touched.newPassword && formik.errors.newPassword && (
              <span className="mt-1 select-none text-sm text-red-600">
                {formik.errors.newPassword}
              </span>
            )}
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor="confirmNewPassword" className="mb-1 block">
            Confirm New Password
          </label>
          <div className="relative flex w-full flex-col lg:w-8/12 ">
            <FInputField
              id="confirmNewPassword"
              name="confirmNewPassword"
              type={isConfirmNewPasswordVisible ? 'text' : 'password'}
              placeholder="Confirm your new password"
              value={formik.values.confirmNewPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <PasswordIcon
              isVisible={isConfirmNewPasswordVisible}
              showPassword={showConfirmNewPassword}
            />
            {formik.touched.confirmNewPassword && formik.errors.confirmNewPassword && (
              <span className="mt-1 select-none text-sm text-red-600">
                {formik.errors.confirmNewPassword}
              </span>
            )}
          </div>
        </div>
        <div className=" mt-10 flex w-full flex-row-reverse lg:w-8/12">
          {' '}
          <FButton type="submit" disabled={isLoading}>
            <span className="flex gap-2">
              Change Password <ESpinner isVisible={isLoading} />
            </span>
          </FButton>
        </div>
      </form>
    </div>
  )
}
