import React from 'react'
import FModal from '../../components/FModal'
import TaxInfoForm from '../Roles/TaxInfoForm'

const AddTaxInfoModal = (
    {openTaxInfoModal, setOpenTaxInfoModal}
) => {
    return (
       <FModal 
         title={'Add Tax Info'}
            isOpen={openTaxInfoModal}
            setIsOpen={() => {
                setOpenTaxInfoModal(false)
            }}
         >
            
            <TaxInfoForm
                openTaxInfoModal={openTaxInfoModal}
                setOpenTaxInfoModal={setOpenTaxInfoModal}
            />

        </FModal>
    )
}

export default AddTaxInfoModal