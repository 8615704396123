import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import React from 'react'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import FButton from '../../../components/FButton'
import FFormWrapper from '../../../components/FFormWrapper'
import FInputField from '../../../components/FInputField'
import FLabel from '../../../components/FLabel'
import * as Yup from 'yup'
import FFormInvalidInput from '../../../components/FFormInvalidInput'
import { useFormik } from 'formik'

const CompanyInfoStep = ({ formData, setFormData, nextStep, prevStep }) => {
  const handleChange = (e) => {
    const { name, value } = e.target

    // Check if the input is a file input
    setFormData({ ...formData, [name]: value })

    // Update the Formik state
    Formik.handleChange(e)
  }

  const phoneRegExp = /^(\+?(1|52)[- ]?)?(\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$/
  const einRegex = /^\d{2}-\d{7}$/

  const validationSchema = Yup.object().shape({
    company_name: Yup.string().required('Company name is required'),
    company_domain: Yup.string().required('Company domain is required'),
    company_size: Yup.string().required('Company size is required'),
    EIN: Yup.string()
      .required('EIN is required')
      .matches(einRegex, 'EIN number must be 9 numeric digits in this format: xx-xxxxxxx'),
    company_phone_number: Yup.string()
      .required('Company phone number is required')
      .matches(phoneRegExp, 'Phone number is not valid'),
    company_address: Yup.string().required('Company address is required'),
    scac: Yup.string()
      .required('SCAC is required')
      // scasc length shoud be 4 characters and contain only upeercase letters
      .matches(/^[A-Z]{2,4}$/, 'SCAC must be 4 uppercase letters'),

    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    country: Yup.string().required('Country is required'),
    zip_code: Yup.string().required('Zip code is required'),
  })

  const Formik = useFormik({
    initialValues: {
      company_name: formData.company_name,
      company_domain: formData.company_domain,
      company_size: formData.company_size,
      EIN: formData.EIN,
      scac: formData.scac,
      company_fax_number: formData.company_fax_number,
      company_phone_number: formData.company_phone_number,
      company_address: formData.company_address,
      city: formData.city,
      state: formData.state,
      country: formData.country,
      zip_code: formData.zip_code,
    },

    validationSchema: validationSchema,
    onSubmit: () => {
      // This function will be called if the form is valid
      nextStep()
    },
  })

  return (
    <form className="w-full flex-col p-5 lg:flex" onSubmit={Formik.handleSubmit}>
      <FFormWrapper>
        <FLabel htmlFor={'company_name'}>Company Name </FLabel>
        <FInputField
          id={'company_name'}
          name="company_name"
          placeholder="Enter your company name"
          type="text"
          value={Formik.values.company_name}
          onChange={handleChange}
          onBlur={Formik.handleBlur}
        />
        <FFormInvalidInput
          touched={Formik.touched}
          FieldName="company_name"
          errors={Formik.errors}
        />
      </FFormWrapper>
      <div className="flex justify-between gap-3">
        <FFormWrapper className="flex-1">
          <FLabel htmlFor={'company-domain'}>Company domain</FLabel>
          <FInputField
            id={'company-domain'}
            name="company_domain"
            placeholder="Enter your company domain"
            type="text"
            onChange={handleChange}
            value={Formik.values.company_domain}
            onBlur={Formik.handleBlur}
          />
          <FFormInvalidInput
            touched={Formik.touched}
            FieldName="company_domain"
            errors={Formik.errors}
          />
        </FFormWrapper>

        <FFormWrapper className="flex-1">
          <FLabel htmlFor={'company-size'}>Company size</FLabel>

          <Select
            id={'company-size'}
            name="company_size"
            placeholder="Enter your company size"
            type="select"
            onChange={handleChange}
            className="h-10 w-full rounded-sm   border-gray-300"
            labelId="demo-select-small-label"
            value={Formik.values.company_size}
            onBlur={Formik.handleBlur}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="1-10">1-10</MenuItem>
            <MenuItem value="11-50">11-50</MenuItem>
            <MenuItem value="51-100">51-100</MenuItem>
            <MenuItem value=">100"> more than 100</MenuItem>
          </Select>
          <FFormInvalidInput
            touched={Formik.touched}
            FieldName="company_size"
            errors={Formik.errors}
          />
        </FFormWrapper>
      </div>
      <FFormWrapper className="flex-1">
        <FLabel htmlFor={'EIN'}>Employer Identification Number</FLabel>
        <FInputField
          id={'EIN'}
          name="EIN"
          placeholder="Enter your EIN"
          type="text"
          onChange={handleChange}
          value={Formik.values.EIN}
          onBlur={Formik.handleBlur}
        />
        <FFormInvalidInput
          touched={Formik.touched}
          FieldName="EIN"
          errors={Formik.errors}
        />
      </FFormWrapper>
      <FFormWrapper>
        <FLabel htmlFor={'scac'}>SCAC</FLabel>
        <FInputField
          id={'scac'}
          name="scac"
          placeholder="Enter your SCAC"
          type="text"
          onChange={handleChange}
          value={Formik.values.scac}
          onBlur={Formik.handleBlur}
        />
        <FFormInvalidInput
          touched={Formik.touched}
          FieldName="scac"
          errors={Formik.errors}
        />
      </FFormWrapper>

      <div className="justify-between gap-3 lg:flex">
        <FFormWrapper className="lg:w-1/2">
          <FLabel htmlFor={'company_fax_number'}>Company Fax Number</FLabel>
          <FInputField
            id={'company_fax_number'}
            name="company_fax_number"
            placeholder="Enter your company fax number"
            type="text"
            onChange={handleChange}
            value={Formik.values.company_fax_number}
            onBlur={Formik.handleBlur}
          />
        </FFormWrapper>
        <div className="lg:w-6/12">
          <FFormWrapper>
            <FLabel htmlFor={'company_phone_number'}>Company Phone number</FLabel>

            <PhoneInput
              name="company_phone_number"
              id={'company_phone_number'}
              className={'input-phone-number '}
              placeholder="Enter company phone number"
              defaultCountry="US"
              countries={['US', 'MX', 'CA']}
              value={Formik.values.company_phone_number}
              onChange={(e) => {
                setFormData({ ...formData, company_phone_number: e })
                Formik.setFieldValue('company_phone_number', e)
              }}
              onBlur={Formik.handleBlur}
            />
            <FFormInvalidInput
              touched={Formik.touched}
              FieldName="company_phone_number"
              errors={Formik.errors}
            />
          </FFormWrapper>
        </div>
      </div>

      <FFormWrapper>
        <FLabel htmlFor={'company_address'}>Company Address</FLabel>
        <FInputField
          id={'company_address'}
          name="company_address"
          placeholder="Enter your company address"
          type="text"
          onChange={handleChange}
          onBlur={Formik.handleBlur}
          value={Formik.values.company_address}
        />
        <FFormInvalidInput
          touched={Formik.touched}
          FieldName="company_address"
          errors={Formik.errors}
        />
      </FFormWrapper>
      <div className="justify-between gap-3 lg:flex">
        <FFormWrapper className="lg:w-1/2">
          <FLabel htmlFor={'city'}>Company City</FLabel>
          <FInputField
            id={'city'}
            name="city"
            placeholder="Enter your city"
            type="text"
            onChange={handleChange}
            value={Formik.values.city}
            onBlur={Formik.handleBlur}
          />
          <FFormInvalidInput
            touched={Formik.touched}
            FieldName="city"
            errors={Formik.errors}
          />
        </FFormWrapper>
        <FFormWrapper className="lg:w-1/2">
          <FLabel htmlFor={'state'}>Company State</FLabel>
          <FInputField
            id={'state'}
            name="state"
            placeholder="Enter your state"
            type="text"
            onChange={handleChange}
            value={Formik.values.state}
            onBlur={Formik.handleBlur}
          />
          <FFormInvalidInput
            touched={Formik.touched}
            FieldName="state"
            errors={Formik.errors}
          />
        </FFormWrapper>
      </div>
      <div className="justify-between gap-3 lg:flex">
        <FFormWrapper className="lg:w-1/2">
          <FLabel htmlFor={'country'}> Company Country</FLabel>
          <FInputField
            id={'country'}
            name="country"
            placeholder="Enter your country"
            type="text"
            onChange={handleChange}
            value={Formik.values.country}
            onBlur={Formik.handleBlur}
          />
          <FFormInvalidInput
            touched={Formik.touched}
            FieldName="country"
            errors={Formik.errors}
          />
        </FFormWrapper>
        <FFormWrapper className="lg:w-1/2">
          <FLabel htmlFor={'zip_code'}>Company Zip Code</FLabel>
          <FInputField
            id={'zip_code'}
            name="zip_code"
            placeholder="Enter your zip code"
            type="text"
            onChange={handleChange}
            value={Formik.values.zip_code}
            onBlur={Formik.handleBlur}
          />
          <FFormInvalidInput
            touched={Formik.touched}
            FieldName="zip_code"
            errors={Formik.errors}
          />
        </FFormWrapper>
      </div>

      {/* Add more company-related fields here as needed */}
      <div className="mt-5 flex flex-row-reverse  justify-center gap-5">
        <FButton className={'w-28'} type="submit">
          Next
        </FButton>
        <FButton className={'w-28'} btnType={'secondary'} onClick={prevStep}>
          Back
        </FButton>
      </div>
    </form>
  )
}

export default CompanyInfoStep
