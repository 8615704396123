import { useState } from 'react'
import CompanyInfoStep from './CompanyInfoStep'
import PersonalInfoStep from './PersonalInfoStep'
import InsuranceInfoStep from './InsuranceInfoStep'
import { Link } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { axiosInstance } from '../../../axios/requister'
import 'react-toastify/dist/ReactToastify.css'
import SuccessStep from './SuccessStep'

const RegistraionCompanyManager = () => {
  // const navigate = useNavigate()
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    personal_phone_number: '',
    company_name: '',
    company_domain: '',
    company_size: '',
    EIN: '',
    company_fax_number: '',
    company_phone_number: '',
    address: '',
    city: '',
    state: '',
    country: '',
    zip_code: '',
    sid_photo: null,
    personal_photo: null,
    insurance_provider: '',
    insurance_policy_number: '',
    insurance_type: '',
    insurance_premium_amount: '',

    // Add fields for company and insurance info
  })

  const handleSubmit = async (e) => {
    setLoading(true)
    if (e) {
      e.preventDefault()
    }

    // console.log(formData)

    const formDataObject = new FormData()

    // Append form data fields
    formDataObject.append('sid_photo', formData.sid_photo.at(0))
    formDataObject.append('personal_photo', formData.personal_photo.at(0))
    formDataObject.append('email', formData.email)
    formDataObject.append('first_name', formData.first_name)
    formDataObject.append('last_name', formData.last_name)
    formDataObject.append('personal_phone_number', formData.personal_phone_number)
    formDataObject.append('company_name', formData.company_name)
    formDataObject.append('company_domain', formData.company_domain)
    formDataObject.append('company_size', formData.company_size)
    formDataObject.append('EIN', formData.EIN)
    formDataObject.append('scac', formData.scac)
    formDataObject.append('company_fax_number', formData.company_fax_number)
    formDataObject.append('company_phone_number', formData.company_phone_number)
    formDataObject.append('address', formData.company_address)
    formDataObject.append('city', formData.city)
    formDataObject.append('state', formData.state)
    formDataObject.append('country', formData.country)
    formDataObject.append('zip_code', formData.zip_code)
    formDataObject.append('insurance_provider', formData.insurance_provider)
    formDataObject.append('insurance_policy_number', formData.insurance_policy_number)
    formDataObject.append('insurance_type', formData.insurance_type)
    formDataObject.append('insurance_premium_amount', formData.insurance_premium_amount)

    // console.log(formDataObject)
    // for (const item of formDataObject) {
    //   console.log(item)
    // }

    try {
      // Make the API request with FormData
      await axiosInstance.post('support/create-ticket/', formDataObject, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      toast.success('Ticket created successfully')
      setLoading(false)
      setErrors([])
      nextStep()
    } catch (error) {
      setLoading(false)
      toast.error('Ticket creation failed')
      if (error?.response?.staus === 500) {
        setErrors(['Internal server error'])
      } else {
        // console.log(error)
        // console.log(error?.response?.data)
        setErrors(Object.values(error?.response?.data || {}))
      }
      window.scrollTo(0, 0)
    }
  }

  const nextStep = () => {
    setStep(step + 1)
  }
  const prevStep = (data) => {
    //i want to go back to the previous step with the data i have entered
    setStep(step - 1)
    setFormData({ ...formData, ...data })
  }
  // Render the appropriate step based on the current step state
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <PersonalInfoStep
            formData={formData}
            setFormData={setFormData}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        )
      case 2:
        return (
          <CompanyInfoStep
            formData={formData}
            setFormData={setFormData}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        )
      case 3:
        return (
          <InsuranceInfoStep
            formData={formData}
            setFormData={setFormData}
            nextStep={nextStep}
            prevStep={prevStep}
            handleSubmit={handleSubmit}
            loading={loading}
          />
        )
      case 4:
        return <SuccessStep />
      default:
        return null
    }
  }

  return (
    <>
      {step !== 4 && (
        <div className=" absolute top-1/4 left-48 hidden  flex-col items-center space-y-2 lg:left-48 lg:block xl:left-64  ">
          <div className="flex flex-col items-center space-y-2">
            <div
              className={`mx-auto flex h-8 w-8 items-center justify-center rounded-full ${
                step === 1 || step === 2 || step === 3
                  ? 'bg-primary-500 text-white'
                  : 'border border-gray-300 bg-white'
              }`}
            >
              1
            </div>
            <span
              className={`text-sm  
          ${
            step === 1 || step === 2 || step === 3 ? 'text-primary-500' : 'text-gray-500'
          }`}
            >
              Personal Info
            </span>
            <div
              className={`h-20 w-0.5  
          ${step === 2 || step === 3 ? 'bg-primary-500' : 'bg-gray-300'}`}
            ></div>
          </div>
          {/* step 2 */}
          <div className="flex flex-col items-center space-y-2">
            <div
              className={`mx-auto flex h-8 w-8 items-center justify-center rounded-full ${
                step === 2 || step === 3
                  ? 'bg-primary-500 text-white'
                  : 'border border-gray-300 bg-white'
              }`}
            >
              2
            </div>
            <span
              className={`text-sm  
          ${step === 2 || step === 3 ? 'text-primary-500' : 'text-gray-500'}`}
            >
              Company Info
            </span>
            <div
              className={`h-20 w-0.5  
          ${step === 3 ? 'bg-primary-500' : 'bg-gray-300'}`}
            ></div>
          </div>

          {/* step 3 */}
          <div className="flex flex-col items-center space-y-2">
            <div
              className={`mx-auto flex h-8 w-8 items-center justify-center rounded-full ${
                step === 3
                  ? 'bg-primary-500 text-white'
                  : 'border border-gray-300 bg-white'
              }`}
            >
              3
            </div>
            <span
              className={`text-sm  
          ${step === 3 ? 'text-primary-500' : 'text-gray-500'}`}
            >
              insurance Info
            </span>
          </div>
        </div>
      )}

      <div className="flex min-h-screen flex-col items-center justify-center bg-[#F2F3F3]">
        {errors.length !== 0 &&
          errors.map((error) => (
            <div
              key={error}
              className={
                'f-col-center-center  mb-5  flex flex-col items-start border border-red-500 bg-red-50 p-3'
              }
            >
              <span className={'block text-left text-sm text-red-600'}>{error}</span>
            </div>
          ))}
        <div className="flex w-full flex-col items-center bg-white shadow-lg md:h-[750] md:w-5/12  ">
          {renderStep()}
        </div>
        {step !== 4 && (
          <span className="my-5 text-center">
            Already have an account?{' '}
            <Link
              to={'/login'}
              className="mt-4 ml-1 text-center font-semibold text-primary-500"
            >
              Login
            </Link>
          </span>
        )}
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  )
}
export default RegistraionCompanyManager
