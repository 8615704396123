import React, { useState, useContext } from 'react'
import { axiosInstance } from '../../axios/requister'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import FFormWrapper from '../../components/FFormWrapper'
import FLabel from '../../components/FLabel'
import FInputField from '../../components/FInputField'
import * as Yup from 'yup'
import FFormInvalidInput from '../../components/FFormInvalidInput'
import FButton from '../../components/FButton'
import FIconWrapper from '../../components/FIconWrapper'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import ESpinner from '../../components/ESpinner'
import { UserContext } from '../../components/UserContext'

// import { useNavigate } from 'react-router-dom'

export default function TaxInfoForm({
  isDispatcherRoleModalOpen,
  setIsDispatcherRoleModalOpen,
  isCarrierRoleModalOpen,
  setIsCarrierRoleModalOpen,
  handleRefreshUser,
  openTaxInfoModal,
  setOpenTaxInfoModal,
}) {
  // const navigate = useNavigate()
  const [isErr, setIsErr] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [tab, setTab] = useState('companyId')
  const [companyIdError, setCompanyIdError] = useState('')
  const [taxNumberError, setTaxNumberError] = useState('')
  const { user } = useContext(UserContext)

  const Formik = useFormik({
    initialValues: {
      companyIdentifier: '',

      dotNumber: '',
      mc_number: '',
      taxNumber: '',
      address: '',
      city: '',
      state: '',
      zip_code: '',
      country: '',
    },

    validationSchema: Yup.object().shape({
      ...(tab === 'companyId'
        ? {
            companyIdentifier: Yup.string().required('Company ID is required'),
          }
        : {
            taxNumber: Yup.string()
              .required('Tax Number is required')
              .matches(/^\d{9}$/, 'TIN Number must be 9 digits')
              .typeError('TIN number must be a number'),
            address: Yup.string().required('Address is required'),
            city: Yup.string().required('City is required'),
            state: Yup.string().required('State is required'),
            zip_code: Yup.string().required('Zip Code is required'),
            country: Yup.string().required('Country is required'),
          }),

      ...(isDispatcherRoleModalOpen
        ? {
            mc_number: Yup.string().required('MC number is required'),
            dotNumber: Yup.string(),
          }
        : isCarrierRoleModalOpen? {
            dotNumber: Yup.string().required('DOT Number is required'),
            mc_number: Yup.string(),
          } 
          :{
            dotNumber: Yup.string(),
            mc_number: Yup.string(),
          }
      ),
    }),
    onSubmit: () => {
      setIsLoading(true)
      setIsErr([])
      setCompanyIdError('')
      setTaxNumberError('')

      const addDispatcherRolerequest = () => {
        setIsErr([])
        axiosInstance
          .post('authentication/add-role/', {
            type: 'dispatcher',
            mc_number: Formik.values.mc_number,
          })
          .then(() => {
            setIsDispatcherRoleModalOpen(false)
            setIsLoading(false)
            handleRefreshUser()
            setTimeout(() => {
              toast.success('Dispatcher Role Added Successfully')
            }, 200)
          })
          .catch(() => {
            setIsLoading(false)
            toast.error(
              'Failed to add MC number and dispatcher role. Please verify the MC number and try again.'
            )
            setIsErr([
              'Invalid MC number, Please double check your MC number or contact the FMCSA for assistance.',
            ])
          })
      }

      const addCarrierRolerequest = () => {
        setIsErr([])
        axiosInstance
          .post('authentication/add-role/', {
            type: 'carrier',
            dot_number: Formik.values.dotNumber,
          })
          .then(() => {
            setIsCarrierRoleModalOpen(false)
            setIsLoading(false)
            handleRefreshUser()
            setTimeout(() => {
              toast.success('Carrier Role Added Successfully')
            }, 200)
          })
          .catch(() => {
            setIsLoading(false)
            toast.error(
              'Failed to add DOT number and carrier role. Please verify the DOT number and try again.'
            )
            setIsErr([
              'Invalid DOT number, Please double check your DOT number or contact the FMCSA for assistance.',
            ])
          })
      }

      const addTaxInfo = () => {
        axiosInstance
          .post('authentication/user-tax/', {
            TIN: Formik.values.taxNumber,
            address: Formik.values.address,
            city: Formik.values.city,
            state: Formik.values.state,
            zip_code: Formik.values.zip_code,
            country: Formik.values.country,
          })
          .then(() => {
            if (isDispatcherRoleModalOpen) {
              addDispatcherRolerequest()
            } else if (isCarrierRoleModalOpen){
              addCarrierRolerequest()
            } else {
              toast.success('Tax Info Added Successfully')
              window.location.reload()
            }
            setOpenTaxInfoModal(false)
            handleRefreshUser()
            setIsLoading(false)
            setTimeout(() => {
              toast.success('Tax Info Added Successfully')
            }, 200)
          })
          .catch(() => {
            setIsLoading(false)
            setTaxNumberError('Tax Number already exist')
          })
      }

      const checkCompany = () => {
        let company = null
        axiosInstance
          .get(`/authentication/check-company/?id=${Formik.values.companyIdentifier}`)
          .then((res) => {
            //if company exist the response is 200 and if not exist the response is 404
            company = res.data
            axiosInstance
              .post('/authentication/company-employee/', {
                company: company.id,
                app_user: user.id,
              })
              .then(() => {
                if (isDispatcherRoleModalOpen) {
                  addDispatcherRolerequest()
                } else if (isCarrierRoleModalOpen){
                  addCarrierRolerequest()
                }
                else {
                  toast.success('Company ID Added Successfully')
                  window.location.reload()
                }
                setOpenTaxInfoModal(false)
                setIsLoading(false)
                handleRefreshUser()
              })
              .catch(() => {
                setIsLoading(false)
              })
          })

          //if company not exist the response is 404 and the setAssignCompany is true
          .catch((err) => {
            if (err.response.status === 404) {
              setCompanyIdError('Company not found')
            }
            setIsLoading(false)
          })
      }

      if (tab === 'companyId') {
        checkCompany()
      } else {
        addTaxInfo()
      }
    },
  })


  return (
    <div className={' '}>
      <form formik={Formik} onSubmit={Formik.handleSubmit}>
        {isCarrierRoleModalOpen || isDispatcherRoleModalOpen ? (
        <h2 className="mt-3 flex justify-center text-lg font-semibold text-gray-700">
          You can add a new role by a company ID or a TIN number and fill out the form to
          continue.
        </h2>
      ) : (
        <h2 className="mt-3 flex justify-center text-lg font-semibold text-gray-700">
          Add you Tax Information to expand your capabilities and access more features.
        </h2>
      )}
        {/* tabs for company id and tin number */}
        <div className="mx-auto mt-5 flex w-fit justify-center gap-3 rounded-sm bg-gray-100 py-2 px-10 font-medium ">
          <div
            className={`transition-all duration-300 ease-in-out 
      ${tab === 'companyId' ? 'bg-primary-500 text-white' : 'text-gray-700'}
      cursor-pointer rounded-sm p-2`}
            onClick={() => {
              setTab('companyId')
              Formik.resetForm()
              setCompanyIdError('')
            }}
          >
            Company ID
          </div>
          <div
            className={`transition-all duration-300 ease-in-out 
      ${tab === 'tinNumber' ? 'bg-primary-500 text-white' : 'text-gray-700'}
      cursor-pointer rounded-sm p-2 `}
            onClick={() => {
              setTab('tinNumber')
              Formik.resetForm()
              setTaxNumberError('')
            }}
          >
            TIN Number
          </div>
        </div>
        {/* if tab is company id show this div */}
        {tab === 'companyId' && (
          <>
            <FLabel htmlFor="companyIdentifier">Company Identifier</FLabel>
            <FInputField
              name="companyIdentifier"
              id="companyIdentifier"
              // maxLength="10"
              // minLength="10"
              value={Formik.values.companyIdentifier}
              onChange={(e) => {
                Formik.handleChange(e)
                setCompanyIdError('')
              }}
              type="text"
              placeholder="Enter your Company ID"
              onBlur={Formik.handleBlur}
            />
            <div className="flex">
              <FFormInvalidInput
                touched={Formik.touched}
                FieldName="companyIdentifier"
                errors={Formik.errors}
              />
            </div>
            {companyIdError.length > 0 && (
              <div>
                <small className="flex font-medium text-red-700">{companyIdError}</small>
              </div>
            )}
          </>
        )}
        {/* if tab is tin number show this div */}
        {tab === 'tinNumber' && (
          <div className="">
            <FLabel htmlFor="taxNumber">Tax Identification Number (TIN)</FLabel>
            <FInputField
              name="taxNumber"
              id="taxNumber"
              maxLength="9"
              value={Formik.values.taxNumber}
              onChange={(e) => {
                Formik.handleChange(e)
              }}
              type="text"
              placeholder="Enter your TIN"
              pattern="\d{9}"
              onBlur={Formik.handleBlur}
            />
            <div className="flex">
              <FFormInvalidInput
                touched={Formik.touched}
                FieldName="taxNumber"
                errors={Formik.errors}
              />

              <small className="flex font-medium text-red-700">{taxNumberError}</small>
            </div>

            <FFormWrapper>
              <FLabel htmlFor={'address'}>Address</FLabel>
              <FInputField
                id={'address'}
                name={'address'}
                value={Formik.values.address}
                onChange={Formik.handleChange}
                onBlur={Formik.handleBlur}
                placeholder={'Enter your address'}
              />
              <FFormInvalidInput
                touched={Formik.touched}
                FieldName={'address'}
                errors={Formik.errors}
              />
            </FFormWrapper>
            <div className={'lg:flex lg:flex-row  lg:justify-center lg:gap-10'}>
              <FFormWrapper className={'lg:w-1/2 '}>
                <FLabel htmlFor={'city'}>City</FLabel>
                <FInputField
                  id={'city'}
                  name={'city'}
                  value={Formik.values.city}
                  onChange={Formik.handleChange}
                  onBlur={Formik.handleBlur}
                  placeholder={'Enter your city'}
                />

                <FFormInvalidInput
                  touched={Formik.touched}
                  FieldName={'city'}
                  errors={Formik.errors}
                />
              </FFormWrapper>
              <FFormWrapper className={'lg:w-1/2 '}>
                <FLabel htmlFor={'state'}>State</FLabel>
                <FInputField
                  id={'state'}
                  name={'state'}
                  value={Formik.values.state}
                  onChange={Formik.handleChange}
                  onBlur={Formik.handleBlur}
                  placeholder={'Enter your state'}
                />
                <FFormInvalidInput
                  touched={Formik.touched}
                  FieldName={'state'}
                  errors={Formik.errors}
                />
              </FFormWrapper>
            </div>
            <div className={'lg:flex lg:flex-row  lg:justify-center lg:gap-10'}>
              <FFormWrapper className={'lg:w-1/2 '}>
                <FLabel htmlFor={'country'}>Country</FLabel>
                <FInputField
                  id={'country'}
                  name={'country'}
                  value={Formik.values.country}
                  onChange={Formik.handleChange}
                  onBlur={Formik.handleBlur}
                  placeholder={'Enter your country'}
                />
                <FFormInvalidInput
                  touched={Formik.touched}
                  FieldName={'country'}
                  errors={Formik.errors}
                />
              </FFormWrapper>

              <FFormWrapper className={'lg:w-1/2 '}>
                <FLabel htmlFor={'zip_code'}>Zip Code</FLabel>
                <FInputField
                  id={'zip_code'}
                  name={'zip_code'}
                  value={Formik.values.zip_code}
                  onChange={Formik.handleChange}
                  onBlur={Formik.handleBlur}
                  placeholder={'Enter your zip code'}
                />
                <FFormInvalidInput
                  touched={Formik.touched}
                  FieldName={'zip_code'}
                  errors={Formik.errors}
                />
              </FFormWrapper>
            </div>
          </div>
        )}
        {/* ---------------------------------------------------------------------------------------------- */}
        { isDispatcherRoleModalOpen ? (
          <>
            <FFormWrapper>
              <FLabel htmlFor="mc_number"> MC Number</FLabel>
              <FInputField
                type="text"
                name={'mc_number'}
                id={'mc_number'}
                placeholder={'Enter a MC Number'}
                onChange={Formik.handleChange}
                onBlur={Formik.handleBlur}
                value={Formik.values.mc_number}
              />

              <FFormInvalidInput
                touched={Formik.touched}
                FieldName={'mc_number'}
                errors={Formik.errors}
              />
              {isErr.length > 0 && (
                <div>
                  {isErr.map((err, index) => (
                    <small className={'text-left font-medium text-red-700'} key={index}>
                      {isErr}
                    </small>
                  ))}
                </div>
              )}
            </FFormWrapper>
          </>
        ) : isCarrierRoleModalOpen? (
          <>
            <FFormWrapper>
              <FLabel htmlFor="dotNumber"> DOT Number</FLabel>
              <FInputField
                type="text"
                name={'dotNumber'}
                id={'dotNumber'}
                placeholder={'Enter a DOT Number'}
                onChange={Formik.handleChange}
                onBlur={Formik.handleBlur}
                value={Formik.values.dotNumber}
              />

              <FFormInvalidInput
                touched={Formik.touched}
                FieldName={'dotNumber'}
                errors={Formik.errors}
              />
              {isErr.length > 0 && (
                <div>
                  {isErr.map((err, index) => (
                    <small className={'text-left font-medium text-red-700'} key={index}>
                      {isErr}
                    </small>
                  ))}
                </div>
              )}
            </FFormWrapper>
          </>
        ): null}

        {isDispatcherRoleModalOpen && (
          <div className={'mt-5 flex w-full flex-row-reverse gap-5'}>
            <FButton type={'submit'}>
              <FIconWrapper>
                <AiOutlinePlusCircle size={20} />
                Add Dispatcher Role
                <ESpinner isVisible={isLoading} />
              </FIconWrapper>
            </FButton>
            <FButton
              btnType={'secondary'}
              onClick={() => {
                setIsDispatcherRoleModalOpen(false)
                Formik.resetForm()
              }}
            >
              <FIconWrapper>Cancel</FIconWrapper>
            </FButton>
          </div>
        )}

        {isCarrierRoleModalOpen && (
          <div className={'mt-5 flex w-full flex-row-reverse gap-5'}>
            <FButton type={'submit'}>
              <FIconWrapper>
                <AiOutlinePlusCircle size={20} />
                Add Carrier Role
                <ESpinner isVisible={isLoading} />
              </FIconWrapper>
            </FButton>
            <FButton
              btnType={'secondary'}
              onClick={() => {
                setIsCarrierRoleModalOpen(false)
                Formik.resetForm()
              }}
            >
              <FIconWrapper>Cancel</FIconWrapper>
            </FButton>
          </div>
        )}

        {openTaxInfoModal && (
          <div className={'mt-5 flex w-full flex-row-reverse gap-5'}>
            <FButton type={'submit'}>
              <FIconWrapper>
                <AiOutlinePlusCircle size={20} />
                Add Tax Info
                <ESpinner isVisible={isLoading} />
              </FIconWrapper>
            </FButton>
            <FButton
              btnType={'secondary'}
              onClick={() => {
                setOpenTaxInfoModal(false)
                Formik.resetForm()
              }}
            >
              <FIconWrapper>Cancel</FIconWrapper>
            </FButton>
          </div>
        )}
      </form>
    </div>
  )
}
