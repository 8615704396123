import { useState } from 'react'

const FTooltip = ({ children, content }) => {
  const [TooltipVisible, setIsTooltipVisible] = useState(false)
  return (
    <div>
      <div
        onMouseOver={() => setIsTooltipVisible(true)}
        onMouseLeave={() => setIsTooltipVisible(false)}
      >
        {children}
      </div>
      {TooltipVisible && (
        <div
          className={
            'absolute mt-2 rounded bg-gray-800 p-2  text-xs leading-normal text-white '
          }
        >
          {content}
        </div>
      )}
    </div>
  )
}
export default FTooltip
