import LoadForm from "./LoadForm"
import useLoad from "./useLoad"
import { useFormik } from 'formik'
import {
    customValidation,
    getValidationSchema,
} from "../utils"
 import { useLocation } from "react-router-dom"
const CreateLoadForm = () => {
    const location = useLocation()
    const {customer} = location.state || {}
    console.log('location', location )
    console.log('customer', customer)
    const {
        loadInfo,
        setUnits,
        setIsExistingShipment,
        setSelectorKeyForPickup,
        setSelectorKeyForDrop,
        createDraft,
        initialValues: defaultInitialValues, // rename initialValues to avoid conflict
        onSubmit
    } = useLoad({ formType: 'create' })

    




    const Formik = useFormik({
        initialValues :
            {  
                ...defaultInitialValues,
                customer: customer
                ? {
                    id: customer?.id || '', // Use customer.id if available
                    value: customer?.username || '', // Use customer.username if available
                     
                    label: (
                        <div className="flex gap-2">
                          {customer?.first_name} {customer?.last_name}
                          <span className="my-auto text-sm text-gray-500">
                            {customer?.username}
                          </span>
                        </div>
                      ) || ' ' , 
                    
                    // Use customer.username if available
                  } : null,
            },
        onSubmit,
        validationSchema: getValidationSchema(),
        validate: (values) => customValidation(values, loadInfo.isExistingShipment),
        validateOnChange: true,
        validateOnBlur: true,
    })

    return (
        <LoadForm
            formType='create'
            Formik={Formik}
            loadInfo={loadInfo}
            formTitle='Create New Load'
            setUnits={setUnits}
            setIsExistingShipment={setIsExistingShipment}
            setSelectorKeyForDrop={setSelectorKeyForDrop}
            setSelectorKeyForPickup={setSelectorKeyForPickup}
            primaryBtnTitle='Create'
            primaryLoading={loadInfo.creatLoadLoading}
            secondaryBtnTitle='Save As Draft'
            secondaryLoading={loadInfo.draftLoading}
            onClickSecondary={() => createDraft(Formik.values)}
        />
    )
}

export default CreateLoadForm