import React from 'react'

const getRoleClassNames = (role) => {
  switch (role) {
    case 'customer':
      return 'border-blue-300 bg-blue-100 text-blue-600'
    case 'shipper':
      return 'border-blue-300 bg-blue-100 text-blue-600'
    case 'consignee':
      return 'border-blue-300 bg-blue-100 text-blue-600'
    case 'carrier':
      return 'border-yellow-300 bg-yellow-100 text-yellow-600'
    case 'manager':
      return ''
    case 'dispatcher':
      return 'border-green-300 bg-green-100 text-green-600'
    default:
      return ''
  }
}

export default function UserRole({ role }) {
  return (
    <span
      className={`border px-2 py-1 text-sm font-semibold ${getRoleClassNames(
        role?.trim()
      )}`}
    >
      {role?.trim()}
    </span>
  )
}
