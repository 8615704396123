import React, { useState } from 'react'
import FModal from '../../../components/FModal'
import FFormWrapper from '../../../components/FFormWrapper'
import FFormInvalidInput from '../../../components/FFormInvalidInput'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { axiosInstance } from '../../../axios/requister'
import { toast } from 'react-toastify'
// import FInputField from '../../../components/FInputField'
import FLabel from '../../../components/FLabel'
import FButton from '../../../components/FButton'
import FIconWrapper from '../../../components/FIconWrapper'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import ESpinner from '../../../components/ESpinner'
import FileUploadButton from '../../../components/FileUploadButton '
import { uploadSignedUrl } from '../utils'

const ClaimNoteForm = ({ claimId, isOpen, setIsOpen, claimNoteRefech }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)

  const handleClose = () => {
    Formik.resetForm()
    setIsOpen(false)
    // abort??
  }

  const Formik = useFormik({
    initialValues: {
      message: '',
      supporting_docs: [],
    },
    validationSchema: Yup.object({
      message: Yup.string().trim().required('Claim note message must be provided.'),
      supporting_docs: Yup.array().of(Yup.mixed().required()).nullable(),
    }),
    onSubmit: async (values) => {
      setIsLoading(true)

      const formData = {
        message: values.message,
        claim_id: claimId,
        supporting_docs: values.supporting_docs.map((file) => ({
          name: file.name,
          content_type: file.type,
        })),
      }

      try {
        const response = await axiosInstance.post('/shipment/load/claim/note/', formData)
        const serverAttachments = response?.data.supporting_docs
        const onUploadProgress = (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          setUploadProgress(progress)
        }
        await uploadSignedUrl({
          serverAttachments,
          uploadedFiles: values.supporting_docs,
          onUploadProgress,
          objId: response.data.id,
          metaKey: 'claim_note_id',
          type: 'claim note',
        })
        await claimNoteRefech()
        toast.success('Claim note has been created successfully.')
        Formik.resetForm()
        setIsOpen(false)
      } catch (error) {
        toast.error(error.response?.data?.details || 'Something went wrong!')
      } finally {
        setIsLoading(false)
      }
    },
  })

  return (
    <FModal
      title={'Add your Claim Note'}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      callbackFunction={handleClose}
    >
      <form onSubmit={Formik.handleSubmit}>
        <FFormWrapper>
          <FLabel> Claim Note Description </FLabel>
          <textarea
            id="message"
            rows={3}
            name="message"
            placeholder="Write your message before adding a note"
            onChange={Formik.handleChange}
            onBlur={Formik.handleBlur}
            value={Formik.values.message}
            className="input-style"
          />
          <FFormInvalidInput
            touched={Formik.touched}
            FieldName={'message'}
            errors={Formik.errors}
          />
        </FFormWrapper>

        <FFormWrapper className="w-full lg:w-1/2">
          <FLabel htmlFor="supporting_docs"> Upload Supporting Documents</FLabel>

          <FileUploadButton
            name="supporting_docs"
            initialFiles={Formik.values.supporting_docs}
            acceptedTypes={['image/png', 'image/jpeg', 'application/pdf']}
            onFileChange={(files) => Formik.setFieldValue('supporting_docs', files)}
            onBlur={Formik.handleBlur}
            multiple={true}
            onRemoveImage={(image) => {
              Formik.setFieldValue(
                'supporting_docs',
                Formik.values.supporting_docs.filter((file) => file.name !== image.name)
              )
            }}
          />

          <FFormInvalidInput
            touched={Formik.touched}
            FieldName="supporting_docs"
            errors={Formik.errors}
          />
        </FFormWrapper>

        <div className="flex w-full flex-row-reverse gap-2">
          <FButton type="submit" disabled={isLoading}>
            <FIconWrapper>
              <AiOutlinePlusCircle />
              Add Claim Note {isLoading && `${uploadProgress}%`}
              <ESpinner isVisible={isLoading} />
            </FIconWrapper>
          </FButton>
          <FButton btnType="secondary" onClick={handleClose}>
            <FIconWrapper>Cancel</FIconWrapper>
          </FButton>
        </div>
      </form>
    </FModal>
  )
}

export default ClaimNoteForm
