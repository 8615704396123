import FIconWrapper from '../../../components/FIconWrapper'
import { useCustomAxios } from '../../../Hooks/useAxios'
import { useParams, useNavigate } from 'react-router-dom'
import FButton from '../../../components/FButton'
import { BiEdit, BiCopy } from 'react-icons/bi'
import { GoChevronLeft } from 'react-icons/go'
import React, { useState, useContext, useEffect } from 'react'
import DocumentsTab from '../LoadDetails/DocumentsTab'
import ESpinner from '../../../components/ESpinner'
import LoadTab from './LoadTab/LoadTab'
import Status from '../../../components/Status'
import { BASE_URL } from '../../../env'
import Alert from '../../../components/Alert'
import { UserContext } from '../../../components/UserContext'
import ClaimsPage from '../Claims/ClaimsPage'
import { Tooltip } from '@mui/material'
import LoadInsuranceTab from './LoadInsuranceTab'
import LogsTab from './LogsTab'

const LoadDetails = () => {
  const { id } = useParams()
  const [tab, setTab] = useState('Load Details')
  const navigate = useNavigate()
  const [alertVisible, setAlertVisible] = useState(true) // State variable to track the alert visibility

  const { user } = useContext(UserContext)

  const { response, loading, Refetch } = useCustomAxios({
    url:
      user?.selected_role === 'manager'
        ? `/manager/load-details/${id}/`
        : `/shipment/load-details/${id}/`,
    method: 'GET',
  })
  const userInfo = useCustomAxios({
    url: `${BASE_URL}authentication/app-user/`,
    method: 'GET',
  })
  const startBidInfo = useCustomAxios({
    url:
      user.selected_role === 'manager'
        ? `${BASE_URL}manager/offer/${id}/`
        : `${BASE_URL}shipment/offer/${id}/`,
    method: 'GET',
  })

  const edit = () => {
    navigate(`/load/${id}`)
  }

  const handleClickCopy = () => navigate(`/load/${id}/copy`)

  const closeAlert = () => {
    setAlertVisible(false)
  }
  useEffect(() => {
    // Check if alert has been shown in the last 24 hours
    const hide = localStorage.getItem('hideAlert')
    if (hide) {
      setAlertVisible(false)
    }
  }, [])

  const isAllowedStatus =
    response?.status.toLowerCase() === 'ready for pickup' ||
    response?.status.toLowerCase() === 'in transit' ||
    response?.status.toLowerCase() === 'delivered'

  const isAllowedUser =
    response?.customer?.username !== response?.shipper?.username ||
    response?.customer?.username !== response?.consignee?.username ||
    response?.customer?.username !== response?.dispatcher?.username ||
    response?.customer?.username !== response?.carrier?.username

  if (
    loading ||
    startBidInfo.loading ||
    userInfo.loading
    // ||
    // loadParties.loading
    // ||
    // loadNoteInfo.loading ||
    // deletedLoadNotes.loading
  ) {
    return (
      <div className={'f-col-center-center h-full w-full'}>
        <ESpinner isVisible={true} size={30} />
        <span className="mt-5">Loading...</span>
      </div>
    )
  } else if (!response || response?.is_draft) {
    navigate('/not-found')
  } else
    return (
      <div>
        {alertVisible && (
          <div
            className="fixed top-0 left-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-25"
            onClick={closeAlert}
          >
            <div className="fixed top-12 right-0">
              <Alert
                message={
                  <div className=''>
                    {`You are currently acting as a ${userInfo.response?.selected_role}. If you need to perform actions related to a different role you can switch to that role from this menu.`}
                    <br />
                    <button
                      className="mt-1 whitespace-nowrap  text-primary-600 underline"
                      onClick={() => {
                        closeAlert()
                        localStorage.setItem('hideAlert', true)
                      }}
                    >
                      Don’t show again
                    </button>
                  </div>
                }
                type={'alert'}
              />
            </div>
          </div>
        )}
        <div
          className={
            'sticky top-0 z-10  flex w-full  justify-between border-b bg-white p-3 ' +
            'text-left text-xl font-semibold text-gray-700'
          }
        >
          <FIconWrapper>
            <div>
              <div className="flex flex-col lg:flex-row ">
                <div className="flex flex-row">
                  <GoChevronLeft
                    className={
                      'cursor-pointer rounded p-0.5  hover:border hover:bg-gray-100'
                    }
                    // i want to navigate to the previous page according the pagination
                    onClick={() => navigate(-1)}
                    size={28}
                  />

                  <div className="flex flex-col">
                    <span>{'Load' + ' ' + response?.name} </span>
                    <span className="text-sm font-normal text-gray-400">
                      Created by : {''}
                      {response?.created_by === userInfo.response?.username
                        ? 'You'
                        : response?.created_by}
                    </span>
                    <span className="  mt-1 block lg:ml-10 lg:hidden">
                      <Status statusName={response?.status} />{' '}
                    </span>
                  </div>
                </div>

                <span className="mt-1 hidden  lg:ml-10 lg:block">
                  <Status statusName={response?.status} />{' '}
                </span>
              </div>
            </div>
          </FIconWrapper>

          <div className="flex justify-end gap-2">
            {userInfo?.response?.username === response?.created_by && (
              <FButton className="block h-fit" onClick={handleClickCopy}>
                <span className="flex gap-2">
                  <BiCopy size={20} /> Copy Load
                </span>
              </FButton>
            )}
            <FButton
              className={`${
                userInfo?.response?.username === response?.created_by &&
                response?.status.toLowerCase() === 'Created'.toLowerCase()
                  ? 'block h-fit'
                  : 'hidden'
              }`}
              type={'submit'}
              onClick={edit}
            >
              <span className="flex gap-2">
                <BiEdit size={20} /> Edit Load
              </span>
            </FButton>
          </div>
        </div>

        <div
          className={
            'primary-shadow  z-10  flex w-full justify-between gap-2 bg-white px-2'
          }
        >
          <div className="flex items-center">
            <div
              onClick={() => setTab('Load Details')}
              className={` ${
                tab === 'Load Details' && ' border-b-4'
              } cursor-pointer border-primary-500  p-2 `}
            >
              <span className={'text-sm font-semibold  text-gray-700'}>
                {'Load Details'}
              </span>
            </div>

            <div
              onClick={() => setTab('documents')}
              className={` ${
                tab === 'documents' && ' border-b-4'
              } cursor-pointer border-primary-500  p-2 `}
            >
              <span className={'text-sm font-semibold  text-gray-700'}>
                {'Documents'}
              </span>
            </div>

            <div
              onClick={() => setTab('insurances')}
              className={` ${
                tab === 'insurances' && 'border-b-4'
              } cursor-pointer border-primary-500 p-2 `}
            >
              <span className={'text-sm font-semibold  text-gray-700'}>
                {'Insurances'}
              </span>
            </div>
            <div
              onClick={() => setTab('Logs')}
              className={` ${
                tab === 'Logs' && ' border-b-4'
              } cursor-pointer border-primary-500 p-2 `}
            >
              <span className={'text-sm font-semibold  text-gray-700'}>{'Logs'}</span>
            </div>

            {/* <div
                className={
                  'rounded-full border bg-gray-100 px-3 py-1 text-sm font-medium leading-3'
                }
              >
                1
              </div> */}
            {isAllowedStatus && isAllowedUser ? (
              <div
                onClick={() => setTab('Claims')}
                className={` ${tab === 'Claims' && ' border-b-4'}
                 cursor-pointer border-primary-500 p-2 `}
              >
                <span className={'text-sm font-semibold text-gray-700'}>Claims</span>
              </div>
            ) : (
              <Tooltip
                placement="bottom-start"
                arrow
                title="You can only file a claim if the load is in transit, ready for pickup or delivered"
              >
                <div className={` cursor-not-allowed  p-2 `}>
                  <span className={'text-sm font-semibold text-gray-700'}>Claims</span>
                </div>
              </Tooltip>
            )}
          </div>
        </div>

        {tab === 'Load Details' && (
          <LoadTab
            response={response}
            userInfo={userInfo}
            startBidInfo={startBidInfo}
            Refetch={Refetch}
          />
        )}
        {tab === 'documents' && (
          <DocumentsTab
            response={response}
            loadId={response?.id}
            loadName={response?.name}
            userInfo={userInfo}
            Refetch={Refetch}
            status={response?.status}
          />
        )}

        {tab === 'Claims' && (
          <ClaimsPage
            loadId={response?.id}
            claimId={response?.claim?.id}
            userName={user?.username}
            Refetch={Refetch}
          />
        )}

        {tab === 'insurances' && (
          <LoadInsuranceTab
            id={response?.id}
            isUnderInsured={response?.is_under_insured}
          />
        )}
        {tab === 'Logs' && <LogsTab />}
      </div>
    )
}

export default LoadDetails
