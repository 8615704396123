import propTypes from 'prop-types'

const FFormInvalidInput = ({ touched, errors, FieldName }) => {
  return (
    <>
      {touched[FieldName] && errors[FieldName] ? (
        <small className={'text-left font-medium text-red-700'}>
          {errors[FieldName]}
        </small>
      ) :
        null
      }
    </>
  )
}
export default FFormInvalidInput

FFormInvalidInput.propTypes = {
  touched: propTypes.object.isRequired,
  errors: propTypes.object.isRequired,
  FieldName: propTypes.string.isRequired,
}
