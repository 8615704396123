import React from 'react'

const ECard = ({ children, className }) => (
  <div
    className={`primary-shadow w-full border bg-white ${className !== undefined ? className : ''
      }`}
  >
    {children}
  </div>
)
// eslint-disable-next-line react/display-name
ECard.Header = (props) => (
  <div
    style={{ background: '#faf9fb' }}
    className={`card-header   border-b p-2 ${props.className}`}
  >
    {props.children}
  </div>
)
// eslint-disable-next-line react/display-name
ECard.Body = (props) => (
  <div className={`card-body p-3 ${props.className}`}>{props.children}</div>
)
// eslint-disable-next-line react/display-name
ECard.Footer = (props) => (
  <div className={`card-header   p-3 ${props.className}`}>{props.children} </div>
)
export default ECard
