import React from 'react'
import FIconWrapper from '../../components/FIconWrapper'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import FButton from '../../components/FButton'
import FModal from '../../components/FModal'
import FFormWrapper from '../../components/FFormWrapper'
import { useFormik } from 'formik'
import ESpinner from '../../components/ESpinner'
import { InsuranceCardBody } from './InsuranceCard'
import * as Yup from 'yup'
import { axiosInstance } from '../../axios/requister'
import { toast } from 'react-toastify'

const CreateInsurance = ({
  isCreateInsuranceModalOpen,
  setIsCreateInsuranceModalOpen,
  refetch,
  selectedInsurance,
}) => {
  const [loading, setLoading] = React.useState(false)
  const [insranceNameErr, setInsuranceNameErr] = React.useState(false)
  const [insurancePolicyErr, setInsurancePolicyErr] = React.useState(false)

  const Formik = useFormik({
    initialValues: {
      insurance_name: selectedInsurance ? selectedInsurance.name : '',
      insurance_provider: selectedInsurance ? selectedInsurance.provider : '',
      insurance_policy_number: selectedInsurance ? selectedInsurance.policy_number : '',
      insurance_type: selectedInsurance ? selectedInsurance.type.id : '',
      insurance_premium_amount: selectedInsurance ? selectedInsurance.premium_amount : '',
      insurance_expiration_date: selectedInsurance
        ? selectedInsurance.expiration_date
        : '',
    },
    validationSchema: Yup.object().shape({
      insurance_name: Yup.string().required('Insurance name is required'),
      insurance_provider: Yup.string().required('Insurance provider is required'),
      insurance_policy_number: Yup.string()
        .required('Policy number is required')
        .matches(
          /^[A-Za-z0-9-]{8,}$/,
          'IPN should be at least 8 characters long and can include letters, digits, and hyphens.'
        ),
      insurance_type: Yup.number().required('Insurance type is required'),

      insurance_premium_amount: Yup.number()
        .typeError('Premium amount is required and must be a number')
        .required('Premium amount is required')
        .positive('Premium amount must be a positive number'),

      insurance_expiration_date: Yup.date()
        .min(new Date(), 'Expiration date must be in the future')
        .required('Expiration date is required'),
    }),

    onSubmit: async (values) => {
      setLoading(true)
      try {
        let response
        if (selectedInsurance) {
          // Update existing insurance (send a PATCH request)
          response = await axiosInstance.patch(`/insurance/${selectedInsurance.id}/`, {
            name: values.insurance_name,
            provider: values.insurance_provider,
            policy_number: values.insurance_policy_number,
            type: values.insurance_type,
            premium_amount: values.insurance_premium_amount,
            expiration_date: values.insurance_expiration_date,
          })
        } else {
          // Create new insurance (send a POST request)
          response = await axiosInstance.post('/insurance/', {
            name: values.insurance_name,
            provider: values.insurance_provider,
            policy_number: values.insurance_policy_number,
            type: values.insurance_type,
            premium_amount: values.insurance_premium_amount,
            expiration_date: values.insurance_expiration_date,
          })
        }

        setIsCreateInsuranceModalOpen(false)
        setLoading(false)
        await refetch()
        if (response) {
          toast.success(
            'Insurance ' + (selectedInsurance ? 'updated' : 'created') + ' successfully'
          )
        }
      } catch (error) {
        Object.keys(error.response.data).forEach((key) => {
          if (key === 'name') {
            setInsuranceNameErr([error.response.data[key]])
          }
          if (key === 'policy_number') {
            setInsurancePolicyErr([error.response.data[key]])
          }
        })

        setLoading(false)
        toast.error(
          'Error ' + (selectedInsurance ? 'updating' : 'creating') + ' insurance'
        )
      }
    },
  })
  const buttonTitle = selectedInsurance ? 'Update Insurance' : 'Create Insurance'

  return (
    <div>
      <FModal
        title={selectedInsurance ? 'Update Insurance' : 'Create Insurance'}
        isOpen={isCreateInsuranceModalOpen}
        setIsOpen={setIsCreateInsuranceModalOpen}
      >
        <form onSubmit={Formik.handleSubmit}>
          <FFormWrapper>
            <InsuranceCardBody
              Formik={Formik}
              handleInputChange={Formik.handleChange}
              insranceNameErr={insranceNameErr}
              insurancePolicyErr={insurancePolicyErr}
              setInsuranceNameErr={setInsuranceNameErr}
              setInsurancePolicyErr={setInsurancePolicyErr}
            />
          </FFormWrapper>

          <div className={'flex w-full flex-row-reverse gap-1'}>
            <FButton type={'submit'}>
              <FIconWrapper>
                <AiOutlinePlusCircle size={20} />
                <span className="flex gap-2">
                  {buttonTitle} {loading && <ESpinner isVisible={true} size={18} />}
                </span>
              </FIconWrapper>
            </FButton>
            <FButton
              btnType={'secondary'}
              type={'submit'}
              onClick={() => {
                Formik.resetForm()
                setIsCreateInsuranceModalOpen(false)
              }}
            >
              <FIconWrapper>Cancel</FIconWrapper>
            </FButton>
          </div>
        </form>
      </FModal>{' '}
    </div>
  )
}

export default CreateInsurance
