import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { axiosInstance } from '../../axios/requister'
import ESpinner from '../../components/ESpinner'
import Instance from '../Load/Instance'
import FButton from '../../components/FButton'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import CustomPagination from '../../components/CustomPaginationStyle'
import { useState } from 'react'


export default function Drafts() {
  const navigate = useNavigate()
const [page, setPage] = useState(1)

  const {
    data: drafts,
    status,
    refetch,
  } = useQuery(
    ['drafts', page],
    async () => {
      const response = await axiosInstance.get(`/shipment/load/draft/?page=${page}`)
      return response.data
    },
    {
      keepPreviousData: true,
      retry: false,
    }
  )

 
  const totalNumberOfPages = Math.ceil(drafts?.count / 5) || 0
  const handlePageChange = (event, value) => {
    setPage(value)
  }


  return (
    <div>
      <div
        className={
          ' sticky top-0  z-10 flex  w-full justify-between border-b bg-white p-3 ' +
          'text-left text-xl font-semibold text-gray-700'
        }
      >
        <span className={''}>Drafts</span>
        <div
          className={'block'}
        >
          {/* <Link to={'/load/create'}> */}
          {!!drafts?.results?.length &&
            <FButton
              // disabled={loadInfo.draftLoading}
              onClick={() => navigate('/load/create')}
            >
              <AiOutlinePlusCircle size={20} />
              <span className={'pl-1 text-sm font-medium'}>Create New Draft</span>{' '}
            </FButton>
          }
          {/* </Link> */}
        </div>
      </div>
      {status === 'loading' ? (
        <div className={'f-col-center-center mt-60 h-full w-full'}>
          <ESpinner isVisible={true} size={30} />
          <span>Loading...</span>
        </div>
      ) : drafts?.results?.length > 0 ? (
        <div className="gap2 flex flex-col">
          {drafts?.results?.map((item) => (
            <div className="px-2 lg:px-10" key={item.id}>
              <Instance
                id={item.id}
                name={item.name}
                status={item.status}
                shipper={item.shipper?.username}
                pick_up_date={item.pick_up_date}
                pick_up_location={item.pick_up_location?.building_name}
                consignee={item.consignee?.username}
                delivery_date={item.delivery_date}
                delivery_time={item.delivery_time}
                earliest_pick_up_time={item.earliest_pick_up_time}
                latest_pick_up_time={item.latest_pick_up_time}
                destination={item.destination?.building_name}
                dispatcher={item.dispatcher?.username}
                customer={item.customer?.username}
                carrier={item.carrier?.username}
                // page={page}
                // setPage={setPage}
                has_claim={item.has_claim}
                isDraft
                onDeleteDraft={refetch}
              />
            </div>
          ))}
        
        <div className={` flex justify-center items-center py-5`}>
              <div>
                <CustomPagination
                  count={totalNumberOfPages}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                  size="large"
                />
              </div>
            </div>

          
        </div>
      ) : (
        <div className={'flex flex-col items-center justify-center py-56'}>
          <img
            src="https://storage.googleapis.com/freight_static_files/empty-state.svg"
            alt={'empty_state'}
            width={200}
            className={''}
          />
          <span className={'my-5 font-semibold text-gray-700'}> No Drafts Found</span>
          {!drafts?.results?.length &&
            <FButton
              btnType={'primary'}
              onClick={() => navigate('/load/create')}
            // disabled={loadInfo.draftLoading}
            >
              <span className="flex gap-2">
                <AiOutlinePlusCircle size={20} />
                Add Load Draft
              </span>
            </FButton>
          }
        </div>
      )}
    </div>
  )
}
