import { useState } from 'react'
import { SiAcclaim } from 'react-icons/si'
import TimeAgo from '../../components/TimeAgo'
import FAvatar from '../../components/FAvatar'
import UserRole from '../Load/Claims/UserRole'
import Status from '../../components/Status'
import ESpinner from '../../components/ESpinner'
import { useQuery } from '@tanstack/react-query'
import { axiosInstance } from '../../axios/requister'
import { Tooltip } from '@mui/material'
import PaginationControls from '../../components/PaginationControls'

const SupportClaims = () => {
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const getNextPage = () => setPage((prevPage) => prevPage + 1)
  const getPreviousPage = () => setPage((prevPage) => prevPage - 1)

  const { data, status } = useQuery(
    ['claims', page],
    async () => {
      const response = await axiosInstance.get(`support/list-claims/?page=${page}`)
      setCount(response.data.count)
      return response.data
    },
    {
      keepPreviousData: true,
      retry: false,
    }
  )

  const totalNumberOfPages = Math.ceil(count / 5)

  return (
    <div className="mx-auto py-10 w-11/12 h-full">

      {status === 'loading' && (
        <div className=" mx-auto flex h-full w-full flex-col items-center justify-center">
          <ESpinner isVisible size={20} />
          <p className="text-gray-500">Loading...</p>
        </div>
      )}

      {/* Results */}
      {status === 'success' && !!(data?.results.length) ? (
        <div className="flex flex-col gap-5 ">
          {data?.results.map((claim) => (
            <div
              key={claim.load}
              className="xl:s flex flex-col items-start  gap-5 
                border bg-white p-5 shadow-sm xl:flex-row xl:items-start"
            >
              <div className="my-auto flex lg:w-4/12 ">
                <div>
                  <SiAcclaim
                    size={25}
                    className="mt-2 hidden text-3xl  text-gray-800 lg:block"
                  />
                </div>{' '}
                <div className="flex flex-col pl-2">
                  <div className="whitespace-nowrap text-lg font-medium text-gray-800">
                    Load Claim
                  </div>
                  <Tooltip title={'load name'} placement={'top'} arrow>
                    <div className="text-base  text-gray-500">
                      {'load: ' + claim.load}
                    </div>
                  </Tooltip>
                </div>
              </div>

              {/* CLAIMED_BY */}
              <div className=" gap-2 lg:w-3/12 lg:justify-start xl:flex xl:flex-col">
                <div className="flex items-center gap-1">
                  <Tooltip title={'claimant name'} placement={'top'} arrow>
                    <div>
                      <FAvatar width={35} name={claim.claimant.username} />
                    </div>
                  </Tooltip>
                  <p className="text-gray-800">{claim.claimant.username}</p>
                </div>
                <div className="mt-2 flex gap-1">
                  {claim.claimant.party_roles.map((role) => (
                    <UserRole key={role} role={role} />
                  ))}
                </div>
              </div>

              {/* CLAIMED_ON */}
              <div className="my-auto  font-medium text-gray-800 lg:flex lg:w-3/12 xl:justify-end">
                <div className="flex items-center gap-1">
                  <TimeAgo timestamp={claim.created_at} />
                </div>
              </div>

              {/* STATUS */}
              <div className="my-auto lg:flex lg:w-2/12 xl:justify-end ">
                <Status
                  statusName={claim.status}
                  color={claim.status === 'closed' ? 'red' : 'yellow'}
                />
              </div>
            </div>
          ))}

          {/* Pagination */}
          <PaginationControls
            page={page}
            getNextPage={getNextPage}
            getPreviousPage={getPreviousPage}
            totalNumberOfPages={totalNumberOfPages}
          />
        </div>
      ) : (
        <div className="mx-auto flex h-full w-full flex-col items-center justify-center">
          <p className="text-xl text-gray-500">No claims were submitted yet.</p>
        </div>
      )}

    </div>
  )
}

export default SupportClaims
