import FCard from '../../components/FCard'
import { BsCheckLg } from 'react-icons/bs'
import FAvatar from '../../components/FAvatar'
import { HiOutlineArrowRight, HiOutlineInformationCircle } from 'react-icons/hi'
import FButton from '../../components/FButton'
import { Link } from 'react-router-dom'
import { GoChevronLeft } from 'react-icons/go'
import { FaRegCalendarAlt } from 'react-icons/fa'
import FTooltip from '../../components/FTooltip'

const Afator = ({ role, name }) => {
  return (
    <>
      <div className={'f-col-center-center'}>
        <div
          className={
            'primary-shadow f-col-center-center h-16 w-16 rounded-lg border-4 border-gray-100 bg-gray-300'
          }
        >
          <BsCheckLg size={30} width={10} />
        </div>
        <div>
          <div
            className={
              'f-col-center-center -mt-3 h-6 w-6 rounded-full border-2 border-white bg-green-600 text-white'
            }
          >
            <BsCheckLg size={10} />
          </div>
        </div>
        <span className={'text-sm font-semibold text-gray-700'}>{name}</span>
        <span className={'text-xs text-gray-600'}>{role}</span>
      </div>
    </>
  )
}

const ViewLoad = () => {
  return (
    <div className={''}>
      <div className={'primary-shadow flex border-b bg-white px-3 py-5'}>
        <div className={'flex items-center gap-3'}>
          <Link to={'/load'} className={'hove:border rounded p-0.5 hover:bg-gray-200'}>
            <GoChevronLeft size={25} />
          </Link>
          <span className={'text-2xl font-semibold'}>Load L#464118</span>
          <span className={'text-sm text-yellow-500'}>. PENDING</span>
          <FTooltip content={'This load is pending approval from the shipper.'}>
            <HiOutlineInformationCircle className={'text-yellow-500'} size={20} />
          </FTooltip>
        </div>
        <div className={'ml-auto flex items-center gap-2'}>
          <div className={'flex gap-2'}>
            <FaRegCalendarAlt size={30} />
            <div className={'flex flex-col'}>
              <span className={'text-sm font-semibold text-gray-700'}>
                Expected delivery date
              </span>
              <span className={'text-xs text-gray-500'}>15 Mar, 2021 10:00 PM</span>
            </div>
          </div>
          <div className={'flex gap-2'}>
            <FaRegCalendarAlt size={30} />
            <div className={'flex flex-col'}>
              <span className={'text-sm font-semibold text-gray-700'}>
                Expected delivery date
              </span>
              <span className={'text-xs text-gray-500'}>15 Mar, 2021 10:00 PM</span>
            </div>
          </div>
        </div>
      </div>
      <div className={'p-3'}>
        <div>
          <span className={'text-xl font-medium '}>You have a dispatcher offer</span>
          <div
            className={
              'primary-shadow mb-5 flex h-full w-fit items-center gap-2 border bg-white p-3 '
            }
          >
            <div className={'flex  gap-2  '}>
              <FAvatar name={'John Doe'} />
              <div className={'flex flex-col '}>
                <span className={'text-lg font-semibold'}>Guy Hawkins</span>
                <span className={'text-sm leading-5'}>1 Minutes ago</span>
              </div>
            </div>
            <div className={'h-full bg-white  '}>
              <HiOutlineArrowRight />
            </div>
            <div className={'flex flex-col text-sm  '}>
              <span>OFFERED PRICE</span>
              <span className={'text-xl font-semibold leading-5 text-orange-500'}>
                $164.55
              </span>
            </div>
            <div className={'ml-16 flex gap-5 '}>
              <FButton btnType={'success'}>Accept</FButton>
              <FButton btnType={'danger'}>Reject</FButton>
            </div>
          </div>
        </div>

        <FCard>
          <FCard.Header>
            <span>Shipment Contributers</span>
          </FCard.Header>
          <FCard.Body>
            <div className={'flex justify-between gap-3'}>
              <Afator role={'Shipper'} />
              <Afator role={'Customer'} />
              <Afator role={'Dispatcher'} />
              <Afator role={'Carrier'} />
              <Afator role={'Consignee'} />
            </div>
          </FCard.Body>
        </FCard>
      </div>
    </div>
  )
}
export default ViewLoad
