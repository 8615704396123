import React from 'react'
import UserRole from './UserRole'
import { HiLink } from 'react-icons/hi'
import FCard from '../../../components/FCard'
import FileRenderer from './FileRenderer'
import Status from '../../../components/Status'

const ClaimsDetails = ({ loadId, claim }) => {
  const openRBol = () => {
    window.open(`${window.location.origin}/rbol?load=${loadId}`, '_blank')
  }

  return (
    <div className="f-col-center-center">
      <FCard className="mt-5 flex w-11/12 flex-col gap-5 p-3 px-5 lg:w-10/12">
        <div className="flex items-center justify-between">
          <p className="mb-3 text-xl font-medium">Claim Details</p>
          <Status statusName={claim?.status} />
        </div>
        <div className="flex flex-col gap-2 lg:flex-row">
          <div className="flex-1">
            <p className="font-medium">Claimant Name</p>
            <div className="items-center lg:flex">
              <p className="text-gray-500 mr-2">{claim?.claimant.username}</p>
              {claim?.claimant.party_roles.map((role) => (
                <UserRole key={role} role={role} />
              ))}
            </div>
          </div>
          <div className="flex-1">
            <p className="font-medium">Date of loss</p>
            <p className="text-gray-500">{claim?.date_of_loss}</p>
          </div>
        </div>
        <div className="w-full flex">
          <div className="flex w-full flex-wrap gap-5">
            <div className="w-full lg:w-1/2">
              <p className="font-medium">Description of Loss</p>
              <p className="text-gray-500">{claim?.description_of_loss}</p>
            </div>
            <div className="w-full lg:w-1/2">
              <p className="font-medium">Load BOL</p>
              <div className="flex items-center gap-1 text-blue-500 hover:text-blue-700">
                <HiLink />
                <div onClick={openRBol} className="cursor-pointer">
                  View BOL
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5 flex w-full flex-wrap lg:mt-0">
            <div className="w-full">
              <p className="font-medium">Photos</p>
              <div className="flex flex-wrap gap-2">
                {claim?.supporting_docs.length !== 0 ? claim?.supporting_docs.map((file, index) => (
                  <FileRenderer
                    key={file.name}
                    name={file.name}
                    url={file.url}
                    alt={`Claim Photo ${index + 1}`}
                  />
                )) : (
                  <p className="text-gray-500">No photos.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </FCard>
    </div>
  )
}

export default ClaimsDetails
