import React, { useState, useContext } from 'react'
import RadioCard from '../../components/RadioCard'
import FButton from '../../components/FButton'
import { UserContext } from '../../components/UserContext'
import ShipmentRoleModal from './ShipmentRoleModal'
import DispatcherRoleModal from './DispatcherRoleModal'
import CarrierRoleModal from './CarrierRoleModal'

const Roles = () => {
  const [isShipmentRoleModalOpen, setIsShipmentRoleModalOpen] = useState(false)
  const [isDispatcherRoleModalOpen, setIsDispatcherRoleModalOpen] = useState(false)
  const [isCarrierRoleModalOpen, setIsCarrierRoleModalOpen] = useState(false)
  const [selectedRole, setSelectedRole] = useState('')
  const { user, userRoles, refreshUser } = useContext(UserContext)

  const hadleRefreshUser = () => {
    refreshUser()
  }

  const handleSelectRole = (role) => {
    setSelectedRole(role)
  }
  const handleClick = () => {
    if (selectedRole === 'Shipment Party') {
      setIsShipmentRoleModalOpen(true)
    } else if (selectedRole === 'Dispatcher') {
      setIsDispatcherRoleModalOpen(true)
    } else if (selectedRole === 'Carrier') {
      setIsCarrierRoleModalOpen(true)
    } else {
      alert('Please Select a Role')
    }
  }
  const handleChange = (selectedRole) => {
    setSelectedRole(selectedRole)
  }
  const roles = [
    {
      label: 'Shipment Party',
      body: 'I need to ship/receive cargo from one facility to another.',
      value: 'Shipment Party',
    },
    {
      label: 'Dispatcher',
      body: 'I am a middleman who connects shippers and receivers.',
      value: 'Dispatcher',
    },
    {
      label: 'Carrier',
      body: 'I am in charge of transporting cargo from one facility to another.',
      value: 'Carrier',
    },
  ]

  return (
    <>
      <ShipmentRoleModal
        isShipmentRoleModalOpen={isShipmentRoleModalOpen}
        setIsShipmentRoleModalOpen={setIsShipmentRoleModalOpen}
        handleRefreshUser={hadleRefreshUser}
      />
      <DispatcherRoleModal
        isDispatcherRoleModalOpen={isDispatcherRoleModalOpen}
        setIsDispatcherRoleModalOpen={setIsDispatcherRoleModalOpen}
        handleRefreshUser={hadleRefreshUser}
      />
      <CarrierRoleModal
        isCarrierRoleModalOpen={isCarrierRoleModalOpen}
        setIsCarrierRoleModalOpen={setIsCarrierRoleModalOpen}
        handleRefreshUser={hadleRefreshUser}
      />

      <div
        className={
          ' sticky top-0  z-10 flex  w-full justify-between border-b bg-white p-3 ' +
          'text-left text-xl font-semibold text-gray-700'
        }
      >
        <span className={''}>Manage Your Role </span>
      </div>
      <div className="relative">
        <div className="mx-auto mt-10 flex w-11/12 flex-col justify-center gap-5 bg-gray-900 px-5 py-8">
          <div className="text-xl font-semibold text-white">
            Your roles that are currently active on your account
            <br />{' '}
            <span className="text-xl font-bold text-white">({user?.user_type})</span>
          </div>
          <div className="w-full text-lg font-normal text-white">
            Expand your capabilities on our platform by selecting from the available
            roles,
            <br /> each offering unique features and benefits to enhance your experience.
          </div>
        </div>

        <img
          src={'https://storage.googleapis.com/freight_static_files/roles.png'}
          alt="image"
          className="absolute -top-16 right-24 hidden h-auto w-72 sm:hidden md:hidden lg:-top-8 lg:right-6 lg:block lg:w-60 xl:-top-16 xl:right-24 xl:w-72"
        />
      </div>
      <div className=" mt-5 flex w-full flex-col items-center  ">
        {userRoles.length === roles.length ? (
          <div className="text-2xl font-bold text-gray-700">
            No More Roles Are Available
          </div>
        ) : (
          <div className="text-2xl font-bold text-gray-700">Available Roles</div>
        )}
        {userRoles.length === roles.length ? (
          <div className="mt-2 text-center font-inter text-sm text-gray-500  lg:text-lg">
            Please note that you have reached the maximum limit of roles that can be added
            to your account.
            <br />
            Further additions of roles are no longer possible at this time.
          </div>
        ) : (
          <div className="mt-2 font-inter text-sm text-gray-500 lg:text-lg ">
            Choose Additional Roles for More Benefits
          </div>
        )}
      </div>
      <div className={'f-col-center  gap-y-4  p-5'}>
        <div className={' flex flex-col gap-3 '}>
          {roles.map((role) => {
            if (!userRoles.includes(role.value.toLowerCase())) {
              return (
                <RadioCard
                  key={role.value}
                  label={role.label}
                  body={role.body}
                  handleClick={handleSelectRole}
                  isActive={selectedRole === role.value}
                  onChange={(e) => {
                    handleChange(e.target.value)
                  }}
                  value={role.value}
                />
              )
            }
          })}
          {userRoles.length !== roles.length && (
            <FButton onClick={handleClick} disabled={selectedRole === ''}>
              Next
            </FButton>
          )}
          {/* {userRoles.length === roles.length && (
            <div className="rounded-sm bg-green-200 p-3 text-center text-green-900">
              <>
                Please note that you have reached the maximum limit of roles that can be
                added to your account. Further additions of roles are no longer possible
                at this time.
              </>
            </div>
          )} */}
        </div>
      </div>
    </>
  )
}
export default Roles
