import FCard from '../../../components/FCard'
import FFormInvalidInput from '../../../components/FFormInvalidInput'
import AsyncSelect from 'react-select/async'

const ShipmentName = ({
  Formik,
  loadOldShipmentName,
  isExistingShipment,
  setIsExistingShipment,
}) => {
  return (
    <FCard>
      <FCard.Header>
        <span>Add load to shipment</span>
      </FCard.Header>
      <FCard.Body>
        <div className={'flex flex-col items-start  gap-2 '}>
          <label className="flex items-center">
            <input
              type="radio"
              onChange={() => {
                setIsExistingShipment(false)
                Formik.setFieldValue('shipment', '')
              }}
              checked={!isExistingShipment}
              name="shipment"
            />
            <div className={'px-2'}>
              <div>New Shipment</div>
            </div>
          </label>
          <label className="flex items-center">
            <input
              onChange={() => {
                setIsExistingShipment(true)
                Formik.setFieldValue('shipment', '')
              }}
              checked={isExistingShipment}
              type="radio"
              name="shipment"
            />
            <div className={'px-2'}>
              <div>Existing Shipment</div>
            </div>
          </label>
        </div>

        <div className={'mt-3'}>
          {isExistingShipment ? (
            <div>
              <AsyncSelect
                defaultOptions
                name="shipment"
                isClearable
                openMenuOnFocus={true}
                value={Formik.values.shipment}
                cacheOptions
                placeholder={'Select shipment name'}
                loadOptions={(e) => loadOldShipmentName(e, Formik)}
                onChange={(e) => {
                  Formik.setFieldValue('shipment', e)
                }}
                onBlur={() => {
                  Formik.setFieldTouched('shipment', true)
                }}
              />

              <FFormInvalidInput
                touched={Formik.touched}
                FieldName={'shipment'}
                errors={Formik.errors}
              />
            </div>
          ) : null}
        </div>
      </FCard.Body>
    </FCard>
  )
}
export default ShipmentName
