import React, { useEffect, useState } from 'react'

const TimeAgo = ({ timestamp }) => {
  const [timeAgo, setTimeAgo] = useState('')

  useEffect(() => {
    // Function to convert the timestamp to a human-readable format
    const timeDifference = (current, previous) => {
      const msPerMinute = 60 * 1000
      const msPerHour = msPerMinute * 60
      const msPerDay = msPerHour * 24
      const msPerMonth = msPerDay * 30
      const msPerYear = msPerDay * 365

      const elapsed = current - previous

      if (elapsed < msPerMinute) {
        return Math.round(elapsed / 1000) + ' seconds ago'
      } else if (elapsed < msPerHour) {
        return Math.round(elapsed / msPerMinute) + ' minutes ago'
      } else if (elapsed < msPerDay) {
        return Math.round(elapsed / msPerHour) + ' hours ago'
      } else if (elapsed < msPerMonth) {
        return Math.round(elapsed / msPerDay) + ' days ago'
      } else if (elapsed < msPerYear) {
        return Math.round(elapsed / msPerMonth) + ' months ago'
      } else {
        return Math.round(elapsed / msPerYear) + ' years ago'
      }
    }

    // Parse the given timestamp into a Date object
    const createdAtDate = new Date(timestamp)

    // Get the current time
    const currentDate = new Date()

    // Calculate the time difference and update the state
    const timeAgoString = timeDifference(currentDate, createdAtDate)
    setTimeAgo(timeAgoString)
  }, [timestamp])

  return <span className="block text-xs text-gray-400">{timeAgo}</span>
}

export default TimeAgo
