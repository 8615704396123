import React , {useState} from 'react'
import FCustomDataTable from '../../../components/FCustomDataTable'
import Status from '../../../components/Status'
import { useQuery } from '@tanstack/react-query'
import { axiosInstance } from '../../../axios/requister'
import ESpinner from '../../../components/ESpinner'
import FCard from '../../../components/FCard'

const BillingHistory = () => {
  const [page, setPage] = useState(1)

  const { data ,status,refetch } = useQuery(
    ['billingHistory',page],
    async () => {
      const response = await axiosInstance.get(`authentication/user/billing-history/?page=${page}`)
      return response.data
    },
    {
      keepPreviousData: true, // Keep the previous data while fetching new data
      retry: false,
      enabled: page > 0,
    }
  )
  // useEffect(() => {
  //   refetch()
  // }, [page, refetch])

  
  const cols = [
    {
      Header: 'Date',
      accessor: 'bill',
      cell: (row) => <span>{row?.bill_date}</span>,
    },
    {
      Header: 'Load',
      accessor: 'bill',
      cell: (row) => <span>{row?.load.name}</span>,
    },
    {
      Header: 'Amount',
      accessor: 'bill',
      cell: (row) => <span>{row?.amount}</span>,
    },
    {
      Header: 'Customer',
      accessor: 'bill',
      cell: (row) => <span>{row?.customer.username}</span>,
    },
    {
      Header: 'Vendor',
      accessor: 'bill',
      cell: (row) => <span>{row?.vendor.username}</span>,
    },

    {
      Header: 'Status',
      accessor: 'status',
      cell: (row) => <Status statusName={row} />,
    },
    // {
    //   Header: 'Receipt',
    //   accessor: 'receipt',
    //   cell: (row) => <span>{row}</span>,
    // },
  ]
  if (data?.results.length === 0) {
    return <FCard className='flex flex-col w-full items-center justify-center p-10'>
      <img
          src="https://storage.googleapis.com/freight_static_files/empty-state.svg"
          alt={'empty_state'}
          width={200}
          className={''}
        />
        <span className={'mt-5 font-semibold text-gray-700'}>No billings Found</span>
    </FCard>
  }

 
    else if (status === 'loading') {
      return <div className='  flex w-full items-center justify-center '>
        <ESpinner isVisible={true} size={30} />
        
      </div>
    }
    else 
    return (
    <FCard>
      <FCustomDataTable cols={cols}
       data={data?.results}
      setPage={setPage}
      page={page}
      totalNumberOfPages={Math.ceil(data?.count / 5) }
      isLoading={status === 'loading'}
      refetch={refetch}

      />
    </FCard>
  )
}

export default BillingHistory
