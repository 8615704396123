import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { axiosInstance } from '../../axios/requister'
import { toast } from 'react-toastify'
import FacilityForm from './FacilityForm'
import { useState } from 'react'

const CreateFacility = () => {
  const [loading, setLoading] = useState(false)
  const navigation = useNavigate()
  const buttonTitle = 'Create Facility'
  const FormTitle = ' Create New Facility'

  const Formik = useFormik({
    initialValues: {
      building_name: '',
      address: '',
      city: '',
      state: '',
      zip_code: '',
      country: '',
      extra_info: '',
      coords: undefined,
    },
    onSubmit: (values) => {
      setLoading(true)
      axiosInstance
        .post('/shipment/facility/', values)
        .then(() => {
          toast.success('Facility created successfully')
          navigation('/facilities')
          setLoading(false)
        })
        .catch(() => {
          toast.error('Facility creation failed')
          setLoading(false)
        })
    },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: Yup.object({
      city: Yup.string().trim().required('City is required'),
      building_name: Yup.string().trim().required('Building name is required'),
      address: Yup.string().trim().required('Address is required'),
      zip_code: Yup.string().trim().required('Zip code is required'),
      country: Yup.string().trim().required('Country is required'),
      state: Yup.string().trim().required('State is required'),
    }),
  })
  return (
    <FacilityForm
      Formik={Formik}
      loading={loading}
      buttonTitle={buttonTitle}
      FormTitle={FormTitle}
    />
  )
}
export default CreateFacility
