import FButton from '../../components/FButton'
import Modal from '@mui/material/Modal'
import { axiosInstance } from '../../axios/requister'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import ESpinner from '../../components/ESpinner'

const RejectionModal = ({ open, handleClose, id }) => {
  const navigate = useNavigate()
  const [rejectionReason, setRejectionReason] = useState('')

  const [loading, setLoading] = useState(false)
  const handleTicketReject = () => {
    setLoading(true)
    axiosInstance
      .put(`support/handle-ticket/${id}/`, {
        action: 'deny',
        rejection_reason: rejectionReason,
      })
      .then(() => {
        setLoading(false)
        toast.success('Ticket rejected successfully')
        navigate('/tickets')
      })
      .catch(() => {
        setLoading(false)
        toast.error('Ticket rejected field')
      })
  }
  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose()
        setRejectionReason('')
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        className="absolute top-1/2 left-1/2  w-8/12 -translate-x-1/2 -translate-y-1/2 transform bg-white  shadow-sm lg:w-5/12
        "
      >
        <div className="flex flex-col p-5">
          <div className="mx-auto whitespace-nowrap p-5 text-2xl font-semibold text-gray-700">
            Rejection Reason
          </div>
          <textarea
            className="input-style h-20"
            placeholder="Enter your reason for rejection"
            value={rejectionReason}
            onChange={(e) => setRejectionReason(e.target.value)}
          ></textarea>
          <div className="mt-5 flex flex-row-reverse justify-center gap-5">
            <FButton className={'w-20'} onClick={handleTicketReject}>
              <span className="px-1">Send</span>
              <ESpinner isVisible={loading} />
            </FButton>
            <FButton
              onClick={() => {
                handleClose()
                setRejectionReason('')
              }}
              btnType={'secondary'}
            >
              Cancel
            </FButton>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default RejectionModal
