import {BiCheckShield} from 'react-icons/bi'
import FButton from '../../components/FButton'
import {useEffect, useState} from 'react'
import OTPInput from 'otp-input-react'

const OTP = () => {
  const email = 'shipper01@freighmonster.com'
  const waitingTime = 30
  const optLength = 6
  const [counter, setCounter] = useState(waitingTime)
  const [otp, setOtp] = useState('')
  useEffect(() => {
    const interval =
      counter > 0 &&
      setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1)
      }, 1000)
    return () => clearInterval(interval)
  }, [counter])
  const handleOtpVerification = () => {
    console.log('Verifying otp ...')
  }
  const handleResendOtp = () => {
    console.log('Submitting otp ...')
    setCounter(waitingTime)
  }
  return (
    <>
      <div className={'flex h-screen w-screen flex-col items-center justify-center'}>
        <div className={' flex flex-col items-center justify-center'}>
          <BiCheckShield className={'text-primary-500'} size={60} />
          <span className={'text-2xl font-semibold text-gray-700'}>
            Email Verification
          </span>
          <span className={'text-center text-sm text-gray-500'}>
            Check your email. We have sent <br />
            you the code at <span className={'text-base font-bold'}> {email} </span>
          </span>
          <OTPInput
            placeholder={['*', '*', '*', '*', '*', '*']}
            class={'my-4 p-3'}
            inputClassName={'p-3 mx-0 otp-input-custom my-4'}
            value={otp}
            onChange={setOtp}
            autoFocus
            OTPLength={optLength}
            otpType="any"
            disabled={false}
          />
          <span className={'text-center text-gray-600'}>
            If you didn’t reach out the confirmation email.
            <br /> Your can{' '}
            <button
              onClick={handleResendOtp}
              disabled={counter !== 0}
              className={
                'border-b-2  border-primary-500  font-bold text-primary-500 disabled:border-primary-300  disabled:text-primary-300'
              }
            >
              resend
            </button>{' '}
            the email
            {counter !== 0 && ` within ${counter} seconds`}.
          </span>
          <FButton
            disabled={otp.length !== optLength}
            onClick={() => handleOtpVerification()}
            className={'mt-3 w-56'}
          >
            Verify Email
          </FButton>
        </div>
      </div>
    </>
  )
}
export default OTP
