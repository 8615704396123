import { useState, useContext } from 'react'
import { BsThreeDotsVertical } from 'react-icons/bs'
import {
  MdOutlineModeEditOutline,
  MdOutlineDeleteOutline,
  MdOutlineRemoveRedEye,
} from 'react-icons/md'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import FInputField from '../../../components/FInputField'
import { axiosInstance } from '../../../axios/requister'
import { UserContext } from '../../../components/UserContext'
import { toast } from 'react-toastify'
import { BiUndo } from 'react-icons/bi'

const ActionMenu = ({
  note,
  setIsNoteModalOpen,
  visibleTo,
  setVisibleTo,
  loadNoteRefetch,
  // otherLoadParties,
  deletedLoadNotesRefetch,
  LoadId,
  LoadDispatcher,
}) => {
  const [visibleToTouched, setVisibleToTouched] = useState(false)
  const [newVisibleTo, setNewVisibleTo] = useState([])

  const [anchorEl, setAnchorEl] = useState(null)
  const { user } = useContext(UserContext)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    axiosInstance
      .get(`shipment/load/note/visible-to/?load_id=${LoadId}&load_note_id=${note.id}`)
      .then((response) => {
        setNewVisibleTo(response.data.other_load_parties)
        // setVisibleTo(response.data.other_load_parties)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleClose = () => {
    setAnchorEl(null)
    if (visibleToTouched) {
      axiosInstance
        .patch(
          `/shipment/load/notes/${note.id}/`,
          {
            visible_to: visibleTo?.map((party) => party.id),
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(loadNoteRefetch)
        .catch((error) => console.log(error))
      setVisibleToTouched(false)
    }
  }

  const handleEdit = () => {
    setIsNoteModalOpen(true)
    handleClose()
  }

  const handleRemove = () => {
    axiosInstance
      .delete(`/shipment/load/notes/${note.id}/`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(() => {
        toast.success('Note was deleted successfully')
        loadNoteRefetch()
        deletedLoadNotesRefetch()
      })
      .catch((err) => console.log(err))
    handleClose()
  }

  const handleUndo = () => {
    axiosInstance
      .patch(`/shipment/load/notes/deleted-notes/undo/${note.id}/`, undefined, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(() => {
        toast.success('Note was restored successfully')
        loadNoteRefetch()
        deletedLoadNotesRefetch()
      })
      .catch((err) => console.log(err))

    handleClose()
  }

  const handleCheckBoxChange = (party) => {
    const index = visibleTo?.findIndex((p) => p.id === party.id)

    if (index !== -1) {
      setVisibleTo(visibleTo?.filter((p) => p.id !== party.id))
    } else {
      setVisibleTo([...visibleTo, party])
    }

    setVisibleToTouched(true)
  }


  return (
    <>
      {(user?.id === note?.creator.id ||
        (user?.selected_role == 'dispatcher' && LoadDispatcher?.id === user?.id)) && (
        <div className="text-black">
          <BsThreeDotsVertical
            className="cursor-pointer"
            onClick={handleClick}
            size={20}
          />

          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={note?.is_deleted ? handleUndo : handleRemove}
              className="gap-2"
            >
              {note?.is_deleted ? <BiUndo /> : <MdOutlineDeleteOutline />}
              {note?.is_deleted ? 'Undo' : 'Remove'}
            </MenuItem>

            {!note?.is_deleted && (
              <MenuItem onClick={handleEdit} className="gap-2">
                <MdOutlineModeEditOutline />
                Edit
              </MenuItem>
            )}

            {!note?.is_deleted && (
              <div className="flex gap-2 bg-gray-200 px-4 py-2 font-medium">
                <MdOutlineRemoveRedEye className="my-auto" />
                Visible to
              </div>
            )}

            {note?.is_deleted ? null : newVisibleTo?.length === 0 || !newVisibleTo ? (
              <MenuItem disabled>No other load parties to show</MenuItem>
            ) : (
              <div className=" mt-2 flex flex-col gap-1 px-7 ">
                {newVisibleTo?.map((party) => (
                  <label
                    key={party.id}
                    className="flex w-10/12 cursor-pointer items-center"
                  >
                    <FInputField
                      className="mr-2 h-4 w-4 text-orange-500 hover:bg-gray-100 
                                        focus:shadow-none focus:ring-0 focus:ring-offset-0"
                      type="checkbox"
                      checked={!!visibleTo.find((p) => p.id === party.id)}
                      onChange={() => handleCheckBoxChange(party)}
                    />
                    {party.username}
                  </label>
                ))}
              </div>
            )}
          </Menu>
        </div>
      )}
    </>
  )
}

export default ActionMenu
