/* eslint-disable object-curly-spacing */
import React, { useState,useEffect } from 'react'
import {axiosInstance} from '../../axios/requister'
import {BASE_URL} from '../../env' 

import NotificationTab from './NotificationTab'
import DetailssTab from './DitailsTab'
import PasswordTab from './PasswordTab'
import Billing from './Billing/Billing'

const Settings = () => {
  const [tab, setTab] = useState('My details')
  const [taxInfo, setTaxInfo] = useState({})

console.log('taxInfo',taxInfo)




  useEffect(() => {
    axiosInstance
      .get(`${BASE_URL}authentication/tax-info/ `)
      .then((res) => {
        setTaxInfo(res)
      })
      .catch(() => {})
  }, [])

  return (
    <div>
      <div
        className={
          'sticky top-0 z-10  flex w-full   border-b bg-white p-3 ' +
          'text-left text-xl font-semibold text-gray-700'
        }
      >
        <span className={''}>Settings</span>
      </div>
     
      <div
        style={{ top: 'top: 53px' }}
        className={' mb-3 flex gap-5 border-b-2 bg-white  px-2 '}
      >
        <div
          onClick={() => setTab('My details')}
          className={` ${tab === 'My details' && '  border-b-4'
            } cursor-pointer border-primary-500  p-2 `}
        >
          <span className={'text-sm font-semibold whitespace-nowrap  text-gray-700'}>My Details</span>
        </div>
        {/* ------------------------------------------------ */}
        <div
          onClick={() => setTab('Notification')}
          className={` ${tab === 'Notification' && '  border-b-4'
            } cursor-pointer border-primary-500  p-2 `}
        >
          <span className={'text-sm font-semibold  text-gray-700'}>Notification</span>
        </div>
        {/* ------------------------------------------------ */}
        <div
          onClick={() => setTab('Password')}
          className={` ${tab === 'Password' && '  border-b-4'
            } cursor-pointer border-primary-500  p-2 `}
        >
          <span className={'text-sm font-semibold  text-gray-700'}>Password</span>
        </div>
        {/* ------------------------------------------------ */}
        <div
          onClick={() => setTab('Billing')}
          className={` ${tab === 'Billing' && '  border-b-4'
            } cursor-pointer border-primary-500  p-2 `}
        >
          <span className={'text-sm font-semibold  text-gray-700'}>Billing</span>
        </div>
        {/* ------------------------------------------------ */}
      </div>
      {tab === 'My details' && <DetailssTab  taxInfo={taxInfo} />}
      {tab === 'Notification' && <NotificationTab />}
      {tab === 'Password' && <PasswordTab />}
      {tab === 'Billing' &&  <Billing taxInfo={taxInfo} />}
    </div>
  )
}
export default Settings
