import React from 'react'
import FFormWrapper from '../../../components/FFormWrapper'
import FLabel from '../../../components/FLabel'
import FInputField from '../../../components/FInputField'
import FButton from '../../../components/FButton'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import * as Yup from 'yup'
import FFormInvalidInput from '../../../components/FFormInvalidInput'
import { useFormik } from 'formik'
import ESpinner from '../../../components/ESpinner'

const InsuranceInfoStep = ({
  formData,
  setFormData,
  prevStep,
  handleSubmit,
  loading,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target

    setFormData({ ...formData, [name]: value })
    Formik.handleChange(e)
  }

  const validationSchema = Yup.object().shape({
    insurance_provider: Yup.string().required('Insurance provider is required'),
    insurance_policy_number: Yup
      .string()
      .required('Policy number is required')
      .matches(/^[A-Za-z0-9-]{8,}$/, 'IPN should be at least 8 characters long and can include letters, digits, and hyphens.'),
    insurance_type: Yup.string().required('Insurance type is required'),

    insurance_premium_amount: Yup.string().required('Premium amount is required'),
  })
  const Formik = useFormik({
    initialValues: {
      insurance_provider: formData.insurance_provider,
      insurance_policy_number: formData.insurance_policy_number,
      insurance_type: formData.insurance_type,
      insurance_premium_amount: formData.insurance_premium_amount,
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      handleSubmit()
    },
  })

  return (
    <form className="flex w-full flex-col p-5" onSubmit={Formik.handleSubmit}>
      <FFormWrapper>
        <FLabel htmlFor={'insurance_provider'}>Insurance Provider</FLabel>
        <FInputField
          id={'insurance_provider'}
          name="insurance_provider"
          placeholder="Enter your insurance provider"
          type="text"
          value={Formik.values.insurance_provider}
          onChange={handleChange}
          onBlur={Formik.handleBlur}
        />
        <FFormInvalidInput
          touched={Formik.touched}
          FieldName="insurance_provider"
          errors={Formik.errors}
        />
      </FFormWrapper>

      <FFormWrapper>
        <FLabel htmlFor={'insurance_policy_number'}>Insurance Policy Number</FLabel>
        <FInputField
          id={'insurance_policy_number'}
          name="insurance_policy_number"
          placeholder="Enter your policy number"
          type="text"
          onChange={handleChange}
          onBlur={Formik.handleBlur}
          value={Formik.values.insurance_policy_number}
        />
        <FFormInvalidInput
          touched={Formik.touched}
          FieldName="insurance_policy_number"
          errors={Formik.errors}
        />
      </FFormWrapper>

      <FFormWrapper className="">
        <FLabel htmlFor={'insurance_type'}>Insurance Type</FLabel>
        <Select
          id={'insurance_type'}
          name="insurance_type"
          placeholder="Enter your insurance type"
          className="h-10 w-full rounded-sm border  border-gray-300"
          labelId="demo-select-small-label"
          value={Formik.values.insurance_type}
          onChange={handleChange}
          onBlur={Formik.handleBlur}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="health">Health</MenuItem>
          <MenuItem value="life">Life</MenuItem>
          <MenuItem value="auto">Auto</MenuItem>
          <MenuItem value="home">Home</MenuItem>
        </Select>
        <FFormInvalidInput
          touched={Formik.touched}
          FieldName="insurance_type"
          errors={Formik.errors}
        />
      </FFormWrapper>

      <FFormWrapper>
        <FLabel htmlFor={'insurance_premium_amount'}>Premium Amount</FLabel>
        <FInputField
          id={'insurance_premium_amount'}
          name="insurance_premium_amount"
          placeholder="Enter the premium amount"
          type="number"
          value={Formik.values.insurance_premium_amount}
          onChange={handleChange}
          onBlur={Formik.handleBlur}
        />

        <FFormInvalidInput
          touched={Formik.touched}
          FieldName="insurance_premium_amount"
          errors={Formik.errors}
        />
      </FFormWrapper>

      {/* Add more insurance-related fields here as needed */}

      <div className="mt-5 flex flex-row-reverse  justify-center gap-5">
        <FButton className={'flex w-28 gap-2'} type="submit">
          Submit {loading && <ESpinner isVisible={true} size={18} />}
        </FButton>
        <FButton className={'w-28'} btnType={'secondary'} onClick={prevStep}>
          Back
        </FButton>
      </div>
    </form>
  )
}

export default InsuranceInfoStep
