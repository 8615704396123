import React, { useState } from 'react'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { MdDeleteOutline } from 'react-icons/md'
import { trancuateString } from '../views/Load/utils'

const getFilesNames = (files) => {
  return (files || []).map((file) => file.name)
}

const FileUploadButton = ({
  label = 'Upload Files',
  acceptedTypes,
  onFileChange,
  name,
  onBlur,
  initialFiles = [],
  onRemoveImage,
  // touched,
  // errors,
  multiple,
}) => {
  const [fileNames, setFileNames] = useState(getFilesNames(initialFiles))

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files)

    if (!multiple) {
      // If multiple is false, directly set the fileNames state with the new file name
      if (files.length > 0) {
        const validFile = files.find(
          (file) => acceptedTypes.includes(file.type) && file.size / (1024 * 1024) < 5
        )

        if (!validFile) {
          alert('Please select a valid file (PNG, JPEG, PDF) and less than 5MB')
          return
        }
        onFileChange([validFile]) // Call onFileChange with the new file
        setFileNames([validFile.name]) // Set the fileNames state with the new file name
      }
    } else {
      // If multiple is true, append the file names to the existing ones
      if (files.length > 0) {
        const validFiles = files.every(
          (file) => acceptedTypes.includes(file.type) && file.size / (1024 * 1024) < 5
        )

        if (!validFiles) {
          alert('Please select valid files (PNG, JPEG, PDF) and each file less than 5MB')
          return
        }

        onFileChange(files)
        setFileNames((prev) => [...prev, ...getFilesNames(files)])
      }
    }
  }

  return (
    <div>
      <label
        className=" flex w-52 cursor-pointer  gap-2 bg-gray-200 p-2 text-base font-medium text-gray-900
              hover:bg-gray-100
              "
      >
        <span className=" my-auto flex gap-2">
          <AiOutlineCloudUpload size={25} />
          {label}
        </span>
        <input
          type="file"
          id="file-upload"
          multiple={multiple}
          name={name}
          accept={acceptedTypes.join(',')}
          onBlur={onBlur}
          className="hidden"
          onChange={handleFileChange}

          // errors={errors}
        />
      </label>
      {fileNames.length > 0 && (
        <div className="mt-3">
          <strong>Uploaded Files:</strong>
          <ul>
            {fileNames.map((name, index) => (
              <li key={index} className="flex">
                <p>{trancuateString(name)}</p>
                {onRemoveImage && (
                  <MdDeleteOutline
                    size={20}
                    className="ml-auto cursor-pointer hover:text-primary-600"
                    onClick={() => {
                      onRemoveImage({ name })
                      setFileNames((prev) => prev.filter((_, i) => i !== index))
                      document.getElementById('file-upload').value = ''
                    }}
                  />
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default FileUploadButton
