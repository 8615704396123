import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import axios from 'axios'
import { BASE_URL } from '../../env'
import { toast } from 'react-toastify'
// import VerifiedIcon from '../../assets/images/verified.gif'

const EmailVerificationProcess = () => {
  const navigation = useNavigate()
  const { key } = useParams()
  useEffect(() => {
    axios
      .post(`${BASE_URL}authentication/account-confirm-email/`, { key: key })
      .then(() => {
        toast.success('Email verified successfully')
        navigation('/login')
      })
      .catch(() => {
        toast.error('Email verification failed')
      })
  }, [key])
  return (
    <>
      <div className={'f-col-center-center h-screen gap-2 bg-white'}>
        <img
          src="https://storage.googleapis.com/freight_static_files/verified.gif"
          width={200}
        />
        <span className={'text-2xl text-gray-500'}>Verifying Your Account...</span>
        <span className={'text-sm text-gray-500'}>
          please wait, we are doing some work to verify your account
        </span>
      </div>
    </>
  )
}
export default EmailVerificationProcess
