import FFormWrapper from '../../components/FFormWrapper'
import FLabel from '../../components/FLabel'
import FInputField from '../../components/FInputField'
import FFormInvalidInput from '../../components/FFormInvalidInput'
import FButton from '../../components/FButton'
import FModal from '../../components/FModal'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { axiosInstance } from '../../axios/requister'
import { toast } from 'react-toastify'
import ESpinner from '../../components/ESpinner'
import React, { useState } from 'react'

const PasswordRecoveryModal = ({ isOpen, setIsOpen }) => {
  const [isLoading, setIsLoading] = useState(false)
  const formikConfirm = useFormik({
    initialValues: { email: '' },
    validationSchema: Yup.object({
      email: Yup.string().email('Email is not valid').required('Email is required'),
    }),
    onSubmit: (values) => {
      setIsLoading(true)
      axiosInstance
        .post('authentication/password-reset/', values)
        .then(() => {
          setIsLoading(false)
          toast.success('Password reset link sent to your email')
          setIsOpen(false)
        })
        .catch(() => {
          setIsLoading(false)
          toast.error('Something went wrong')
        })
    },
  })

  return (
    <>
      <FModal isOpen={isOpen} setIsOpen={setIsOpen} title="Password Recovery">
        <form onSubmit={formikConfirm.handleSubmit}>
          <FFormWrapper>
            <FLabel htmlFor={'email'}>Email</FLabel>
            <FInputField
              name="email"
              id="email"
              type="email"
              placeholder="Enter your email"
              onChange={formikConfirm.handleChange}
              onBlur={formikConfirm.handleBlur}
            />
            <FFormInvalidInput
              touched={formikConfirm.touched}
              FieldName={'email'}
              errors={formikConfirm.errors}
            />
          </FFormWrapper>
          <div className="flex justify-end gap-2">
            <FButton btnType={'secondary'} onClick={() => setIsOpen(false)}>
              Cancel
            </FButton>
            <FButton
              type="submit"
              disabled={formikConfirm.errors.email || formikConfirm.values.email === ''}
              className={'gap-2'}
            >
              <ESpinner isVisible={isLoading} />
              Confirm Email
            </FButton>
          </div>
        </form>
      </FModal>
    </>
  )
}
export default PasswordRecoveryModal
