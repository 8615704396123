import { useState, useContext, useEffect, useRef } from 'react'
import { axiosInstance } from '../axios/requister'
import { UserContext } from './UserContext'
import ESpinner from './ESpinner'
import { HiChat } from 'react-icons/hi'
import { IoCloseSharp } from 'react-icons/io5'
import { GoChevronLeft } from 'react-icons/go'
// import usePagination from '../Hooks/usePagination'
import { useInfiniteQuery } from '@tanstack/react-query'
import InfiniteScroll from 'react-infinite-scroll-component'

const ContactChat = ({ contact, user }) => {
  const inputRef = useRef()
  const socketRef = useRef(null)
  const messagesRef = useRef(null)
  const [inputValue, setInputValue] = useState('')
  const accessToken = localStorage.getItem('access')

  const {
    data: messagesPages,
    error,
    fetchNextPage,
    hasNextPage,
    status,
    isError,
    refetch: refetchMessages,
  } = useInfiniteQuery({
    queryKey: ['chatMessages'],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await axiosInstance.get(
        `/chat/messages/${contact.id}/?page_size=4&page=${pageParam}`
      )
      console.log('responsssssse', response.data)
      console.log('pageParam', pageParam)
      return response.data
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.next) {
        return allPages.length + 1 // Fetch next page (increment the page index)
      }
      return undefined // No more pages
    },
    keepPreviousData: true,
    retry: false,
    cacheTime: 0,
  })

  // This reduces all the pages into a single array of messages
const messages = 
messagesPages?.pages.reduce((acc, page) => [...acc, ...(page.results || [])], []) || []




const connectSocket = () => {
  const encodedToken = encodeURIComponent(accessToken)
  const socket = new WebSocket(
    `ws://app-dev.freightslayer.com/ws/chat?with=${contact.id}&token=${encodedToken}`
  )

  socket.addEventListener('open', () => {
    console.log('Socket opened')
  })

  socket.addEventListener('message', (event) => {
    try {
      console.log('Received message event:', event)
      const message = event.data
      console.log({ message })
      // Refetch messages only when necessary (e.g., upon receiving a new message)
      if (message) {
        refetchMessages({ refetchPage: (_, index) => index === 0 })  // Fetch only the first page, or adjust as needed
      }
    } catch (error) {
      console.error('Error parsing message:', error)
    }
  })

  socket.addEventListener('error', (event) => {
    console.log('Socket error', event)
  })

  socketRef.current = socket
}

const handleSendMessage = () => {
  if (inputValue.trim()) {
    const sendMessage = () => {
      socketRef.current.send(inputValue)
      console.log('sentttt')
      refetchMessages()
      setInputValue('')
    }

    if (
      socketRef.current.readyState === WebSocket.CLOSING ||
      socketRef.current.readyState === WebSocket.CLOSED
    ) {
      connectSocket()
      socketRef.current.addEventListener('open', sendMessage, { once: true })
    } else {
      sendMessage()
    }
  }
}

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
  }

 

  useEffect(() => {
    inputRef?.current && inputRef.current.focus()
    connectSocket()

    return () => {
      if (socketRef.current) {
        socketRef.current.close() // Close the socket when the component is unmounted
      }
    }
  }, [contact.id, accessToken, socketRef])

  

  return (
    <>
      <ul ref={messagesRef} className="flex h-64 flex-col-reverse overflow-y-auto">
        {status === 'loading' ? (
          <div className=" mx-auto flex h-full flex-col items-center justify-center">
            <ESpinner isVisible size={20} />
            <p className="text-gray-500">Loading messages..</p>
          </div>
        ) : isError ? (
          <p className="text-red-500">
            {error?.response?.data?.detail || 'Something went wrong!'}
          </p>
        ) : messages.length === 0 ? (
          <div className="f-col-center-center  h-full w-full text-gray-500">
            <p>No messages here</p>
          </div>
        ) : (
          <InfiniteScroll
            inverse
            style={{ display: 'flex', flexDirection: 'column-reverse' }}
            scrollableTarget={messagesRef?.current}
            dataLength={messages?.length} //This is important field to render the next data
            next={fetchNextPage}
            hasMore={hasNextPage}
            loader={
              <div className=" mx-auto flex h-32 w-full flex-col items-center justify-center">
                <ESpinner isVisible size={20} />
                <p className="text-gray-500">Loading messages..</p>
              </div>
            }
            endMessage={
              <p className="pt-1 text-center text-xs text-orange-500">
                <b>You have reached end of messages.</b>
              </p>
            }
          >
            {messages.map((message, index) => (
              <div
                key={index}
                className={`my-2 flex ${
                  user.id === message.sender.id ? 'justify-end' : 'justify-start'
                }`}
              >
                <div
                  className={`flex flex-col items-${
                    user.id === message.sender.id ? 'end' : 'start'
                  } w-4/5 max-w-xs`}
                >
                  <p className={`text-sm font-semibold `}>
                    {user.id === message.sender.id ? 'Me' : message.sender.username}
                  </p>

                  <div
                    className={`bg-${
                      user.id === message.sender.id ? 'orange' : 'gray'
                    }-200 max-w-full break-words rounded-lg p-2`}
                  >
                    <p>{message.message}</p>
                  </div>
                  <p className="text-xs text-gray-500">
                    {new Date(message.timestamp).toLocaleString()}
                  </p>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        )}
      </ul>
      <div className="mt-4 flex p-3">
        <input
          ref={inputRef}
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Type a message..."
          className="flex-1 rounded-l-lg border-2 border-primary-200 p-2 focus:border-primary-400 focus:ring-0"
          onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
        />
        <button
          onClick={handleSendMessage}
          className="rounded-r-lg bg-primary-500 p-2 text-white hover:bg-primary-600"
        >
          Send
        </button>
      </div>
    </>
  )
}

const ContactList = ({
  // contacts,
  // page,
  // setPage,
  // count,
  currentUserRole,
  debouncedSearchValue,
  handleSelectContact,
}) => {
  const listRef = useRef()

  const {
    data: contactsPages,
    error,
    fetchNextPage,
    hasNextPage,
    status,
    isError,
  } = useInfiniteQuery({
    queryKey: ['chatContacts', debouncedSearchValue],
    queryFn: async ({ pageParam = 1 }) => {
      // const queryPage = debouncedSearchValue ? 1 : pageParam // disable pagination on search
      const response = await axiosInstance.post(
        currentUserRole === 'manager'
          ? `/manager/search-contacts/?page=${pageParam}`
          : `/shipment/search-contacts/?page=${pageParam}`,
        {
          search: debouncedSearchValue,
        }
      )
      return response.data
    },
    initialPageParam: 1,
    getNextPageParam: (response, pages) => {
      const page = pages.length
      if (response.next) return page + 1
      return undefined
    },
    keepPreviousData: true,
    retry: false,
    // cacheTime: 0
  })

  const contacts =
    contactsPages?.pages.reduce((acc, page) => [...acc, ...(page.results || [])], []) ||
    []

  return (
    <ul ref={listRef} className="flex h-64 flex-col gap-1 overflow-y-auto py-1">
      {status === 'loading' ? (
        <div className=" mx-auto flex h-full flex-col items-center justify-center">
          <ESpinner isVisible size={20} />
          <p className="text-gray-500">Loading contacts..</p>
        </div>
      ) : isError ? (
        <p className="text-red-500">
          {error?.response?.data?.detail || 'Something went wrong!'}
        </p>
      ) : contacts?.length === 0 ? (
        <li className="f-col-center-center my-10 h-full w-full">
          <p>No contacts found</p>
        </li>
      ) : (
        <InfiniteScroll
          scrollableTarget={listRef?.current}
          dataLength={contacts?.length} //This is important field to render the next data
          next={fetchNextPage}
          hasMore={hasNextPage}
          loader={
            <div className=" mx-auto flex h-32 w-full flex-col items-center justify-center">
              <ESpinner isVisible size={20} />
              <p className="text-gray-500">Loading contacts..</p>
            </div>
          }
          endMessage={
            <p className="pt-1 text-center text-xs text-orange-500">
              <b>You have reached end of contacts.</b>
            </p>
          }
        >
          {contacts?.map((contact) => (
            <li
              key={contact.contact.id}
              className="flex cursor-pointer items-center gap-2 rounded-lg border-primary-200 px-3 py-4 
              text-sm hover:bg-primary-500 hover:text-white"
              onClick={() => handleSelectContact(contact.contact)}
            >
              <HiChat />
              <p>{contact.contact.username}</p>
            </li>
          ))}
        </InfiniteScroll>
      )}
    </ul>
  )
}

function ChatWindow({ setOpen }) {
  const { user } = useContext(UserContext)

  const [searchContact, setSearchContact] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useState('')
  const [selectedContact, setSelectedContact] = useState(null)
  // const [messages, setMessages] = useState([])
  // const [loadingMessages, setLoadingMessages] = useState(false)

  useEffect(() => {
    const debounce = setTimeout(() => {
      setDebouncedSearch(searchContact)
    }, 300)
    return () => clearTimeout(debounce)
  }, [searchContact])

  const handleSearchContact = (e) => {
    setSearchContact(e.target.value)
  }

  const handleSelectContact = (contact) => {
    setSelectedContact(contact)
  }

  const mainView = selectedContact ? (
    <ContactChat
      contact={selectedContact}
      // setMessages={setMessages}
      // messages={messages}
      user={user}
    />
  ) : (
    <ContactList
      currentUserRole={user?.selected_role}
      debouncedSearchValue={debouncedSearch}
      handleSelectContact={handleSelectContact}
    />
  )

  return (
    <div className="absolute bottom-11 right-8 z-50 w-80 bg-white shadow-lg lg:block">
      <div className=" rounded-t-lg bg-primary-600 py-2 px-3 text-white">
        {selectedContact ? (
          <div className="flex justify-between gap-1">
            <div className="my-auto flex items-center gap-1">
              <GoChevronLeft
                size={18}
                className=" my_auto flex cursor-pointer rounded  hover:bg-gray-100 hover:text-black"
                onClick={() => handleSelectContact(null)}
              />
              <p>{selectedContact?.username}</p>
            </div>
            <div>
              <IoCloseSharp
                size={20}
                className="  cursor-pointer rounded-full p-0.5 text-white hover:bg-gray-100 hover:text-black"
                onClick={() => setOpen(false)}
              />
            </div>
          </div>
        ) : (
          <div className="flex justify-between">
            <p>Select a contact to chat with</p>
            <IoCloseSharp
              size={20}
              className=" my-auto cursor-pointer rounded-full p-0.5 text-white hover:bg-gray-100 hover:text-black"
              onClick={() => setOpen(false)}
            />
          </div>
        )}
      </div>
      <div className="h-full p-2">
        {!selectedContact && (
          <input
            type="text"
            value={searchContact}
            onChange={handleSearchContact}
            placeholder="Search contacts..."
            className="mb-1 w-full rounded border-2 border-primary-200 py-1 px-2 focus:border-primary-400 focus:ring-0"
          />
        )}
        {mainView}
      </div>
    </div>
  )
}

export default ChatWindow
