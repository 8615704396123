import React, { useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import FModal from '../../../components/FModal'
import FButton from '../../../components/FButton'
import FIconWrapper from '../../../components/FIconWrapper'
import FFormWrapper from '../../../components/FFormWrapper'
import FLabel from '../../../components/FLabel'
import ESpinner from '../../../components/ESpinner'
import { axiosInstance } from '../../../axios/requister'
import { toast } from 'react-toastify'
// import axios from 'axios'
import FFormInvalidInput from '../../../components/FFormInvalidInput'
import FileUploadButton from '../../../components/FileUploadButton '
import UserRole from '../Claims/UserRole'
import FInputField from '../../../components/FInputField'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { uploadSignedUrl } from '../utils'

const AddNoteModal = ({
  isOpen,
  setIsOpen,
  callback,
  LoadId,
  note = null,
  otherLoadParties,
}) => {
  const isEditMode = !!note
  const [isLoading, setIsLoading] = useState(false)
  const [touched, setTouched] = useState({})
  const [uploadProgress, setUploadProgress] = useState(0)

  const Formik = useFormik({
    initialValues: note || {
      message: '',
      attachments: [],
      visible_to: [],
    },

    validationSchema: Yup.object({
      message: Yup.string().trim().required('Note message is required.'),
      // attachments: Yup.array().of(Yup.mixed().required()),
      // visible_to: Yup.array().of(Yup.mixed().required())
    }),
    onSubmit: (values) => {
      setIsLoading(false)

      const changedData = Object.keys(touched).reduce((prev, curr) => {
        return {
          ...prev,
          [curr]: values[curr],
        }
      }, {})

      const formData = {
        load_id: LoadId,
        ...(changedData.message ? { message: changedData.message } : {}),
        ...(changedData.visible_to
          ? { visible_to: changedData.visible_to?.map((party) => party.id) }
          : {}),
        ...(changedData.attachments
          ? {
              attachments: changedData.attachments?.map((file) => ({
                name: file.name,
                content_type: file instanceof File ? file.type : '',
              })),
            }
          : {}),
      }

      if (isEditMode) {
        const updateFiles = async () => {
          setIsLoading(true)
          try {
            const response = await axiosInstance.patch(
              `/shipment/load/notes/${note.id}/`,
              formData,
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            )

            const serverAttachments = response?.data.attachments
            const uploadedFiles = changedData.attachments?.filter(
              (file) => file instanceof File
            )

            const onUploadProgress = (progressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )
              setUploadProgress(progress)
            }

            if (uploadedFiles) {
              await uploadSignedUrl({
                serverAttachments,
                uploadedFiles,
                onUploadProgress,
                objId: response.data.id,
                metaKey: 'load_note_id',
                type: 'load note',
              })
            }

            await callback()
            toast.success('Note updated successfully')
            setIsOpen(false)
            Formik.resetForm(true)
          } catch (error) {
            toast.error(error.response?.data?.details || 'Something went wrong.')
          } finally {
            setIsLoading(false)
          }
        }

        // Call the async function
        updateFiles()
      } else {
        // Declare the function as async
        const uploadFiles = async () => {
          setIsLoading(true)
          try {
            const response = await axiosInstance.post('/shipment/load/notes/', formData, {
              headers: {
                'Content-Type': 'application/json',
              },
            })

            // const files = changedData.attachments?.filter(file => file instanceof File)
            const serverAttachments = response?.data.attachments
            const onUploadProgress = (progressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )
              setUploadProgress(progress)
            }

            await uploadSignedUrl({
              serverAttachments,
              uploadedFiles: values.attachments,
              onUploadProgress,
              objId: response.data.id,
              metaKey: 'load_note_id',
              type: 'load note',
            })

            await callback()
            toast.success('Note added successfully')
            setIsOpen(false)
            Formik.resetForm(true)
          } catch (error) {
            toast.error(error.response?.data?.details || 'Something went wrong.')
          } finally {
            setIsLoading(false)
          }
        }

        // Call the async function
        uploadFiles()
      }
    },
  })

  const handleChecboxChange = (party) => {
    const allowed = structuredClone(Formik.values.visible_to)
    const index = allowed.findIndex((p) => p.id === party.id)
    if (index !== -1)
      Formik.setFieldValue(
        'visible_to',
        allowed.filter((p) => p.id !== party.id)
      )
    else Formik.setFieldValue('visible_to', [...allowed, party])
  }

  const modalTitle = isEditMode ? 'Edit Note' : 'Add Note'
  const buttonText = isEditMode ? 'Save Changes' : 'Add Note'

  return (
    <FModal
      title={modalTitle}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      callbackFunction={() => {
        Formik.resetForm()
      }}
      // callbackFunction={() => callback()}
    >
      <form className="flex flex-col gap-1" onSubmit={Formik.handleSubmit}>
        <FFormWrapper className="w-12/12">
          <FLabel>Note Message *</FLabel>
          <textarea
            id="message"
            rows={3}
            name="message"
            placeholder="Write your message before adding a note"
            onChange={(e) => {
              Formik.handleChange(e)
              setTouched((prev) => ({ ...prev, message: true }))
            }}
            onBlur={Formik.handleBlur}
            value={Formik.values.message}
            className="input-style"
          />
          <FFormInvalidInput
            touched={Formik.touched}
            FieldName={'message'}
            errors={Formik.errors}
          />
        </FFormWrapper>

        <FFormWrapper className="w-full lg:w-1/2">
          <FLabel htmlFor="attachments">Upload Attachments</FLabel>

          <FileUploadButton
            label="Upload Attachments"
            name="attachments"
            initialFiles={Formik.values.attachments}
            acceptedTypes={['image/png', 'image/jpeg']}
            // i want to send the file name to the backend
            onFileChange={(files) => {
              Formik.setFieldValue('attachments', [
                ...Formik.values.attachments,
                ...files,
              ])
              setTouched((prev) => ({
                ...prev,
                attachments: true,
              }))
            }}
            onBlur={Formik.handleBlur}
            multiple={true}
            onRemoveImage={(image) => {
              Formik.setFieldValue(
                'attachments',
                Formik.values.attachments.filter((file) => file.name !== image.name)
              )
              setTouched((prev) => ({
                ...prev,
                attachments: true,
              }))
            }}
          />
        </FFormWrapper>

        {!isEditMode && (
          <FFormWrapper className="w-full">
            <FLabel htmlFor="visible_to">
              Select which load parties are allowed to see this note
            </FLabel>

            {otherLoadParties?.length !== 0 && otherLoadParties ? (
              <div className="flex flex-wrap gap-5">
                {otherLoadParties?.map((party) => (
                  <div key={party.id}>
                    <div
                      className="mb-2 flex cursor-pointer items-center gap-1"
                      onClick={() => {
                        setTouched((prev) => ({ ...prev, visible_to: true }))
                        handleChecboxChange(party)
                      }}
                    >
                      <FInputField
                        className="flex h-4 w-4 cursor-pointer text-orange-500
                       focus:shadow-none focus:ring-0 focus:ring-offset-0"
                        type="checkbox"
                        name={party.username}
                        checked={
                          !!Formik.values.visible_to?.find((p) => p.id === party.id)
                        }
                        onChange={() => {
                          setTouched((prev) => ({ ...prev, visible_to: true }))
                          handleChecboxChange(party)
                        }}
                      />
                      <p className="mx-1 text-gray-500">{party.username}</p>
                    </div>
                    {party.party_roles.map((role) => (
                      <UserRole key={role} role={role} />
                    ))}
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-gray-400">No other load parties to show</p>
            )}
          </FFormWrapper>
        )}

        <div className="flex w-full flex-row-reverse gap-1">
          <FButton type="submit" disabled={isLoading}>
            <FIconWrapper>
              <AiOutlinePlusCircle size={20} />
              {buttonText}
              {isLoading && ` ${uploadProgress}%`}
              <ESpinner isVisible={isLoading} />
            </FIconWrapper>
          </FButton>
          <FButton
            btnType="secondary"
            onClick={() => {
              Formik.resetForm()
              setIsOpen(false)
            }}
          >
            <FIconWrapper>Cancel</FIconWrapper>
          </FButton>
        </div>
      </form>
    </FModal>
  )
}

export default AddNoteModal
