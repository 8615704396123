// import logo from './assets/images/logo.png'
// import noImage from '../src/assets/images/no-image.jpg';
export const BASE_URL = 'https://app-dev.freightslayer.com/'
export const SITE_LOGO =
  'https://storage.googleapis.com/freight_static_files/freight-icon.png'
export const FOOTER_LOGO =
  'https://storage.googleapis.com/freight_static_files/footer-icon.png'
// export const NO_IMAGE = noImage;
export const BROKER_ICON = ({ textColor }) => (
  <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 115.7 126.1"
    className="h-8 w-8"
  >
    <path
      d="M603.3,324.7a30.2,30.2,0,1,1-60.4-.1,29.8,29.8,0,0,1,30.2-30C591,294.7,603.3,308.6,603.3,324.7Zm-7,.2a23.2,23.2,0,1,0-23.4,23A23.2,23.2,0,0,0,596.3,324.9Z"
      transform="translate(-512 -292.1)"
      className={`fill-current ${textColor}`}
    />
    <path
      d="M568.5,362.7V372s-41.2-.3-44.7,42.8H513.5S514.3,367.8,568.5,362.7Z"
      transform="translate(-512 -292.1)"
      className={`fill-current ${textColor}`}
    />
    <path
      d="M591.7,412.1v2.7a2.8,2.8,0,0,1-2.9,3H572.3a2.8,2.8,0,0,1-3-3v-24a2.8,2.8,0,0,1,2.9-3h2.7v-23a4.7,4.7,0,0,1,5.1-5.1h36.6a4.8,4.8,0,0,1,5.1,5.1v38h1.8a4.6,4.6,0,0,1,3.8,5,4.8,4.8,0,0,1-4.6,4.3h-31Zm-20.6-9.3v11.9c0,.9.4,1.2,1.1,1.2h16.6a1,1,0,0,0,1.1-1.1c0-1-.1-2.1,0-3.1s-.1-.6-.5-.7l-3.4-1.3a2.1,2.1,0,0,1-1.7-2.2,2.3,2.3,0,0,1,1.7-2.2l3.3-1.3a.7.7,0,0,0,.6-.7V391c0-1-.3-1.3-1.3-1.3H572.4c-1,0-1.3.3-1.3,1.3Zm5.7-31.9h43v-6.1a2.9,2.9,0,0,0-3.2-3.2H579.4a2.8,2.8,0,0,0-2.6,2.5C576.7,366.4,576.8,368.6,576.8,370.9Zm43,2h-43v14.9h1.8V377c0-1.6.7-2.3,2.3-2.3h34.7c1.7,0,2.3.7,2.3,2.3v25.8h1.9Zm-1.9,31.8H593.7v1.8h24.2Zm0,3.8H593.7v1.8h24.2Zm-31.8-26.3v-5.6h-5.5v5.6Zm7.5-5.6H588v5.6h5.6Zm1.9,0v5.6h5.6v-5.6Zm13.1,0H603v5.6h5.6Zm7.5,0h-5.6v5.6h5.6Zm-5.6,13.1H616v-5.6h-5.5Zm-15,7.5h5.6v-5.6h-5.6Zm13,0v-5.6H603v5.6Zm2,0H616v-5.6h-5.5Zm-7.5-7.5h5.5v-5.6H603Zm-1.9-5.6h-5.6v5.6h5.6Zm18.7,26.2h3.1a2.8,2.8,0,0,0,2.5-2.5,2.5,2.5,0,0,0-1.9-2.9,22.2,22.2,0,0,0-3.7-.3ZM588,384.1v3.7h.4a3,3,0,0,1,3.1,1.7c0,.1.2.2.2.2h1.9v-5.6Zm-7.5,0v3.7h5.6v-3.7Zm20.6,15h-5.6v3.7h5.6Zm7.5,0H603v3.7h5.6Zm7.4,3.7v-3.7h-5.5v3.7Zm-24.3,2.4h-.2l-4.9,1.8c-.2.1-.3.3-.5.5s.3.4.5.5l4.1,1.5,1,.4Zm1.9-8v-5.6h-1.8v5.6Zm-1.8,1.9V403l1.8-.3v-3.6Z"
      transform="translate(-512 -292.1)"
      className={`fill-current ${textColor}`}
    />
    <path
      d="M573.1,391.6H588V399H573.1Zm13,1.9H574.9v3.7h11.2Z"
      transform="translate(-512 -292.1)"
      className={`fill-current ${textColor}`}
    />
    <path
      d="M581.6,405.9l1.3,1.3-8,8-2.4-2.5,1.3-1.2.9,1.3Z"
      transform="translate(-512 -292.1)"
      className={`fill-current ${textColor}`}
    />
    <path
      d="M573,402.8V401h5.6v1.8Z"
      transform="translate(-512 -292.1)"
      className={`fill-current ${textColor}`}
    />
    <path
      d="M578.6,404.7v1.8h-5.5v-1.8Z"
      transform="translate(-512 -292.1)"
      className={`fill-current ${textColor}`}
    />
    <path
      d="M583.3,369.1a2.9,2.9,0,0,1-2.8-2.8,2.8,2.8,0,0,1,5.6,0A2.9,2.9,0,0,1,583.3,369.1Zm0-1.9a.9.9,0,0,0,.9-.9c.1-.5-.4-.9-.9-.9s-.9.4-.9.9A.9.9,0,0,0,583.3,367.2Z"
      transform="translate(-512 -292.1)"
      className={`fill-current ${textColor}`}
    />
    <path
      d="M592.7,363.5a2.9,2.9,0,0,1,2.8,2.8,2.8,2.8,0,0,1-2.8,2.8,2.8,2.8,0,1,1,0-5.6Zm-.1,3.7a1,1,0,0,0,1-.9,1,1,0,0,0-1.9-.1A1,1,0,0,0,592.6,367.2Z"
      transform="translate(-512 -292.1)"
      className={`fill-current ${textColor}`}
    />
    <path
      d="M606.7,366.3a2.8,2.8,0,0,1-2.8,2.8,2.8,2.8,0,1,1,0-5.6A2.8,2.8,0,0,1,606.7,366.3Zm-2.8-.9c-.5-.1-.9.4-.9.9a.9.9,0,1,0,1.8,0C604.9,365.8,604.4,365.4,603.9,365.4Z"
      transform="translate(-512 -292.1)"
      className={`fill-current ${textColor}`}
    />
    <path
      d="M613.3,369.1a2.8,2.8,0,0,1-2.8-2.8,2.7,2.7,0,0,1,2.8-2.8,2.8,2.8,0,1,1,0,5.6Zm.9-2.8a.9.9,0,0,0-.9-.9.9.9,0,0,0-1,.9,1,1,0,0,0,1,.9A.9.9,0,0,0,614.2,366.3Z"
      transform="translate(-512 -292.1)"
      className={`fill-current ${textColor}`}
    />
    <path
      d="M586.1,393.5v3.7H574.9v-3.7Z"
      transform="translate(-512 -292.1)"
      className={`fill-current ${textColor}`}
    />
  </svg>
)
export const CARRIER_ICON = ({ textColor }) => (
  <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 144.6 129.3"
    className="h-8 w-8"
  >
    <path
      d="M652.9,405.6c2,.4,2.7,1.3,2.7,3.2V411a2.9,2.9,0,0,1-2.8,2.7h-3.6c-.5-.1-.7.1-.9.6a9.3,9.3,0,0,1-8.7,6,9.1,9.1,0,0,1-8.7-6.1c-.2-.4-.5-.5-1-.5H601.2a.8.8,0,0,0-.9.6,9.4,9.4,0,0,1-8.8,6,9.2,9.2,0,0,1-8.7-6.1c-.2-.5-.4-.6-.9-.5h-3.6a3,3,0,0,1-2.8-2.8,10.5,10.5,0,0,1,.1-2.5,4.1,4.1,0,0,1,.8-1.9,2.4,2.4,0,0,0,.5-1.4V361.6h43.3c3.2,0,4.7,1.5,4.7,4.7v.7h13.4a3.9,3.9,0,0,1,3.9,4c.1,1.1,0,2.3,0,3.5s-.3,1.7-1.7,1.9l.3.6c2,4,4,8,5.9,12a1.2,1.2,0,0,0,1.2.7h1.7a4,4,0,0,1,3.3,4v11.9Zm-73.4.1h3.6a1.8,1.8,0,0,0,1.3-.6,9.1,9.1,0,0,1,14.3,0,1.8,1.8,0,0,0,1.3.6h22.2V366.3c0-1.6-.3-2-1.9-2H581.5c-1.6,0-2,.4-2,2v39.4Zm70.8-9.4v-2.2c-.1-1.4-.5-1.8-1.9-1.8H630.7c-1.4,0-1.8-.4-1.8-1.8V378.2c0-3,1.5-4.6,4.6-4.6h6c0-.1.1-.1.1-.2v-2.1c0-1.2-.5-1.7-1.8-1.7h-11c-1.5,0-1.9.4-1.9,2v34.1h6.7l.6-.3c4.1-4.8,10.6-4.9,14.7-.1a1,1,0,0,0,.7.4h2.6V399h-2.5a1.2,1.2,0,0,1-1.4-1.3,1.3,1.3,0,0,1,1.4-1.4ZM646.2,411a6.7,6.7,0,1,0-13.3,0,6.8,6.8,0,0,0,6.7,6.7A6.6,6.6,0,0,0,646.2,411Zm-48,0a6.6,6.6,0,0,0-6.6-6.7,6.7,6.7,0,0,0-6.7,6.7,6.8,6.8,0,0,0,6.6,6.7A6.7,6.7,0,0,0,598.2,411Zm32,0,.3-2.6H600.6l.4,2.6Zm-52,0h3.9l.4-2.6h-4.3Zm74.7,0v-2.6h-4.3l.4,2.6Z"
      transform="translate(-512 -292.1)"
      className={`fill-current ${textColor}`}
    />
    <path
      d="M599.6,383V371.7c0-1.7.3-2.1,2-2.1h14.8c1.4,0,1.8.5,1.8,1.9v25.7c0,1.4-.4,1.8-1.8,1.8h-31c-1.4,0-1.9-.4-1.9-1.8V384.7c0-1.3.5-1.7,1.8-1.7h14.3Zm2.7-.1h13.2V372.4H613c-.1.1-.1.1-.1.2v3.3c0,1.6-.9,2.2-2.3,1.4a3.2,3.2,0,0,0-3.5,0c-1.3.8-2.2.2-2.2-1.4v-3.5h-2.6Zm-16,2.8v10.6h13.2V385.7h-2.6v3.6c0,1.5-1,2.1-2.2,1.4a3,3,0,0,0-3.6,0c-1.3.7-2.2.1-2.2-1.4v-3.6Zm26.7,0v3.6c0,1.5-1,2.1-2.2,1.4a3,3,0,0,0-3.6,0c-1.3.7-2.2.1-2.2-1.4v-3.6h-2.6v10.6h13.2V385.7Z"
      transform="translate(-512 -292.1)"
      className={`fill-current ${textColor}`}
    />
    <path
      d="M639.5,415a4,4,0,0,1-3.9-4.1,4,4,0,0,1,4-3.9,4,4,0,0,1,4,4.1A4.1,4.1,0,0,1,639.5,415Z"
      transform="translate(-512 -292.1)"
      className={`fill-current ${textColor}`}
    />
    <path
      d="M595.5,411.1a4,4,0,0,1-4.1,3.9,4.2,4.2,0,0,1-3.9-4.1,4,4,0,0,1,4.2-3.9A3.9,3.9,0,0,1,595.5,411.1Z"
      transform="translate(-512 -292.1)"
      className={`fill-current ${textColor}`}
    />
    <path
      d="M603.3,324.7a30.2,30.2,0,1,1-60.4-.1,29.8,29.8,0,0,1,30.2-30C591,294.7,603.3,308.6,603.3,324.7Zm-7,.2a23.2,23.2,0,1,0-23.4,23A23.2,23.2,0,0,0,596.3,324.9Z"
      transform="translate(-512 -292.1)"
      className={`fill-current ${textColor}`}
    />
    <path
      d="M568.5,362.7V372s-41.2-.3-44.7,42.8H513.5S514.3,367.8,568.5,362.7Z"
      transform="translate(-512 -292.1)"
      className={`fill-current ${textColor}`}
    />
  </svg>
)
export const SHIPPER_ICON = ({ textColor }) => (
    <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 111.9 130"
    className="h-8 w-8"
  >
    <path
      d="M603.3,324.7a30.2,30.2,0,1,1-60.4-.1,29.8,29.8,0,0,1,30.2-30C591,294.7,603.3,308.6,603.3,324.7Zm-7,.2a23.2,23.2,0,1,0-23.4,23A23.2,23.2,0,0,0,596.3,324.9Z"
      transform="translate(-512 -292.1)"
      className={`fill-current ${textColor}`}
    />
    <path
      d="M568.5,362.7V372s-41.2-.3-44.7,42.8H513.5S514.3,367.8,568.5,362.7Z"
      transform="translate(-512 -292.1)"
      className={`fill-current ${textColor}`}
    />
    <path
      d="M623.4,396.4l-1,.7-5.1,3a1,1,0,0,0-.6.9c.1,2,0,4.1.1,6.1a1.4,1.4,0,0,1-.8,1.3l-22.2,12.9a1.2,1.2,0,0,1-1.5.1l-22.2-13a1.3,1.3,0,0,1-.8-1.3V401c0-.5-.1-.7-.5-.9l-5.4-3.1c-.9-.5-1-1.1-.3-1.9l6.3-6.2a9.2,9.2,0,0,1,2.1-1.3l1.6-.9a.9.9,0,0,1,1.3.3.9.9,0,0,1-.4,1.3l-1.8,1.1.5.3,19.8,11.6a.7.7,0,0,0,.9,0l20.1-11.7.4-.2-1.7-1c-.6-.4-.8-.9-.5-1.4s.8-.6,1.4-.2l3,1.7a1,1,0,0,1,.5.4l6.3,6.2.5.6Zm-52.8-5.8-5.3,5.3h0l20.6,12c.3.2.5.1.7-.2l4.6-4.5.2-.4C584.4,398.7,577.4,394.7,570.6,390.6Zm50.2,5.3-5.2-5.3c-7,4.1-14,8.1-21,12.3l4.7,4.7a.8.8,0,0,0,1.1.2l11.7-6.9ZM594,419h.2L614.5,407a.7.7,0,0,0,.3-.6c.1-1.5.1-2.9.1-4.4s-.1-.3-.1-.4h-.3l-14.1,8.2c-.8.5-1,.5-1.7-.2l-4.3-4.3-.4-.4Zm-1.9-14a1.2,1.2,0,0,0-.5.5l-4.4,4.3a1,1,0,0,1-1.4.2l-14.2-8.2-.4-.2v4.7a.8.8,0,0,0,.4.8l19.9,11.6.6.3Z"
      transform="translate(-512 -292.1)"
      className={`fill-current ${textColor}`}
    />
    <path
      d="M593.4,360.9l.5.3,15.3,8.1a1.5,1.5,0,0,1,.9,1.6v14.3a1.3,1.3,0,0,1-.8,1.4c-1.6.8-3.1,1.7-4.6,2.6a1.1,1.1,0,0,1-1.5-.4,1,1,0,0,1,.5-1.3l4.1-2.3a.7.7,0,0,0,.4-.5V372l-.5.2-13.3,7.1a.8.8,0,0,0-.4.6v2.8h2.5c1,0,1.3.3,1.3,1.3v5.4h2.5c.5,0,.9,0,1.2.5s-.1.9-.4,1.3l-7.2,7.2c-.7.6-1.1.6-1.7,0l-7.3-7.2c-.3-.4-.6-.7-.3-1.2s.6-.6,1.1-.6h2.6v-5.6a1,1,0,0,1,1-1.1h2.8v-3a.5.5,0,0,0-.3-.3L578,372.1h-.1a4.3,4.3,0,0,0-.1.5v11.9a.9.9,0,0,0,.5.8l4.1,2.2a1,1,0,0,1,.5,1.3,1,1,0,0,1-1.3.5c-1.7-1-3.5-1.9-5.2-3a1,1,0,0,1-.4-.8c-.1-5-.1-10-.1-15,0-.5.3-.8.8-1.1l15.4-8.2.6-.3Zm-6.7,13.6,6.1,3.2a.9.9,0,0,0,.7-.1l13.1-6.9.5-.3-6.1-3.2h-.6l-2.8,1.5Zm-7.8-4.1h.3c1.7.9,3.4,1.9,5.2,2.7a.6.6,0,0,0,.6,0l13.3-7,.4-.3-5.2-2.8a.9.9,0,0,0-.9.1l-11.8,6.2Zm14,26,5.2-5.1h-1.2a1,1,0,0,1-1-1.1v-5.5h-5.7v5.4c0,.8-.4,1.2-1.1,1.2h-1.3Z"
      transform="translate(-512 -292.1)"
      className={`fill-current ${textColor}`}
    />
    <path
      d="M601.4,382.1c-.2-.2-.6-.5-.7-.8a.9.9,0,0,1,.4-1.1l2.6-1.4,1.3-.7a.8.8,0,0,1,1.2.4.9.9,0,0,1-.3,1.2l-4,2.2Z"
      transform="translate(-512 -292.1)"
      className={`fill-current ${textColor}`}
    />
    <path
      d="M606.3,382.9l-.5.7-3.7,2a1,1,0,0,1-1.4-.3,1,1,0,0,1,.4-1.3l3.8-2.1A1,1,0,0,1,606.3,382.9Z"
      transform="translate(-512 -292.1)"
      className={`fill-current ${textColor}`}
    />
  </svg>
)
