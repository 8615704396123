import { FOOTER_LOGO } from '../../env'
import FAvatar from '../FAvatar'
import FMenu from '../Menus/FMenu'
import RoleMenu from '../Menus/RoleMenu'
import NotificationMenu from '../Menus/NotificationMenu'
import React, { useEffect } from 'react'
import SideBar from './SideBar'
import { RiUserSettingsLine, RiArrowDownSLine, RiNotification2Line } from 'react-icons/ri'
import { Outlet, useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { UserContext } from './../UserContext'
import { Tooltip } from '@mui/material'
import { SITE_LOGO } from '../../env'
import { IoChatboxOutline } from 'react-icons/io5'
import ChatWindow from '../ChatWindow'

const Footer = () => {
  return (
    <div className={'mt-auto flex items-center justify-between border-t bg-white p-3'}>
      <span className={'text-sm font-medium text-gray-500'}>V.0.0.2</span>
      <img width={190} src={FOOTER_LOGO} alt="logo" />
      <span
        onClick={() => window.open(`${window.location.origin}/help-center`, '_blank')}
        className={'cursor-pointer text-sm font-medium text-gray-500 hover:underline '}
      >
        Contact us
      </span>
    </div>
  )
}
const Layout = ({ title }) => {
  const navigate = useNavigate()
  const { user } = React.useContext(UserContext)
  const [open, setOpen] = React.useState(false)

  useEffect(() => {
    if (!localStorage.getItem('access')) {
      navigate('/login')
    } else if (!user) {
      navigate('/role-select')
    }
  }, [])
  let className = 'flex h-14 items-center divide-x px-3 border-b'
  let bgColor = ''
  let borderColor = ''
  let textColor = ''
  let devideColor = ''

  if (user?.selected_role === 'shipment party') {
    bgColor = 'bg-blue-100'
    borderColor = 'border-blue-300'
    textColor = 'text-blue-600'
    devideColor = 'divide-blue-400'
  } else if (user?.selected_role === 'dispatcher') {
    bgColor = 'bg-green-100'
    borderColor = 'border-green-300'
    textColor = 'text-green-600'
    devideColor = 'divide-green-400'
  } else if (user?.selected_role === 'carrier') {
    bgColor = 'bg-yellow-100'
    borderColor = 'border-yellow-300'
    textColor = 'text-yellow-600'
    devideColor = 'divide-yellow-400'
  } else {
    bgColor = 'bg-gray-900'
    borderColor = 'border-gray-700'
    textColor = 'text-gray-100'
    devideColor = 'divide-gray-700'
  }

  className += ` ${bgColor} ${borderColor} ${devideColor}`

  const handleClickOpen = () => {
    setOpen(true)
  }

  // const handleClose = () => {
  //   setOpen(false)
  // }

  if (!localStorage.getItem('access')) {
    navigate('/login')
  } else {
    return (
      <>

        <div className="absolute bottom-16 right-8 z-50  lg:block ">
          <Tooltip title="Chat Now!!" arrow placement="top">
            <button
              className="rounded-full border-primary-600 bg-primary-500 p-2 text-white shadow-xl ring-primary-500 hover:bg-primary-600 focus:ring-2  focus:ring-offset-2"
              variant="outlined"
              onClick={!open ? handleClickOpen : () => setOpen(false)}
            >
              <IoChatboxOutline className="text-white" size={25} />
            </button>
          </Tooltip>

          
          {open && <ChatWindow setOpen={setOpen} />}
        </div>

        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div
          style={{ backgroundColor: 'rgb(247 253 255)' }}
          className="flex h-full min-h-screen w-full flex-grow  flex-col overflow-hidden sm:flex-row"
        >
          <SideBar textColor={textColor}> </SideBar>
          <div></div>
          <main role="main" className={'flex w-full flex-grow flex-col'}>
            <div className={'flex grow flex-col '}>
              <div className={className}>
                <span className={`mr-auto text-lg ${textColor} `}>{title}</span>

                <div
                  className={`gap- flex items-center  justify-center divide-x  ${devideColor}`}
                >
                  <NotificationMenu>
                    <div
                      className={` relative my-auto  cursor-pointer text-sm ${textColor}`}
                    >
                      <RiNotification2Line className={`mx-2 ${textColor}`} size={25} />
                    </div>
                  </NotificationMenu>
                  {user?.selected_role !== 'support'.toLowerCase() &&
                    user?.selected_role !== 'manager'.toLowerCase() && (
                      <RoleMenu
                        bgColor={bgColor}
                        textColor={textColor}
                        devideColor={devideColor}
                        borderColor={borderColor}
                      >
                        <Tooltip title="Select Your Role" arrow>
                          <div className="relative mx-3 flex cursor-pointer">
                            <RiUserSettingsLine
                              className={`ml-2 ${textColor}`}
                              size={25}
                            />
                            <div
                              className={`my-auto ml-2 hidden text-sm ${textColor} lg:block`}
                            >
                              {user?.selected_role}
                            </div>
                            <RiArrowDownSLine
                              className={`my-auto ml-2 ${textColor} hover:text-black`}
                              size={25}
                            />
                          </div>
                        </Tooltip>
                      </RoleMenu>
                    )}
                  {user?.selected_role === 'support'.toLowerCase() && (
                    <div className={`relative mx-3  flex `}>
                      <div className={`text- my-auto ml-2 hidden ${textColor} lg:block`}>
                        Support Team
                      </div>
                    </div>
                  )}
                  {user?.selected_role === 'manager'.toLowerCase() && (
                    <div className={`relative mx-3  flex `}>
                      <div
                        className={`my-auto ml-2 hidden text-lg ${textColor} lg:block`}
                      >
                        Company Manager
                      </div>
                    </div>
                  )}

                  <FMenu>
                    <div className={` mx-3 flex `}>
                      {user?.selected_role !== 'support'.toLowerCase() ? (
                        <>
                          <FAvatar name={user?.first_name + ' ' + user?.last_name} />
                          <div
                            className={`my-auto ml-2 hidden text-sm ${textColor} lg:block`}
                          >
                            {user?.first_name + ' ' + user?.last_name}
                          </div>
                        </>
                      ) : (
                        <div className="flex items-center">
                          <img className=" w-15 h-10" src={SITE_LOGO} alt="logo" />
                        </div>
                      )}
                      <RiArrowDownSLine
                        className={`my-auto    ml-2 ${textColor} hover:text-black`}
                        size={25}
                      />
                    </div>
                  </FMenu>
                </div>
              </div>
              <div
                style={{ backgroundColor: '#F2F3F3' }}
                className={`h-14  flex-grow overflow-y-auto`}
              >
                <Outlet />
              </div>
            </div>
            <Footer />
          </main>
        </div>
      </>
    )
  }
}

export default Layout
