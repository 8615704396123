import React from 'react'
import LoadsCardsComponent from './LoadsCardsComponent'

function LoadsCards({ response }) {
  const cardsData = [
    {
      title: 'Total Loads',
      value: response?.cards?.total,
      bgColor: 'gray-100',
      iconColor: 'gray-600',
      trendIconColor: 'green-500',
    },
    {
      title: 'Pending',
      value: response?.cards?.pending,
      bgColor: 'yellow-100',
      iconColor: 'yellow-600',
      trendIconColor: 'green-500',
    },
    {
      title: 'Ready For Pickup',
      value: response?.cards?.ready_for_pick_up,
      bgColor: 'violet-100',
      iconColor: 'violet-600',
      trendIconColor: 'green-500',
    },
    {
      title: 'In Transit',
      value: response?.cards?.in_transit,
      bgColor: 'blue-100',
      iconColor: 'blue-600',
      trendIconColor: 'green-500',
    },
    {
      title: 'Delivered',
      value: response?.cards?.delivered,
      bgColor: 'green-100',
      iconColor: 'green-600',
      trendIconColor: 'green-500',
    },

    {
      title: 'Canceled',
      value: response?.cards?.canceled,
      bgColor: 'red-100',
      iconColor: 'red-600',
      trendIconColor: 'green-500',
    },
  ]

  return (
    <>
      {cardsData.map((card) => (
        <LoadsCardsComponent key={card.title} {...card} />
      ))}
    </>
  )
}

export default LoadsCards
