import { useContext, useState } from 'react'
import FCard from '../../../../components/FCard'
import LoadNote from '../../Notes/LoadNote'
import ESpinner from '../../../../components/ESpinner'
import { useCustomAxios } from '../../../../Hooks/useAxios'
import { BASE_URL } from '../../../../env'
// import { HiPlusSm } from 'react-icons/hi'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import AddNoteModal from '../../Notes/AddNoteModal'
import { useInfiniteQuery } from '@tanstack/react-query'
import { axiosInstance } from '../../../../axios/requister'
import { UserContext } from '../../../../components/UserContext'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Tooltip } from '@mui/material'


const LoadNotesCard = ({ LoadId, allLoadParties }) => {
  const { user } = useContext(UserContext)
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false)
  const [filter, setFilter] = useState('all') // | 'deleted'

  const {
    data: loadNotes,
    status: loadNotesStatus,
    refetch: loadNotesRefetch,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['loadNotes', LoadId],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await axiosInstance.get(
        `${BASE_URL}shipment/load/notes/?load_id=${LoadId}&page=${pageParam}`
      )
      return response.data
    },
    initialPageParam: 1,
    getNextPageParam: (response, pages) => {
      const page = pages.length
      if (response.next) return page + 1
      return undefined
    },
    enabled: filter === 'all',
    keepPreviousData: true,
    retry: false,
    cacheTime: 0
  })

  const isLoadNotesLoading = filter === 'all' && loadNotesStatus === 'loading'

  const {
    data: deletedNotes,
    status: deletedNotesStatus,
    refetch: deletedNotesRefetch
  } = useInfiniteQuery({
    queryKey: ['deletedLoadNotes', LoadId],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await axiosInstance.get(
        `${BASE_URL}shipment/load/notes/deleted-notes/?load_id=${LoadId}&page=${pageParam}`
      )
      return response.data
    },
    initialPageParam: 1,
    getNextPageParam: (response, pages) => {
      const page = pages.length
      if (response.next) return page + 1
      return undefined
    },
    enabled: filter === 'all',
    keepPreviousData: false,
    retry: false,
  })

  const isDeletedNotesLoading = filter === 'deleted' && deletedNotesStatus === 'loading'

  const otherLoadParties = useCustomAxios({
    url: `shipment/load/note/visible-to/?load_id=${LoadId}`,
    method: 'GET',
  })

  const handleChangeFilter = (e) => {
    setFilter(e.target.value)
  }

  const renderedNotes = (filter === 'all'
    ? loadNotes
    : deletedNotes
  )?.pages.reduce((acc, page) => ([...acc, ...(page?.results || [])]), [])

  

  return (
    <>
      <AddNoteModal
        isOpen={isNoteModalOpen}
        setIsOpen={setIsNoteModalOpen}
        callback={loadNotesRefetch}
        LoadId={LoadId}
        otherLoadParties={otherLoadParties?.response?.other_load_parties}
      />


      <FCard>
        <FCard.Header className="flex justify-between">
          <h3 className="font-semibold text-gray-800 flex my-auto">Load Additional Notes 

          {allLoadParties.includes(user?.username) &&

          <Tooltip title={'Add Note'} arrow placement={'top'}>
                    <div className={'my-auto ml-2'}>
                      <AiOutlinePlusCircle
                        size={25}
                        className="cursor-pointer text-primary-500"
                        onClick={() => {
                          setIsNoteModalOpen(true)
                        }}
                      />
                    </div>
                  </Tooltip>
          }
          </h3>

          <select
            className="focus:shadow-outline-blue w-fit border  
          border-gray-400 bg-white py-1 text-sm leading-5 
          text-gray-900 placeholder-gray-500 
          transition duration-150 ease-in-out focus:border-blue-400 focus:outline-none"
            value={filter}
            onChange={handleChangeFilter}
          >
            <option value={'all'}>All</option>
            <option value={'deleted'}>Deleted</option>
          </select>
        </FCard.Header>

        {(isLoadNotesLoading || isDeletedNotesLoading) ? (
          <div className=" mx-auto flex h-32 w-full flex-col items-center justify-center">
            <ESpinner isVisible size={20} />
            <p className="text-gray-500">Loading notes..</p>
          </div>
        ) : !renderedNotes?.length ? (
          <div className="flex h-32 items-center justify-center">
            <span className="text-gray-500">
              No notes were {filter === 'all' ? 'added' : 'deleted'} yet.
            </span>
          </div>
        ) : (
          <div id="scrollableDiv" className="card-body max-h-72 flex flex-col gap-5 overflow-auto p-3">
            <InfiniteScroll
              scrollableTarget="scrollableDiv"
              dataLength={renderedNotes?.length} //This is important field to render the next data
              next={fetchNextPage}
              hasMore={hasNextPage}
              loader={
                <div className=" mx-auto flex h-32 w-full flex-col items-center justify-center">
                  <ESpinner isVisible size={20} />
                  {/* <p className="text-gray-500">Loading notes..</p> */}
                </div>
              }
              

              endMessage={
                renderedNotes?.length > 2 && (
                  <p className='pt-2 text-center text-orange-500 text-xs'>
                    <b>You have reached end of notes.</b>
                  </p>
                )
              }
            >

              {renderedNotes?.map((note, index) => (
                <div key={note.id}>
                  <LoadNote
                    note={note}
                    LoadId={LoadId}
                    otherLoadParties={otherLoadParties?.response?.other_load_parties}
                    loadNoteRefetch={loadNotesRefetch}
                    deletedLoadNotesRefetch={deletedNotesRefetch}
                    loadNoteLoading={isLoadNotesLoading}
                  />
                  {index !== renderedNotes?.length - 1 && (
                    <div className="my-5 w-full border-t border-gray-300" />
                  )}
                </div>
              ))}
            </InfiniteScroll>
          </div>
        )}


      

      </FCard>

      
    </>

  )
}

export default LoadNotesCard