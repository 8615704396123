import { AiOutlineAppstoreAdd } from 'react-icons/ai'
import FButton from '../../components/FButton'
import FFormWrapper from '../../components/FFormWrapper'
import { Link } from 'react-router-dom'

const EmptyState = () => {
  return (
    <>
      <div className={'f-col-center-center h-full text-gray-800'}>
        <AiOutlineAppstoreAdd size={85} />
        <span className={'text-3xl font-semibold'}>You don’t have any Loads yet!</span>
        <span className={'mt-2 text-sm text-gray-500'}>
          <span className={'block text-center'}>
            You have to start by creating your first load and assign
          </span>
          <span className={'block text-center'}>it to your contact list members.</span>
        </span>
        <Link to={'/load/create'}>
          <FFormWrapper>
            <FButton>+ Create Load</FButton>
          </FFormWrapper>
        </Link>
      </div>
    </>
  )
}
export default EmptyState
