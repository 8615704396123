import axios from 'axios'
import { useFormik } from 'formik'
import queryString from 'query-string'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import * as Yup from 'yup'
import ESpinner from '../../components/ESpinner'
import FButton from '../../components/FButton'
import FFormInvalidInput from '../../components/FFormInvalidInput'
import FFormWrapper from '../../components/FFormWrapper'
import FIconWrapper from '../../components/FIconWrapper'
import FInputField from '../../components/FInputField'
import FLabel from '../../components/FLabel'
import { UserContext } from '../../components/UserContext'
import { BASE_URL, SITE_LOGO } from '../../env'
import PasswordRecoveryModal from './PasswordRecoveryModal'
import { PasswordIcon } from './components/PasswordIcon'

const Login = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [serverError, setServerError] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [notificationRedirect, setNotificationRedirect] = useState(null) // Store notification redirect path

  const showPassword = () => setIsPasswordVisible(!isPasswordVisible)
  const navigate = useNavigate()
  const { refreshUser, user } = useContext(UserContext)
  const location = useLocation()

  useEffect(() => {
    const queryParams = queryString.parse(location.search)
    if (queryParams.redirect) {
      setNotificationRedirect(queryParams.redirect)
    }
  }, [location.search])

  const formik = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema: Yup.object({
      email: Yup.string().email('Email is not valid').required('Email is required'),
      password: Yup.string().required('Password is required.'),
    }),
    onSubmit: (values) => {
      setServerError([])
      setIsLoading(true)
      axios
        .post(`${BASE_URL}authentication/login/`, values)
        .then((response) => {
          setIsLoading(false)
          localStorage.setItem('access', response.data.access)
          localStorage.setItem('refresh', response.data.refresh)
          localStorage.setItem('username', response.data.user.username)
          localStorage.setItem('email', response.data.user.email)
          localStorage.setItem(
            'name',
            response.data.user.first_name + ' ' + response.data.user.last_name
          )

          axios
            .get(`${BASE_URL}authentication/app-user/`, {
              headers: {
                Authorization: `Bearer ${response.data.access}`,
              },
            })
            .then((appUserResponse) => {
              localStorage.setItem('roles', appUserResponse.data.user_type)
              toast.success('successfully logged in')
              if (notificationRedirect) {
                navigate(notificationRedirect, { replace: true })
              } else {
                if (appUserResponse.data.selected_role === 'support') {
                  navigate('/tickets', { replace: true })
                } else {
                  navigate('/dashboard', { replace: true })
                }
              }
            })
            .catch((error) => {
              if (error.response.status === 401) {
                toast.error('Unauthorized')
              } else if (error.response.status === 403) {
                toast.error('Forbidden')
              } else if (error.response.status === 404) {
                navigate('/role-select', { replace: true })
                toast.success('Select your role')
              }

              // toast.success('Select your role')
            })
          refreshUser()
        })
        .catch((error) => {
          setIsLoading(false)
          Object.keys(error.response.data).map((key) => {
            setServerError((prevState) => [...prevState, error.response.data[key]])
          })
        })
    },
  })

  useEffect(() => {
    const queryParams = queryString.parse(location.search)

    if (localStorage.getItem('access') && user) {
      if (queryParams.redirect) {
        navigate(queryParams.redirect, { replace: true })
      } else {
        navigate('/dashboard')
      }
    }
  }, [user, notificationRedirect])

  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-[#F2F3F3]">
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="flex w-full flex-col items-center bg-white p-5 shadow-lg md:h-[750] md:w-4/12  ">
        <PasswordRecoveryModal isOpen={isOpen} setIsOpen={setIsOpen} />
        <img src={SITE_LOGO} width={300} className="mx-auto mb-5 " alt={'freight_logo'} />
        <div className=" mt-2 text-center text-lg font-bold text-gray-700 lg:text-2xl">
          Welcome Back to Freight Slayer
        </div>
        <form onSubmit={formik.handleSubmit} className={' w-full p-5'}>
          {serverError.length > 0 && (
            <div
              className={
                'f-col-center-center mb-5 flex flex-col items-start border border-red-500 bg-red-50 p-3'
              }
            >
              {serverError.map((error, index) => (
                <span className={'block text-left text-sm text-red-600'} key={index}>
                  {error}
                </span>
              ))}
            </div>
          )}

          <FFormWrapper>
            <FLabel>Email</FLabel>
            <FInputField
              name="email"
              type="email"
              placeholder="example@domain.com"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <FFormInvalidInput
              touched={formik.touched}
              FieldName={'email'}
              errors={formik.errors}
            />
          </FFormWrapper>
          <FFormWrapper>
            <div className="flex justify-between">
              <FLabel>Password</FLabel>
              <button
                type="button"
                className="order-last text-xs font-semibold text-orange-500"
                onClick={() => setIsOpen(!isOpen)}
              >
                Forget password?
              </button>
            </div>
            <div className="relative mb-3 flex flex-col">
              <FInputField
                name="password"
                type={isPasswordVisible ? 'text' : 'password'}
                placeholder="Enter your password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <PasswordIcon isVisible={isPasswordVisible} showPassword={showPassword} />
              <FFormInvalidInput
                touched={formik.touched}
                FieldName={'password'}
                errors={formik.errors}
              />
            </div>
          </FFormWrapper>

          <FButton
            type="submit"
            disabled={
              Object.keys(formik.errors).length ||
              formik.values.email === '' ||
              formik.values.password === ''
            }
            className={'w-full'}
          >
            <FIconWrapper>
              <div className="mr-2">
                {' '}
                <ESpinner isVisible={isLoading} />
              </div>
              <div>Login</div>
            </FIconWrapper>
          </FButton>
        </form>
        <div className={'mt-2 text-center'}>
          <span>
            New to Freight Slayer?{' '}
            <Link
              to={'/select-account-type'}
              className="mt-4 ml-1 text-center font-semibold text-primary-500"
            >
              Join now
            </Link>
          </span>
        </div>
      </div>
    </div>
  )
}

export default Login
