import React from 'react'
import MessageCard from '../../../../components/MessageCard'

const Message = ({ response, userInfo }) => {
  return (
    <>
      <div>
        {response?.status.toLowerCase() === 'Created'.toLowerCase() && (
          <MessageCard
            message={`This load is created and waiting for its dispatcher to start bidding on it`}
            type={'pending'}
          />
        )}
        {response?.status.toLowerCase() === 'Awaiting Customer'.toLowerCase() && (
          <MessageCard
            message={`This load is waiting for its customer to continue the bidding process`}
            type={'pending'}
          />
        )}
        {response?.status.toLowerCase() === 'Awaiting Dispatcher'.toLowerCase() && (
          <MessageCard
            message={`This load is waiting for its dispatcher to continue the bidding process`}
            type={'pending'}
          />
        )}
        {response?.status.toLowerCase() === 'Awaiting Carrier'.toLowerCase() && (
          <MessageCard
            message={`This load is waiting for its carrier to continue the bidding process`}
            type={'pending'}
          />
        )}
        {response?.status.toLowerCase() === 'Assigning Carrier'.toLowerCase() &&
          response?.carrier?.username === null && (
            <MessageCard
              message={`This load is waiting for its dispatcher to assign a carrier`}
              type={'pending'}
            />
          )}
        {response?.status.toLowerCase() === 'Assigning Carrier'.toLowerCase() &&
          response?.carrier?.username !== null && (
            <MessageCard
              message={`This load is waiting for its dispatcher to start bidding on it with the assigned carrier`}
              type={'pending'}
            />
          )}
        {response?.status.toLowerCase() === 'Ready For Pickup'.toLowerCase() && (
          <MessageCard
            message={`This load is ready for pickup by its carrier and waiting for its shipper or carrier to confirm pickup`}
            type={'pending'}
          />
        )}
        {response?.status.toLowerCase() === 'In Transit'.toLowerCase() && (
          <MessageCard
            message={`This load is in transit and waiting for its consignee or carrier to confirm delivery`}
            type={'pending'}
          />
        )}
        {/* {response?.status.toLowerCase() === 'Delivered'.toLowerCase() && (
          <MessageCard message={`This load has been delivered`} type={'success'} />
        )} */}
      </div>
      <div
        className={`${userInfo.response?.username === response?.dispatcher?.username &&
          userInfo.response?.selected_role === 'dispatcher' &&
          response?.carrier?.username === null &&
          response?.status.toLowerCase() === 'Assigning Carrier'.toLowerCase()
          ? ' block'
          : 'hidden'
          }
    `}
      >
        <MessageCard
          message={'To compelete offer on this load, you must assign a carrier.'}
          type={'pending'}
        />
      </div>
      <div
        className={`${userInfo.response?.username === response?.created_by &&
          response?.dispatcher?.username === null
          ? ' block'
          : 'hidden'
          }
    `}
      >
        <MessageCard
          message={'To begin making an offer on this load, you must assign a dispatcher.'}
          type={'pending'}
        />
      </div>
    </>
  )
}

export default Message
