import FButton from '../../components/FButton'
import FIconWrapper from '../../components/FIconWrapper'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import FFormWrapper from '../../components/FFormWrapper'
import FLabel from '../../components/FLabel'
import FInputField from '../../components/FInputField'
import FFormInvalidInput from '../../components/FFormInvalidInput'
import { axiosInstance } from '../../axios/requister'
import { toast } from 'react-toastify'
import { useState } from 'react'
import ESpinner from '../../components/ESpinner'

export default function DispatherWithTax({
  setIsDispatcherRoleModalOpen,
  handleRefreshUser,
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [isErr, setIsErr] = useState([])

  const Formik = useFormik({
    initialValues: {
      mc_number: '',
    },
    validationSchema: Yup.object({
      mc_number: Yup.string().required('Mc Number is required'),
    }),
    onSubmit: (values) => {
      setIsLoading(true)
      setIsErr([])
      axiosInstance
        .post('authentication/add-role/', {
          type: 'dispatcher',
          mc_number: values.mc_number,
        })
        .then(() => {
          setIsLoading(false)
          setIsDispatcherRoleModalOpen(false)
          handleRefreshUser()
          setTimeout(() => {
            toast.success('Dispatcher Role Added Successfully')
          }, 200)
        })
        .catch((error) => {
          setIsLoading(false)
          toast.error('Something went wrong')
          setIsErr(error.response.data.detail)
        })
    },
  })
  return (
    <>
      <div className="text-base font-medium leading-6 text-gray-700">
        <p>
          By selecting [Dispatcher], you will be adding a new role to your account that
          allows you to act as a middleman in the cargo transportation industry. This role
          is suitable if you want to connect shippers and receivers and manage their
          transportation needs. Please enter your MC number to continue.
        </p>
      </div>

      <form onSubmit={Formik.handleSubmit}>
        <FFormWrapper>
          <FLabel htmlFor="mc_number"> MC Number</FLabel>
          <FInputField
            type="text"
            name={'mc_number'}
            id={'mc_number'}
            placeholder={'Enter a MC Number'}
            onChange={Formik.handleChange}
            onBlur={Formik.handleBlur}
            value={Formik.values.mc_number}
          />

          <FFormInvalidInput
            touched={Formik.touched}
            FieldName={'mc_number'}
            errors={Formik.errors}
          />
          
            <div>
              
                <small className={'text-left font-medium text-red-700'} >
                  {isErr}
                </small>
              
            </div>
          
        </FFormWrapper>

        <div className="text-base font-medium leading-6 text-gray-700"></div>
        {/*if taxinfo res found then show this div if its not found dont show it */}

        <div className={'mt-3 flex w-full flex-row-reverse gap-5'}>
          <FButton type={'submit'}>
            <FIconWrapper>
              <AiOutlinePlusCircle size={20} />
              Add Dispatcher Role
              <ESpinner isVisible={isLoading} />
            </FIconWrapper>
          </FButton>
          <FButton
            btnType={'secondary'}
            onClick={() => {
              setIsDispatcherRoleModalOpen(false)
              setIsErr([])
              Formik.resetForm()
            }}
          >
            <FIconWrapper>Cancel</FIconWrapper>
          </FButton>
        </div>
      </form>
    </>
  )
}
