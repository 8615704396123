import { useState } from 'react'
import { Link } from 'react-router-dom'
import FButton from '../../components/FButton'
import FIconWrapper from '../../components/FIconWrapper'
import { GoChevronLeft } from 'react-icons/go'
import FCard from '../../components/FCard'
import FLabel from '../../components/FLabel'
import FInputField from '../../components/FInputField'
import FFormInvalidInput from '../../components/FFormInvalidInput'
import FFormWrapper from '../../components/FFormWrapper'
import ESpinner from '../../components/ESpinner'
import { MenuItem, Select, Tooltip } from '@mui/material'
import FacilityMap from './FacilityMap'
import GoogleMapsWrapper from '../../components/GoogleMapsWrapper'
import { MdOutlineAddLocationAlt } from 'react-icons/md'
import FModal from '../../components/FModal'
import useLocationData from '../../components/useLocationData'

const FacilityForm = ({ Formik, loading, buttonTitle, FormTitle }) => {
  const [openMapModal, setOpenMapModal] = useState(false)

  const { countries, states, cities } = useLocationData({
    selectedCountry: Formik.values.country,
    selectedState: Formik.values.state,
  })

  return (
    <div className={''}>
      <FModal
        isOpen={openMapModal}
        setIsOpen={setOpenMapModal}
        title={'Search for new location on map'}
      >
        <GoogleMapsWrapper>
          <FacilityMap Formik={Formik} />
          <div
            className={'mt-2 flex flex-row-reverse '}
            onClick={() => setOpenMapModal(false)}
          >
            <FButton>Ok</FButton>
          </div>
        </GoogleMapsWrapper>
      </FModal>
      <div
        className={
          'primary-shadow sticky top-0 block w-full border-b  bg-white p-3 ' +
          'z-10 text-left text-xl font-semibold text-gray-700'
        }
      >
        <FIconWrapper>
          <Link
            to={'/facilities'}
            className={'hove:border rounded p-0.5 hover:bg-gray-100'}
          >
            <GoChevronLeft size={25} />
          </Link>
          <span className={''}>{FormTitle}</span>
        </FIconWrapper>
      </div>
      <div className={'mx-auto p-5 lg:flex lg:w-2/3 lg:p-10 '}>
        <FCard>
          <FCard.Header>
            <span>Add Facility</span>
          </FCard.Header>

          <FCard.Body>
            <form onSubmit={Formik.handleSubmit}>
              <FFormWrapper className={' '}>
                <FLabel htmlFor={'building_name'}>Building Name</FLabel>
                <FInputField
                  id={'building_name'}
                  name={'building_name'}
                  value={Formik.values.building_name}
                  onChange={Formik.handleChange}
                  onBlur={Formik.handleBlur}
                />
                <FFormInvalidInput
                  touched={Formik.touched}
                  FieldName={'building_name'}
                  errors={Formik.errors}
                />
              </FFormWrapper>
              <FFormWrapper className={'  '}>
                <FLabel htmlFor={'address'}>Address</FLabel>
                <FInputField
                  id={'address'}
                  name={'address'}
                  value={Formik.values.address}
                  onChange={Formik.handleChange}
                  onBlur={Formik.handleBlur}
                />
                <FFormInvalidInput
                  touched={Formik.touched}
                  FieldName={'address'}
                  errors={Formik.errors}
                />
              </FFormWrapper>
              <FFormWrapper>
                <FLabel htmlFor={'country'}>Country</FLabel>
                <Select
                  className="input-style h-10"
                  id={'country'}
                  name={'country'}
                  value={Formik.values.country}
                  onChange={(e) => {
                    Formik.setFieldValue('country', e.target.value)
                    Formik.setFieldValue('state', '')
                    Formik.setFieldValue('city', '')
                  }}
                  onBlur={Formik.handleBlur}
                >
                  {countries?.map((country) => (
                    <MenuItem key={country.id} value={country.country}>
                      {country.country}
                    </MenuItem>
                  ))}
                </Select>
                <FFormInvalidInput
                  touched={Formik.touched}
                  FieldName={'country'}
                  errors={Formik.errors}
                />
              </FFormWrapper>

              <div className={'lg:flex lg:flex-row  lg:justify-center lg:gap-10'}>
                <FFormWrapper className={'lg:w-1/3'}>
                  <FLabel htmlFor={'state'}>State / Province</FLabel>
                  <Select
                    className="input-style h-10"
                    id="state"
                    name="state"
                    value={Formik.values.state} // Use Formik to manage the state value
                    onChange={(e) => {
                      Formik.setFieldValue('state', e.target.value)
                      Formik.setFieldValue('city', '')
                      // Update Formik's state on change
                    }}
                    onBlur={Formik.handleBlur}
                  >
                    {states.map((state) => (
                      <MenuItem key={state.id} value={state.name}>
                        {state.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FFormInvalidInput
                    touched={Formik.touched}
                    FieldName={'state'}
                    errors={Formik.errors}
                  />
                </FFormWrapper>
                <FFormWrapper className={'lg:w-1/3 '}>
                  <FLabel htmlFor={'city'}>City</FLabel>
                  <Select
                    className="input-style h-10"
                    id="city"
                    name="city"
                    value={Formik.values.city} // Use Formik to manage the city value
                    onChange={(e) => {
                      Formik.setFieldValue('city', e.target.value) // Update Formik's state on change
                    }}
                    onBlur={Formik.handleBlur} // Use Formik's onBlur handler
                  >
                    {cities.map((city) => (
                      <MenuItem key={city.id} value={city.name}>
                        {city.name}
                      </MenuItem>
                    ))}
                  </Select>

                  <FFormInvalidInput
                    touched={Formik.touched}
                    FieldName={'city'}
                    errors={Formik.errors}
                  />
                </FFormWrapper>

                <FFormWrapper className={'lg:w-1/3 '}>
                  <FLabel htmlFor={'zip_code'}>Zip Code</FLabel>
                  <FInputField
                    id={'zip_code'}
                    name={'zip_code'}
                    value={Formik.values.zip_code}
                    onChange={Formik.handleChange}
                    onBlur={Formik.handleBlur}
                  />
                  <FFormInvalidInput
                    touched={Formik.touched}
                    FieldName={'zip_code'}
                    errors={Formik.errors}
                  />
                </FFormWrapper>
              </div>

              <div className={' mt-10 flex flex-row-reverse gap-5'}>
                <FButton type={'submit'} className={'flex gap-2'}>
                  <span>{buttonTitle}</span>

                  <ESpinner isVisible={loading} />
                </FButton>
                <Tooltip title="Search for new location on map" arrow placement="top">
                  <div
                    className={
                      'my-auto flex cursor-pointer rounded-sm border border-gray-300 bg-gray-100 p-1 transition-all duration-300 ease-in-out hover:bg-gray-200'
                    }
                    onClick={() => {
                      setOpenMapModal(true)
                      //clear all the form values
                      // Formik.setFieldValue('address', '')

                      // Formik.setFieldValue('zip_code', '')
                      //i want coords to be in the center of usa by default
                      Formik.setFieldValue('coords', { lat: 39.8283, lng: -98.5795 })
                    }}
                  >
                    <MdOutlineAddLocationAlt
                      size={25}
                      className=" text-2xl text-primary-500"
                    />
                  </div>
                </Tooltip>
              </div>
            </form>
          </FCard.Body>
        </FCard>
      </div>
    </div>
  )
}

export default FacilityForm
