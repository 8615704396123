import { Menu, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect, useContext } from 'react'
import FAvatar from '../FAvatar'
import { axiosInstance } from '../../axios/requister'
import TimeAgo from '../TimeAgo'
import ESpinner from '../ESpinner'
import { Link } from 'react-router-dom'
import { UserContext } from '../UserContext'
// import { toast } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'

export default function NotificationMenu({ children }) {
  const [response, setResponse] = useState(null)
  const [activeTab, setActiveTab] = useState('Unread')
  const [loading, setLoading] = useState(false)
  const [currentpage, setCurrentPage] = useState(1)
  const [next, setNext] = useState(null)
  const [moreLoading, setMoreLoading] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false) // Track menu open/closed state
  const [unReadNotifications, setUnReadNotifications] = useState(0)

  const { user } = useContext(UserContext)

  useEffect(() => {
    let getURL

    if (user?.selected_role === 'manager') {
      getURL = `manager/notifications/?page=1&seen=${
        activeTab === 'Unread' ? 'unread' : 'read'
      }`
    } else {
      getURL = `notifications/?page=1&seen=${activeTab === 'Unread' ? 'unread' : 'read'}`
    }
    setLoading(true)
    setCurrentPage(1)
    axiosInstance
      .get(getURL)
      .then((res) => {
        setResponse(res.data)
        if (activeTab === 'Unread') {
          setUnReadNotifications(res.data.count)
        }

        setLoading(false)
        setNext(res.data.next)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [activeTab, isMenuOpen])

  const handleLoadMore = () => {
    let loadMoreUrl
    if (user?.selected_role === 'manager') {
      loadMoreUrl = `manager/notifications/?page=${currentpage + 1}&seen=${
        activeTab === 'Unread' ? 'unread' : 'read'
      }`
    } else {
      loadMoreUrl = `notifications/?page=${currentpage + 1}&seen=${
        activeTab === 'Unread' ? 'unread' : 'read'
      }`
    }

    setMoreLoading(true)
    axiosInstance
      .get(loadMoreUrl)
      .then((res) => {
        setResponse((prev) => {
          return {
            ...prev,
            results: [...prev.results, ...res.data.results],
          }
        })
        setNext(res.data.next)
        setCurrentPage(currentpage + 1) // Update the currentPage state
        setMoreLoading(false)
      })
      .catch(() => {
        setMoreLoading(false)
      })
  }

  const handleSeen = (id) => {
    let putURL
    if (user?.selected_role === 'manager') {
      putURL = `manager/notifications/${id}/`
    } else {
      putURL = `notifications/update/${id}/`
    }

    // refreshUser()
    if (activeTab === 'Unread') {
      axiosInstance
        .put(putURL, {
          seen: true,
        })
        .then(() => {})
        .catch(() => {})
    } else {
      return
    }
  }
  const handleMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  // const existingNotificationIds = new Set()

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     axiosInstance.get(`notifications/?page=1&seen=unread`).then((response) => {
  //       // Filter out notifications that haven't been seen or displayed yet
  //       const newNotifications = response.data.results.filter(
  //         (notif) => !existingNotificationIds.has(notif.id)
  //       )
  
  //       // Show a toast for each new notification and add it to the existingNotificationIds set
  //       newNotifications.forEach((notification) => {
  //         toast.info(notification.message, {
  //           position: toast.POSITION.TOP_RIGHT,
  //           autoClose: false,
  //           closeOnClick: true,
  //           onClose: () => handleSeen(notification.id),
  //         })
  //         existingNotificationIds.add(notification.id) // Mark as shown
  //       })
  //     })
  //   }, 15000) // Polling interval, e.g., every 15 seconds
  
  //   return () => clearInterval(intervalId)
  // }, [])

  function renderNotifications() {
    if (response?.results?.length === 0) {
      return (
        <div className="flex h-40 items-center justify-center">
          <span className="text-sm font-medium">No Notifications</span>
        </div>
      )
    } else {
      return (
        <>
          {response?.results?.map((item) => (
            <div key={item.id}>
              <Menu.Item>
                <Link to={item.url.split('=')[1]}>
                  <div
                    className="mt-3 flex cursor-pointer items-start p-2 hover:bg-gray-100"
                    //i want to make this function just on unread notifications
                    onClick={() => {
                      handleSeen(item.id)
                    }}
                  >
                    <div className="flex items-start">
                      {
                        //if the notification is unread then show the dot
                        !item.seen && (
                          <div className="my-auto mr-2 h-2 w-2 rounded-full bg-primary-500"></div>
                        )
                      }
                      {item.sender.user === null ? (
                        <FAvatar name={'Load Status'} />
                      ) : (
                        <FAvatar
                          name={item.sender.first_name + ' ' + item.sender.last_name}
                        />
                      )}
                    </div>


                    <div className="ml-2">
                      {item.sender.user === null ? (
                        <div>{item.message.replace(/<br>/g, '') }</div>
                      ) : (
                        <div>
                          {/* <span className="font-medium">
                            {item.message.split(' (')[0].replace(/<br>/g, '') }
                          </span> */}
                          <span>
                            {' ('} {item.message.split(' (')[1].replace(/<br>/g, '') }
                          </span>
                        </div>
                      )}

                      {/* Use the TimeAgo component to display the time in a human-readable format */}
                      <TimeAgo timestamp={item.created_at} />
                    </div>
                  </div>
                </Link>
              </Menu.Item>
            </div>
          ))}

          {moreLoading ? (
            <div className="flex h-40 items-center justify-center gap-2 p-3">
              <ESpinner size={15} isVisible={true} /> Loading...
            </div>
          ) : next ? (
            <div className="flex justify-center">
              <button
                onClick={handleLoadMore}
                className="p-3 text-sm font-medium text-primary-500 hover:text-primary-600 hover:underline"
              >
                Load More
              </button>
            </div>
          ) : (
            <div className="flex justify-center">
              {response?.count > 5 && (
                <span className="p-3 text-sm font-medium">
                  {"looks like you've seen everything"}
                </span>
              )}
            </div>
          )}
        </>
      )
    }
  }

  return (
    <div className="z-30 flex flex-col items-center text-left">
      <Menu as="div" className="relative z-40 inline-block text-left">
        <div className={'f-col-center-center'}>
          {unReadNotifications > 0 && (
            <span
              className={
                'absolute top-1 right-2 z-50 rounded-full bg-red-500  p-1  text-xs '
              }
            ></span>
          )}
          <Menu.Button className=" w-full" onClick={handleMenuOpen}>
            {children}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="primary-shadow absolute -right-40 z-50 mt-2  origin-top-right  divide-gray-100 rounded-sm border bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none lg:right-2">
            <div className="menu-wrap flex max-h-[500px]  w-80 flex-col overflow-hidden p-1 lg:w-[500px] ">
              <Menu.Item>
                <>
                  <div className=" sticky -top-1 z-50 flex justify-between border-b bg-white p-2">
                    <div className="my-auto pl-2">
                      <span className="text-sm font-medium">Notifications</span>
                    </div>
                    <div className="flex gap-3 pr-2 ">
                      <div
                        onClick={() => setActiveTab('Unread')}
                        className={` ${
                          activeTab === 'Unread' && 'bg-gray-100'
                        } cursor-pointer   p-1  `}
                      >
                        <span className={'text-sm font-medium'}>{'Unread'}</span>
                      </div>
                      <div
                        onClick={() => setActiveTab('Read')}
                        className={` ${
                          activeTab === 'Read' && 'bg-gray-100'
                        } cursor-pointer   p-1  `}
                      >
                        <span className={'text-sm font-medium'}>{'Read'}</span>
                      </div>
                    </div>
                  </div>

                  {loading ? (
                    <div className="flex h-40 items-center justify-center">
                      <ESpinner size={25} isVisible={true} />
                    </div>
                  ) : (
                    renderNotifications()
                  )}
                </>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}
