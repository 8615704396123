import './App.css'
import { Routes, Route } from 'react-router-dom'
import Register from './views/Authentication/Register'
import RegistraionCompanyManager from './views/Authentication/Company Manager Signup/RegistraionCompanyManager'
import Login from './views/Authentication/Login'
import TwoFactorAuth from './views/Authentication/TwoFactorAuth'
import RoleSelect from './views/Authentication/RoleSelect'
import EmailVerification from './views/Authentication/EmailVerification'
import OTP from './views/Authentication/OTP'
import ResetPassword from './views/Authentication/ResetPassword'
import Dashboard from './views/Dashboard/Dashboard'
import Layout from './components/Layout/Layout'
import EmailVerificationProcess from './views/Authentication/EmailVerificationProcess'
import EmptyState from './views/Load/EmptyState'
import Onboarding from './views/Load/Onboarding'
import ListLoad from './views/Load/ListLoad'
import ViewLoad from './views/Load/ViewLoad'
import ListShipment from './views/Shipment/ListShipment'
import ContactsPage from './views/Contact/ContactsPage'
import Roles from './views/Roles/Roles'
import ListFacilities from './views/Facilities/ListFacilities'
import CreateCompany from './views/Company/CreateCompany'
import CompanyProfile from './views/Company/CompanyProfile'
import 'react-toastify/dist/ReactToastify.css'
import CreateFacility from './views/Facilities/CreateFacility'
import EditFacility from './views/Facilities/EditFaciity'
// import { appRoutes } from './Routes/appRoutes'
import LoadDetails from './views/Load/LoadDetails/LoadDetails'
import ShipmentDetails from './views/Shipment/ShipmentDetails'
import RcCarrier from './components/RcCarrier'
import RcCustomer from './components/RcCustomer'
import BillDoc from './components/BillDoc'
import CutomerBillDispatcher from './components/CustomerBillDispatcher'
import CarrierBillDispatcher from './components/CarrierBillDispatcher'
import RBol from './components/RBol'
import RoleForm from './views/Authentication/RoleForm'
import NotFoundPage from './views/errors/NotFoundPage'
import IndividualUser from './views/Company/IndividualUser'
import Settings from './views/Settings/Settings '
import AccountSelect from './views/Authentication/AccountSelect'
import HelpCenter from './views/Help-Center/HelpCenter'
import Tickets from './views/Support-Team/Tickets'
import AdminTicketDetails from './views/Support-Team/AdminTicketDetails'
// import ClaimTicketDetails from './views/Support-Team/ClaimTicketDetails'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import SupportClaims from './views/Support-Team/Claims'
import Drafts from './views/Drafts/Drafts'
import InsuranceTab from './views/Insurance/InsuranceTab'
import CreateLoadForm from './views/Load/Forms/CreateLoadForm'
import UpdateDraftForm from './views/Load/Forms/UpdateDraftForm'
import EditLoadForm from './views/Load/Forms/EditLoadForm'
import CopyLoadForm from './views/Load/Forms/CopyLoadForm'

function App() {
  return (
    <QueryClientProvider client={new QueryClient()}>
      <div className="App flex h-full">
        <div className="w-full">
          <Routes>
            <Route path={'/'} element={<Login />} />
            <Route path={'/register'} element={<Register />} />
            <Route
              path={'/company-manager-register'}
              element={<RegistraionCompanyManager />}
            />
            <Route element={<Layout />}>
              {/* {appRoutes.map((route) => (
                <Route key={route.name} path={route.path} element={route.component} />
              ))} */}
              <Route path={'/dashboard'} element={<Dashboard />} />
              <Route path={'/shipment'} element={<ListShipment />} />
              <Route path={'/shipment-details/:id'} element={<ShipmentDetails />} />
              <Route path={'/view-load'} element={<ViewLoad />} />
              <Route path={'/load'} element={<ListLoad />} />
              <Route path={'/load-details/:id'} element={<LoadDetails />} />
              <Route path={'/load/create'} element={<CreateLoadForm />} />
              <Route path={'/drafts'} element={<Drafts />} />
              <Route path={'/drafts/:id'} element={<UpdateDraftForm />} />
              <Route path={'/load/:id'} element={<EditLoadForm />} />
              <Route path={'/load/:id/copy'} element={<CopyLoadForm />} />
              <Route path={'/empty'} element={<EmptyState />} />
              <Route path={'/onb'} element={<Onboarding />} />
              <Route path={'/contact'} element={<ContactsPage />} />
              <Route path={'/roles'} element={<Roles />} />
              <Route path={'/company/create'} element={<CreateCompany />} />
              <Route path={'/company-profile'} element={<CompanyProfile />} />
              <Route path={'/facilities'} element={<ListFacilities />} />
              <Route path={'/facilities/create'} element={<CreateFacility />} />
              <Route path={'/facilities/edit/:id'} element={<EditFacility />} />
              <Route path={'/individualuser'} element={<IndividualUser />} />
              <Route path={'/settings'} element={<Settings />} />
              <Route path={'/tickets'} element={<Tickets />} />
              <Route path={'/support-claims'} element={<SupportClaims />} />
              <Route
                path={'/admin-ticket-details/:id'}
                element={<AdminTicketDetails />}
              />

              <Route path={'/insurance'} element={<InsuranceTab />} />
            </Route>
            <Route path={'/login'} element={<Login />} />
            <Route path={'/role-select'} element={<RoleSelect />} />
            <Route path={'/select-account-type'} element={<AccountSelect />} />
            <Route path={'/roleform'} element={<RoleForm />} />
            <Route path={'/twoFactor'} element={<TwoFactorAuth />} />
            <Route path={'/verification'} element={<EmailVerification />} />
            <Route path={'/verify/:key'} element={<EmailVerificationProcess />} />
            <Route path={'/otp'} element={<OTP />} />
            <Route path={'/rc-carrier'} element={<RcCarrier />} />
            <Route path={'/rc-customer'} element={<RcCustomer />} />
            <Route path={'/bill-doc'} element={<BillDoc />} />
            <Route path={'/customer-bill-dispatcher'} element={<CutomerBillDispatcher />} />
            <Route path={'/carrier-bill-dispatcher'} element={<CarrierBillDispatcher />} />
            <Route path={'/rbol'} element={<RBol />} />
            <Route path={'/help-center'} element={<HelpCenter />} />
            <Route
              path={'/authentication/password/reset/confirm/:uid/:token/'}
              element={<ResetPassword />}
            />
            <Route />
            <Route path="*" element={<NotFoundPage />} />
            <Route path="/pagenotfound" element={<NotFoundPage />} />
          </Routes>
        </div>
      </div>
    </QueryClientProvider>
  )
}

export default App
