import Modal from '@mui/material/Modal'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import FButton from '../../components/FButton'
import RadioCard from '../../components/RadioCard'
import { UserContext } from '../../components/UserContext'
import { SITE_LOGO } from '../../env'

function AccountSelect() {
  const { user } = useContext(UserContext)
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  useEffect(() => {
    if (user) {
      navigate('/dashboard')
    }
  }, [])

  const [selectedAcc, setSelectedAcc] = useState('')

  const handleSelectAcc = (Acc) => {
    setSelectedAcc(Acc)
  }

  const handleClick = () => {
    if (selectedAcc === 'User') {
      navigate('/register?type=User')
    } else if (selectedAcc === 'Company Manager') {
      handleOpen()
    }
  }
  const handleChange = (selectedAcc) => {
    setSelectedAcc(selectedAcc)
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          className="absolute top-1/2 left-1/2  w-11/12 -translate-x-1/2 -translate-y-1/2 transform bg-white  shadow-sm lg:w-5/12
        "
        >
          <div className="w-Full bg-yellow-400 p-2"></div>
          <div className="flex flex-col p-5">
            <div className="flex flex-row">
              <span className="my-auto hidden pr-2 text-5xl lg:block">⚠️</span>
              <div className="ml-2 flex flex-col gap-2">
                <div className="whitespace-nowrap  text-xl font-semibold text-gray-700">
                  Ready to Lead your Company ?
                </div>
                <div className="text-gray-600">
                  {` You are about to create a company manager account. This account will
                  have access to all the company's shipments , you should create your
                  company once you are logged in after 
                  signing up as a company manager.`}
                </div>
              </div>
            </div>
            <hr className="mt-5" />

            <div className="mt-5 flex flex-row-reverse  gap-5">
              <FButton
                onClick={() => {
                  navigate('/register?type=CompanyManager')
                }}
                className={''}
              >
                Proceed
              </FButton>
              <FButton onClick={handleClose} btnType={'secondary'}>
                Cancel
              </FButton>
            </div>
          </div>
        </div>
      </Modal>

      <div className={'f-col-center mt-20  gap-y-4  p-5 '}>
        <img src={SITE_LOGO} width="300" alt={'freight_logo'} />

        <span className="mt-2 text-left text-xl font-medium text-gray-700">
          Create your Freight Slayer account as
        </span>
        <div className={' flex flex-col gap-3 '}>
          <RadioCard
            label="User"
            body="You can access and manage shipments, track orders,
            and utilize the brokerage services provided. "
            handleClick={handleSelectAcc}
            isActive={selectedAcc === 'User'}
            onChange={(e) => {
              handleChange(e.target.value)
            }}
            value="User"
          />
          <RadioCard
            label="Company Manager"
            body="if you represent a company and need to oversee multiple shipments, 
            manage carrier relationships, and streamline logistics operations."
            handleClick={handleSelectAcc}
            isActive={selectedAcc === 'Company Manager'}
            onChange={(e) => {
              handleChange(e.target.value)
            }}
            value="Company Manager"
          />

          <FButton onClick={handleClick} disabled={selectedAcc === ''}>
            Next
          </FButton>
        </div>
      </div>
    </>
  )
}

export default AccountSelect
