import { createContext, useState, useEffect } from 'react'
import { axiosInstance } from '../axios/requister'
import ESpinner from './ESpinner'
import { useNavigate } from 'react-router-dom'

export const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const navigate = useNavigate()
  const [user, setUser] = useState(null)
  const [shouldRefresh, setShouldRefresh] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [userRoles, setUserRoles] = useState([])

  useEffect(() => {
    setIsLoading(true)
    axiosInstance
      .get('authentication/app-user/')
      .then((response) => {
        setUser(response?.data)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [shouldRefresh])

  // my user.user_type is "dispatcher-shipment party" i want to make a function that split it and return an array of ["dispatcher", "shipment party"]
  useEffect(() => {
    if (user) {
      const roles = user.user_type.split('-')
      setUserRoles(roles)
    }
  }, [user])

  const refreshUser = () => {
    setShouldRefresh(!shouldRefresh)
  }

  const logout = () => {
    localStorage.removeItem('access')
    localStorage.removeItem('refresh')
    localStorage.clear() //for localStorage
    sessionStorage.clear() //for sessionStorage
    setUser(null)
    navigate('/login')
  }

  if (isLoading) {
    return (
      <div className={'f-col-center-center h-full w-full'}>
        <ESpinner isVisible={true} size={30} />
        <span>Loading...</span>
      </div>
    )
  }

  return (
    <UserContext.Provider value={{ user, userRoles, refreshUser, logout }}>
      {children}
    </UserContext.Provider>
  )
}
