import FIconWrapper from '../../components/FIconWrapper'
import React, { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import FButton from '../../components/FButton'
import FCustomDataTable from '../../components/FCustomDataTable'
import CreateShipment from './CreateShipment'
import ESpinner from '../../components/ESpinner'
import { UserContext } from '../../components/UserContext'
import { useQuery } from '@tanstack/react-query'
import { axiosInstance } from '../../axios/requister'


const ListShipment = () => {
  const [isCreateShipmentModalOpen, setIsCreateShipmentModalOpen] = useState(false)
  const [page, setPage] = useState(1)
  const [filter, setFilter] = useState([])
  const navigate = useNavigate()
  const { user } = useContext(UserContext)

  const [filterOptions, setFilterOptions] = useState([
    {
      id: 0,
      value: '',
      key: '',
    },
  ])

  const { data, status, refetch } = useQuery(
    ['shipment', page],
    async () => {
      if (user.selected_role === 'manager') {
        const response = await axiosInstance.get(`manager/shipment/?page=${page}`)
        return response.data
      } else {
        const response = await axiosInstance.get(`shipment/?page=${page}`)
        return response.data
      }
    },
    {
      keepPreviousData: true,
      retry: false,
      enabled: page > 0,
    }
  )

  const ShipmentsHeader = () => {
    return (
      <>
        <CreateShipment
          setIsCreateShipmentModalOpen={setIsCreateShipmentModalOpen}
          isCreateShipmentModalOpen={isCreateShipmentModalOpen}
          callback ={() => refetch()}
        />
        <div
          className={
            'sticky top-0 z-10  flex w-full  justify-between border-b bg-white p-3 ' +
            'text-left text-xl font-semibold text-gray-700'
          }
        >
          <FIconWrapper>
            <span className={''}>Shipments</span>
          </FIconWrapper>
          {user.selected_role !== 'manager' && (
            <FButton onClick={() => setIsCreateShipmentModalOpen(true)}>
              <FIconWrapper>
                <AiOutlinePlusCircle size={20} />
                <span className={'text-sm font-medium'}>Create Shipment</span>
              </FIconWrapper>
            </FButton>
          )}
        </div>
      </>
    )
  }
  useEffect(() => {
    refetch()
  }, [page, refetch])


  const nameCell = (row) => {
    return <span className={'text-sm font-semibold'}>{row}</span>
  }
  const createdByCell = (row) => {
    return <span className={'text-sm font-semibold'}>{row}</span>
  }
  const cols = [
    {
      Header: 'Shipment name',
      accessor: 'name',
      cell: nameCell,
    },
    {
      Header: 'Created by',
      accessor: 'created_by', // String-based value accessors!
      cell: createdByCell,
    },
  ]
  if (status === 'loading') {
    return (
      <div>
        <ShipmentsHeader />
        <div className={'mt-60  flex w-full items-center justify-center '}>
          <div className={'f-col-center-center'}>
            <ESpinner isVisible={true} size={30} />
            <span className="mt-5">Loading...</span>
          </div>
        </div>
      </div>
    )
  } else if (data?.results?.length === 0 && status === 'success') {
    return (
      <>
        <ShipmentsHeader />
        <div className={' flex  flex-col items-center justify-center   py-40'}>
          <img
            src="https://storage.googleapis.com/freight_static_files/empty-state.svg"
            alt={'empty_state'}
            width={200}
            className={''}
          />
          <span className={'mt-5 font-semibold text-gray-700'}>No Data Found</span>
          {user.selected_role === 'manager' ? (
            <span className={'mt-1 text-center text-sm text-gray-600 '}>
              Sorry, there are currently no shipments available in your organization.
            </span>
          ) : (
            <span className={'mt-1 text-center text-sm text-gray-600 '}>
              Sorry, no shipments found. Please check back later or create one yourself !
            </span>
          )}
        </div>
      </>
    )
  } else {
    return (
      <div>
        <ShipmentsHeader />

        <div className="">
          <FCustomDataTable
            cols={cols}
            data={data?.results ?? []}
            isLoading={status === 'loading'}
            page={page}
            rowFunction={(row) => {
              navigate(`/shipment-details/${row.id}`)
            }}
            setPage={setPage}
            size={2}
            setFilter={setFilter}
            filter={filter}
            totalNumberOfPages={Math.ceil(data?.count / 10)}
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
            Refetch={refetch}
          />
        </div>

        
      </div>
    )
  }
}
export default ListShipment
