import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { IoClose } from 'react-icons/io5'
import PropTypes from 'prop-types'

const FModal = ({
  isOpen,
  setIsOpen,
  title,
  isDismissible,
  callbackFunction,
  children,
  isAutoWidth,
}) => {
  function closeModal() {
    isDismissible && setIsOpen(false)
    callbackFunction && callbackFunction()
  }

  return (
    <>
      {isOpen ? (
        <>
          <div
            className={`fixed inset-0 z-40 h-full w-full  bg-gray-600 bg-opacity-20`}
          ></div>
          <Transition appear show={isOpen} as={Fragment}>
            <Dialog
              as="div"
              className="fixed inset-0 z-50 overflow-y-auto"
              onClose={closeModal}
            >
              <div className="min-h-screen px-4 text-center ">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0" />
                </Transition.Child>
                <span
                  className="inline-block h-screen align-middle"
                  aria-hidden="true"
                ></span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <div
                    className={`p z-40 my-8 inline-block   transform bg-white text-left align-middle shadow-xl transition-all ${
                      !isAutoWidth && 'w-full lg:w-6/12'
                    } `}
                  >
                    {' '}
                    <>
                      <div
                        className={'mb-5 flex justify-between bg-gray-800 p-3 text-white'}
                      >
                        <span className="text leading-8 text-gray-100">{title}</span>
                        {isDismissible && (
                          <button
                            type="button"
                            className="flex  w-8 flex-col items-center justify-center border border-transparent bg-gray-200 text-sm font-medium leading-none text-gray-500 hover:bg-gray-500 hover:text-gray-100  focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                            onClick={closeModal}
                          >
                            <IoClose size={20} />
                          </button>
                        )}
                      </div>
                      <div className="mt-2 px-6 pb-6">{children}</div>
                    </>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition>
        </>
      ) : (
        ''
      )}
    </>
  )
}
FModal.defaultProps = {
  isDismissible: true,
}

FModal.propTypes = {
  isDismissible: PropTypes.bool,
}
export default FModal
