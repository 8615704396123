import { HiCheck, HiOutlineX } from 'react-icons/hi'

function PasswordStrengthIndicatorItem(props) {
  return (
    <div
      className={`flex flex-row items-center gap-3 text-sm ${
        props.isValid ? 'text-green-600' : 'text-red-700'
      }`}
    >
      {!props.isValid && <HiOutlineX />}
      {props.isValid && <HiCheck />}
      <span>{props.text}</span>
    </div>
  )
}

export default PasswordStrengthIndicatorItem
