import FModal from '../../components/FModal'
import FButton from '../../components/FButton'
import FIconWrapper from '../../components/FIconWrapper'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { axiosInstance } from '../../axios/requister'
import { toast } from 'react-toastify'
import { useState } from 'react'
import ESpinner from '../../components/ESpinner'

export default function ShipmentRoleModal({
  isShipmentRoleModalOpen,
  setIsShipmentRoleModalOpen,
  handleRefreshUser,
}) {
  const [isLoading, setIsLoading] = useState(false)
  const addShipmentRole = () => {
    setIsLoading(true)
    axiosInstance
      .post('authentication/add-role/', {
        type: 'shipment party',
      })
      .then(() => {
        setIsLoading(false)
        setIsShipmentRoleModalOpen(false)
        handleRefreshUser()
        setTimeout(() => {
          toast.success('Shipment party Role Added Successfully')
        }, 200)
      })
      .catch(() => {
        setIsLoading(false)
        setIsShipmentRoleModalOpen(false)
        toast.error('Something went wrong')
      })
  }

  return (
    <FModal
      title={'Add Shipment Party Role'}
      isOpen={isShipmentRoleModalOpen}
      setIsOpen={() => {
        setIsShipmentRoleModalOpen(false)
      }}
    >
      <div className="text-base font-medium leading-6 text-gray-700">
        By selecting [Shipment Party], you can manage cargo transportation for your
        business. <br /> Once you select this role, you will gain access to new features
        and functionalities related to cargo transportation.
      </div>
      <div className={'mt-3 flex w-full flex-row-reverse gap-5'}>
        <FButton
          type={'submit'}
          onClick={() => {
            addShipmentRole()
          }}
        >
          <FIconWrapper>
            <AiOutlinePlusCircle size={20} />
            Add Shipment Party Role
            <ESpinner isVisible={isLoading} />
          </FIconWrapper>
        </FButton>
        <FButton
          btnType={'secondary'}
          onClick={() => {
            setIsShipmentRoleModalOpen(false)
          }}
        >
          <FIconWrapper>Cancel</FIconWrapper>
        </FButton>
      </div>
    </FModal>
  )
}
