import React from 'react'
import FButton from '../../../components/FButton'
import { useNavigate } from 'react-router-dom'
import { BsCheckCircle } from 'react-icons/bs'

const SuccessStep = () => {
  const navigate = useNavigate()
  return (
    <>
      <div className="flex flex-col items-center justify-center ">
        <div className="h-60 w-full bg-primary-500">
          <span>
            <BsCheckCircle className="mx-auto mt-12 text-9xl text-white" />
          </span>
        </div>
        <h2 className="mt-5 text-center text-2xl font-semibold text-gray-700">
          Application Submitted Successfully !
        </h2>

        <p className="mt-2  p-3 text-center text-gray-500">
          Your application has been successfully submitted. Please expect to receive an
          email within 5-7 days regarding the status of your application. Thank you!{' '}
        </p>
        <div className="mt-5 flex w-1/4  justify-center p-5 ">
          {' '}
          <FButton className={'w-full'} onClick={() => navigate('/')}>
            Ok
          </FButton>
        </div>
      </div>
    </>
  )
}

export default SuccessStep
