import PropTypes from 'prop-types'

const FButton = ({ children, className, onClick, type, btnType, ...props }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={` ${className}  ${
        btnType === 'danger'
          ? 'bg-red-500 text-white disabled:bg-red-300 hover:bg-red-600 '
          : btnType === 'success'
          ? 'bg-green-500 text-white disabled:bg-green-300  '
          : btnType === 'secondary'
          ? 'border border-gray-300 bg-gray-100 text-gray-500 hover:bg-gray-200 disabled:bg-gray-300'
          : 'border border-primary-600 bg-primary-500 text-white ring-primary-500 hover:bg-primary-600 focus:ring-2 focus:ring-offset-2 disabled:border-primary-100 disabled:bg-primary-300'
      } 
                       flex justify-center py-2 px-3 text-sm`}
      {...props}
    >
      {children}
    </button>
  )
}
export default FButton

FButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  btnType: PropTypes.string,
  type: PropTypes.string,
}

FButton.defaultProps = {
  type: 'button',
}
