import React from 'react'
import FIconWrapper from '../../components/FIconWrapper'
import { GoChevronLeft } from 'react-icons/go'
import { Link, useParams, useNavigate } from 'react-router-dom'
import FCard from '../../components/FCard'
import FButton from '../../components/FButton'
import RejectionModal from './RejectionModal'
import { useQuery } from '@tanstack/react-query'
import { axiosInstance } from '../../axios/requister'
import ESpinner from '../../components/ESpinner'
import { toast } from 'react-toastify'

const AdminTicketDetails = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const label = 'block text-lg  text-gray-700 font-semibold my-auto'
  const info = 'block text-gray-500 my-auto ml-2'

  const { data, status } = useQuery(['ticket', id], async () => {
    const response = await axiosInstance.get(`support/retrieve-ticket/${id}/`)
    return response.data
  })
  const handleTicketApprove = () => {
    setLoading(true)
    axiosInstance
      .put(`support/handle-ticket/${id}/`, {
        action: 'approve',
      })
      .then(() => {
        setLoading(false)
        toast.success('Ticket approved successfully')
        navigate('/tickets')
      })
      .catch(() => {
        setLoading(false)
        toast.error('Ticket approved field')
      })
  }

  if (status === 'loading') {
    return (
      <div className={'f-col-center-center h-full w-full'}>
        <ESpinner isVisible={true} size={30} />
        <span className="mt-5">Loading...</span>
      </div>
    )
  } else if (status === 'error') {
    return <div>Error fetching data</div>
  } else {
    return (
      <>
        <RejectionModal open={open} handleClose={handleClose} id={id} />
        <div
          className={
            'sticky top-0 z-20  flex w-full  justify-between border-b bg-white p-3 ' +
            'text-left text-xl font-semibold text-gray-700'
          }
        >
          <FIconWrapper>
            <Link
              to={'/tickets'}
              className={'hove:border rounded p-0.5 hover:bg-gray-100'}
            >
              <GoChevronLeft size={25} />
            </Link>
            <span className={''}>
              Ticket information{' '}
              <span className="text-base font-normal text-gray-500 ">
                (Company Admin Request){' '}
              </span>
            </span>
          </FIconWrapper>
        </div>

        <div className={'  mx-auto mt-5 p-5 lg:w-2/3 lg:p-2 '}>
          <FCard>
            <FCard.Header>
              <span className="px-5">Manager Personal information</span>
            </FCard.Header>
            <FCard.Body>
              <div className="my-3 flex flex-col   px-5">
                <div className={'flex flex-col  lg:grid lg:grid-cols-2'}>
                  <div className={'flex py-1 '}>
                    <span className={label}> First name</span>
                    <span className={info}>{data?.first_name}</span>
                  </div>
                  <div className={'flex py-1'}>
                    <span className={label}> Last name</span>
                    <span className={info}> {data?.last_name}</span>
                  </div>
                </div>
                <div className={'flex flex-col  lg:grid lg:grid-cols-2'}>
                  <div className={'flex py-1'}>
                    <span className={label}> Email</span>
                    <span className={info}> {data?.email}</span>
                  </div>
                  <div className={'flex py-1'}>
                    <span className={label}> Phone Number</span>
                    <span className={info}> {data?.personal_phone_number}</span>
                  </div>
                </div>
                <div className={'flex flex-col  lg:grid lg:grid-cols-2'}>
                  <div className={'flex py-1'}>
                    <span className={label}> Profile Picture</span>
                    <span
                      className={
                        'my-auto ml-2 block cursor-pointer text-gray-500 underline'
                      }
                      onClick={() => window.open(data?.personal_photo.url, '_blank')}
                    >
                      personal.png
                    </span>
                  </div>
                  <div className={'flex  py-1'}>
                    <span className={label}> ID Picture </span>
                    <span
                      className={
                        'my-auto ml-2 block cursor-pointer text-gray-500 underline'
                      }
                      onClick={() => window.open(data?.sid_photo.url, '_blank')}
                    >
                      SId.png
                    </span>
                  </div>
                </div>
              </div>
            </FCard.Body>
          </FCard>
          <FCard>
            <FCard.Header>
              <span className="px-5">Company information</span>
            </FCard.Header>
            <FCard.Body>
              <div className="my-3 flex flex-col   px-5">
                <div className={'flex flex-col  lg:grid lg:grid-cols-2'}>
                  <div className={'flex py-1 '}>
                    <span className={label}> Company name</span>
                    <span className={info}>{data?.company_name}</span>
                  </div>
                  <div className={'flex py-1'}>
                    <span className={label}> Company domain</span>
                    <span className={info}> {data?.company_domain}</span>
                  </div>
                </div>
                <div className={'flex flex-col  lg:grid lg:grid-cols-2'}>
                  <div className={'flex py-1 '}>
                    <span className={label}> Company size</span>
                    <span className={info}> {data?.company_size}</span>
                  </div>
                  <div className={'flex py-1 '}>
                    <span className={label}> Company Address</span>
                    <span className={info}> {data?.address}</span>
                  </div>
                </div>
                <div className={'flex flex-col  lg:grid lg:grid-cols-2'}>
                  <div className={'flex py-1'}>
                    <span className={label}> City</span>
                    <span className={info}> {data?.city}</span>
                  </div>
                  <div className={'flex py-1 '}>
                    <span className={label}> State</span>
                    <span className={info}> {data?.state}</span>
                  </div>
                </div>
                <div className={'flex flex-col  lg:grid lg:grid-cols-2'}>
                  <div className={'flex py-1 '}>
                    <span className={label}> Country</span>
                    <span className={info}> {data?.country}</span>
                  </div>
                  <div className={'flex py-1'}>
                    <span className={label}> zip_code </span>
                    <span className={info}> {data?.zip_code}</span>
                  </div>
                </div>
                <div className={'flex flex-col  lg:grid lg:grid-cols-2'}>
                  <div className={'flex py-1 '}>
                    <span className={label}> EIN</span>
                    <span className={info}> {data?.EIN}</span>
                  </div>
                  <div className={'flex py-1'}>
                    <span className={label}>phone_number </span>
                    <span className={info}> {data?.company_phone_number}</span>
                  </div>
                </div>
              </div>
            </FCard.Body>
          </FCard>
          <FCard>
            <FCard.Header>
              <span className="px-5">Insurance information</span>
            </FCard.Header>
            <FCard.Body>
              <div className="my-3 flex flex-col   px-5">
                <div className={'flex flex-col  lg:grid lg:grid-cols-2'}>
                  <div className={'flex py-1 '}>
                    <span className={label}> Insurance provider</span>
                    <span className={info}>{data?.insurance_provider}</span>
                  </div>
                  <div className={'flex py-1'}>
                    <span className={label}> Insurance policy number</span>
                    <span className={info}> {data?.insurance_policy_number}</span>
                  </div>
                </div>
                <div className={'flex flex-col  lg:grid lg:grid-cols-2'}>
                  <div className={'flex py-1 '}>
                    <span className={label}> Insurance type</span>
                    <span className={info}> {data?.insurance_type}</span>
                  </div>
                  <div className={'flex py-1 '}>
                    <span className={label}> Insurance premium amount</span>
                    <span className={info}> {data?.insurance_premium_amount}</span>
                  </div>
                </div>
              </div>
            </FCard.Body>
          </FCard>
          {data?.status === 'Pending' && (
            <div className={'mx-auto mt-10 flex flex-row-reverse justify-center gap-10 '}>
              <FButton
                btnType={'success'}
                className={' w-40 '}
                onClick={handleTicketApprove}
              >
                <span className="px-1">Approve</span>
                <ESpinner isVisible={loading} />
              </FButton>
              <FButton onClick={handleOpen} btnType={'danger'} className={'  w-40'}>
                Reject
              </FButton>
            </div>
          )}
        </div>
      </>
    )
  }
}

export default AdminTicketDetails
