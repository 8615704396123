import LoadForm from "./LoadForm"
import useLoad from "./useLoad"
import { useFormik } from 'formik'
import {
    customValidation,
    getValidationSchema,
} from "../utils"
import { useContext, useEffect } from "react"
import { useCustomAxios } from "../../../Hooks/useAxios"
import { UserContext } from "../../../components/UserContext"
import { useNavigate } from "react-router-dom"

const CopyLoadForm = () => {
    const navigate = useNavigate()
    const {
        id,
        initialValues,
        loadInfo,
        setUnits,
        setIsExistingShipment,
        setSelectorKeyForPickup,
        setSelectorKeyForDrop,
        mapLoad,
        onSubmit,
        createDraft
    } = useLoad({ formType: 'copy' })

    const { user } = useContext(UserContext)

    const { response: load, loading } = useCustomAxios({
        url: `/shipment/load-details/${id}/`,
        method: 'GET',
    })

    useEffect(() => {
        setSelectorKeyForPickup(Math.random())
        setSelectorKeyForDrop(Math.random())
        //TODO: navigate to 403
        if (load) {
            if (load?.created_by !== user?.username) {
                navigate('/')
            }
        }
    }, [load, loading])


    const Formik = useFormik({
        initialValues: mapLoad({
            ...load,
            pick_up_date: null,
            delivery_date: null,
        }) ?? initialValues,
        onSubmit: (values) => onSubmit({
            ...values,
            id: undefined,
            name: undefined
        }),
        validationSchema: getValidationSchema(),
        validate: (values) => customValidation(values, loadInfo.isExistingShipment),
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
    })

    return (
        <LoadForm
            formType='copy'
            Formik={Formik}
            loadInfo={loadInfo}
            formTitle='Copy Load'
            setUnits={setUnits}
            setIsExistingShipment={setIsExistingShipment}
            setSelectorKeyForDrop={setSelectorKeyForDrop}
            setSelectorKeyForPickup={setSelectorKeyForPickup}
            primaryBtnTitle='Create'
            primaryLoading={loadInfo.creatLoadLoading}
            secondaryBtnTitle='Save As Draft'
            onClickSecondary={() => createDraft(Formik.values)}
            secondaryLoading={loadInfo.draftLoading}
            isLoading={loading}
            backLink={`/load-details/${id}`}
        />
    )
}

export default CopyLoadForm