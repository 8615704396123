import React from 'react'

export const usePersistedState = (name, defaultValue) => {
  const [value, setValue] = React.useState(() => {
    try {
      const storedValue = localStorage.getItem(name)
      return storedValue ? JSON.parse(storedValue) : defaultValue
    } catch {
      return defaultValue
    }
  })
  const nameRef = React.useRef(name)

  React.useEffect(() => {
    try {
      localStorage.setItem(nameRef.current, JSON.stringify(value))
    } catch {
      throw new Error('Could not set value')
    }
  }, [value])

  React.useEffect(() => {
    const lastName = nameRef.current
    if (name !== lastName) {
      try {
        localStorage.setItem(name, JSON.stringify(value))
        nameRef.current = name
        localStorage.removeItem(lastName)
      } catch {
        throw new Error('Could not change name')
      }
    }
  }, [name])

  return [value, setValue]
}
