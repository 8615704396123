import FButton from '../../components/FButton'
import React, { useState, useContext } from 'react'
import { AiOutlinePlusCircle, AiOutlineUsergroupAdd } from 'react-icons/ai'
import FIconWrapper from '../../components/FIconWrapper'
import { useCustomAxios } from '../../Hooks/useAxios'
import CreateContact from './CreateContact'
import InviteContactModal from './InviteContactModal'
import InvitationTab from './InvitationTab'
import ContactTab from './ContactTab'
import { UserContext } from '../../components/UserContext'
import { useQuery } from '@tanstack/react-query'
import { axiosInstance } from '../../axios/requister'

const ContactsPage = () => {
  const [isCreateContactModalOpen, setIsCreateContactModalOpen] = useState(false)
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false)
  const [tab, setTab] = useState('Contact list')
  const [contactListpage, setContactListPage] = useState(1)
  const [inviteListpage, setInviteListPage] = useState(1)
  const [invitationsTabs, setInvitationsTabs] = useState('all')
  const { user } = useContext(UserContext)

  const { data, status, refetch } = useQuery(
    ['contact', contactListpage],
    async () => {
      if (user.selected_role === 'manager') {
        const response = await axiosInstance.get(
          `manager/contact/?page=${contactListpage}`
        )
        return response.data
      } else {
        const response = await axiosInstance.get(
          `shipment/contact/?page=${contactListpage}`
        )
        return response.data
      }
    },
    {
      keepPreviousData: true,
      retry: false,
      enabled: contactListpage > 0,
    }
  )

  const invitationList = useCustomAxios({
    method: 'GET',
    url: `invites/handle-invite/?page=${inviteListpage}&target=${invitationsTabs}
    `,
  })
  const inviteRequests = useCustomAxios({
    url: `invites/send-invite/`,
    method: 'GET',
  })

  // inviteRequests.loading = false and inviteRequests.Refetch() not working

  return (
    <div>
      <CreateContact
        setIsCreateContactModalOpen={setIsCreateContactModalOpen}
        isCreateContactModalOpen={isCreateContactModalOpen}
        callback={() => {
          refetch()
        }}
      />
      <InviteContactModal
        setIsInviteModalOpen={setIsInviteModalOpen}
        isInviteModalOpen={isInviteModalOpen}
        RefetchTable={() => {
          invitationList.refetch()
        }}
      />

      <div
        className={
          ' sticky top-0  z-10 flex w-full flex-col  justify-between border-b bg-white p-3 lg:flex-row ' +
          'text-left text-xl font-semibold text-gray-700'
        }
      >
        <span className={''}>Contacts</span>
        {user.selected_role !== 'manager' && (
          <div className="mt-3 flex gap-3 lg:mt-0">
            <FButton onClick={() => setIsCreateContactModalOpen(true)}>
              <FIconWrapper>
                <AiOutlinePlusCircle size={20} />
                <span className={'text-sm font-medium'}>Add Contact</span>
              </FIconWrapper>
            </FButton>

            <FButton onClick={() => setIsInviteModalOpen(true)}>
              <FIconWrapper>
                <AiOutlineUsergroupAdd size={20} />
                <span className={'text-sm font-medium'}>Invite Contact</span>
              </FIconWrapper>
            </FButton>
          </div>
        )}
      </div>
      <div
        className={
          'primary-shadow  z-10   flex w-full justify-between gap-2 border-b bg-white px-2'
        }
      >
        <div className=" flex">
          <div
            onClick={() => setTab('Contact list')}
            className={` ${
              tab === 'Contact list' && ' border-b-4'
            } cursor-pointer border-primary-500  p-2  `}
          >
            <span className={'text-sm font-semibold  text-gray-700'}>{'Contacts'}</span>
          </div>
          {user.selected_role !== 'manager' && (
            <div
              onClick={() => setTab('Invitations')}
              className={` ${
                tab === 'Invitations' && ' border-b-4'
              } cursor-pointer border-primary-500  p-2 `}
            >
              <span className={'text-sm font-semibold  text-gray-700'}>{'Invites'}</span>
            </div>
          )}
        </div>
      </div>

      {tab === 'Contact list' && (
        <>
          <ContactTab
            response={data}
            Refetch={refetch}
            loading={status === 'loading'}
            contactListpage={contactListpage}
            setContactListPage={setContactListPage}
            inviteRequests={inviteRequests}
            RefetchRequests={() => {
              inviteRequests.Refetch()
            }}
          />
        </>
      )}
      {tab === 'Invitations' && (
        <InvitationTab
          invitationList={invitationList}
          inviteListpage={inviteListpage}
          setInviteListPage={setInviteListPage}
          invitationsTabs={invitationsTabs}
          setInvitationsTabs={setInvitationsTabs}
        />
      )}
    </div>
  )
}

export default ContactsPage
