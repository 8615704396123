import Status from '../../components/Status'
import { CgNotes } from 'react-icons/cg'
import FAvatar from '../../components/FAvatar'
import { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { axiosInstance } from '../../axios/requister'
import TimeAgo from '../../components/TimeAgo'
import { UserContext } from '../../components/UserContext'
import { Tooltip } from '@mui/material'
import PaginationControls from '../../components/PaginationControls'
import ESpinner from '../../components/ESpinner'

const Tickets = () => {
  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const { user } = useContext(UserContext)

  const getNextPage = () => setPage((prevPage) => prevPage + 1)
  const getPreviousPage = () => setPage((prevPage) => prevPage - 1)

  useEffect(() => {
    if (user.selected_role !== 'support') {
      navigate('/dashboard')
    }
  }, [user.selected_role])

  const { data, status } = useQuery(
    ['tickets', page],
    async () => {
      const response = await axiosInstance.get(`support/list-ticket/?page=${page}`)
      setCount(response.data.count)
      return response.data
    },
    {
      keepPreviousData: true,
      retry: false,
    }
  )

  const totalNumberOfPages = Math.ceil(count / 5)

  return (
    <>
      {/* Search Bar */}
      <div className="mx-auto py-16 w-11/12 h-full">
        {status === 'loading' && (
          <div className=" mx-auto flex h-full w-full flex-col items-center justify-center">
            <ESpinner isVisible size={20} />
            <p className="text-gray-500">Loading...</p>
          </div>
        )}
        {status === 'success' && !!(data?.results.length) ? (
          <div className="flex flex-col gap-10">
            {data?.results.map((ticket) => (
              <div
                key={ticket.id}
                className="xl:s flex flex-col cursor-pointer border bg-white p-5 hover:border-orange-500
                hover:bg-gray-100 hover:transition-all hover:duration-500
                items-start gap-5 shadow-sm xl:flex-row xl:items-start"
                onClick={() => {
                  navigate(`/admin-ticket-details/${ticket.id}`)
                }}
              >
                <div className="my-auto flex lg:w-4/12 ">
                  <div>
                    <CgNotes className="mt-2 hidden text-3xl  text-gray-800 lg:block" />
                  </div>{' '}
                  <div className="flex flex-col pl-2">
                    <Tooltip title={'company name'} placement={'top'} arrow>
                      <div className="whitespace-nowrap text-lg font-medium text-gray-800">
                        Manager Request For {' ' + ticket.company_name}
                      </div>
                    </Tooltip>
                    <div className="text-base  text-gray-500">Company Admin Request</div>
                  </div>
                </div>
                <div className=" flex gap-2  lg:w-3/12 lg:justify-start ">
                  <Tooltip title={'manager email'} placement={'top'} arrow>
                    <div className="my-auto">
                      <FAvatar width={35} name={ticket.email} />
                    </div>
                  </Tooltip>
                  <div className="my-auto font-medium text-gray-800">{ticket.email}</div>
                </div>

                <div className="my-auto  font-medium text-gray-800 lg:flex lg:w-3/12 xl:justify-center">
                  <TimeAgo timestamp={ticket.created_at} />
                </div>
                <div className="my-auto lg:flex lg:w-2/12 xl:justify-end ">
                  <Status statusName={ticket.status} />
                </div>
              </div>
            ))}

            {/* Pagination */}
            <PaginationControls
              page={page}
              getNextPage={getNextPage}
              getPreviousPage={getPreviousPage}
              totalNumberOfPages={totalNumberOfPages}
            />

          </div>
        ) : (
          <div className="mx-auto flex h-full w-full flex-col items-center justify-center">
            <p className="text-xl text-gray-500">No tickets were submitted yet.</p>
          </div>
        )}
      </div>
    </>
  )
}

export default Tickets
