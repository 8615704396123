import { NavLink } from 'react-router-dom'
const FNavLink = ({ to, children, isCollapse }) => {
  return (
    <>
      <NavLink
        to={to}
        className={(navData) =>
          `flex items-center  justify-start gap-2 py-3 hover:bg-gray-200 hover:text-gray-800 ${
            isCollapse ? 'px-2' : 'px-3'
          }  text-xs ` +
          (navData.isActive
            ? 'border-l-4 border-primary-500 bg-gray-100 text-primary-500'
            : 'text-gray-500 ')
        }
      >
        {children}
      </NavLink>
    </>
  )
}
export default FNavLink
