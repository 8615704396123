import PropTypes from 'prop-types'

const FInputField = ({inputRef, ...props}) => {
  return (
    <>
      <input ref={inputRef} className={'input-style'} {...props} />
    </>
  )
}
export default FInputField

FInputField.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  type: PropTypes.string,
  placeholder: PropTypes.string,
}
