import React from 'react'
import FCard from '../../components/FCard'
import FFormWrapper from '../../components/FFormWrapper'
import FLabel from '../../components/FLabel'
import FFormInvalidInput from '../../components/FFormInvalidInput'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { trancuateString } from '../Load/utils'

function PersonalInfoCard({ Formik }) {
  return (
    <FCard>
      <FCard.Header>
        <span>Personal Information</span>
      </FCard.Header>
      <FCard.Body>
        <div className="justify-between gap-5 lg:flex">
          <FFormWrapper>
            <FLabel htmlFor={'sid_photo'}>Upload your SID </FLabel>
            <div className="text-sm font-normal text-gray-400">
              Upload a clear picture of your State ID
            </div>
            <label
              className=" flex w-52 cursor-pointer  gap-2 bg-gray-200 p-2 text-base font-medium text-gray-900
              hover:bg-gray-100
              "
            >
              <span className=" my-auto flex gap-2">
                <AiOutlineCloudUpload size={25} />
                {'Upload your SID'}
              </span>
              <input
                type="file"
                id="sid_photo"
                name="sid_photo"
                accept="image/png, image/jpeg"
                onBlur={Formik.handleBlur}
                multiple={false}
                className="hidden"
                onChange={(e) => {
                  Formik.setFieldValue('sid_photo', e.target.files)
                }}
              />
            </label>
            {Formik.values.sid_photo && Formik.values.sid_photo.length > 0 && (
              <a
                href={Formik.values.sid_photo[0]?.url}
                target="_blank"
                rel="noreferrer"
                className="text-primary-500 hover:underline"
              >
                {trancuateString(Formik.values.sid_photo[0]?.name)}
              </a>
            )}

            <FFormInvalidInput
              touched={Formik.touched}
              FieldName="sid_photo"
              errors={Formik.errors}
            />
          </FFormWrapper>
          <FFormWrapper className="w-full lg:w-1/2">
            <FLabel htmlFor={'personal_photo'}>Upload your picture </FLabel>
            <div className="text-sm font-normal text-gray-400">
              Upload a clear picture of yourself
            </div>
            <label
              className=" flex w-52 cursor-pointer  gap-2 bg-gray-200 p-2 text-base font-medium text-gray-900
              hover:bg-gray-100
              "
            >
              <span className=" my-auto flex gap-2">
                <AiOutlineCloudUpload size={25} />
                {'Upload your picture'}
              </span>
              <input
                type="file"
                id="personal_photo"
                name="personal_photo"
                accept="image/png, image/jpeg"
                onBlur={Formik.handleBlur}
                multiple={false}
                className="hidden"
                onChange={(e) => {
                  Formik.setFieldValue('personal_photo', e.target.files)
                }}
              />
            </label>
            {Formik.values.personal_photo && Formik.values.personal_photo.length > 0 && (
              <a
                href={Formik.values.personal_photo[0]?.url}
                target="_blank"
                rel="noreferrer"
                className="text-primary-500 hover:underline"
              >
                {trancuateString(Formik.values.personal_photo[0]?.name)}
              </a>
            )}
            <FFormInvalidInput
              touched={Formik.touched}
              FieldName="personal_photo"
              errors={Formik.errors}
            />
          </FFormWrapper>
        </div>
      </FCard.Body>
    </FCard>
  )
}

export default PersonalInfoCard
