import PasswordStrengthIndicatorItem from './PasswordStrengthIndicatorItem'
function PasswordStrengthIndicator(props) {
  return (
    <div>
      <PasswordStrengthIndicatorItem
        text="Password must be 8 characters or more"
        isValid={props.passwordValidity.length}
      />
      <PasswordStrengthIndicatorItem
        text="Password must contain capital letters"
        isValid={props.passwordValidity.capitalLetters}
      />
      <PasswordStrengthIndicatorItem
        text="Password must contain a number"
        isValid={props.passwordValidity.number}
      />
      <PasswordStrengthIndicatorItem
        text="Password must contain a symbol"
        isValid={props.passwordValidity.symbol}
      />
    </div>
  )
}

export default PasswordStrengthIndicator
