import React, { useState, useContext } from 'react'
import FIconWrapper from '../../components/FIconWrapper'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import FButton from '../../components/FButton'
import CreateInsurance from './CreateInsurance'
import { useQuery } from '@tanstack/react-query'
import { axiosInstance } from '../../axios/requister'
import ESpinner from '../../components/ESpinner'
import { useCustomAxios } from '../../Hooks/useAxios'
import CustomPagination from '../../components/CustomPaginationStyle'
import { UserContext } from '../../components/UserContext'
import InsuranceList from './InsuranceList'

export default function InsuranceTab() {
  const [isCreateInsuranceModalOpen, setIsCreateInsuranceModalOpen] = useState(false)
  const [selectedInsurance, setSelectedInsurance] = useState(null)
  const { user } = useContext(UserContext)
  const [page, setPage] = useState(1)

  const { response, loading } = useCustomAxios({
    url: 'authentication/company/',
    method: 'GET',
  })

  const deleteInsurance = async (id) => {
    try {
      await axiosInstance.delete(`/insurance/${id}/`)
      await refetch()
    } catch (error) {
      console.error(error)
    }
  }
  const handleEditInsurance = (insurance) => {
    setSelectedInsurance(insurance)
    setIsCreateInsuranceModalOpen(true)
  }

  const {
    data: insuranceData,
    status,
    refetch,
  } = useQuery(
    [' insuranceData', page],
    async () => {
      const response = await axiosInstance.get(`/insurance/?page=${page}`)
      return response.data
    },
    {
      keepPreviousData: true,
      retry: false,
      enabled: true,
    }
  )
  const totalNumberOfPages = Math.ceil(insuranceData?.count / 5) || 0

  const handlePageChange = (e, value) => {
    setPage(value)
  }

  const InsurancesHeader = () => {
    return (
      <>
        <CreateInsurance
          setIsCreateInsuranceModalOpen={setIsCreateInsuranceModalOpen}
          isCreateInsuranceModalOpen={isCreateInsuranceModalOpen}
          refetch={refetch}
          selectedInsurance={selectedInsurance}
        />
        <div
          className={
            'sticky top-0 z-10  flex w-full  justify-between border-b bg-white p-3 ' +
            'text-left text-xl font-semibold text-gray-700'
          }
        >
          <FIconWrapper>
            <span className={''}>Insurances</span>
          </FIconWrapper>
          <FButton
            onClick={() => {
              setIsCreateInsuranceModalOpen(true)
              setSelectedInsurance(null)
            }}
          >
            <FIconWrapper>
              <AiOutlinePlusCircle size={20} />
              <span className={'text-sm font-medium'}>Create New Insurance</span>
            </FIconWrapper>
          </FButton>
        </div>
      </>
    )
  }

  if (status === 'loading' || loading) {
    return (
      <div>
        <div className={'mt-60  flex w-full items-center justify-center '}>
          <div className={'f-col-center-center'}>
            <ESpinner isVisible={true} size={30} />
            <span className="mt-5">Loading...</span>
          </div>
        </div>
      </div>
    )
  } else if (user?.selected_role === 'manager' && response === undefined) {
    return (
      <div className="flex h-full flex-col justify-between">
        <div className={'my-auto flex flex-col items-center justify-center'}>
          <img
            src="https://storage.googleapis.com/freight_static_files/empty-state.svg"
            alt={'empty_state'}
            width={200}
            className={''}
          />
          <span className={'mt-5 font-semibold text-gray-700'}>No Insurances found</span>
          <span className={'mt-1 text-center text-sm text-gray-600 '}>
            {'You need to have a company profile before you can create an insurance.  '}
          </span>
        </div>
      </div>
    )
  } else if (insuranceData?.results?.length === 0) {
    return (
      <>
        <InsurancesHeader />
        <div className="flex h-full flex-col justify-between">
          <div className={' flex  flex-col items-center justify-center   py-40'}>
            <img
              src="https://storage.googleapis.com/freight_static_files/empty-state.svg"
              alt={'empty_state'}
              width={200}
              className={''}
            />
            <span className={'mt-5 font-semibold text-gray-700'}>
              No Insurances found
            </span>
            <span className={'mt-1 text-center text-sm text-gray-600 '}>
              {
                'Create a new insurance by clicking on the "Create New Insurance" button above.  '
              }
            </span>
          </div>
        </div>
      </>
    )
  } else {
    return (
      <div>
        <InsurancesHeader />
        <InsuranceList
          insuranceData={insuranceData}
          deleteInsurance={deleteInsurance}
          handleEditInsurance={handleEditInsurance}
        />
        {insuranceData?.results?.length > 0 && (
          <div className={'flex justify-center mt-5'}>
           <CustomPagination
           count={totalNumberOfPages}
           page={page}
           onChange={handlePageChange}
           color="primary"
           size='large'
         />
          </div>
        )}
      </div>
    )
  }
}
