/* eslint-disable indent */
import { useState } from 'react'
import FButton from '../../components/FButton'
import FInputField from '../../components/FInputField'
import FLabel from '../../components/FLabel'
import { SITE_LOGO } from '../../env'
import { PasswordIcon } from './components/PasswordIcon'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { axiosInstance } from '../../axios/requister'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ESpinner from './../../components/ESpinner'
import { useParams, useNavigate } from 'react-router-dom'

const ResetPassword = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const Navigate = useNavigate()
  const showPassword = () => setIsPasswordVisible(!isPasswordVisible)
  const showConfirmation = () => setIsConfirmationVisible(!isConfirmationVisible)
  const { uid, token } = useParams()

  const formik = useFormik({
    initialValues: { password: '', confirmPassword: '' },
    validationSchema: Yup.object({
      password: Yup.string()
        .required('Password is required.')
        .matches('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{4,})')
        .min(8, 'Password must be at least 8 characters long'),
      confirmPassword: Yup.string()
        .required('Password is required.')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    }),
    onSubmit: (values) => {
      setIsLoading(true)
      axiosInstance
        .post(`authentication/password/reset/confirm/${uid}/${token}`, {
          uid: uid,
          token: token,
          new_password1: values.password,
          new_password2: values.confirmPassword,
        })
        .then(() => {
          toast.success('Password reset successful')
          setIsLoading(false)
          setTimeout(() => Navigate('/login'), 2000)
        })
        .catch(() => {
          toast.error('Password reset failed')
          setIsLoading(false)
        })
    },
  })
  return (
    <div className="f-col-center-center mt-40 w-full ">
      <img src={SITE_LOGO} width={400} className="mb-10" />
      <span className="mb-5 block text-center text-xl font-bold text-gray-900">
        Reset Password
      </span>

      <form className={'w-full p-5 lg:w-5/12'} onSubmit={formik.handleSubmit}>
        <FLabel>New Password</FLabel>
        <div className="relative mb-3 flex flex-col">
          <FInputField
            name="password"
            type={isPasswordVisible ? 'text' : 'password'}
            placeholder="Enter your password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <PasswordIcon isVisible={isPasswordVisible} showPassword={showPassword} />
          {formik.touched.password && formik.errors.password && (
            <span className="mt-1 select-none text-sm text-red-600">
              {formik.errors.password}
            </span>
          )}
        </div>
        <FLabel>Confirm Password</FLabel>
        <div className="relative mb-3 flex flex-col">
          <FInputField
            name="confirmPassword"
            type={isConfirmationVisible ? 'text' : 'password'}
            placeholder="Enter your password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <PasswordIcon
            isVisible={isConfirmationVisible}
            showPassword={showConfirmation}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <span className="mt-1 select-none text-sm text-red-600">
              {formik.errors.confirmPassword}
            </span>
          )}
        </div>
        <FButton
          className="mt-5 w-full gap-2"
          type="submit"
          disabled={
            formik.errors.password ||
            formik.errors.confirmPassword ||
            formik.values.confirmPassword === '' ||
            formik.values.password === ''
          }
        >
          <ESpinner isVisible={isLoading} />
          Reset Password
        </FButton>
      </form>
      <ToastContainer position="bottom-right" />
    </div>
  )
}
export default ResetPassword
