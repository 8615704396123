import { useState } from 'react'
import { HiOutlineX } from 'react-icons/hi'
import { FiAlertCircle } from 'react-icons/fi'

function Alert(props) {
  const [isAlertVisible, setIsAlertVisible] = useState(true)

  const handleClick = () => {
    setIsAlertVisible(false)
  }

  return (
    <>
      {isAlertVisible && (
        <div
          className={`flex flex-row items-start p-5 text-sm font-semibold leading-5 m-3${
            props.type === 'alert'
              ? ' border border-gray-600 bg-gray-100 text-gray-600'
              : props.type === 'pending'
              ? ' border border-yellow-600 bg-yellow-100 text-yellow-600'
              : ' border border-red-600 bg-red-100 text-red-600'
          }`}
          style={{ position: 'relative' }}
        >
          <div
            className="absolute -top-1.5 right-1/4 h-4 w-4 -translate-x-1/2 rotate-45 transform border-t  border-l border-gray-600 bg-gray-100 text-gray-600"
            style={{ marginTop: '-2px' }}
          />
          <div className="flex">
            <span className="mr-2 text-xl">
              <FiAlertCircle size={22} />
            </span>
            <span className="text-left">{props.message}</span>
          </div>
          <div className="text-xl">
            <HiOutlineX
              onClick={handleClick}
              className="ml-2 cursor-pointer text-gray-700"
            />
          </div>
        </div>
      )}
    </>
  )
}

export default Alert
