import FModal from '../../../components/FModal'
import React, { useState } from 'react'
import FFormWrapper from '../../../components/FFormWrapper'
import FLabel from '../../../components/FLabel'
import FButton from '../../../components/FButton'
import { axiosInstance } from '../../../axios/requister'
import { toast } from 'react-toastify'
import ESpinner from '../../../components/ESpinner'

const CounterModal = ({
  isCounterModalOpen,
  setIsCounterModalOpen,
  Refetch,
  BidId,
  startBidInfo,
}) => {
  const [counterAmount, setCounterAmount] = useState('')
  const [error, setError] = useState('')
  const [isLoaded, setIsLoaded] = useState(false)

  const handleChange = (event) => {
    setError('')
    const value = event.target.value
    const formattedValue = value.replace(/\D/g, '')

    setCounterAmount(formattedValue)
  }

  const handleBlur = () => {
    if (counterAmount === '' || parseFloat(counterAmount) <= 0 || counterAmount === 0) {
      setError('This field is required and should be positive')
    } else {
      setError('')
    }
  }
  const handleSubmit = () => {
    if (counterAmount === '' || parseFloat(counterAmount) <= 0 || counterAmount === 0) {
      setError('This field is required and should be positive')
    } else {
      setIsLoaded(true)
      axiosInstance
        .put(`/shipment/offer/${BidId}/`, {
          current: parseInt(counterAmount),
          action: 'counter',
        })
        .then(() => {
          setIsCounterModalOpen(false)
          setError('')
          Refetch()
          startBidInfo.Refetch()
          setIsLoaded(false)
          toast.success('Offer counter has been updated successfully')
        })
        .catch((err) => {
          setIsLoaded(false)
          setError(
            err.response.data.details ? err.response.data.details : 'Offer counter failed'
          )
        })
    }
  }

  return (
    <>
      <FModal
        callbackFunction={() => {
          setCounterAmount('')
          setError('')
        }}
        title={' $ Counter PRICE '}
        isOpen={isCounterModalOpen}
        setIsOpen={setIsCounterModalOpen}
      >
        <div>
          <FFormWrapper className="mx-auto w-full">
            <FLabel htmlFor="bid">Enter your Counter bid amount </FLabel>
            <div className="flex">
              <span className="my-auto pr-2 text-xl text-primary-500">$</span>
              <input
                maxLength={'6'}
                type="text"
                value={counterAmount}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter your offer"
                className="focus:shadow-outline-blue block w-full appearance-none rounded-md bg-white py-2 px-4 leading-normal text-gray-700 focus:border-blue-300 focus:outline-none"
              />
            </div>
            {error && <small className="text-red-700">{error}</small>}
          </FFormWrapper>
          <FButton
            className={'mx-auto mt-5 w-4/12 gap-2'}
            type={'submit'}
            onClick={() => {
              handleSubmit()
            }}
          >
            Send Bid Counter
            <ESpinner isVisible={isLoaded} />
          </FButton>
        </div>
      </FModal>
    </>
  )
}
export default CounterModal
