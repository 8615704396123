import React, { useState, useContext, useEffect } from 'react'
import ESpinner from '../../components/ESpinner'
import { FaUser } from 'react-icons/fa'
import { AiOutlineSearch } from 'react-icons/ai'
import { useNavigate, Link } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import CompanyInfoSection from './CompanyInfoSection'
import { HiOutlineUsers } from 'react-icons/hi'
import { axiosInstance } from '../../axios/requister'
import { UserContext } from '../../components/UserContext'
import FButton from '../../components/FButton'
import FFormWrapper from '../../components/FFormWrapper'
import CustomPagination from '../../components/CustomPaginationStyle'
export default function CompanyProfile() {
  const [searchQuery, setSearchQuery] = useState('')
  const navigate = useNavigate()
  const { user } = useContext(UserContext)
  const [statusResponse, setStatusResponse] = useState('')
  const [ticketStatusLoading, setTicketStatusLoading] = useState(true)
  const [page, setPage] = useState(1)

  const { data, status } = useQuery(
    ['company'],
    async () => {
      const response = await axiosInstance.get(`authentication/company/`)
      return response.data
    },
    {
      keepPreviousData: true,
      retry: false,
    }
  )

  const userRole = user?.selected_role.toLowerCase()

  const { data: employees, status: employeesStatus } = useQuery(
    ['employees', data?.EIN, page],
    async () => {
      const response = await axiosInstance.get(
        `/authentication/company-employee/?ein=${data?.EIN}&page=${page}`
      )
      return response.data
    },
    {
      keepPreviousData: true,
      retry: false,
    }
  )
  console.log('employees', employees?.results?.employees)
  const handlePageChange = (e, value) => {
    setPage(value)
  }

  //useEffect to handle ticket status
  useEffect(() => {
    // Check if there is a draft available
    setTicketStatusLoading(true)
    axiosInstance
      .get('/support/ticket/status/ ')
      .then((response) => {
        setStatusResponse(response.data)
        setTicketStatusLoading(false)
      })
      .catch(() => {
        setTicketStatusLoading(false)
      })
  }, [])

  const totalNumberOfPages = Math.ceil(employees?.count / 5) || 0

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value)
  }

  const filteredEmployees = employees?.results?.employees.filter((employee) =>
    employee.username.toLowerCase().includes(searchQuery.toLowerCase())
  )
  function formatPhoneNumber(phoneNumber) {
    if (!phoneNumber) return '' // Return empty string if phoneNumber is undefined or null

    return phoneNumber.replace(/^(\+\d{1})(\d{3})(\d{3})(\d{4})$/, '($2) $3-$4')
  }

  if (data === undefined && status === 'error') {
    if (userRole === 'manager') {
      return (
        <div className="flex h-full flex-col justify-between">
          <div className="my-auto flex flex-col items-center justify-center">
            <img
              src="https://storage.googleapis.com/freight_static_files/empty-state.svg"
              alt={'empty_state'}
              width={200}
              className={''}
            />
            <span className="mt-5 font-semibold text-gray-700">
              No Data Available Yet
            </span>

            {statusResponse?.has_ticket === true &&
              statusResponse?.ticket_status?.toLowerCase() === 'pending' && (
                <FFormWrapper className={'mt-5 w-fit'}>
                  <span className="text-center text-sm text-gray-600">
                    Your company creation request is pending, please wait for the support
                    team to respond
                  </span>
                </FFormWrapper>
              )}

            {statusResponse?.ticket_status?.toLowerCase() === 'rejected' && (
              <FFormWrapper className={'mt-5 w-fit'}>
                <span className="text-center text-sm text-gray-600">
                  Your company creation request has been rejected, please try again to
                  create a company
                </span>
              </FFormWrapper>
            )}
            {statusResponse?.has_ticket === false && (
              <>
                <span className="mt-1 text-center text-sm text-gray-600">
                  {statusResponse?.has_ticket_draft
                    ? 'You have a draft for company creation, you can edit it or create a new company'
                    : 'You have no company created yet click the button below to create a company'}
                </span>
                <FFormWrapper className={'mt-5 w-fit'}>
                  <Link to={'/company/create'}>
                    <FButton>
                      {statusResponse?.has_ticket_draft
                        ? 'Edit Company from last draft'
                        : 'Create Company'}
                    </FButton>
                  </Link>
                </FFormWrapper>
              </>
            )}
          </div>
        </div>
      )
    } else {
      return <>{navigate('/pagenotfound')}</>
    }
  } else {
    return (
      <>
        <div
          className={
            ' sticky top-0  z-10 flex  w-full justify-between border-b bg-white p-3 ' +
            'text-left text-xl font-semibold text-gray-700'
          }
        >
          <span className={''}>Company</span>
          <div></div>
        </div>
        {status === 'loading' ||
        employeesStatus === 'loading' ||
        ticketStatusLoading === true ? (
          <div className={'f-col-center-center h-full w-full'}>
            <ESpinner isVisible={true} size={30} />
            <span>Loading...</span>
          </div>
        ) : (
          <>
            <CompanyInfoSection data={data} />

            <div className="mx-auto mt-5 mb-3 flex w-[95%] gap-2 py-3">
              <div className=" font-semibold text-gray-700 ">
                <HiOutlineUsers size={28} />
              </div>
              <div className=" flex flex-col text-2xl font-semibold text-gray-700">
                Company Employees
                <div className="flex ">
                  <div className=" my-auto text-sm font-normal text-gray-700">
                    Num. of employees :{' '}
                  </div>
                  <div className=" ml-1 text-lg font-semibold text-gray-500">
                    {employees?.count || 0}
                  </div>
                </div>
              </div>
            </div>

            <div className="mx-auto mb-2 flex w-[95%]   overflow-hidden">
              <div className="relative w-full ">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <AiOutlineSearch className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  className="block  w-full rounded-sm border border-gray-300 py-2  pl-10 text-sm text-gray-800 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 "
                  type="text"
                  name="search"
                  placeholder="Search by username"
                  value={searchQuery}
                  onChange={handleSearchQueryChange}
                />
              </div>
            </div>

            <div className="mx-auto flex w-[95%] overflow-x-auto">
              <table className="scrollbar  scrollbar-hl-gray-300  scrollbar-thumb-gray-500 w-full table-auto ">
                <thead className="text-base font-semibold text-gray-700">
                  <tr className="  border border-gray-200 bg-gray-50   ">
                    <th className="py-2 px-4 text-left ">USERNAME</th>
                    <th className="py-2 px-4 text-left ">EMAIL ADDRESS</th>
                    <th className="whitespace-nowrap py-2 px-4 text-left ">
                      PHONE NUMBER
                    </th>
                    <th className="py-2 px-4 text-left">ROLE(S)</th>
                  </tr>
                </thead>

                {filteredEmployees?.map((employee) => (
                  <tbody key={employee.id} className=" text-left text-sm">
                    <tr className=" border border-b bg-white">
                      <td className=" flex whitespace-nowrap px-4 py-4 font-semibold text-gray-500">
                        {' '}
                        <FaUser className="mr-2" />
                        {employee.username}
                      </td>
                      <td className=" px-4 py-2 font-semibold text-gray-500">
                        {employee.email}
                      </td>

                      <td className=" flex whitespace-nowrap px-4 py-4 font-semibold text-gray-500">
                        {formatPhoneNumber(employee.phone_number)}
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 font-semibold text-gray-500">
                        {employee.user_type.split('-').map((role, index) => {
                          const trimmedRole = role.trim()
                          let element

                          if (trimmedRole === 'dispatcher') {
                            element = (
                              <span
                                key={index}
                                className="mr-2 border border-green-300 bg-green-100 px-2  py-1 font-semibold text-green-600"
                              >
                                {trimmedRole}
                              </span>
                            )
                          } else if (trimmedRole === 'shipment party') {
                            element = (
                              <span
                                key={index}
                                className="mr-2 border border-blue-300 bg-blue-100 px-2  py-1 font-semibold text-blue-600"
                              >
                                {trimmedRole}
                              </span>
                            )
                          } else if (trimmedRole === 'carrier') {
                            element = (
                              <span
                                key={index}
                                className="mr-2 border border-yellow-300 bg-yellow-100 px-2  py-1 font-semibold text-yellow-600"
                              >
                                {trimmedRole}
                              </span>
                            )
                          } else {
                            element = <span key={index}>{trimmedRole}</span>
                          }

                          return element
                        })}
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
            {searchQuery !== '' && filteredEmployees.length === 0 && (
              <div className="mx-auto mt-10  flex justify-center text-lg font-semibold text-gray-500">
                No Search Results Found
              </div>
            )}
            {employees?.count > 0 && (
              <div className="mx-auto mt-5 flex justify-center">
                <CustomPagination
                  count={totalNumberOfPages}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                  size="large"
                />
              </div>
            )}
          </>
        )}
      </>
    )
  }
}
