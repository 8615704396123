import FModal from '../../components/FModal'
import FFormWrapper from '../../components/FFormWrapper'
import FLabel from '../../components/FLabel'
import FInputField from '../../components/FInputField'
import FFormInvalidInput from '../../components/FFormInvalidInput'
import FButton from '../../components/FButton'
import FIconWrapper from '../../components/FIconWrapper'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { axiosInstance } from '../../axios/requister'
import { useState } from 'react'
import ESpinner from '../../components/ESpinner'
import { toast } from 'react-toastify'

const CreateContact = ({
  isCreateContactModalOpen,
  setIsCreateContactModalOpen,
  callback,
}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [Error, setError] = useState([])

  const Formik = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: Yup.object({
      code: Yup.string().required('Username is required'),
    }),
    onSubmit: (values) => {
      setIsLoaded(true)
      setError([])
      axiosInstance
        .post('/shipment/contact/', {
          contact: values.code,
        })
        .then(() => {
          setIsLoaded(false)
          setIsCreateContactModalOpen(false)

          toast.success('Contact created successfully')
          Formik.resetForm()

          callback()
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 403) {
              // Handle 403 error
              setError(['You cannot add carriers to your contact list'])
            } else if (err.response.status === 404) {
              // Handle 404 error
              setError(['User not found'])
            } else if (err.response.status === 400) {
              // Handle 400 error
              setError(['User already exists in your contact list'])
            } else {
              // Handle other errors
              Object.keys(err.response.data).map((key) => {
                setError([err.response.data[key]])
              })
            }
          } else {
            // Handle other errors
            setError(['Something went wrong'])
          }
          setIsLoaded(false)
        })
    },
  })
  return (
    <FModal
      callbackFunction={() => {
        Formik.resetForm()
      }}
      title={'Add Contact'}
      isOpen={isCreateContactModalOpen}
      setIsOpen={() => {
        setIsCreateContactModalOpen(false)
        setError([])
      }}
    >
      <form onSubmit={Formik.handleSubmit}>
        <FFormWrapper>
          <FLabel htmlFor="code">User name </FLabel>
          <FInputField
            type="text"
            name={'code'}
            id={'code'}
            placeholder={'Enter a username#00000'}
            onChange={Formik.handleChange}
            onBlur={Formik.handleBlur}
            value={Formik.values.code}
          />

          <FFormInvalidInput
            touched={Formik.touched}
            FieldName={'code'}
            errors={Formik.errors}
          />
          {Error.length > 0 && (
            <div>
              {Error.map((err, index) => (
                <small className={'text-left font-medium text-red-700'} key={index}>
                  {err}
                </small>
              ))}
            </div>
          )}
        </FFormWrapper>

        <div className={'flex w-full flex-row-reverse gap-1'}>
          <FButton type={'submit'} onClick={() => setIsCreateContactModalOpen(true)}>
            <FIconWrapper>
              <AiOutlinePlusCircle size={20} />
              Add Contact
              <ESpinner isVisible={isLoaded} />
            </FIconWrapper>
          </FButton>
          <FButton
            btnType={'secondary'}
            onClick={() => {
              Formik.resetForm()
              setIsCreateContactModalOpen(false)
              setError([])
            }}
          >
            <FIconWrapper>Cancel</FIconWrapper>
          </FButton>
        </div>
      </form>
    </FModal>
  )
}
export default CreateContact
