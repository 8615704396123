import { useState } from 'react'

const usePagination = () => {
    const [page, setPage] = useState(1)
    const getPreviousPage = () => setPage((page) => page - 1)
    const getNextPage = () => setPage((page) => page + 1)
    return {
        page,
        setPage,
        getPreviousPage,
        getNextPage
    }
}

export default usePagination