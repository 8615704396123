// CustomPagination.js
import { styled } from '@mui/system'
import Pagination from '@mui/material/Pagination'

const CustomPagination = styled(Pagination)({
  '& .MuiPaginationItem-root': {
    '&.Mui-selected': {
      backgroundColor: '#fb923c', // Custom color
      color: '#fff',
      '&:hover': {
        backgroundColor: '#f97316', // Custom hover color
      },
    },
  },
})

export default CustomPagination