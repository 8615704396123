// eslint-disable-next-line
import { Wrapper } from '@googlemaps/react-wrapper'

export const addSingleMarkers = ({
    locations,
    map,
}) =>
    locations.map(
        position =>
            new window.google.maps.Marker({
                position,
                map,
            }),
    )

const GoogleMapsWrapper = ({ children }) => {
    const apiKey = 'AIzaSyAo1pbLjr-At_wWTJUeKBflpW8LLaHzUMs' // dev

    if (!apiKey) {
        return <div>Cannot display the map: google maps api key missing</div>
    }

    return <Wrapper apiKey={apiKey} libraries={["places", "routes"]}>{children}</Wrapper>
}

export default GoogleMapsWrapper