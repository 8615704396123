import Tooltip from '@mui/material/Tooltip'
import { MdOutlineNoteAlt } from 'react-icons/md'
import { trancuateString } from '../utils'
import { useState } from 'react'
import AddNoteModal from './AddNoteModal'
import ActionMenu from './ActionMenu'

const LoadNote = ({
  note,
  LoadId,
  otherLoadParties,
  loadNoteRefetch,
  deletedLoadNotesRefetch,
  LoadDispatcher,
}) => {
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false)
  const [visibleTo, setVisibleTo] = useState(note.visible_to)

  return (
    <>
      {isNoteModalOpen && (
        <AddNoteModal
          isOpen={isNoteModalOpen}
          setIsOpen={setIsNoteModalOpen}
          LoadId={LoadId}
          note={note}
          otherLoadParties={otherLoadParties}
          callback={loadNoteRefetch}
        />
      )}

      <div key={note.id} className="flex flex-col gap-2">
        <div className="flex items-start justify-between gap-2">
          <div className=" flex items-end gap-2 ">
            <Tooltip
              title={
                <div className="text-sm">
                  {visibleTo?.length !== 0 ? (
                    <>
                      {visibleTo?.map((party) => party.name || party.username).join(`, `)} 
                      
                      <br />
                      can see this note
                    </>
                  ) : (
                    'Only you can see this note'
                  )}
                </div>
              }
              arrow
              placement="top"
            >
              <div>
                <MdOutlineNoteAlt className="flex-shrink-0 text-gray-700" size={30} />
              </div>
            </Tooltip>

            <p className="text-base font-medium">{note.message}</p>
          </div>
          <ActionMenu
            note={note}
            setIsNoteModalOpen={setIsNoteModalOpen}
            visibleTo={visibleTo}
            setVisibleTo={setVisibleTo}
            loadNoteRefetch={loadNoteRefetch}
            deletedLoadNotesRefetch={deletedLoadNotesRefetch}
            otherLoadParties={otherLoadParties}
            LoadId={LoadId}
            LoadDispatcher={LoadDispatcher}
          />
        </div>
        <div className="flex flex-col gap-2 px-3">
          <div className="flex flex-wrap items-center gap-4">
            {note.attachments.map((attachment, index) => (
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      background: 'transparent',
                    },
                  },
                }}
                title={
                  <div
                    key={index}
                    className="flex h-16 w-28 items-center justify-center rounded-md  "
                  >
                    <a href={attachment.url} target="_blank" rel="noreferrer">
                      <img
                        className="h-full w-full rounded-md object-cover"
                        src={attachment.url}
                        alt="attachment"
                      />
                    </a>
                  </div>
                }
                followCursor
                placement="top"
                bg="white"
                key={index}
              >
                <div
                  key={index}
                  className="flex cursor-pointer items-center 
                      gap-1 text-blue-500 hover:text-blue-700"
                >
                  <a
                    className="text-sm"
                    href={attachment.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {trancuateString(attachment.name)}
                  </a>
                </div>
              </Tooltip>
            ))}
          </div>
          <p className="text-gray-400">Created by: {note.creator.username}</p>
          <p className="text-gray-400">
            at: {new Date(note.created_at).toLocaleString()}
          </p>
        </div>
      </div>
    </>
  )
}

export default LoadNote
