import { Menu, Transition } from '@headlessui/react'
import { Fragment, useContext } from 'react'
import FIconWrapper from '../FIconWrapper'
import { HiLogout } from 'react-icons/hi'
import FAvatar from '../FAvatar'
import { VscCopy } from 'react-icons/vsc'
import { UserContext } from '../UserContext'
import Tooltip from '@mui/material/Tooltip'
import { FiSettings } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

export default function FMenu({ children }) {
  const { user, logout } = useContext(UserContext)
  const navigate = useNavigate()

  const copyTextToClipboard = (text) => {
    const textarea = document.createElement('textarea')
    textarea.value = text
    document.body.appendChild(textarea)
    textarea.select()
    navigator.clipboard.writeText(text)
    document.body.removeChild(textarea)
  }

  return (
    <div className="z-30 flex flex-col items-center text-left">
      <Menu as="div" className="relative z-40 inline-block text-left">
        <div className={'f-col-center-center'}>
          <Menu.Button className=" w-full">{children}</Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="primary-shadow absolute right-2 z-50 mt-2  origin-top-right divide-gray-100 rounded-sm border bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="f-col-center-center   py-1 ">
              <Menu.Item>
                <div className={' flex w-80 items-center gap-3 border-b px-3 py-2'}>
                  {user?.selected_role !== 'support'.toLowerCase() && (
                    <FAvatar name={user?.first_name + ' ' + user?.last_name} />
                  )}
                  <div className={'flex flex-col'}>
                    <div className="flex justify-between gap-1">
                      <span
                        className={
                          'whitespace-nowrap text-sm font-semibold text-gray-500'
                        }
                      >
                        {user?.username}
                      </span>
                      <span
                        className={
                          'whitespace-nowrap text-sm font-semibold text-gray-800 hover:text-primary-500'
                        }
                      >
                        <Tooltip title="Copy Username" arrow placement="top">
                          <div>
                            <VscCopy
                              className="mt-0.5 cursor-pointer "
                              size={20}
                              onClick={() => {
                                copyTextToClipboard(user?.username)
                              }}
                            />
                          </div>
                        </Tooltip>
                      </span>
                    </div>
                    <span className={'whitespace-nowrap text-xs text-gray-400'}>
                      {user?.user_type}
                    </span>
                  </div>
                </div>
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-primary-500 text-white ' : 'text-gray-900'
                    } group flex w-full items-center border-b  px-2 py-3 text-sm`}
                    onClick={() => navigate('/settings')}
                  >
                    <FIconWrapper>
                      <FiSettings size={20} />
                      <span className={'ml-2 whitespace-nowrap'}>Settings</span>
                    </FIconWrapper>
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-primary-500 text-white ' : 'text-gray-900'
                    } group flex w-full items-center px-2 py-3 text-sm`}
                    onClick={() => logout()}
                  >
                    <FIconWrapper>
                      <HiLogout size={20} />
                      <span className={'ml-2 whitespace-nowrap'}>Logout</span>
                    </FIconWrapper>
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}
