import React, { useEffect, useState } from 'react'
import FCard from '../../../../components/FCard'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import Tooltip from '@mui/material/Tooltip'
import { axiosInstance } from '../../../../axios/requister'

const ShipmentContributersCard = ({ response, userInfo, setIsCarrierModalOpen }) => {
  const [evidence, setEvidence] = useState([])
  useEffect(() => {
    axiosInstance
      .get(`/shipment/load/evidence/?load_id=${response?.id}`) // `load_id=${response?.id}
      .then((res) => {
        setEvidence(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  return (
    <FCard className={' my-2'}>
      <FCard.Header>
        <span className="font-semibold text-gray-800">Shipment Contributers</span>
      </FCard.Header>
      <FCard.Body>
        <div className={'grid  gap-5 lg:py-3'}>
          <div className={'text-lg font-semibold  text-gray-900'}>
            Customer:{' '}
            <span className={'text-base font-normal text-gray-500'}>
              {response?.customer?.username}
            </span>
          </div>
          <div className={'text-lg font-semibold  text-gray-900'}>
            Shipper:
            <span className={'text-base font-normal text-gray-500'}>
              {' '}
              {response?.shipper?.username}
            </span>
          </div>
          <div className={''}>
            <span className={'text-lg font-semibold  text-gray-900'}>
              Consignee:{' '}
              <span className={'text-base font-normal text-gray-500'}>
                {response?.consignee?.username}
              </span>
            </span>
          </div>
          <div className={''}>
            <span className={'text-lg font-semibold  text-gray-900'}>
              Dispatcher:{' '}
              <span className={'text-base font-normal text-gray-500'}>
                {response?.dispatcher?.username === null
                  ? 'Not assigned'
                  : response?.dispatcher?.username}
              </span>
            </span>
          </div>
          <div className={' flex'}>
            <div className={'text-lg font-semibold  text-gray-900  '}>
              Carrier:{' '}
              <span className={'text-base font-normal text-gray-500'}>
                {response?.carrier === null
                  ? 'Not assigned'
                  : response?.carrier?.username}
              </span>
              
              <div>
      { evidence && (
        <div className="flex flex-wrap items-center gap-4">
          {evidence?.evidence?.map((attachment, index) => (
            <Tooltip
              key={index}
              componentsProps={{
                tooltip: {
                  sx: {
                    background: 'transparent',
                  },
                },
              }}
              title={
                <div className="flex h-16 w-28 items-center justify-center rounded-md">
                  <a href={attachment.url} target="_blank" rel="noreferrer">
                    <img
                      className="h-full w-full rounded-md object-cover"
                      src={attachment.url}
                      alt="attachment"
                    />
                  </a>
                </div>
              }
              followCursor
              placement="top"
              bg="white"
            >
              <div
                className="flex cursor-pointer items-center gap-1 text-blue-500 hover:text-blue-700 "
              >
                <a
                  className="text-sm underline"
                  href={attachment.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {'Carrier Delivery Evidence'}
                </a>
              </div>
            </Tooltip>
          ))}
        </div>
      )}
    </div>
            </div>
            {userInfo.response?.username === response?.dispatcher?.username &&
              response?.status.toLowerCase() === 'Assigning Carrier'.toLowerCase() &&
              userInfo.response?.selected_role === 'dispatcher' &&
              response?.carrier === null && (
                <div
                  className={
                    'mt-1 ml-2 block cursor-pointer text-primary-500 hover:text-primary-600'
                  }
                  onClick={() => {
                    setIsCarrierModalOpen(true)
                  }}
                >
                  <Tooltip title={'Add carrier'} arrow placement="top">
                    <div>
                      <AiOutlinePlusCircle size={25} />
                    </div>
                  </Tooltip>
                </div>
              )}
          </div>
        </div>
      </FCard.Body>
    </FCard>
  )
}

export default ShipmentContributersCard
