const FLabel = ({ isRequired, onClick, htmlFor, children, ...props }) => {
  return (
    <div className={'flex w-fit gap-1'} onClick={onClick !== undefined ? onClick : null}>
      <label
        {...props}
        htmlFor={htmlFor}
        className={'mb-1 select-none font-inter text-sm'}
      >
        {children}
      </label>
      {isRequired === true && <span className={'font-bold text-red-500'}>*</span>}
    </div>
  )
}
export default FLabel
