import React from 'react'
import { FaRegBuilding } from 'react-icons/fa'
import { BsBuildingCheck, BsTelephone } from 'react-icons/bs'
import { HiOutlineIdentification, HiOutlineLocationMarker } from 'react-icons/hi'
import { MdDomain, MdOutlineLocalPostOffice } from 'react-icons/md'
import { Tooltip } from '@mui/material'



const CompanyInfoSection = ({data}) => {
    function formatPhoneNumber(phoneNumber) {
        if (!phoneNumber) return '' // Return empty string if phoneNumber is undefined or null
    
        return phoneNumber.replace(/^(\+\d{1})(\d{3})(\d{3})(\d{4})$/, '($2) $3-$4')
      }
    return (
        <div>
<div className="mx-auto mt-5 mb-3 flex w-[95%] gap-2 py-3">
              <div className=" font-semibold text-gray-700 ">
                <Tooltip title="Company name" arrow placement="top">
                  <div>
                    <FaRegBuilding size={28} />
                  </div>
                </Tooltip>
              </div>
              <div className=" text-2xl font-semibold text-gray-700 ">{data?.name}</div>
            </div>

            <div className="mx-auto w-[95%] overflow-hidden rounded-sm border  border-gray-300 bg-white shadow-sm">
              <div className=" lg:grid grid-cols-2 grid-rows-2 ">
                <div className="flex items-center border-b border-r p-4">
                  <Tooltip title="Company Identifier" arrow placement="top">
                    <div>
                      <BsBuildingCheck size={26} className="mr-2 text-gray-700" />
                    </div>
                  </Tooltip>
                  <div>
                    <div className=" font-medium text-gray-500">{data?.identifier || 'No identifier'}</div>
                  </div>
                </div>
                <div className="flex items-center border-b  p-4">
                  <Tooltip
                    title="Employer Identification Number (EIN)"
                    arrow
                    placement="top"
                  >
                    <div>
                      <HiOutlineIdentification size={28} className="mr-2 text-gray-700" />
                    </div>
                  </Tooltip>
                  <div>
                    <div className=" font-medium text-gray-500">{data?.EIN || 'No EIN'}</div>
                  </div>
                </div>
                <div className="flex items-center border-b border-r p-4">
                  <Tooltip title={'Phone number'} arrow placement="top">
                    <div>
                      <BsTelephone size={24} className="mr-2 text-gray-700" />
                    </div>
                  </Tooltip>
                  <div>
                    <div className=" font-medium text-gray-500">
                      {formatPhoneNumber(data?.phone_number || 'No phone number')}
                    </div>
                  </div>
                </div>

                <div className="flex items-center border-b  p-4">
                  <Tooltip
                    title={'Zip-code'}
                    arrow
                    placement="top"
                    sx={{ bgcolor: '#ffffff', color: '#000000' }}
                  >
                    <div>
                      <MdOutlineLocalPostOffice
                        size={25}
                        className="mr-2 text-gray-700"
                      />
                    </div>
                  </Tooltip>
                  <div>
                    <div className=" font-medium text-gray-500">
                      {data?.address?.zip_code || 'No zip code'}
                    </div>
                  </div>
                </div>

                <div className="flex items-center border-b border-r  p-4">
                  <Tooltip title={'Company location'} arrow placement="top">
                    <div>
                      <HiOutlineLocationMarker size={25} className="mr-2 text-gray-700" />
                    </div>
                  </Tooltip>
                  <div>
                    <div className=" font-medium text-gray-500">
                      {data?.address?.address +
                        ', ' +
                        data?.address?.city +
                        ', ' +
                        data?.address?.state}
                    </div>
                  </div>
                </div>

                <div className="flex items-center border-b   p-4">
                  <Tooltip title={'Company Domain'} arrow placement="top">
                    <div>
                      <MdDomain size={24} className="mr-2 text-gray-700" />
                    </div>
                  </Tooltip>
                  <div>
                    <div className=" font-medium text-gray-500">
                      {data?.domain ? data?.domain : 'No domain'}
                    </div>
                  </div>
                </div>
              </div>
            </div>        </div>
    )
}

export default CompanyInfoSection