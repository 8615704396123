import FCard from '../../../components/FCard'
import FFormWrapper from '../../../components/FFormWrapper'
import FLabel from '../../../components/FLabel'
import FInputField from '../../../components/FInputField'
import FFormInvalidInput from '../../../components/FFormInvalidInput'
import AsyncSelect from 'react-select/async'
import React from 'react'

const LoadLogistics = ({
  Formik,
  selectorKeyForPickup,
  loadFacilities,
  selectorKeyForDrop,
}) => {
  const pickupRef = React.useRef(null)
  const dropRef = React.useRef(null)
 
  return (
    <>
      <FCard>
        <FCard.Header>
          <span>Pick-up information</span>
        </FCard.Header>
        <FCard.Body>
          <FFormWrapper>
            <FLabel htmlFor={'pick_up_date'}>
              When will the cargo be ready for pickup
            </FLabel>
            <FInputField
              id={'pick_up_date'}
              type={'date'}
              min={new Date().toISOString().split('T')[0]}
              name={'pick_up_date'}
              value={Formik.values.pick_up_date}
              onChange={Formik.handleChange}
              onBlur={Formik.handleBlur}
            />
            <FFormInvalidInput
              touched={Formik.touched}
              FieldName={'pick_up_date'}
              errors={Formik.errors}
            />
          </FFormWrapper>
          <div className={'w-full flex gap-4'}>
            <FFormWrapper className = {'w-1/2'}>
            
            <FLabel htmlFor={'earliest_pick_up_time'}>
              Earliest pickup time
            </FLabel>
            <FInputField
              id={'earliest_pick_up_time'}
              type={'time'}
              name={'earliest_pick_up_time'}
              value={Formik.values.earliest_pick_up_time}
              onChange={Formik.handleChange}
              onBlur={Formik.handleBlur}
            />
            <FFormInvalidInput
              touched={Formik.touched}
              FieldName={'earliest_pick_up_time'}
              errors={Formik.errors}
            />
            </FFormWrapper>
            <FFormWrapper className = {'w-1/2'}>

            <FLabel htmlFor={'latest_pick_up_time'}>
              Latest pickup time
            </FLabel>
            <FInputField

              id={'latest_pick_up_time'}
              type={'time'}
              name={'latest_pick_up_time'}
              value={Formik.values.latest_pick_up_time}
              onChange={Formik.handleChange}
              onBlur={Formik.handleBlur}
            />
            <FFormInvalidInput

              touched={Formik.touched}
              FieldName={'latest_pick_up_time'}
              errors={Formik.errors}
            />

          </FFormWrapper>
          </div>

          <FFormWrapper className={'w-full '}>
            <FLabel onClick={() => pickupRef.current.focus()}>Building Name</FLabel>
            <AsyncSelect
              ref={pickupRef}
              openMenuOnFocus={true}
              isClearable
              defaultOptions
              key={selectorKeyForPickup}
              value={Formik.values.pick_up_location}
              cacheOptions
              loadOptions={(e) => loadFacilities(e, 'shipper', Formik)}
              onChange={(e) => {
                Formik.setFieldValue('pick_up_location', e)
              }}
              onBlur={() => {
                Formik.setFieldTouched('pick_up_location', true)
              }}
              placeholder={'Select pickup facility'}
              noOptionsMessage={() => 'No options for the selected shipper.'}
            />
            <FFormInvalidInput
              touched={Formik.touched}
              FieldName={'pick_up_location'}
              errors={Formik.errors}
            />
          </FFormWrapper>
        </FCard.Body>
      </FCard>
      <FCard>
        <FCard.Header>
          <span>Drop off information</span>
        </FCard.Header>
        <FCard.Body>
          <FFormWrapper>
            <FLabel htmlFor={'delivery_date'}>Delivery date</FLabel>
            <FInputField
              id={'delivery_date'}
              type={'date'}
              min={new Date().toISOString().split('T')[0]}
              name={'delivery_date'}
              value={Formik.values.delivery_date}
              onChange={Formik.handleChange}
              onBlur={Formik.handleBlur}
            />
            <FFormInvalidInput
              touched={Formik.touched}
              FieldName={'delivery_date'}
              errors={Formik.errors}
            />
          </FFormWrapper>
          <FFormWrapper>
            <FLabel htmlFor={'delivery_time'}>
              Delivery time
            </FLabel>
            <FInputField
              id={'delivery_time'}
              type={'time'}
              name={'delivery_time'}
              value={Formik.values.delivery_time}
              onChange={Formik.handleChange}
              onBlur={Formik.handleBlur}
            />
            <FFormInvalidInput
              touched={Formik.touched}
              FieldName={'delivery_time'}
              errors={Formik.errors}
            />
          </FFormWrapper>

          <FFormWrapper className={'w-full '}>
            <FLabel onClick={() => dropRef.current.focus()}>Building Name</FLabel>
            <AsyncSelect
              ref={dropRef}
              openMenuOnFocus={true}
              isClearable
              defaultOptions
              key={selectorKeyForDrop}
              name="destination"
              value={Formik.values.destination}
              cacheOptions
              loadOptions={(e) => loadFacilities(e, 'consignee', Formik)}
              onChange={(e) => {
                Formik.setFieldValue('destination', e)
              }}
              onBlur={() => {
                Formik.setFieldTouched('destination', true)
              }}
              placeholder={'Select drop off facility'}
              noOptionsMessage={() => 'No options for the selected consignee.'}
            />
            <FFormInvalidInput
              touched={Formik.touched}
              FieldName={'destination'}
              errors={Formik.errors}
            />
          </FFormWrapper>
        </FCard.Body>
      </FCard>
    </>
  )
}
export default LoadLogistics
