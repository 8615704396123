import FileRenderer from './FileRenderer'
import UserRole from './UserRole'
import FCard from '../../../components/FCard'

const ClaimNoteDetails = ({ claimNote }) => {
  return (
    <div className="f-col-center-center">
      <FCard className="mt-5 flex w-11/12 flex-col gap-5 p-3 px-5 lg:w-10/12">
        <div className="flex items-center justify-between">
          <p className="mb-3 text-xl font-medium text-gray-800">Claim Note Details</p>
        </div>
        <div className="flex flex-col gap-5 lg:flex-row">
          <div className="flex flex-1 flex-col gap-5">
            <div>
              <p className="font-medium"> Claim Note By</p>
              <div className="lg:flex lg:items-center">
                <p className="text-gray-500 mr-2">{claimNote?.creator?.username}</p>
                <div>
                  {claimNote?.creator?.party_roles.map((role) => (
                    <UserRole key={role} role={role} />
                  ))}
                </div>
              </div>
            </div>
            <div>
              <p className="font-medium"> Claim Note Description</p>
              <p className="text-gray-500">{claimNote?.message}</p>
            </div>
          </div>
          <div className="flex flex-1 flex-col gap-2">
            <p className="font-medium">Photos</p>
            {claimNote?.supporting_docs?.length !== 0 ? (
              <div className="flex flex-wrap gap-2">
                {claimNote?.supporting_docs?.map((file, index) => (
                  <FileRenderer
                    key={file.name}
                    name={file.name}
                    url={file.url}
                    alt={`Claim Note file ${index + 1}`}
                  />
                ))}
              </div>
            ) : (
              <p className="text-gray-500">No photos.</p>
            )}
          </div>
        </div>
      </FCard>
    </div>
  )
}

export default ClaimNoteDetails
