import React from 'react'

const NotFoundPage = () => {
  return (
    <div className="flex h-screen flex-col items-center justify-between md:flex-row">
      <div
        className="h-64 w-full bg-cover bg-center md:h-full md:w-1/2"
        style={{
          backgroundImage: `url(https://storage.googleapis.com/freight_static_files/404-error.svg)`,
        }}
      ></div>
      <div className="w-full p-10 md:w-1/2">
        <h1 className="mb-8 text-4xl font-bold text-gray-800 lg:text-8xl">
          404 - Page Not Found
        </h1>
        <p className="mb-8 text-2xl text-gray-600">
          Sorry, the page you are looking for does not exist.
        </p>
        <div className="flex ">
          <a
            href="/dashboard"
            className=" bg-primary-500 py-2 px-4 font-bold text-white hover:bg-primary-600"
          >
            Go Back Home
          </a>
        </div>
      </div>
    </div>
  )
}

export default NotFoundPage
