import FCard from '../../../components/FCard'
import FFormWrapper from '../../../components/FFormWrapper'
import React from 'react'
import FFormInvalidInput from '../../../components/FFormInvalidInput'

const CarrierTruck = ({ Formik }) => {
  return (
    <>
      <FCard>
        <FCard.Header>
          <span>Carrier truck specifications</span>
        </FCard.Header>
        <FCard.Body>
          <FFormWrapper>
            Shipment type
            <div className={'flex  items-center gap-2'}>
              <label className="labl   w-fit ">
                <input
                  type="radio"
                  name="load_type"
                  checked={Formik.values.load_type === 'LTL'}
                  onChange={() => {
                    Formik.setFieldValue('load_type', 'LTL')
                  }}
                  value={Formik.values.load_type}
                />
                <div className={'px-5 py-1'}>
                  <div>LTL</div>
                </div>
              </label>
              <label className="labl   w-fit  ">
                <input
                  type="radio"
                  name="load_type"
                  checked={Formik.values.load_type === 'FTL'}
                  onChange={() => {
                    Formik.setFieldValue('load_type', 'FTL')
                  }}
                  value={Formik.values.load_type}
                />
                <div className={'px-5 py-1'}>
                  <div>FTL</div>
                </div>
              </label>
            </div>
            <FFormInvalidInput
              touched={Formik.touched}
              FieldName={'load_type'}
              errors={Formik.errors}
            />
          </FFormWrapper>
        </FCard.Body>
      </FCard>
    </>
  )
}
export default CarrierTruck
