import React from 'react'
import { UserContext } from '../../components/UserContext'
import UserDashboard from './UserDashboard'
import ManagerDashboard from './ManagerDashboard'
import ESpinner from '../../components/ESpinner'
import { useQuery } from '@tanstack/react-query'
import { axiosInstance } from '../../axios/requister'

export default function Dashboard() {
  const { user } = React.useContext(UserContext)

  const { data, status } = useQuery(
    ['dashboard'],
    async () => {
      if (user.selected_role === 'manager') {
        const response = await axiosInstance.get(`manager/dashboard/`)
        return response.data
      } else {
        const response = await axiosInstance.get(`shipment/dashboard/`)
        return response.data
      }
    },
    {
      keepPreviousData: true,
      retry: false,
    }
  )

  return (
    <div className="flex h-full flex-col  pb-5">
      <div className="flex flex-col px-5 py-2">
        <div className="font-inter text-2xl font-bold text-gray-900">
          Dashboard Overview
        </div>
        <div className="text-lg text-gray-500">
          Hi {user?.first_name + ' ' + user?.last_name}, Welcome back!
        </div>
      </div>

      {status === 'loading' && (
        <div className="f-col-center-center h-full w-full">
          <ESpinner isVisible={true} size={30} />
          <span className="mt-5">Loading...</span>
        </div>
      )}

      {status === 'error' && (
        <div className="flex h-full flex-col justify-between">
          <div className="my-auto flex flex-col items-center justify-center">
            <img
              src="https://storage.googleapis.com/freight_static_files/empty-state.svg"
              alt="empty_state"
              width={200}
              className=""
            />
            <span className="mt-5 font-semibold text-gray-700">
              No Data Available Yet
            </span>
            {user.selected_role === 'manager' ? (
              <span className="mt-1 text-center text-sm text-gray-600">
                Sorry, there are currently no data available in your organization.
              </span>
            ) : (
              <span className="mt-1 text-center text-sm text-gray-600">
                Sorry, there are currently no loads available. Please check back later or
                create one yourself!
              </span>
            )}
          </div>
        </div>
      )}

      {status !== 'loading' &&
        status === 'success' &&
        user.selected_role !== 'manager' && <UserDashboard response={data} />}

      {status !== 'loading' &&
        status === 'success' &&
        user.selected_role === 'manager' && <ManagerDashboard response={data} />}
    </div>
  )
}
