import { useState } from 'react'
import ClaimForm from './ClaimForm'
import ClaimNoteDetails from './ClaimNoteDetails'
import ClaimsDetails from './ClaimsDetails'
import FButton from '../../../components/FButton'
import ClaimNoteForm from './ClaimNoteForm'
import { AiOutlinePlusCircle } from 'react-icons/ai'
// import { useQuery } from '@tanstack/react-query'
// import { axiosInstance } from '../../../axios/requister'
import ESpinner from '../../../components/ESpinner'
import { useCustomAxios } from '../../../Hooks/useAxios'

const ClaimsPage = ({ loadId, claimId, userName, Refetch }) => {
  const [isClaimNoteFormOpen, setIsClaimNoteFormOpen] = useState(false)

  const claim = useCustomAxios({
    url: `/shipment/load/claim/${claimId}`,
    method: 'GET',
  })

  const claimNote = useCustomAxios(
    {
      url: `/shipment/load/claim/note/?claim_id=${claimId}`,
      method: 'GET',
    },
    !!claimId
  )

  const showClaimNoteButton =
    !claimNote.response &&
    claimNote?.loading === false &&
    claim.loading === false &&
    userName !== claim?.response?.claimant?.username &&
    claimId //if you dont have a cliam note so you cant create a new claim note

  const isLoading = claim?.loading || claimNote?.loading
  if (isLoading) {
    return (
      <div className="f-col-center-center mt-20">
        <ESpinner isVisible={true} size={30} />
        <span className="mt-5">Loading...</span>
      </div>
    )
  } else {
    return (
      <div className="pb-5">
        {claimId ? (
          <ClaimsDetails loadId={loadId} claim={claim?.response} />
        ) : (
          <ClaimForm loadId={loadId} Refetch={Refetch} />
        )}

        {claimNote?.response && <ClaimNoteDetails claimNote={claimNote?.response} />}

        {isClaimNoteFormOpen && (
          <ClaimNoteForm
            claimId={claimId}
            isOpen={isClaimNoteFormOpen}
            setIsOpen={setIsClaimNoteFormOpen}
            claimNoteRefech={claimNote.Refetch}
          />
        )}

        {/* 
        If claim note & user.username !== claimNote.creator.username
        OR user.username !== claim.creator.username
      */}

        {showClaimNoteButton && (
          <div className="flex justify-center">
            <FButton
              onClick={() => setIsClaimNoteFormOpen(true)}
              className="my-5 w-4/12 gap-3"
            >
              <AiOutlinePlusCircle size={20} />
              Create Claim Note
            </FButton>
          </div>
        )}
      </div>
    )
  }
}

export default ClaimsPage
