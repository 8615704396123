import React, { useEffect, useState } from 'react'
import FCustomDataTable from '../../components/FCustomDataTable'
import Status from '../../components/Status'
import ESpinner from '../../components/ESpinner'
import { toast } from 'react-toastify'
import { axiosInstance } from '../../axios/requister'
const InvitationTab = ({
  invitationList,
  setInviteListPage,
  inviteListpage,
  invitationsTabs,
  setInvitationsTabs,
}) => {
  const [filter, setFilter] = useState([])
  const [filterOptions, setFilterOptions] = useState([
    {
      id: 0,
      value: '',
      key: '',
    },
  ])

  useEffect(() => {
    invitationList.Refetch()
  }, [invitationsTabs, inviteListpage])

  const handleResendInvite = async (id) => {
    try {
      await axiosInstance.post(`/invites/resend-invite/${id}/`)
      toast.success('Invitation was resent successfully')
    } catch {
      toast.error('Failed to resend invitation')
    }
  }

  const emailCell = (row) => {
    return <span className={'text-sm font-semibold'}>{row}</span>
  }

  const invitedDateCell = (row) => {
    const dateTime = new Date(row)
    const dateOptions = { month: '2-digit', day: '2-digit', year: 'numeric' }
    const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true }

    const formattedDate = dateTime.toLocaleDateString('en-US', dateOptions)
    const formattedTime = dateTime.toLocaleTimeString('en-US', timeOptions)

    return (
      <span className="text-sm font-semibold">
        {formattedDate} {formattedTime}
      </span>
    )
  }

  const statusCell = (row) => {
    return <Status statusName={row.charAt(0).toUpperCase() + row.slice(1)} />
  }

  const EmptyState = ({ status }) => (
    <div className="flex flex-col items-center justify-center py-40">
      <img
        src="https://storage.googleapis.com/freight_static_files/empty-state.svg"
        alt="empty_state"
        width={200}
        className=""
      />
      {status === 'All' ? (
        <span className="mt-5 font-semibold text-gray-700">No invites Found</span>
      ) : (
        <span className="mt-5 font-semibold text-gray-700">
          No {status} invites Found
        </span>
      )}
    </div>
  )

  const TABS = () => (
    <div className="z-10  mt-5 flex gap-10 px-2">
      <div className="flex">
        <div
          onClick={() => {
            setInvitationsTabs('all')
            setInviteListPage(1)
          }}
          className={`mx-2 flex cursor-pointer items-center justify-center px-20  ${
            invitationsTabs === 'all'
              ? 'bg-gray-50 text-gray-700'
              : 'bg-gray-200 text-gray-400'
          }  px-4   hover:text-gray-700`}
        >
          <span className="text-sm font-semibold">All</span>
        </div>
        <div
          onClick={() => {
            setInvitationsTabs('pending')
            setInviteListPage(1)
          }}
          className={` mx-2 flex cursor-pointer items-center justify-center px-20  ${
            invitationsTabs === 'pending'
              ? 'bg-gray-50 text-gray-700'
              : 'bg-gray-200 text-gray-400'
          }  px-4  hover:text-gray-700`}
        >
          <span className="text-sm font-semibold">Pending</span>
        </div>
        <div
          onClick={() => {
            setInvitationsTabs('accepted')
            setInviteListPage(1)
          }}
          className={` mx-2 flex cursor-pointer items-center justify-center px-20  ${
            invitationsTabs === 'accepted'
              ? 'bg-gray-50 text-gray-700'
              : 'bg-gray-200 text-gray-400'
          }  px-4  hover:text-gray-700`}
        >
          <span className="text-sm font-semibold">Accepted</span>
        </div>
        <div
          onClick={() => {
            setInvitationsTabs('rejected')
            setInviteListPage(1)
          }}
          className={` mx-2 flex cursor-pointer items-center justify-center px-20  ${
            invitationsTabs === 'rejected'
              ? 'bg-gray-50 text-gray-700'
              : 'bg-gray-200 text-gray-400'
          }  px-4 py-3 hover:text-gray-700`}
        >
          <span className="text-sm font-semibold">Rejected</span>
        </div>
      </div>
    </div>
  )

  const cols = [
    {
      Header: 'Email address',
      accessor: 'invitee', // String-based value accessors!
      cell: emailCell,
    },

    {
      Header: 'Invited date',
      accessor: 'created_at', // String-based value accessors!
      cell: invitedDateCell,
    },
    {
      Header: 'Status',
      accessor: 'status', // String-based value accessors!
      cell: statusCell,
    },
    {
      Header: 'Action',
      accessor: 'id', // String-based value accessors!
      cell: (row) => {
        const isPending =
          invitationList?.response?.results.find((contact) => contact.id === row)
            ?.status === 'pending'
        return isPending ? (
          <span
            onClick={() => handleResendInvite(row)}
            className="cursor-pointer text-primary-500 
            underline hover:text-primary-600"
          >
            Resend
          </span>
        ) : null
      },
    },
  ]

  if (invitationList.loading) {
    return (
      <>
        <TABS />
        <div className={'f-col-center-center mt-20 h-full w-full'}>
          <ESpinner isVisible={true} size={30} />
          <span className="mt-5">Loading...</span>
        </div>
      </>
    )
  }

  return (
    <div>
      <TABS />

      {invitationsTabs === 'all' && (
        <>
          {invitationList.response?.results?.length === 0 ? (
            <EmptyState status="All" />
          ) : (
            <FCustomDataTable
              cols={cols}
              data={invitationList.response?.results ?? []}
              isLoading={invitationList.loading}
              page={inviteListpage}
              setPage={setInviteListPage}
              size={2}
              Refetch={invitationList.Refetch}
              setFilter={setFilter}
              filter={filter}
              totalNumberOfPages={Math.ceil(invitationList.response?.count / 10)}
              filterOptions={filterOptions}
              setFilterOptions={setFilterOptions}
            />
          )}
        </>
      )}

      {invitationsTabs === 'pending' && (
        <>
          {invitationList.response?.results?.filter(
            (invitation) => invitation.status === 'pending'
          ).length === 0 ? (
            <EmptyState status="Pending" />
          ) : (
            <FCustomDataTable
              cols={cols}
              data={invitationList.response?.results ?? []}
              isLoading={invitationList.loading}
              page={inviteListpage}
              setPage={setInviteListPage}
              size={2}
              Refetch={invitationList.Refetch}
              setFilter={setFilter}
              filter={filter}
              totalNumberOfPages={Math.ceil(invitationList.response?.count / 10)}
              filterOptions={filterOptions}
              setFilterOptions={setFilterOptions}
            />
          )}
        </>
      )}

      {invitationsTabs === 'accepted' && (
        <>
          {invitationList.response?.results?.filter(
            (invitation) => invitation.status === 'accepted'
          ).length === 0 ? (
            <EmptyState status="Accepted" />
          ) : (
            <FCustomDataTable
              cols={cols}
              data={invitationList.response?.results ?? []}
              isLoading={invitationList.loading}
              page={inviteListpage}
              setPage={setInviteListPage}
              size={2}
              Refetch={invitationList.Refetch}
              setFilter={setFilter}
              filter={filter}
              totalNumberOfPages={Math.ceil(invitationList.response?.count / 10)}
              filterOptions={filterOptions}
              setFilterOptions={setFilterOptions}
            />
          )}
        </>
      )}

      {invitationsTabs === 'rejected' && (
        <>
          {invitationList.response?.results?.filter(
            (invitation) => invitation.status === 'rejected'
          ).length === 0 ? (
            <EmptyState status="Rejected" />
          ) : (
            <FCustomDataTable
              cols={cols}
              data={invitationList.response?.results ?? []}
              isLoading={invitationList.loading}
              page={inviteListpage}
              setPage={setInviteListPage}
              size={2}
              Refetch={invitationList.Refetch}
              setFilter={setFilter}
              filter={filter}
              totalNumberOfPages={Math.ceil(invitationList.response?.count / 5)}
              filterOptions={filterOptions}
              setFilterOptions={setFilterOptions}
            />
          )}
        </>
      )}
    </div>
  )
}
export default InvitationTab
