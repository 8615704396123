import FCard from '../../../components/FCard'
import React from 'react'
import FInputFieldCompound from '../../../components/FInputFieldCompound'
import FFormInvalidInput from '../../../components/FFormInvalidInput'
import UnitsConversion from '../../../components/UnitsConversion'
import { Tooltip } from '@mui/material'

const LoadBasicInfo = ({ Formik, units, setUnits }) => {
  const handleChange = (event) => {
    let newValue = event.target.value
    if (newValue === 'metric') {
      Formik.setFieldValue('length', (Formik.values.length * 2.54).toFixed(2))
      Formik.setFieldValue('width', (Formik.values.width * 2.54).toFixed(2))
      Formik.setFieldValue('height', (Formik.values.height * 2.54).toFixed(2))
      Formik.setFieldValue('weight', (Formik.values.weight / 2.20462).toFixed(2))
    } else {
      Formik.setFieldValue('length', (Formik.values.length / 2.54).toFixed(2))
      Formik.setFieldValue('width', (Formik.values.width / 2.54).toFixed(2))
      Formik.setFieldValue('height', (Formik.values.height / 2.54).toFixed(2))
      Formik.setFieldValue('weight', (Formik.values.weight * 2.20462).toFixed(2))
    }
    setUnits(newValue)
  }

  return (
    <div>
      <FCard>
        <FCard.Header>
          <span>Load basic information</span>
        </FCard.Header>
        <FCard.Body>
          <UnitsConversion
            units={units}
            handleChange={handleChange}
            options={[
              { value: 'imperial', label: 'Imperial (in, lb)' },
              { value: 'metric', label: 'Metric (cm, kg)' },
            ]}
          />

          <div className={'flex  gap-3 '}>
            <Tooltip
              title={
                <h1 style={{ fontSize: '13px' }}>
                  Length: $
                  {units === 'metric'
                    ? (Formik.values.length * 0.0328084).toFixed(2)
                    : (Formik.values.length / 12).toFixed(2)}{' '}
                  ft
                </h1>
              }
              arrow
              placement="top"
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -60],
                      },
                    },
                  ],
                },
              }}
            >
              <div>
                <FInputFieldCompound
                  Formik={Formik}
                  type={'float'}
                  fieldName={'length'}
                  placeHolder={'Enter length'}
                  value={Formik.values.length}
                  label={
                    <>
                      length{' '}
                      <span className="text-xs font-medium  text-primary-500">
                        {' '}
                        ({units === 'metric' ? 'cm' : 'inches'})
                      </span>
                    </>
                  }
                  min={0}
                  step={0.1}
                />
              </div>
            </Tooltip>
            <Tooltip
              title={
                <h1 style={{ fontSize: '13px' }}>
                  Width: $
                  {units === 'metric'
                    ? (Formik.values.width * 0.0328084).toFixed(2)
                    : (Formik.values.width / 12).toFixed(2)}{' '}
                  ft
                </h1>
              }
              arrow
              placement="top"
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -60],
                      },
                    },
                  ],
                },
              }}
            >
              <div>
                <FInputFieldCompound
                  Formik={Formik}
                  type={'float'}
                  fieldName={'width'}
                  value={Formik.values.width}
                  placeHolder={'Enter width'}
                  label={
                    <>
                      width{' '}
                      <span className="text-xs font-medium  text-primary-500">
                        {' '}
                        ({units === 'metric' ? 'cm' : 'inches'})
                      </span>
                    </>
                  }
                  min={0}
                  step={0.1}
                />
              </div>
            </Tooltip>

            <Tooltip
              title={
                <h1 style={{ fontSize: '13px' }}>
                  Height: $
                  {units === 'metric'
                    ? (Formik.values.height * 0.0328084).toFixed(2)
                    : (Formik.values.height / 12).toFixed(2)}{' '}
                  ft
                </h1>
              }
              arrow
              placement="top"
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -60],
                      },
                    },
                  ],
                },
              }}
            >
              <div>
                <FInputFieldCompound
                  Formik={Formik}
                  type={'float'}
                  fieldName={'height'}
                  value={Formik.values.height}
                  placeHolder={'Enter height'}
                  label={
                    <>
                      height{' '}
                      <span className="text-xs font-medium  text-primary-500">
                        {' '}
                        ({units === 'metric' ? 'cm' : 'inches'})
                      </span>
                    </>
                  }
                  min={0}
                  step={0.1}
                />
              </div>
            </Tooltip>
          </div>
          <div className={'mb-5  flex  flex-nowrap gap-3  '}>
            <FInputFieldCompound
              Formik={Formik}
              type={'float'}
              fieldName={'weight'}
              placeHolder={'Enter Weight'}
              label={
                <>
                  Weight{' '}
                  <span className="text-xs font-medium  text-primary-500">
                    {' '}
                    ({units === 'metric' ? 'kg' : 'pounds'})
                  </span>
                </>
              }
              min={0}
              step={0.1}
            />
            <FInputFieldCompound
              Formik={Formik}
              type={'float'}
              fieldName={'quantity'}
              placeHolder={'Enter quantity'}
              label={
                <>
                  quantity{' '}
                  <span className="text-xs font-medium  text-primary-500"> (num)</span>
                </>
              }
              min={0}
              step={1}
            />
          </div>
          <FInputFieldCompound
            Formik={Formik}
            type={'text'}
            fieldName={'commodity'}
            placeHolder={'Enter commodity Name'}
            label={'commodity'}
          />
          <FInputFieldCompound
            Formik={Formik}
            type={'text'}
            fieldName={'equipment'}
            placeHolder={'Enter Equipment Type'}
            label={'Equipment Type'}
          />

          <div>
            <span>Does your load contains any Hazaradious Materials ?</span>
            <div className={'my-3 flex flex-col gap-2'}>
              <label htmlFor={'Yes'} className={'flex items-center gap-1'}>
                <input
                  id={'Yes'}
                  name="goods_info"
                  type={'radio'}
                  onChange={() => {
                    Formik.setFieldValue('goods_info', 'Yes')
                  }}
                  checked={Formik.values.goods_info === 'Yes'}
                />
                <span className={'text-sm text-gray-800'}>Yes</span>
              </label>
              <label htmlFor={'No'} className={'flex items-center gap-1'}>
                <input
                  id={'No'}
                  type={'radio'}
                  name="goods_info"
                  onChange={() => {
                    Formik.setFieldValue('goods_info', 'No')
                  }}
                  checked={Formik.values.goods_info === 'No'}
                />
                <span className={'text-sm text-gray-800'}>No</span>
              </label>
              <FFormInvalidInput
                touched={Formik.touched}
                FieldName={'goods_info'}
                errors={Formik.errors}
              />
            </div>
          </div>
        </FCard.Body>
      </FCard>
    </div>
  )
}
export default LoadBasicInfo
