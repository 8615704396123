import React, { useState } from 'react'
import FCard from '../../../components/FCard'
import FInputField from '../../../components/FInputField'
import FFormWrapper from '../../../components/FFormWrapper'
import FLabel from '../../../components/FLabel'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import FButton from '../../../components/FButton'
import FFormInvalidInput from '../../../components/FFormInvalidInput'
import FileUploadButton from '../../../components/FileUploadButton '
import { axiosInstance } from '../../../axios/requister'
import { toast } from 'react-toastify'
import { uploadSignedUrl } from '../utils'
import ESpinner from '../../../components/ESpinner'

const ClaimForm = ({ loadId, Refetch }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)

  const formik = useFormik({
    initialValues: {
      date_of_loss: '',
      description_of_loss: '',
      supporting_docs: [],
    },

    validationSchema: Yup.object({
      date_of_loss: Yup.string().trim().required('Date of loss is required'),
      supporting_docs: Yup.array()
        .of(Yup.mixed().required())
        .min(1, 'Supporting documents must have at least 1 file.')
        .required('Supporting documents are required.'),
    }),
    onSubmit: async (values) => {
      setIsLoading(true)

      const formData = {
        load_id: loadId,
        date_of_loss: values.date_of_loss,
        description_of_loss: values.description_of_loss,
        supporting_docs: values.supporting_docs.map((file) => ({
          name: file.name,
          content_type: file.type,
        })),
      }

      try {
        const response = await axiosInstance.post('/shipment/load/claim/', formData)
        const serverAttachments = response?.data.supporting_docs
        const onUploadProgress = (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          setUploadProgress(progress)
        }
        await uploadSignedUrl({
          serverAttachments,
          uploadedFiles: values.supporting_docs,
          onUploadProgress,
          objId: response.data.id,
          metaKey: 'claim_id',
          type: 'claim',
        })
        toast.success('Claim created successfully')
        Refetch()
        formik.resetForm()
      } catch (error) {
        toast.error(error.response?.data?.details || 'Something went wrong!')
      } finally {
        setIsLoading(false)
      }
    },
  })

  return (
    <div className="f-col-center-center">
      <FCard className={'mt-5 w-11/12 lg:w-10/12'}>
        <div className="p-2">
          <div className="text-lg font-semibold text-gray-800 ">{'Add your Claim'}</div>
          <span className="text-sm text-gray-500">
            {'Please fill out the form below to claim this load'}
          </span>
        </div>
        <hr />
        <form onSubmit={formik.handleSubmit}>
          <FCard.Body>
            <div className="flex flex-col lg:flex-row lg:gap-14 ">
              <FFormWrapper className="w-full lg:w-1/2">
                <FLabel htmlFor={'date_of_loss'}>Date of loss </FLabel>
                <FInputField
                  id={'date_of_loss'}
                  name="date_of_loss"
                  placeholder="Date of Loss"
                  type="date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.date_of_loss}
                />
                <FFormInvalidInput
                  touched={formik.touched}
                  FieldName={'date_of_loss'}
                  errors={formik.errors}
                />
              </FFormWrapper>
            </div>
            <div className="flex flex-col lg:flex-row">
              <FFormWrapper className=" w-full">
                <FLabel htmlFor={'description_of_loss'}>
                  Description of loss <span className="text-gray-500"> (optional)</span>
                </FLabel>{' '}
                <textarea
                  id={'description_of_loss'}
                  name="description_of_loss"
                  placeholder="Description of loss"
                  type="text"
                  className="input-style "
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description_of_loss}
                />
                <FFormInvalidInput
                  touched={formik.touched}
                  FieldName={'description_of_loss'}
                  errors={formik.errors}
                />
              </FFormWrapper>
            </div>
            <div className="flex flex-col gap-14 lg:flex-row ">
              <FFormWrapper className="w-full lg:w-1/2">
                <FLabel htmlFor={'supporting_docs'}> Upload Supporting Document</FLabel>

                <FileUploadButton
                  initialFiles={formik.values.supporting_docs}
                  acceptedTypes={['image/png', 'image/jpeg', 'application/pdf']}
                  onFileChange={(files) => formik.setFieldValue('supporting_docs', files)}
                  onBlur={formik.handleBlur}
                  name={'supporting_docs'}
                  multiple={true}
                  onRemoveImage={(image) => {
                    formik.setFieldValue(
                      'supporting_docs',
                      formik.values.supporting_docs.filter(
                        (file) => file.name !== image.name
                      )
                    )
                  }}
                />

                <FFormInvalidInput
                  touched={formik.touched}
                  FieldName={'supporting_docs'}
                  errors={formik.errors}
                />
              </FFormWrapper>
            </div>
            <div className=" flex w-full justify-center gap-3 p-5 lg:justify-end ">
              <div>
                <FButton
                  className="flex w-full gap-1 lg:w-fit"
                  type="submit"
                  disabled={isLoading}
                >
                  Add Claim {isLoading && `${uploadProgress}%`}
                  <ESpinner isVisible={isLoading} />
                </FButton>
              </div>
            </div>
          </FCard.Body>
        </form>
      </FCard>
    </div>
  )
}

export default ClaimForm
