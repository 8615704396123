function RadioCard({handleClick, label, body, isActive}) {
  return (
    <>
      <div
        onClick={() => {
          handleClick(label)
        }}
        className={`flex max-w-lg  select-none flex-col items-start border p-5 cursor-pointer${
          isActive ? ' border-primary-500 bg-primary-100' : ' border-gray-400'
        }`}
      >
        <div className="flex w-full flex-row items-center">
          <input
            id="push-nothing"
            name="push-notifications"
            type="radio"
            className="h-4 w-4 border-gray-400 text-primary-500 focus:outline-none focus:ring-0 focus:ring-offset-0"
            checked={isActive}
            onChange={() => {
              handleClick(label)
            }}
          />
          <label className="ml-3 block text-xl font-medium text-gray-700 hover:cursor-pointer">
            {label}
          </label>
        </div>
        <span className="ml-7 text-left text-sm text-gray-700">{body}</span>
      </div>
    </>
  )
}

export default RadioCard
