const UnitsConversion = ({ units, handleChange, options }) => {
  return (
    <div className="relative mr-0 rounded-md">
      <label htmlFor="unit">
        <div className="select-none font-inter text-sm">Units of Measure</div>
      </label>
      <select
        className="focus:shadow-outline-blue mt-2 w-fit rounded-md border border-gray-400 bg-white py-2 text-sm leading-5 text-gray-900 placeholder-gray-500 transition duration-150 ease-in-out focus:border-blue-400 focus:outline-none"
        value={units}
        onChange={handleChange}
        placeholder="Select unit"
        id="unit"
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  )
}

export default UnitsConversion
