import * as Yup from 'yup'
import React from 'react'
import { axiosInstance } from '../../axios/requister'
import axios from 'axios'

export const handleRequestBody = (values, units) => {
  return {
    ...(values?.dispatcher === null ? {} : { dispatcher: values.dispatcher.value }),
    name: values.name,
    customer: values.customer.value,
    shipper: values.shipper.value,
    consignee: values.consignee.value,
    destination: values.destination.value,
    pick_up_location: values.pick_up_location.value,
    delivery_date: values.delivery_date,
    pick_up_date: values.pick_up_date,
    delivery_time: values.delivery_time,
    earliest_pick_up_time : values.earliest_pick_up_time,
    latest_pick_up_time: values.latest_pick_up_time,
    length: units === 'imperial' ? values.length : (values.length / 2.54).toFixed(2),
    width: units === 'imperial' ? values.width : (values.width / 2.54).toFixed(2),
    height: units === 'imperial' ? values.height : (values.height / 2.54).toFixed(2),
    weight: units === 'imperial' ? values.weight : (values.weight * 2.20462).toFixed(2),
    quantity: values.quantity,
    load_type: values.load_type,
    goods_info: values.goods_info,
    commodity: values.commodity,
    equipment: values.equipment,
  }
}

export const getValidationSchema = () => {
  return Yup.object({
    delivery_date: Yup.date()
      .nullable(true)
      .required('Delivery date is required')
      .min(
        new Date(new Date().setDate(new Date().getDate() - 1)),
        'Delivery date must be in the future'
      ),
    pick_up_date: Yup.date()
      .nullable(true)
      .required('Pick-up date is required')
      .min(
        new Date(new Date().setDate(new Date().getDate() - 1)),
        'Pick-up date must be in the future'
      ),
    delivery_time: Yup.string().trim().nullable(true).required('Delivery time is required'),
    earliest_pick_up_time: Yup.string().trim().nullable(true).required('Earliest pick up time is required'),
    latest_pick_up_time: Yup.string().trim().nullable(true).required('Latest pick up time is required'),
    
    length: Yup.number()
      .nullable(true)
      .typeError('Length must be a number')
      .required('Length is required')
      .positive('Length must be positive'),
    width: Yup.number()
      .nullable(true)
      .typeError('Width must be a number')
      .required('Width is required')
      .positive('Width must be positive'),
    height: Yup.number()
      .nullable(true)
      .typeError('Height must be a number')
      .required('Height is required')
      .positive('Height must be positive'),
    quantity: Yup.number()
      .nullable(true)
      .typeError('Quantity must be a number')
      .required('Quantity is required')
      .positive('Quantity must be positive'),
    weight: Yup.number()
      .nullable(true)
      .required('Weight is required')
      .typeError('Weight must be a number')
      .positive('Weight must be positive'),
    commodity: Yup.string().trim().nullable(true).required('Commodity name is required'),
    equipment: Yup.string().trim().nullable(true).required('Equipment type is required'),
    customer: Yup.object().nullable(true).required('Customer is required'),
    dispatcher: Yup.object().nullable(true).required('Dispatcher is required'),
    consignee: Yup.object().nullable(true).required('Consignee is required'),
    shipper: Yup.object().nullable(true).required('Shipper is required'),
    pick_up_location: Yup.object().nullable(true).required('Facility pick is required'),
    destination: Yup.object().nullable(true).required('Facility drop is required'),
    load_type: Yup.string().trim().nullable(true).required('Load type is required'),
    goods_info: Yup.string().trim().nullable(true).required('Goods info is required'),
  })
}

export const customValidation = (values, isExistingShipment) => {
  const errors = {}
  if (values.destination && values.pick_up_location) {
    if (values.pick_up_location?.value === values.destination?.value) {
      errors.destination = 'Pick up location and Drop off location cannot be the same'
    }
    if (values.pick_up_location?.value === values.destination?.value) {
      errors.pick_up_location =
        'Pick up location and Drop off location cannot be the same'
    }
  }
  const datesSelected = values.delivery_date && values.pick_up_date
  if (values.delivery_date <= values.pick_up_date && datesSelected) {
    errors.delivery_date = 'Delivery date must be after pick up date'
  }
  if (values.pick_up_date >= values.delivery_date && datesSelected) {
    errors.pick_up_date = 'Pick up date must be before delivery date'
  }
  if (isExistingShipment && !values.shipment) {
    errors.shipment = 'Shipment name is required'
  }

  return errors
}

export const NoOptionsMessage = () => {
  return (
    <span className="text-sm">
      Before assigning a user to the load, add them to your contact list.
    </span>
  )
}

export const loadShipmentParty = ({ inputValue, callback, user }) => {
  axiosInstance
    .post(`/shipment/filter-contact/`, {
      type: 'shipment party',
      keyword: inputValue,
    })
    .then((res) => {
      const options = res.data.map((customer) => {
        return {
          id: customer.contact.id,
          value: customer.contact.username,
          label: (
            <div className="flex gap-2">
              {customer.contact.first_name} {customer.contact.last_name}
              <span className="my-auto text-sm text-gray-500">
                {customer.contact.username}
              </span>
            </div>
          ),
        }
      })

      if (user.user_type.includes('shipment party')) {
        callback([
          {
            id: user.id,
            value: user.username,
            label: (
              <div className="flex gap-2">
                {user.first_name} {user.last_name}
                <span className="my-auto text-sm text-gray-500">
                  {user.username} (me)
                </span>
              </div>
            ),
          },
          ...options,
        ])
      } else {
        callback(options)
      }
    })
    .catch(() => {})
}

export const loadDispatcher = ({ inputValue, callback, user }) => {
  axiosInstance
    .post(`/shipment/filter-contact/`, {
      type: 'dispatcher',
      keyword: inputValue,
    })
    .then((res) => {
      const options = res.data.map((customer) => {
        return {
          id: customer.contact.id,
          value: customer.contact.username,
          label: (
            <div className="flex gap-2">
              {customer.contact.first_name} {customer.contact.last_name}
              <span className="my-auto text-sm text-gray-500">
                {customer.contact.username}
              </span>
            </div>
          ),
        }
      })
      if (user.user_type.includes('dispatcher')) {
        callback([
          ...options,
          {
            id: user.id,
            value: user.username,
            label: (
              <div className="flex gap-2">
                {user.first_name} {user.last_name}
                <span className="my-auto text-sm text-gray-500">
                  {user.username} (me)
                </span>
              </div>
            ),
          },
        ])
      } else {
        callback(options)
      }
    })
    .catch(() => {})
}

export const formatDate = (dateString) => {
  const date = new Date(dateString)

  const options = {
    weekday: 'long',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  }

  return date.toLocaleString('en-US', options)
}

export const loadOldShipmentName = async (inputValue, Formik) => {
  const res = await axiosInstance.post(`/shipment/filter-shipment/`, {
    name: Formik?.values?.shipment?.value,
    keyword: inputValue,
  })
  return res.data.map((item) => {
    return {
      id: item.id,
      value: item.name,
      label: (
        <div className="flex justify-between">
          <span>{item.name}</span>
          <span className="my-auto text-xs text-gray-500">
            {' '}
            Created at: {formatDate(item.created_at)}
          </span>
        </div>
      ),
    }
  })
}

export const loadFacilities = async (input, key, Formik) => {
  const res = await axiosInstance.post(`/shipment/filter-facility/`, {
    [key]: Formik.values[key].value,
    keyword: input,
  })

  return res.data.map((facility) => {
    return {
      id: facility.id,
      value: facility.id,
      label: (
        <div className="flex ">
          <span>{facility.building_name}{'  '}</span>
          <span className="my-auto text-sm text-gray-500">
            {' , '}
             {facility.address. address}
          </span>
        </div>
      ),
    }
  })
}

export const trancuateString = (str) => {
  return str.length > 15
    ? str.substring(0, 10) + '...' + str.substring(str.length - 5)
    : str
}

export const uploadSignedUrl = async ({
  serverAttachments,
  uploadedFiles,
  onUploadProgress,
  objId,
  type,
  metaKey,
}) => {
  const successFiles = []

  for (let i = 0; i < uploadedFiles.length; i++) {
    const file = uploadedFiles[i]
    const url = serverAttachments[i].url
    const headers = {
      'Content-Type': file.type,
      [`x-goog-meta-${metaKey}`]: `${objId}`,
    }

    try {
      await axios
        .put(url, file, {
          headers,
          onUploadProgress,
        })
        .then((response) => {
          if (response.status === 200) {
            successFiles.push(serverAttachments[i].name)
          }
        })
    } catch (error) {
      console.log('Upload Signed Url Error', error)
      throw error
    }
  }

  if (successFiles.length) {
    try {
      await axiosInstance.post('/upload-confirmation-client-side/', {
        id: objId,
        attachments_names: successFiles,
        type, // claim, claim note, load note
      })
    } catch (error) {
      console.log('Confirmation URL Error', error)
      throw error
    }
  }

  // console.log('success uploaded files ', successFiles)
}

export const constructAddress = (addressObject) => {
  const {
    buildingName,
    address,
    // apartment,
    city,
    state,
    postalCode,
    country,
  } = addressObject

  // Construct the address string
  let addressString = [
    buildingName?.trim(),
    address?.trim(),
    // apartment,
    `${city ? `${city.trim()},` : ''}${state ? ` ${state.trim()}` : ''}${
      postalCode ? ` ${postalCode}` : ''
    }`,
    country,
  ]
    .filter((prop) => prop) // Filter out undefined properties
    .join(', ')

  return addressString
}
