import FCard from '../../../components/FCard'
import FFormWrapper from '../../../components/FFormWrapper'
import AsyncSelect from 'react-select/async'
import FFormInvalidInput from '../../../components/FFormInvalidInput'
import React, { useRef } from 'react'
import { NoOptionsMessage, loadShipmentParty, loadDispatcher } from '../utils'
import FLabel from '../../../components/FLabel'
import { UserContext } from './../../../components/UserContext'

export const ShipmentParty = ({
  Formik,
  // loadShipmentParty,
  // loadDispatcher,
  selectorKeyForDrop,
  selectorKeyForPickup,
  error,
  loadInfo,
}) => {
  const customerRef = useRef(null)
  const shipperRef = useRef(null)
  const consigneeRef = useRef(null)
  const dispatcherRef = useRef(null)
  const { user } = React.useContext(UserContext)

  const loadCustomerOptions = (inputValue, callback) =>
    loadShipmentParty({ inputValue, callback, user })

  const loadShipperOptions = (inputValue, callback) =>
    loadShipmentParty({ inputValue, callback, user })

  const loadConsigneeOptions = (inputValue, callback) =>
    loadShipmentParty({ inputValue, callback, user })

  const loadDispatcherOptions = (inputValue, callback) =>
    loadDispatcher({ inputValue, callback, user })

  if (Formik.values.shipper) {
    delete Formik.errors.shipper
  }

  if (Formik.values.consignee) {
    delete Formik.errors.consignee
  }

  return (
    <div>
      <FCard>
        <FCard.Header>
          <span>Shipment parties</span>
        </FCard.Header>
        <FCard.Body>
          <FFormWrapper className={'w-12/12'}>
            <FLabel onClick={() => customerRef.current.focus()}>Customer </FLabel>
            <AsyncSelect
              ref={customerRef}
              isClearable={true}
              openMenuOnFocus={true}
              defaultOptions
              value={Formik.values.customer}
              cacheOptions
              loadOptions={loadCustomerOptions}
              onChange={(e) => {
                Formik.setFieldValue('customer', e)
                loadInfo.error = ''
              }}
              onBlur={() => Formik.setFieldTouched('customer', true)}
              placeholder={'Select customer'}
              noOptionsMessage={NoOptionsMessage}
            />
            <FFormInvalidInput
              touched={Formik.touched}
              FieldName={'customer'}
              errors={Formik.errors}
            />
            <small className={'text-left font-medium text-red-700'}>{error}</small>
          </FFormWrapper>
          <FFormWrapper className={'w-12/12'}>
            <FLabel onClick={() => shipperRef.current.focus()}>Shipper</FLabel>
            <AsyncSelect
              ref={shipperRef}
              isClearable={true}
              openMenuOnFocus={true}
              defaultOptions
              value={Formik.values.shipper}
              cacheOptions
              loadOptions={loadShipperOptions}
              onChange={(e) => {
                Formik.setFieldValue('shipper', e)
                Formik.setFieldValue('pick_up_location', null)
                selectorKeyForPickup(Math.random())
              }}
              onBlur={() => Formik.setFieldTouched('shipper', true)}
              placeholder={'Select shipper'}
              noOptionsMessage={NoOptionsMessage}
            />
            <FFormInvalidInput
              touched={Formik.touched}
              FieldName={'shipper'}
              errors={Formik.errors}
            />
          </FFormWrapper>
          <FFormWrapper className={'w-12/12'}>
            <FLabel onClick={() => consigneeRef.current.focus()}>Consignee</FLabel>
            <AsyncSelect
              isClearable
              ref={consigneeRef}
              openMenuOnFocus={true}
              defaultOptions
              value={Formik.values.consignee}
              cacheOptions
              loadOptions={loadConsigneeOptions}
              onChange={(e) => {
                Formik.setFieldValue('consignee', e)
                Formik.setFieldValue('destination', null)
                selectorKeyForDrop(Math.random())
              }}
              onBlur={() => Formik.setFieldTouched('consignee', true)}
              placeholder={'Select consignee'}
              noOptionsMessage={NoOptionsMessage}
            />
            <FFormInvalidInput
              touched={Formik.touched}
              FieldName={'consignee'}
              errors={Formik.errors}
            />
          </FFormWrapper>
          <FFormWrapper className={'w-12/12'}>
            <FLabel onClick={() => dispatcherRef.current.focus()}>Dispatcher</FLabel>
            <AsyncSelect
              ref={dispatcherRef}
              isClearable
              openMenuOnFocus={true}
              defaultOptions
              value={Formik.values.dispatcher}
              cacheOptions
              loadOptions={loadDispatcherOptions}
              onBlur={() => Formik.setFieldTouched('dispatcher', true)}
              onChange={(e) => {
                Formik.setFieldValue('dispatcher', e)
              }}
              placeholder={'Select dispatcher'}
              noOptionsMessage={NoOptionsMessage}
            />
            <FFormInvalidInput
              touched={Formik.touched}
              FieldName={'dispatcher'}
              errors={Formik.errors}
            />
          </FFormWrapper>
        </FCard.Body>
      </FCard>
    </div>
  )
}
export default ShipmentParty
