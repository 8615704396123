import React from 'react'
import ESpinner from '../../components/ESpinner'
import { HiOutlineLocationMarker, HiOutlineIdentification } from 'react-icons/hi'

import { useQuery } from '@tanstack/react-query'
import { axiosInstance } from '../../axios/requister'

export default function IndividualUser() {
  const { data, status } = useQuery(
    ['taxInfo'],
    async () => {
      const response = await axiosInstance.get(`authentication/tax-info/`)
      return response.data
    },
    {
      keepPreviousData: true,
      retry: false,
    }
  )

  if (status === 'loading') {
    return (
      <div className={'f-col-center-center h-full w-full'}>
        <ESpinner isVisible={true} size={30} />
        <span>Loading...</span>
      </div>
    )
  } else {
    return (
      <div className="flex flex-col overflow-hidden lg:flex-row">
        <div className="my-auto flex flex-col justify-center px-10 py-5 lg:w-1/2">
          <h1 className="mb-4 font-inter text-2xl font-bold text-gray-900">
            Hi, {data?.user_tax.app_user.username}
          </h1>
          <p className="mb-4 text-lg font-normal leading-8 text-gray-700">
            As an{' '}
            <span className="text-xl font-semibold text-gray-900">individual user</span>{' '}
            without a company, you are not affiliated with any business entity and are
            responsible for your own personal taxes. This means that you have your own tax
            identification information, including your Taxpayer Identification Number and
            Address <span className="text-xl font-semibold text-gray-900">(TIN)</span> and
            address, when filing your taxes with the appropriate government agencies.
          </p>
          <div className="mb-4">
            <h2 className="mb-2 text-xl font-semibold text-gray-900">
              Your tax identification info:
            </h2>
            <div className="flex flex-col text-gray-700">
              <span className="mb-2 flex">
                <HiOutlineIdentification size={25} className="mr-1 text-gray-700" />
                <span className="font-semibold">TIN: </span>
                {data?.user_tax.TIN}
              </span>
              <span className="mb-2 flex">
                <HiOutlineLocationMarker size={25} className="mr-1 text-gray-700" />
                <span className="font-semibold ">Address: </span>
                {' ' +
                  data?.user_tax.address.address +
                  ', ' +
                  data?.user_tax.address.city +
                  ', ' +
                  data?.user_tax.address.state +
                  ', ' +
                  data?.user_tax.address.zip_code}
              </span>
            </div>
          </div>
        </div>
        <div className="my-auto flex justify-center overflow-hidden lg:w-1/2">
          <img
            src="https://storage.googleapis.com/freight_static_files/tax.png"
            width={650}
            alt="tax"
            className="h-full"
          />
        </div>
      </div>
    )
  }
}
