import { useState, useEffect } from 'react'
// import envelope from '../../assets/images/Envelop.png'
import axios from 'axios'
import { BASE_URL } from '../../env'
import { toast } from 'react-toastify'

const EmailVerification = () => {
  const email = localStorage.getItem('registered_email')
  const [remainingSeconds, setRemainingSeconds] = useState(30)

  useEffect(() => {
    if (remainingSeconds > 0) {
      const intervalId = setInterval(() => {
        setRemainingSeconds((prev) => prev - 1)
      }, 1000)

      return () => clearInterval(intervalId)
    } else {
      // Resend email logic
    }
  }, [remainingSeconds])

  return (
    <div className={'flex h-screen flex-col items-center justify-center'}>
      <img
        src="https://storage.googleapis.com/freight_static_files/envelop.png"
        alt={'logo'}
        width={90}
      />
      <span className={'mt-16 text-2xl font-semibold text-gray-700'}>
        Confirm your Identity
      </span>
      <span className={'text-sm text-gray-500'}>
        We need to verify your email to secure your account.
      </span>
      <span className={'my-10 text-center text-gray-800'}>
        We sent an email with a verification code to <br />
        <span className={'font-bold text-black'}>{email}</span>
      </span>
      {remainingSeconds > 0 ? (
        <span className={'my-10 text-center'}>
          If you didn’t receive the confirmation email. <br />
          You can resend the email after{' '}
          <span className="text-primary-600"> {remainingSeconds} </span>seconds
        </span>
      ) : (
        <button
          onClick={handleResendEmail}
          className="mb-5 font-semibold text-primary-600 underline"
        >
          Resend Email
        </button>
      )}
      <span>Did not get the code yet?</span>
      <ul className={'list-disc'}>
        <li className={'ml-16 text-left text-xs'}>
          Codes can take up to 5 minutes to arrive.
        </li>
        <li className={'ml-16 text-left text-xs'}>Check your spam folder.</li>
      </ul>
    </div>
  )

  function handleResendEmail() {
    // Resend email logic

    setRemainingSeconds(30)
    axios
      .post(`${BASE_URL}authentication/resend-email/`, {
        email: email,
      })
      .then((res) => {
        toast.success(res.data.message)
      })
      .catch((err) => {
        toast.error(err.response.data.message)
      })
  }
}
export default EmailVerification
