// import twoFactorAuth from '../../assets/images/2fav2.png'
import FButton from '../../components/FButton'
import { Link } from 'react-router-dom'

const TwoFactorAuth = () => {
  const proceedToAcount = () => {
    console.log('test to see if works')
  }
  return (
    <div className={'flex h-screen flex-col items-center justify-center p-5'}>
      <img
        src="https://storage.googleapis.com/freight_static_files/two-factor-auth.png"
        alt={'logo'}
        width={150}
      />
      <div className={'f-col-center gap-2'}>
        <span
          className={
            'my-2.5 mt-10  text-center text-2xl font-semibold leading-7 text-gray-700'
          }
        >
          {"Making sure you are secure - it's what we do."}
        </span>
        <span className={'my-2.5 block   text-center text-xs leading-4  text-gray-700'}>
          To apply an extra security layer to your account, We have enabled the two factor
          authentication
          <br />
          and you will be asked in each login to verify your identity through your email
        </span>
        <Link to={'/role-select'}>
          <FButton className={'my-2'} onClick={proceedToAcount}>
            <span className={'text-sm'}> Proceed to account</span>
          </FButton>
        </Link>
      </div>
    </div>
  )
}
export default TwoFactorAuth
