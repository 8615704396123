import React, { useState, useEffect, useContext } from 'react'
// import { RiQuestionnaireFill } from 'react-icons/ri'
import Switch from '@mui/material/Switch'
import { MdOutlineNotificationsActive, MdOutlineMail, MdOutlineSms } from 'react-icons/md'
// import { AiOutlineSound } from 'react-icons/ai'
import { AiOutlineNotification } from "react-icons/ai"

import FButton from '../../components/FButton'
import FIconWrapper from '../../components/FIconWrapper'
import FCard from '../../components/FCard'
import { axiosInstance } from '../../axios/requister'
import { UserContext } from '../../components/UserContext'
import { useCustomAxios } from '../../Hooks/useAxios'
import ESpinner from '../../components/ESpinner'
import { toast } from 'react-toastify'

export default function NotificationTab() {
  const { user } = useContext(UserContext)
  const [buttonLoading, setButtonLoading] = useState(false)

  const { response, loading } = useCustomAxios({
    url: `notifications/settings/?id=${user?.id}`,
    method: 'GET',
  })

  // intial state variables
  const [notificationEnabled, setNotificationEnabled] = useState(false)
  const [toastNotificationEnabled, setToastNotificationEnabled] = useState(false)
  const [emailNotificationEnabled, setEmailNotificationEnabled] = useState(false)
  const [smsNotificationEnabled, setSmsNotificationEnabled] = useState(false)
  const [addContactNotificationEnabled, setAddContactNotificationEnabled] =
    useState(false)
  const [addToLoadNotificationEnabled, setAddToLoadNotificationEnabled] = useState(false)
  const [gotOfferNotificationEnabled, setGotOfferNotificationEnabled] = useState(false)
  const [gotCounterOfferNotificationEnabled, setGotCounterOfferNotificationEnabled] =
    useState(false)

  const [
    addedAsShimpmentAdminNotificationEnabled,
    setAddedAsShimpmentAdminNotificationEnabled,
  ] = useState(false)
  const [loadStatusChangedNotificationEnabled, setLoadStatusChangedNotificationEnabled] =
    useState(false)
  const [approveRCsNotificationEnabled, setApproveRCsNotificationEnabled] =
    useState(false)

  // Use useEffect to update state variables when the response is available
  useEffect(() => {
    if (response) {
      setNotificationEnabled(response?.is_allowed)
      setToastNotificationEnabled(response?.is_allowed)
      setEmailNotificationEnabled(
        response?.methods === 'email' || response?.methods === 'both'
      )
      setSmsNotificationEnabled(
        response?.methods === 'sms' || response?.methods === 'both'
      )
      setAddContactNotificationEnabled(response?.add_as_contact || false)
      setAddToLoadNotificationEnabled(response?.add_to_load || false)
      setGotOfferNotificationEnabled(response?.got_offer || false)
      setGotCounterOfferNotificationEnabled(response?.offer_updated || false)
      setAddedAsShimpmentAdminNotificationEnabled(
        response?.add_as_shipment_admin || false
      )
      setLoadStatusChangedNotificationEnabled(response?.load_status_changed || false)
      setApproveRCsNotificationEnabled(response?.RC_approved || false)
    }
  }, [response])

  //this function is used to enable and disable notification settings and also disable all notification methods if notification is disabled
  const handleNotificationToggle = () => {
    setNotificationEnabled(!notificationEnabled)
    if (!notificationEnabled) {
      setEmailNotificationEnabled(false)
      setSmsNotificationEnabled(false)
      setAddContactNotificationEnabled(false)
      setAddToLoadNotificationEnabled(false)
      setGotOfferNotificationEnabled(false)
      setGotCounterOfferNotificationEnabled(false)
      setAddedAsShimpmentAdminNotificationEnabled(false)
      setLoadStatusChangedNotificationEnabled(false)
      setApproveRCsNotificationEnabled(false)
    }
  }
  const handleToastNotificationToggle = () => {
    setToastNotificationEnabled(!toastNotificationEnabled)
  }

  //these functions are used to enable and disable notification methods
  const handleEmailNotificationToggle = () => {
    setEmailNotificationEnabled(!emailNotificationEnabled)
  }

  const handleSmsNotificationToggle = () => {
    setSmsNotificationEnabled(!smsNotificationEnabled)
  }

  const handleAddContactNotificationToggle = () => {
    setAddContactNotificationEnabled(!addContactNotificationEnabled)
  }

  const handleAddToLoadNotificationToggle = () => {
    setAddToLoadNotificationEnabled(!addToLoadNotificationEnabled)
  }

  const handleGotOfferNotificationToggle = () => {
    setGotOfferNotificationEnabled(!gotOfferNotificationEnabled)
  }

  const handleGotCounterOfferNotificationToggle = () => {
    setGotCounterOfferNotificationEnabled(!gotCounterOfferNotificationEnabled)
  }

  const handleAddedAsShimpmentAdminNotificationToggle = () => {
    setAddedAsShimpmentAdminNotificationEnabled(!addedAsShimpmentAdminNotificationEnabled)
  }

  const handleLoadStatusChangedNotificationToggle = () => {
    setLoadStatusChangedNotificationEnabled(!loadStatusChangedNotificationEnabled)
  }

  const handleApproveRCsNotificationToggle = () => {
    setApproveRCsNotificationEnabled(!approveRCsNotificationEnabled)
  }

  //this useEffect is used to set notificationEnabled to false when emailNotificationEnabled and smsNotificationEnabled are set to false
  useEffect(() => {
    if (!emailNotificationEnabled && !smsNotificationEnabled) {
      setAddContactNotificationEnabled(false)
      setAddToLoadNotificationEnabled(false)
      setGotOfferNotificationEnabled(false)
      setGotCounterOfferNotificationEnabled(false)
      setAddedAsShimpmentAdminNotificationEnabled(false)
      setLoadStatusChangedNotificationEnabled(false)
      setApproveRCsNotificationEnabled(false)
    }
  }, [emailNotificationEnabled, smsNotificationEnabled])

  //put request to update notification settings
  const handleSubmit = () => {
    let methods = ''
    if (emailNotificationEnabled && smsNotificationEnabled) {
      methods = 'both'
    } else if (emailNotificationEnabled) {
      methods = 'email'
    } else if (smsNotificationEnabled) {
      methods = 'sms'
    } else {
      methods = 'none'
    }
    setButtonLoading(true)
    axiosInstance

      .put(`notifications/settings/${response?.id}/`, {
        is_allowed: notificationEnabled,
        methods,
        add_as_contact: addContactNotificationEnabled,
        add_to_load: addToLoadNotificationEnabled,
        got_offer: gotOfferNotificationEnabled,
        offer_updated: gotCounterOfferNotificationEnabled,
        add_as_shipment_admin: addedAsShimpmentAdminNotificationEnabled,
        load_status_changed: loadStatusChangedNotificationEnabled,
        RC_approved: approveRCsNotificationEnabled,
      })
      .then(() => {
        toast.success('Notification settings updated successfully')
        setButtonLoading(false)
      })
      .catch(() => {
        toast.error('Error updating notification settings')
        setButtonLoading(false)
      })
  }

  if (loading) {
    return (
      <div className={'f-col-center-center mt-20 h-full w-full '}>
        <ESpinner isVisible={true} size={30} />
        <span className="mt-5">Loading...</span>
      </div>
    )
  } else {
    return (
      <div className="mx-2 my-5 flex flex-col  p-4 sm:mx-8 sm:my-8 md:mx-20 md:my-5 ">
        <div className="flex flex-col items-center gap-4 sm:w-full sm:flex-row sm:justify-between">
          <span className=" text-xl font-bold text-gray-700">
            Manage your notification preferences.
            <br />{' '}
            <span className="mt-2 flex text-base font-normal text-gray-500">
              FreightSlayer website allows you to manage your notification preferences.
              You can choose to be notified by email, SMS, or both.
              <br /> You can also customize your notifications according to your
              preferences.
            </span>
          </span>
        </div>
        <hr className="my-5 w-full border-gray-300" />
        <div className=" mb-7 flex  justify-between    text-gray-700">
          <div className="my-auto flex gap-3">
            <MdOutlineNotificationsActive size={25} />
            <span className="text-lg font-semibold ">Allow Notifications</span>
          </div>
          <Switch
            checked={notificationEnabled}
            onChange={handleNotificationToggle}
            color="warning"
          />
        </div>
        <div className=" mb-7 flex  justify-between    text-gray-700">
          <div className="my-auto flex gap-3">
            <AiOutlineNotification size={25} />
            <span className="text-lg font-semibold ">Allow To Receive Toast Notifications</span>
          </div>
          <Switch
            checked={toastNotificationEnabled}
            onChange={handleToastNotificationToggle}
            color="warning"
          />
        </div>
        {/* if notification is enabled then show this */}
        {notificationEnabled && (
          <>
            {' '}
            <span className="text-xl font-bold text-gray-700">
              How You Get Notifications
            </span>
            <FCard className={'mt-3'}>
              <FCard.Body>
                <div>
                  <div className="flex items-center justify-between px-5 py-2 text-gray-700">
                    <div className="flex gap-3">
                      <MdOutlineMail size={25} />
                      <span>Receive Notifications via Email</span>
                    </div>
                    <Switch
                      checked={emailNotificationEnabled}
                      onChange={handleEmailNotificationToggle}
                      color="warning"
                    />
                  </div>

                  <hr />

                  <div className="flex items-center justify-between px-5 py-2 text-gray-700">
                    <div className="flex gap-3">
                      <MdOutlineSms size={25} />
                      <span>Receive Notifications via SMS</span>
                    </div>
                    <Switch
                      checked={smsNotificationEnabled}
                      onChange={handleSmsNotificationToggle}
                      color="warning"
                    />
                  </div>
                </div>
              </FCard.Body>
            </FCard>
          </>
        )}

        {/* if notification is enabled and email or sms notification is enabled then show this */}
        {(emailNotificationEnabled || smsNotificationEnabled) && notificationEnabled && (
          <>
            {' '}
            <span className=" mt-7 flex text-xl font-bold text-gray-700">
              What Notifications You Receive
            </span>
            <FCard className={'mt-3'}>
              <FCard.Body>
                <div>
                  <div className="flex items-center justify-between px-5 py-2 text-gray-700">
                    <div className="flex gap-3">
                      <span>Allow Notification When Added As Contact</span>
                    </div>
                    <Switch
                      checked={addContactNotificationEnabled}
                      onChange={handleAddContactNotificationToggle}
                      color="warning"
                    />
                  </div>
                  <hr />
                  <div className="flex items-center justify-between px-5 py-2 text-gray-700">
                    <div className="flex gap-3">
                      <span>Allow Notification When Added To Load</span>
                    </div>
                    <Switch
                      checked={addToLoadNotificationEnabled}
                      onChange={handleAddToLoadNotificationToggle}
                      color="warning"
                    />
                  </div>
                  <hr />
                  <div className="flex items-center justify-between px-5 py-2 text-gray-700">
                    <div className="flex gap-3">
                      <span>Allow Notification When Got Offer</span>
                    </div>
                    <Switch
                      checked={gotOfferNotificationEnabled}
                      onChange={handleGotOfferNotificationToggle}
                      color="warning"
                    />
                  </div>
                  <hr />
                  <div className="flex items-center justify-between px-5 py-2 text-gray-700">
                    <div className="flex gap-3">
                      <span>Allow Notification When Got Counter Offer</span>
                    </div>
                    <Switch
                      checked={gotCounterOfferNotificationEnabled}
                      onChange={handleGotCounterOfferNotificationToggle}
                      color="warning"
                    />
                  </div>
                  <hr />
                  <div className="flex items-center justify-between px-5 py-2 text-gray-700">
                    <div className="flex gap-3">
                      <span> Allow Notification When Added As Shipment Admin</span>
                    </div>
                    <Switch
                      checked={addedAsShimpmentAdminNotificationEnabled}
                      onChange={handleAddedAsShimpmentAdminNotificationToggle}
                      color="warning"
                    />
                  </div>
                  <hr />
                  <div className="flex items-center justify-between px-5 py-2 text-gray-700">
                    <div className="flex gap-3">
                      <span> Allow Notification When Load Status Changed</span>
                    </div>
                    <Switch
                      checked={loadStatusChangedNotificationEnabled}
                      onChange={handleLoadStatusChangedNotificationToggle}
                      color="warning"
                    />
                  </div>
                  <hr />
                  <div className="flex items-center justify-between px-5 py-2 text-gray-700">
                    <div className="flex gap-3">
                      <span> Allow Notification When RCs Gets Approved </span>
                    </div>
                    <Switch
                      checked={approveRCsNotificationEnabled}
                      onChange={handleApproveRCsNotificationToggle}
                      color="warning"
                    />
                  </div>
                </div>
              </FCard.Body>
            </FCard>
          </>
        )}

        <div className="my-5  flex  flex-row-reverse">
          <FButton
            className={' w-fit gap-10 '}
            onClick={() => {
              handleSubmit()
            }}
          >
            <FIconWrapper>
              <span className={'flex gap-2 text-sm font-medium'}>
                Save Chages <ESpinner isVisible={buttonLoading} />
              </span>
            </FIconWrapper>
          </FButton>
        </div>
      </div>
    )
  }
}
