import axios from 'axios'
import { BASE_URL } from '../env'
// eslint-disable-next-line camelcase
// import jwt_decode from 'jwt-decode'
// import daysjs from 'dayjs'
export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

axiosInstance.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line camelcase
    const access = localStorage.getItem('access')
    // eslint-disable-next-line camelcase
    if (access) {
      // eslint-disable-next-line camelcase
      config.headers['Authorization'] = `Bearer ${access}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      // Refresh the access token
      const refreshToken = localStorage.getItem('refresh')
      if (refreshToken) {
        try {
          const refreshResponse = await axios.post(
            `${BASE_URL}authentication/token/refresh/`,
            {
              refresh: refreshToken,
            }
          )

          // Update the access token in the headers
          axiosInstance.defaults.headers.common.Authorization = `Bearer ${refreshResponse.data.access}`

          // Updating the access token in local storage
          localStorage.setItem('access', refreshResponse.data.access)

          // Re-send the original request
          return axiosInstance(originalRequest)
        } catch (error) {
          localStorage.removeItem('access')
          localStorage.removeItem('refresh')
          localStorage.clear() //for localStorage
          sessionStorage.clear() //for sessionStorage
          window.location.replace('/login')
        }
      }
    }
    return Promise.reject(error)
  }
)
