import React, { useContext, useEffect } from 'react'
import FIconWrapper from '../../components/FIconWrapper'
import { GoChevronLeft } from 'react-icons/go'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import FButton from '../../components/FButton'
import { axiosInstance } from '../../axios/requister'
import { toast } from 'react-toastify'
import ESpinner from '../../components/ESpinner'
import PersonalInfoCard from './PersonalInfoCard'
import CompanyInfoCard from './CompanyInfoCard'
// import InsuranceCard from '../Insurance/InsuranceCard'
import { UserContext } from '../../components/UserContext'

export default function CreateCompany() {
  const { user } = useContext(UserContext)
  const [draftResponse, setDraftResponse] = React.useState([{}])
  const [isDraftFound, setIsDraftFound] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [draftLoading, setDraftLoading] = React.useState(false)
  const navigate = useNavigate()
  const [errors, setErrors] = React.useState([])
  const phoneRegExp = /^(\+?(1|52)[- ]?)?(\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$/
  const einRegex = /^\d{2}-\d{7}$/

  const validationSchema = Yup.object().shape({
    sid_photo: Yup.mixed().required('SID photo is required'),
    personal_photo: Yup.mixed().required('Personal photo is required'),
    company_name: Yup.string().required('Company name is required'),
    company_domain: Yup.string().required('Company domain is required'),
    company_size: Yup.string().required('Company size is required'),
    EIN: Yup.string()
      .required('EIN is required')
      .matches(
        einRegex,
        'EIN number must be 9 numeric digits in this format: xx-xxxxxxx'
      ),
    company_phone_number: Yup.string()
      .required('Company phone number is required')
      .matches(phoneRegExp, 'Phone number is not valid'),
    address: Yup.string().required('Company address is required'),
    scac: Yup.string()
      .required('SCAC is required')
      // scasc length shoud be 4 characters and contain only upeercase letters
      .matches(/^[A-Z]{2,4}$/, 'SCAC must be 4 uppercase letters'),

    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    country: Yup.string().required('Country is required'),
    zip_code: Yup.string().required('Zip code is required'),
    // insurance_provider: Yup.string().required('Insurance provider is required'),
    // insurance_policy_number: Yup.string()
    //   .required('Policy number is required')
    //   .matches(
    //     /^[A-Za-z0-9-]{8,}$/,
    //     'IPN should be at least 8 characters long and can include letters, digits, and hyphens.'
    //   ),
    // insurance_type: Yup.string().required('Insurance type is required'),

    // insurance_premium_amount: Yup.number()
    //   .typeError('Premium amount is required and must be a number')
    //   .required('Premium amount is required')
    //   .positive('Premium amount must be a positive number'),
    // insurance_expiration_date: Yup.date()
    //   .min(new Date(), 'Expiration date must be in the future')
    //   .required('Expiration date is required'),
  })

  const Formik = useFormik({
    initialValues: {
      sid_photo: '',
      personal_photo: '',
      company_name: '',
      company_domain: '',
      company_size: '',
      EIN: '',
      company_phone_number: '',
      company_fax_number: '',
      address: '',
      scac: '',
      city: '',
      state: '',
      country: '',
      zip_code: '',
      coords: undefined,
      // insurance_provider: '',
      // insurance_policy_number: '',
      // insurance_type: '',
      // insurance_premium_amount: '',
      // insurance_expiration_date: '',
    },

    validationSchema: validationSchema,
    onSubmit: () => {
      setLoading(true)
      axiosInstance
        .post(
          'support/create-ticket/',
          {
            ...Formik.values,
            sid_photo: Formik.values.sid_photo[0],
            personal_photo: Formik.values.personal_photo[0],
          },

          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        .then(() => {
          setLoading(false)
          toast.success('Ticket created successfully')
          navigate('/company-profile')
        })
        .catch((error) => {
          setLoading(false)
          toast.error('Ticket creation failed')
          if (error?.response?.status === 500) {
            setErrors(['Internal server error'])
          } else {
            setErrors(Object.values(error?.response?.data || {}))
            window.scrollTo(0, 0)
          }
        })
    },
  })

  useEffect(() => {
    // TEMP
    if (user.selected_role !== 'manager') navigate('/')
  }, [user])

  useEffect(() => {
    axiosInstance
      .get('/support/create-ticket/draft/')
      .then((response) => {
        setDraftResponse(response.data)
        setIsDraftFound(true)

        // Check if response.data.sid_photo is null or not
        const sidPhoto = response.data.sid_photo
          ? [
              {
                name: response.data.sid_photo.name,
                url: response.data.sid_photo.url,
              },
            ]
          : []

        // Check if response.data.personal_photo is null or not
        const personalPhoto = response.data.personal_photo
          ? [
              {
                name: response.data.personal_photo.name,
                url: response.data.personal_photo.url,
              },
            ]
          : []

        Formik.setValues({
          ...response.data,
          sid_photo: sidPhoto,
          personal_photo: personalPhoto,
        })
      })
      .catch(() => {
        setIsDraftFound(false)
      })
  }, []) // Run once on component mount

  const createTicketDraft = () => {
    setDraftLoading(true)
    // Check if sid_photo and personal_photo are not null before accessing index 0
    const sidPhoto = Formik.values.sid_photo?.[0]
    const personalPhoto = Formik.values.personal_photo?.[0]

    axiosInstance
      .post(
        'support/create-ticket/draft/',
        {
          ...Formik.values,
          sid_photo: sidPhoto,
          personal_photo: personalPhoto,
        },

        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then(() => {
        setDraftLoading(false)
        navigate('/company-profile')
        toast.success('Ticket draft created successfully')
      })
      .catch(() => {
        setDraftLoading(false)
        toast.error('Ticket draft creation failed')
      })
  }
  // Function to update ticket draft
  const updateTicketDraft = () => {
    setDraftLoading(true)
    axiosInstance
      .patch(
        `support/create-ticket/draft/${Formik.values.id}/ `,
        {
          ...Formik.values,
          sid_photo: Formik.values.sid_photo[0],
          personal_photo: Formik.values.personal_photo[0],
        },

        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then(() => {
        setDraftLoading(false)
        navigate('/company-profile')
        toast.success('Ticket draft updated successfully')
      })
      .catch(() => {
        setDraftLoading(false)
        toast.error('Ticket draft update failed')
      })
  }

  const DraftButtonTitle = isDraftFound ? 'Update Draft' : 'Save As Draft'

  // Function to handle input change
  const handleInputChange = (event) => {
    const { name, value } = event.target
    Formik.setFieldValue(name, value)
  }

  return (
    <div>
      <div
        className={
          'primary-shadow sticky top-0 block w-full border-b bg-white p-3 ' +
          'z-10 text-left text-xl font-semibold text-gray-700'
        }
      >
        <FIconWrapper>
          <Link
            to={'/company-profile'}
            className={'hove:border rounded p-0.5 hover:bg-gray-100'}
          >
            <GoChevronLeft size={25} />
          </Link>
          <span className={''}>Create Your Company</span>
        </FIconWrapper>
      </div>

      <form onSubmit={Formik.handleSubmit}>
        <div className={'mx-auto flex flex-col gap-5 p-5 lg:w-8/12 lg:p-10 '}>
          {errors.length !== 0 &&
            errors.map((error) => (
              <div
                key={error}
                className={
                  'f-col-center-center  mb-5  flex flex-col items-start border border-red-500 bg-red-50 p-3'
                }
              >
                <span className={'block text-left text-sm text-red-600'}>{error}</span>
              </div>
            ))}
          {/* personal information card here */}
          <PersonalInfoCard Formik={Formik} />
          {/* company information card here */}
          <CompanyInfoCard Formik={Formik} handleInputChange={handleInputChange} />
          {/* insurance information card here */}
          {/* <InsuranceCard Formik={Formik} handleInputChange={handleInputChange} /> */}

          <div className={'mt-2 flex  justify-between gap-2'}>
            <FButton
              btnType={'secondary'}
              onClick={isDraftFound ? updateTicketDraft : createTicketDraft}
            >
              <span className="flex gap-2">
                {' '}
                {DraftButtonTitle}{' '}
                {draftLoading && <ESpinner isVisible={true} size={18} />}
              </span>
            </FButton>
            {isDraftFound && (
              <span className="text-sm text-gray-500">
                Draft last updated: {new Date(draftResponse.created_at).toDateString()}
              </span>
            )}

            <FButton btnType={'primary'} type={'submit'}>
              <span className="flex gap-2">
                Create Company {loading && <ESpinner isVisible={true} size={18} />}
              </span>
            </FButton>
          </div>
        </div>
      </form>
    </div>
  )
}
