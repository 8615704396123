import { useState, useEffect } from 'react'
import { axiosInstance } from '../axios/requister'

const useLocationData = ({ selectedCountry, selectedState }) => {
  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axiosInstance.get('/countries/')
        setCountries(response.data)
      } catch (error) {
        console.error('Error fetching countries:', error)
      }
    }
    fetchCountries()
  }, [])

  useEffect(() => {
    const fetchStates = async () => {
      if (selectedCountry && countries.length) {
        try {
          const iso2 = countries.find(
            (country) => country.country === selectedCountry
          )?.iso2
          if (iso2) {
            const response = await axiosInstance.get(`/countries/${iso2}/states/`)
            setStates(response.data)
          }
        } catch (error) {
          console.error('Error fetching states:', error)
        }
      }
    }
    fetchStates()
  }, [selectedCountry, countries])

  useEffect(() => {
    const fetchCities = async () => {
      if (selectedCountry && selectedState && states.length) {
        try {
          const countryIso2 = countries.find(
            (country) => country.country === selectedCountry
          )?.iso2
          const stateIso2 = states.find((state) => state.name === selectedState)?.iso2
          if (countryIso2 && stateIso2) {
            const response = await axiosInstance.get(
              `/countries/${countryIso2}/states/${stateIso2}/cities/`
            )
            setCities(response.data)
          }
        } catch (error) {
          console.error('Error fetching cities:', error)
        }
      }
    }
    fetchCities()
  }, [selectedState, states, countries])

  return {
    countries,
    states,
    cities,
    selectedCountry,
    selectedState,
  }
}

export default useLocationData
