import React from 'react'
import { MdOutlineEdit } from 'react-icons/md'
import FCard from '../../../components/FCard'

const BillingInfo = ({
    taxInfo, billing, setOpenModal
}) => {
    function formatPhoneNumber(phoneNumber) {
        if (!phoneNumber) return '' // Return empty string if phoneNumber is undefined or null
    
        return phoneNumber.replace(/^(\+\d{1})(\d{3})(\d{3})(\d{4})$/, '($2) $3-$4')
      }
    return (
        <FCard className="relative my-3 flex h-auto w-full flex-col gap-5 bg-white p-5">
     
        <div className="flex flex-col gap-4">
          {/* Display billing details */}
          <div>
            <span className="font-semibold text-gray-700">
              Name to Print on Checks:{' '}
            </span>
            <span className="text-base font-normal text-gray-500">
              {billing[0].name_to_print_on_checks || 'N/A'}
            </span>
          </div>
          <div>
            <span className="font-semibold text-gray-700">Email: </span>
            <span className="text-base font-normal text-gray-500">
              {billing[0].email || 'N/A'}
            </span>
          </div>
          <div>
            <span className="font-semibold text-gray-700">Phone Number: </span>
            <span className="text-base font-normal text-gray-500">
              {formatPhoneNumber(billing[0].phone_number) || 'N/A'}
            </span>
          </div>
          <div>
            <span className="font-semibold text-gray-700">Address: </span>
            <span className="text-base font-normal text-gray-500">
              {billing[0].address || 'N/A'}
            </span>
          </div>
          <div>
            <span className="font-semibold text-gray-700">City: </span>
            <span className="text-base font-normal text-gray-500">
              {billing[0].city || 'N/A'}
            </span>
          </div>
          <div>
            <span className="font-semibold text-gray-700">State: </span>
            <span className="text-base font-normal text-gray-500">
              {billing[0].state || 'N/A'}
            </span>
          </div>
          <div>
            <span className="font-semibold text-gray-700">Zip Code: </span>
            <span className="text-base font-normal text-gray-500">
              {billing[0].zip_code || 'N/A'}
            </span>
          </div>
          <div>
            <span className="font-semibold text-gray-700">Country: </span>
            <span className="text-base font-normal text-gray-500">
              {billing[0].country || 'N/A'}
            </span>
          </div>
          <div>
            <span className="font-semibold">Fax Number: </span>
            <span className="text-base font-normal text-gray-500">
              {billing[0].fax_number || 'N/A'}
            </span>
          </div>
          <div>
            <span className="font-semibold text-gray-700">Website: </span>
            <span className="text-base font-normal text-gray-500">
              {billing[0].website || 'No website'}
            </span>
          </div>
          {taxInfo?.data?.type === 'individual' && (
          <div
            className="flex cursor-pointer underline text-sm font-semibold text-primary-500 hover:text-primary-600"
            size={20}
            onClick={() => setOpenModal(true)}
          > 
          <MdOutlineEdit  size={20} />
           Edit Billing Information
            </div>
            
        )}
        </div>
      </FCard>
    )
}

export default BillingInfo