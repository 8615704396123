import React, { useRef, useState } from 'react'
import { AiOutlineUsergroupAdd } from 'react-icons/ai'
import { VscCopy } from 'react-icons/vsc'
import FButton from '../../components/FButton'
import FInputField from '../../components/FInputField'
import { axiosInstance } from '../../axios/requister'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import FFormInvalidInput from '../../components/FFormInvalidInput'
import FModal from '../../components/FModal'
import ESpinner from '../../components/ESpinner'
import FFormWrapper from '../../components/FFormWrapper'
import FLabel from '../../components/FLabel'

export default function InviteContactModal({
  isInviteModalOpen,
  setIsInviteModalOpen,
  RefetchTable,
}) {
  const spanRef = useRef(null)
  const [inviteError, setInviteError] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const handleCopyLink = () => {
    if (spanRef.current) {
      const text = spanRef.current.innerText
      navigator.clipboard
        .writeText(text)
        .then(() => {
          console.log('Link copied to clipboard:', text)
        })
        .catch((error) => {
          console.error('Failed to copy link:', error)
        })
    }
  }

  const Formik = useFormik({
    initialValues: {
      email: '',
      message: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Email is required'),
    }),
    onSubmit: (values) => {
      setInviteError(null)
      setIsLoaded(true)
      axiosInstance
        .post('invites/send-invite/', {
          invitee: values.email,
          message: values.message,
        })
        .then(() => {
          setIsInviteModalOpen(false)
          toast.success('Invitation sent successfully')
          setIsLoaded(false)
          RefetchTable()
          Formik.resetForm()
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 409) {
              setInviteError('user already exists, try adding them as a contact.')
            } else {
              toast.error('Something went wrong')
              setInviteError('Something went wrong')
            }
          }
          setIsLoaded(false)
        })
    },
  })

  return (
    <FModal
      callbackFunction={() => {
        Formik.resetForm()
      }}
      title={'Invite Contact'}
      isOpen={isInviteModalOpen}
      setIsOpen={() => {
        setIsInviteModalOpen(false)
        setInviteError(null)
      }}
    >
      <div className="mt-3 flex flex-col items-center justify-center text-gray-800">
        <div className="mb-5">
          <AiOutlineUsergroupAdd size={50} />
        </div>
        <h1 className="mb-2 text-center text-xl font-bold">
          Invite New Contact, Expand Your Network!
        </h1>

        <p className="text-center text-base">
          we believe in the power of connections and the mutual benefits they can bring.
        </p>
        <form onSubmit={Formik.handleSubmit} className="w-full">
          <FFormWrapper>
            <FLabel>Email</FLabel>
            <FInputField
              type="email"
              name="email"
              placeholder="Enter the email address you want to invite"
              onChange={(e) => {
                Formik.handleChange(e)
                setInviteError(null)
              }} // Added handleChange event handler
              value={Formik.values.email} // Added value prop here
              // onBlur={Formik.handleBlur} // Added onBlur event handler
            />

            <FFormInvalidInput
              touched={Formik.touched}
              FieldName={'email'}
              errors={Formik.errors}
            />
          </FFormWrapper>
          {inviteError && <small className="text-red-700">{inviteError}</small>}

          <FFormWrapper>
            <FLabel>
              {' '}
              Message to invitee <span className="text-gray-400">(optional)</span>
            </FLabel>
            <textarea
              id="message"
              name="message"
              value= {Formik.values.message}
              onChange={Formik.handleChange}
              // placeholder="Write a message to your contact "
              className="input-style"
            />
          </FFormWrapper>
          <div
            //center the button
            className="mt-5 flex justify-center"
          >
            {' '}
            <FButton
              type={'submit'}
              className={'flex gap-1 whitespace-nowrap'}
              style={{ height: '40px' }}
            >
              Send Invitation
              <ESpinner isVisible={isLoaded} />
            </FButton>
          </div>
        </form>
        <p className="my-2 font-semibold">Or</p>
        <div className=" flex flex-col items-center gap-2 lg:flex-row">
          <p className="text-lg font-semibold">
            Share the website link:{' '}
            <a href="https://dev.freightslayer.com" className="underline" ref={spanRef}>
              dev.freightslayer.com
            </a>
          </p>
          <VscCopy
            size={18}
            onClick={handleCopyLink}
            className="cursor-pointer hover:text-primary-500"
          />
        </div>
      </div>
    </FModal>
  )
}
