import React from 'react'
import { ImTruck } from 'react-icons/im'
import { MdTrendingUp } from 'react-icons/md'

const LoadsCardsComponent = ({ title, bgColor, iconColor, value, trendIconColor }) => {
  return (
    <div>
      <div className={`  bg-white p-5`}>
        <div className="flex flex-col ">
          <div className="flex justify-between gap-5">
            <span
              className={`my-auto flex flex-col truncate text-lg font-medium tracking-wide text-gray-900`}
            >
              {title}
              <div className="text-xs font-normal text-gray-400">Recently updated</div>
            </span>
            <div className={`rounded-full p-2 bg-${bgColor}`}>
              <ImTruck className={`text-${iconColor}`} size={25} />
            </div>
          </div>
          <hr className="my-3" />
          <div className="flex">
            <span className="text-3xl font-semibold">{value}</span>
            <MdTrendingUp className={`my-auto ml-7 text-${trendIconColor}`} size={30} />
            <span className="my-auto ml-1 font-semibold text-gray-400">Loads</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoadsCardsComponent
