import {FaEye, FaEyeSlash} from 'react-icons/fa'

export const PasswordIcon = ({isVisible, showPassword}) => {
  return !isVisible ? (
    <FaEye
      className="absolute right-3 cursor-pointer text-gray-400 hover:text-gray-500"
      style={{top: '10px'}}
      onClick={() => showPassword()}
    />
  ) : (
    <FaEyeSlash
      className="absolute right-3 cursor-pointer text-gray-400 hover:text-gray-500"
      style={{top: '10px'}}
      onClick={() => showPassword()}
    />
  )
}
