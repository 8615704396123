import { useState } from 'react'
import FCard from '../../components/FCard'
import FFormWrapper from '../../components/FFormWrapper'
import FLabel from '../../components/FLabel'
import FFormInvalidInput from '../../components/FFormInvalidInput'
import FInputField from '../../components/FInputField'
import { MenuItem, Select, Tooltip } from '@mui/material'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import useLocationData from '../../components/useLocationData'
import FModal from '../../components/FModal'
import GoogleMapsWrapper from '../../components/GoogleMapsWrapper'
import FButton from '../../components/FButton'
import { MdOutlineAddLocationAlt } from 'react-icons/md'
import CompanyMap from './CompanyMap'

export default function CompanyInfoCard({ Formik, handleInputChange }) {
  const [openMapModal, setOpenMapModal] = useState(false)
  const { countries, states, cities } = useLocationData({
    selectedCountry: Formik.values.country,
    selectedState: Formik.values.state,
  })
  return (
    <>
      <FModal
        isOpen={openMapModal}
        setIsOpen={setOpenMapModal}
        title={'Search for new location on map'}
      >
        <GoogleMapsWrapper>
          <CompanyMap Formik={Formik} />
          <div
            className={'mt-2 flex flex-row-reverse '}
            onClick={() => setOpenMapModal(false)}
          >
            <FButton>Ok</FButton>
          </div>
        </GoogleMapsWrapper>
      </FModal>
      <FCard>
        <FCard.Header>
          <span>Company Information</span>
        </FCard.Header>
        <FCard.Body>
          <div>
            <FFormWrapper>
              <FLabel htmlFor={'company_name'}>Company Name </FLabel>
              <FInputField
                id={'company_name'}
                name="company_name"
                placeholder="Enter your company name"
                type="text"
                value={Formik.values.company_name}
                onChange={handleInputChange}
                onBlur={Formik.handleBlur}
              />
              <FFormInvalidInput
                touched={Formik.touched}
                FieldName="company_name"
                errors={Formik.errors}
              />
            </FFormWrapper>
            <div className="flex justify-between gap-3">
              <FFormWrapper className="flex-1">
                <FLabel htmlFor={'company-domain'}>Company domain</FLabel>
                <FInputField
                  id={'company-domain'}
                  name="company_domain"
                  placeholder="Enter your company domain"
                  type="text"
                  onChange={handleInputChange}
                  value={Formik.values.company_domain}
                  onBlur={Formik.handleBlur}
                />
                <FFormInvalidInput
                  touched={Formik.touched}
                  FieldName="company_domain"
                  errors={Formik.errors}
                />
              </FFormWrapper>

              <FFormWrapper className="flex-1">
                <FLabel htmlFor={'company-size'}>Company size</FLabel>

                <Select
                  id={'company-size'}
                  name="company_size"
                  placeholder="Enter your company size"
                  type="select"
                  onChange={handleInputChange}
                  className="h-9 w-full rounded-sm   border border-gray-300 py-2 px-2 text-sm text-gray-800 focus:border-gray-400 focus:outline-none focus:ring-1"
                  labelId="demo-select-small-label"
                  value={Formik.values.company_size}
                  onBlur={Formik.handleBlur}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="1-10">1-10</MenuItem>
                  <MenuItem value="11-50">11-50</MenuItem>
                  <MenuItem value="51-100">51-100</MenuItem>
                  <MenuItem value=">100"> more than 100</MenuItem>
                </Select>
                <FFormInvalidInput
                  touched={Formik.touched}
                  FieldName="company_size"
                  errors={Formik.errors}
                />
              </FFormWrapper>
            </div>
            <FFormWrapper className="flex-1">
              <FLabel htmlFor={'EIN'}>Employer Identification Number</FLabel>
              <FInputField
                id={'EIN'}
                name="EIN"
                placeholder="Enter your EIN"
                type="text"
                onChange={handleInputChange}
                value={Formik.values.EIN}
                onBlur={Formik.handleBlur}
              />
              <FFormInvalidInput
                touched={Formik.touched}
                FieldName="EIN"
                errors={Formik.errors}
              />
            </FFormWrapper>
            <FFormWrapper>
              <FLabel htmlFor={'scac'}>SCAC</FLabel>
              <FInputField
                id={'scac'}
                name="scac"
                placeholder="Enter your SCAC"
                type="text"
                onChange={handleInputChange}
                value={Formik.values.scac}
                onBlur={Formik.handleBlur}
              />
              <FFormInvalidInput
                touched={Formik.touched}
                FieldName="scac"
                errors={Formik.errors}
              />
            </FFormWrapper>

            <div className="justify-between gap-3 lg:flex">
              <FFormWrapper className="lg:w-1/2">
                <FLabel htmlFor={'company_fax_number'}>
                  Company Fax Number{` `}
                  <span className="text-sm text-gray-400">(Optional)</span>
                </FLabel>
                <FInputField
                  id={'company_fax_number'}
                  name="company_fax_number"
                  placeholder="Enter your company fax number"
                  type="text"
                  onChange={handleInputChange}
                  value={Formik.values.company_fax_number}
                  onBlur={Formik.handleBlur}
                />
              </FFormWrapper>
              <div className="lg:w-6/12">
                <FFormWrapper>
                  <FLabel htmlFor={'company_phone_number'}>Company Phone number</FLabel>

                  <PhoneInput
                    name="company_phone_number"
                    id={'company_phone_number'}
                    className={'input-phone-number '}
                    placeholder="Enter company phone number"
                    defaultCountry="US"
                    countries={['US', 'MX', 'CA']}
                    value={Formik.values.company_phone_number}
                    onChange={(value) => {
                      Formik.setFieldValue('company_phone_number', value)
                    }}
                    onBlur={Formik.handleBlur}
                  />
                  <FFormInvalidInput
                    touched={Formik.touched}
                    FieldName="company_phone_number"
                    errors={Formik.errors}
                  />
                </FFormWrapper>
              </div>
            </div>

            <FFormWrapper>
              <FLabel htmlFor={'address'}>Company Address</FLabel>
              <div className="flex items-center gap-3">
                <FInputField
                  id={'address'}
                  name="address"
                  placeholder="Enter your company address"
                  type="text"
                  onChange={handleInputChange}
                  onBlur={Formik.handleBlur}
                  value={Formik.values.address}
                />
                <Tooltip title="Search for new location on map" arrow placement="top">
                  <div
                    className={
                      'my-auto flex cursor-pointer rounded-sm border border-gray-300 bg-gray-100 p-1 transition-all duration-300 ease-in-out hover:bg-gray-200'
                    }
                    onClick={() => {
                      setOpenMapModal(true)
                    }}
                  >
                    <MdOutlineAddLocationAlt
                      size={28}
                      className=" text-2xl text-primary-500"
                    />
                  </div>
                </Tooltip>
              </div>
              <FFormInvalidInput
                touched={Formik.touched}
                FieldName="address"
                errors={Formik.errors}
              />
            </FFormWrapper>
            <div className="justify-between gap-3 lg:flex">
              <FFormWrapper className="lg:w-1/2">
                <FLabel htmlFor={'country'}>Company Country</FLabel>
                <Select
                  className="input-style h-10"
                  id={'country'}
                  name={'country'}
                  value={Formik.values.country}
                  onChange={(e) => {
                    Formik.setFieldValue('country', e.target.value)
                    Formik.setFieldValue('state', '')
                    Formik.setFieldValue('city', '')
                  }}
                  onBlur={Formik.handleBlur}
                >
                  {countries?.map((country) => (
                    <MenuItem key={country.id} value={country.country}>
                      {country.country}
                    </MenuItem>
                  ))}
                </Select>
                <FFormInvalidInput
                  touched={Formik.touched}
                  FieldName={'country'}
                  errors={Formik.errors}
                />
              </FFormWrapper>
              <FFormWrapper className="lg:w-1/2">
                <FLabel htmlFor={'state'}>Company State</FLabel>
                <Select
                  className="input-style h-10"
                  id="state"
                  name="state"
                  value={Formik.values.state} // Use Formik to manage the state value
                  onChange={(e) => {
                    Formik.setFieldValue('state', e.target.value)
                    Formik.setFieldValue('city', '')
                    // Update Formik's state on change
                  }}
                  onBlur={Formik.handleBlur}
                >
                  {states.map((state) => (
                    <MenuItem key={state.id} value={state.name}>
                      {state.name}
                    </MenuItem>
                  ))}
                </Select>
                <FFormInvalidInput
                  touched={Formik.touched}
                  FieldName="state"
                  errors={Formik.errors}
                />
              </FFormWrapper>
            </div>
            <div className="justify-between gap-3 lg:flex">
              <FFormWrapper className="lg:w-1/2">
                <FLabel htmlFor={'city'}>Company City</FLabel>
                <Select
                  className="input-style h-10"
                  id="city"
                  name="city"
                  value={Formik.values.city} // Use Formik to manage the city value
                  onChange={(e) => {
                    Formik.setFieldValue('city', e.target.value) // Update Formik's state on change
                  }}
                  onBlur={Formik.handleBlur} // Use Formik's onBlur handler
                >
                  {cities.map((city) => (
                    <MenuItem key={city.id} value={city.name}>
                      {city.name}
                    </MenuItem>
                  ))}
                </Select>
                <FFormInvalidInput
                  touched={Formik.touched}
                  FieldName="city"
                  errors={Formik.errors}
                />
              </FFormWrapper>
              <FFormWrapper className="lg:w-1/2">
                <FLabel htmlFor={'zip_code'}>Company Zip Code</FLabel>
                <FInputField
                  id={'zip_code'}
                  name="zip_code"
                  placeholder="Enter your zip code"
                  type="text"
                  onChange={handleInputChange}
                  value={Formik.values.zip_code}
                  onBlur={Formik.handleBlur}
                />
                <FFormInvalidInput
                  touched={Formik.touched}
                  FieldName="zip_code"
                  errors={Formik.errors}
                />
              </FFormWrapper>
            </div>
          </div>
        </FCard.Body>
      </FCard>
    </>
  )
}
