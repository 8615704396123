import FModal from '../../components/FModal'
import { axiosInstance } from '../../axios/requister'
import { useState, useEffect } from 'react'
import TaxInfoForm from './TaxInfoForm'
import CarrierWithTax from './CarrierWithTax'
import { BASE_URL } from '../../env'

export default function CarrierRoleModal({
  isCarrierRoleModalOpen,
  setIsCarrierRoleModalOpen,
  handleRefreshUser,
}) {
  const [taxInfo, setTaxInfo] = useState({})
  useEffect(() => {
    axiosInstance
      .get(`${BASE_URL}authentication/tax-info/ `)
      .then((res) => {
        setTaxInfo(res)
      })
      .catch(() => {})
  }, [])

  return (
    <FModal
      callbackFunction={() => {}}
      title={'Add Carrier Role'}
      isOpen={isCarrierRoleModalOpen}
      setIsOpen={() => {
        setIsCarrierRoleModalOpen(false)
      }}
    >
      <div>
        {taxInfo.data !== undefined ? (
          <CarrierWithTax
            setIsCarrierRoleModalOpen={setIsCarrierRoleModalOpen}
            handleRefreshUser={handleRefreshUser}
          />
        ) : (
          <TaxInfoForm
            isCarrierRoleModalOpen={isCarrierRoleModalOpen}
            setIsCarrierRoleModalOpen={setIsCarrierRoleModalOpen}
            handleRefreshUser={handleRefreshUser}
          />
        )}
      </div>
    </FModal>
  )
}
